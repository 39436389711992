import React from 'react';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Dropdown from '../../../../CommonComponents/Form/Dropdown';
import { languages } from '../../../constants';
import ProductsList from '../../../../CommonComponents/ProductsList';
import { getErrorFromGraphqlError } from '../../../../../util/errors';
import {
  LanguagePreferences,
  Product,
  useCreateOrgMutation,
} from '../../../../../__generated__/graphql';
import { createOrgEntsPayload } from '../utils/converter';

const useStyles = makeStyles({
  container: {
    marginTop: '30px',
  },
});

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type NewOrganizationModalState = {
  org: {
    name: string;
    adminNotes: string;
    language: LanguagePreferences;
    products?: Array<ProductItemProps>;
  };
};

const initialState = {
  org: {
    name: '',
    adminNotes: '',
    language: LanguagePreferences.En,
  },
};

const NewOrganization = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = React.useState<NewOrganizationModalState>(
    JSON.parse(JSON.stringify(initialState)),
  );
  const [displayError, setDisplayError] = React.useState(null);
  const [displayAdminNotes, setDisplayAdminNotes] = React.useState(false);
  const [createOrgMutation] = useCreateOrgMutation();

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setData({ ...data, org: { ...data.org, [name]: value } });
  };

  const onDropdownLanguageChange = (e: { name: string; value: string }) => {
    const { name, value } = e;
    setData({ ...data, org: { ...data.org, [name]: value } });
  };

  const onProductsChange = (value: Array<ProductItemProps>) => {
    setData({ ...data, org: { ...data.org, products: value } });
  };

  const goBack = () => {
    navigate(-1);
  };

  const onSubmit = () => {
    if (!data.org.name) {
      setDisplayError('Name is required');
      return;
    }
    // const products = new Map<Product, string[]>();
    // data.org.products.forEach((p) => {
    //   if (p.regions.length) {
    //     products.set(p.product, p.regions);
    //   }
    // });
    const { profiles, ents } = createOrgEntsPayload(data.org.products);
    if (!profiles.length) {
      setDisplayError('At least one product needs to be selected');
      return;
    }
    // const profiles: Array<{ product: Product; zones: string[] }> = [];
    // products.forEach((v, k) => {
    //   profiles.push({ product: k, zones: v });
    // });
    const payload = {
      name: data.org.name,
      adminNotes: data.org.adminNotes,
      // profiles,
      entitlements: ents,
      preferences: { defaultLanguage: data.org.language },
    };
    createOrgMutation({ variables: { org: payload } })
      .then(() => {
        navigate('/orgs');
      })
      .catch((e) => {
        console.log('newOrgError', e.graphQLErrors[0]);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError(convertedError);
      });
  };

  const showAdminNotes = () => {
    const displayNotes = !displayAdminNotes;
    if (!displayNotes) {
      setData({ ...data, org: { ...data.org, adminNotes: '' } });
    }
    setDisplayAdminNotes(displayNotes);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px' }}>
        <Box sx={{ width: '100%' }}>
          <Typography
            data-test-id="new-organization-modal-title"
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            New Organization
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={6}>
              <TextField
                id="name"
                label="Name"
                name="name"
                fullWidth
                value={data.org.name}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: data.org.name ? null : '#D0D0D0' },
                }}
                inputProps={{
                  autoComplete: 'off',
                  'data-test-id': 'OrganizationName-Input',
                }}
                sx={{ mt: 1, ml: 1 }}
              />
            </Grid>

            <Grid item xs={6}>
              <Dropdown
                fullWidth
                selectedValue={data.org.language}
                onChange={onDropdownLanguageChange}
                id="language"
                name="language"
                label="Language"
                list={languages}
                data-test-id="OrganizationLanguage-Select"
              />
            </Grid>
          </Grid>

          <Stack
            style={{ width: '100%' }}
            marginTop="10px"
            direction="row"
            justifyContent="flex-end"
          >
            <Button
              size="small"
              onClick={showAdminNotes}
              data-test-id={displayAdminNotes ? 'HideAdminNotes-Btn' : 'ShowAdminNotes-Btn'}
            >
              {displayAdminNotes ? '- Admin Notes' : '+ Admin Notes'}
            </Button>
          </Stack>

          {displayAdminNotes ? (
            <Box sx={{ width: '100%' }}>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                  <TextField
                    style={{ marginLeft: '10px' }}
                    label="Admin Notes"
                    value={data.org.adminNotes}
                    id="adminNotes"
                    name="adminNotes"
                    onChange={(e) =>
                      setData({ ...data, org: { ...data.org, adminNotes: e.target.value } })
                    }
                    multiline
                    rows={4}
                    maxRows={20}
                    fullWidth
                    InputLabelProps={{
                      style: { color: data.org.adminNotes ? null : '#D0D0D0' },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      'data-test-id': 'AdminNotes-Input',
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <ProductsList value={data.org.products} onChange={onProductsChange} />
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={goBack} data-test-id="Cancel-Btn">
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit} data-test-id="CreateOrganization-Btn">
              Create
            </Button>
          </Stack>
          {displayError ? (
            <InputLabel
              sx={{ marginTop: '10px', color: 'red' }}
              data-test-id="CreateOrganization-Error"
            >
              {displayError}
            </InputLabel>
          ) : null}
        </Box>
      </Paper>
    </Container>
  );
};

NewOrganization.displayName = 'NewOrganization';
export default NewOrganization;
