import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

type DropdownProps = {
  list: Array<{ name: string; value: string }>;
  onChange: (e: { name: string; value: string }) => void;
  selectedValue: string;
  id?: string;
  label: string;
  name?: string;
  width?: number;
  disabled?: boolean;
  fullWidth?: boolean;
};

const Dropdown: React.FC<DropdownProps> = ({
  list,
  onChange,
  selectedValue,
  id,
  label,
  name,
  width,
  disabled,
  fullWidth,
  // including data-test-id, etc.
  ...otherProps
}) => {
  return (
    <FormControl sx={{ m: 1, minWidth: width }} fullWidth={fullWidth}>
      <InputLabel style={{ color: selectedValue ? null : '#D0D0D0' }} id="demo-simple-select-label">
        {label}
      </InputLabel>
      <Select
        disabled={disabled}
        labelId="demo-simple-select-label"
        id={id}
        name={name}
        value={selectedValue}
        label={label}
        onChange={(e) => onChange(e.target)}
        {...otherProps}
      >
        {list.map((e) => {
          return (
            <MenuItem key={e.name} value={e.name}>
              {e.value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const defaultProps: DropdownProps = {
  // eslint-disable-next-line
  list: [],
  // eslint-disable-next-line
  onChange: () => {},
  // eslint-disable-next-line
  selectedValue: '',
  // eslint-disable-next-line
  label: '',
  name: '',
  width: 350,
  disabled: false,
  fullWidth: false,
};

Dropdown.defaultProps = defaultProps;
Dropdown.displayName = 'Dropdown';
export default Dropdown;
