import {
  CountryCode,
  LanguagePreferences,
  MeasurementPreferences,
  MibRequestStatus,
  NotificationsChannel,
  UserRole,
} from '../../__generated__/graphql';

export const INHERITED_DEFAULT_NAME = 'inherited_default';
export const INHERITED_DEFAULT_VALUE = 'Inherited default';

export const timezones = [
  { name: 'America/Los_Angeles', value: 'America/Los_Angeles' },
  { name: 'Asia/Tokyo', value: 'Asia/Tokyo' },
];

export const languages = [
  { name: LanguagePreferences.En, value: 'en' },
  { name: LanguagePreferences.Ja, value: 'ja' },
];

export const countries = [
  { name: 'United States', value: 'United States' },
  { name: 'Japan', value: 'Japan' },
];

export const countriesCode = [
  { name: CountryCode.Us, value: 'US' },
  { name: CountryCode.Jp, value: 'JP' },
];

export const unitsPref = [
  { name: MeasurementPreferences.ImperialSystem, value: 'Imperial System' },
  { name: MeasurementPreferences.MetricSystem, value: 'Metric System' },
];

export const roles = [
  { name: UserRole.Admin, value: 'Admin' },
  { name: UserRole.Contributor, value: 'Contributor' },
  { name: UserRole.Viewer, value: 'Viewer' },
];

export const products = [
  { name: 'DOMINO', value: 'DOMINO' },
  { name: 'READY', value: 'READY' },
];

export const readyRegions = [
  '100_ichino',
  '101_yokkai',
  '102_ibarak',
  '103_kishiw',
  '104_kakoga',
  '105_takara',
  '106_saga',
  '107_kumaga',
  '10_kyoto',
  '11_nagoya',
  '12_niigata',
  '13_okayama',
];

export const dominoRegions = ['us', 'jp'];

export const notificationsEnabled = [
  { name: 'true', value: 'true' },
  { name: 'false', value: 'false' },
];

export const notificationsChannels = [NotificationsChannel.Email];

export const mibRequestStatuses = [
  { name: MibRequestStatus.None, value: 'NONE' },
  { name: MibRequestStatus.Pending, value: 'PENDING' },
  { name: MibRequestStatus.Completed, value: 'COMPLETED' },
  { name: MibRequestStatus.Cancelled, value: 'CANCELLED' },
  { name: MibRequestStatus.Rejected, value: 'REJECTED' },
];
