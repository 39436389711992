import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { GetCurrentUserQuery } from '../__generated__/graphql';
import HOME_PATH from '../util/productGlobals';
import Organizations from '../Components/ProductComponents/Organizations';
import NewOrganization from '../Components/ProductComponents/Organizations/OrganizationsTab/NewOrganization';
import OrganizationDetails from '../Components/ProductComponents/Organizations/OrganizationsTab/OrganizationDetails';
import NewUser from '../Components/ProductComponents/Users/NewUser';
import DominoSettings from '../Components/ProductComponents/Domino';
import AppBar from '../Components/CommonComponents/AppBar';

const ErrorPage: React.FC = () => {
  return <div>Error</div>;
};

type CSAdminProps = {
  user: GetCurrentUserQuery['user'];
  onClickLogout?: () => void;
};

const CSAdmin: React.FC<CSAdminProps> = ({ user, onClickLogout }) => {
  console.log('user', user);
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={HOME_PATH} />} />
      <Route
        path="/orgs"
        element={<AppBar child={<Organizations />} onClickLogout={onClickLogout} />}
      />
      <Route
        path="/orgs/:id"
        element={<AppBar child={<OrganizationDetails />} onClickLogout={onClickLogout} />}
      />
      <Route
        path="/orgs/:id/user/new"
        element={<AppBar child={<NewUser />} onClickLogout={onClickLogout} />}
      />

      <Route
        path="/orgs/new"
        element={<AppBar child={<NewOrganization />} onClickLogout={onClickLogout} />}
      />

      <Route
        path="/domino"
        element={<AppBar child={<DominoSettings />} onClickLogout={onClickLogout} />}
      />
      {/* <Route */}
      {/*  path="/entitlements" */}
      {/*  element={<AppBar child={<Entitlements />} onClickLogout={onClickLogout} />} */}
      {/* /> */}
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
CSAdmin.displayName = 'CSAdmin';
export default CSAdmin;
