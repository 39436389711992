import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import BusinessIcon from '@mui/icons-material/Business';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';

type Anchor = 'left';

type MenuDrawerProps = { onClickLogout?: () => void; onItemSelected: (item: string) => void };

const MenuDrawer: React.FC<MenuDrawerProps> = ({ onClickLogout, onItemSelected }) => {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem key="orgs" disablePadding>
          <ListItemButton
            onClick={() => {
              navigate('/orgs');
              onItemSelected('Organizations');
            }}
          >
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Organizations" />
          </ListItemButton>
        </ListItem>
        <ListItem key="domino-settings" disablePadding>
          <ListItemButton
            onClick={() => {
              navigate('/domino');
              onItemSelected('Domino Settings');
            }}
          >
            <ListItemIcon>
              <DomainAddIcon />
            </ListItemIcon>
            <ListItemText primary="DOMINO settings" />
          </ListItemButton>
        </ListItem>
        {/* <ListItem key="entitlements" disablePadding> */}
        {/*  <ListItemButton */}
        {/*    onClick={() => { */}
        {/*      navigate('/entitlements'); */}
        {/*      onItemSelected('Entitlements Structure'); */}
        {/*    }} */}
        {/*  > */}
        {/*    <ListItemIcon> */}
        {/*      <DomainAddIcon /> */}
        {/*    </ListItemIcon> */}
        {/*    <ListItemText primary="Entitlements Structure" /> */}
        {/*  </ListItemButton> */}
        {/* </ListItem> */}
      </List>
      <Divider />
      <List>
        <ListItem key="logout" disablePadding>
          <ListItemButton
            onClick={() => {
              onClickLogout();
              navigate('/');
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  const anchor = 'left';

  return (
    <div>
      <React.Fragment key={anchor}>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={toggleDrawer(anchor, true)}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
};

MenuDrawer.displayName = 'MenuDrawer';
export default MenuDrawer;
