/* eslint-disable import/prefer-default-export */
import { searchUsersRes, userRes } from './user';
import * as requestsRes from './mibRequests';
import { entitlementsStructure, organization, organizations } from './organization';

export const mocks = {
  Map: () => ({}),
  Query: () => ({
    searchUsers: () => searchUsersRes,
    getOrganizations: () => organizations,
    mibRequests: () => requestsRes.default.mibRequests,
    entitlementsStructure: () => entitlementsStructure,
    organization: () => organization,
    getOrganization: () => organization,
    getUser: () => userRes,
    user: () => userRes,
  }),
};
