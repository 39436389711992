import {
  ApolloClient,
  ApolloLink,
  FetchResult,
  gql,
  HttpLink,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { buildClientSchema, OperationDefinitionNode, print } from 'graphql';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { ServerError } from '@apollo/client/core';
import { addMocksToSchema } from 'graphql-tools';
import { SchemaLink } from '@apollo/client/link/schema';
import { App } from '../AppConfig';
import cache from './cache';
import { locationUploadFileStore } from './stores/LocationUploadFileStore';
import { buildDefaultStore, CreateClientStore } from './CreateClientStore';

// TODO Add more Queries here as we start integrating them with the real resilienceapi endpoint
// const IMPLEMENTED_SERVER_API_QUERIES = [
//   'GetCurrentUser',
//   'GetCurrentUserAndSlices',
//   'GetOrganizationUsers',
//   'GetHistoricalEvents',
//   'GetLocationsFileDetails',
//   'GetOriginalLocationsFile',
//   'GetSliceIndexes',
//   'GetThresholds',
//   'GetWidgetsInfo',
//   'GetOrganization',
//   /* disabling location-related queries while  location/builtobject APIs for getting lifeline
//      markers & stats is broken
//   */
//
//   'GetLocations',
//   'GetLocationsInfo',
//
//   'GetLocation',
//   'GetLocationResilienceStats',
//   'GetLocationLifelineMarkers',
//   'GetLocationLifelineStats',
//   'GetLocationAnalysis',
//
//   'GetBuiltObject',
//   'GetBuiltObjectMarker',
//   'GetBuiltObjectResilienceStats',
//   'GetBuiltObjectLifelineMarkers',
//   'GetBuiltObjectLifelineStats',
//   'GetBuiltObjectAnalysis',
//
//   'SearchLocation',
//   'SearchNearestBuiltObject',
//   'GetUserEntitlements',
// ];

// TODO Add more Mutations here as we start integrating them with the real resilienceapi endpoint
// const IMPLEMENTED_SERVER_API_MUTATIONS = [
//   'InviteUser',
//   'UpdateThresholds',
//   'UploadLocationsFile',
//   'DeleteLocationsFile',
//   'UpdateUser',
//   'UpdatePassword',
//   'UpdateOtherUser',
//   'EnableUser',
//   'DisableUser',
// ];

// Operations routed through the upload link: locations file upload, default
// sample file upload (for admin users)
const UPLOAD_OPERATIONS = ['UploadLocationsFile', 'UploadSampleFile', 'UploadUserLocationsFile'];

// interface IDefinition {
//   kind: string;
//   operation?: string;
// }
// eslint-disable-next-line @typescript-eslint/no-var-requires
const introspectionResult = require('../../__generated__/schema.json');

const { resolvers } = new CreateClientStore(cache, [
  // planViewStore,
  // recentViewStore,
  // navigatorInfoStore,
  // firstToFailStore,
  // locationSearchStore,
  locationUploadFileStore,
]);

export const setupHttpLink: () => ApolloLink | HttpLink = () => {
  const federatedApiHttpLink = new HttpLink({
    // standard uri routed to the GraphQL gateway and federate backends
    uri: `${App.config.endpoints.csadmin}/query?`,
    credentials: 'include',
  });

  const uploadHttpLink = createUploadLink({
    // bypass gateway by specifying a direct route to upstream API (GraphQL gateway does not
    // support multipart http requests)
    // Notice that straight routes use a versioned endpoint.
    uri: `${App.config.endpoints.csadmin}/upload/v1/query?`,
    credentials: 'include',
  });

  const resilienceApiHttpLink = split(
    // Split based on the particular mutations that are not supported by the gateway because the
    // transport implies multipart http requests.
    ({ query }) => {
      const mainDef = getMainDefinition(query);
      const apiOperation = mainDef?.name?.value;
      const bypassGateway =
        mainDef?.kind === 'OperationDefinition' &&
        mainDef?.operation === 'mutation' &&
        UPLOAD_OPERATIONS.includes(apiOperation);
      if (bypassGateway) {
        App.debug(
          `[Client] - directing ${
            (mainDef as OperationDefinitionNode).operation
          } ${apiOperation} straight to resilienceapi endpoint (no federated gateway)`,
        );
      }
      return bypassGateway;
    },
    uploadHttpLink,
    federatedApiHttpLink,
  );

  if (process.env.REACT_APP_ENV === 'local') {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const { mocks } = require('../../mocks/serverMock');
    const mocksSchema = buildClientSchema(introspectionResult);
    addMocksToSchema({ schema: mocksSchema, mocks, preserveResolvers: true });
    const frontendMocksLink = new SchemaLink({ schema: mocksSchema });

    // if (App.config.features.allowpartialmocks) {
    //   const partialMocksHttpLink = split(
    //     // Split based on whether the particular query/mutation is fully implemented in the
    //     // resilienceapi backend or whether we have to use the frontend mocks instead
    //     ({ query }) => {
    //       const mainDef = getMainDefinition(query);
    //       const apiOperation = mainDef?.name?.value;
    //       const directToRealServer =
    //         (mainDef?.kind === 'OperationDefinition' &&
    //           mainDef?.operation === 'query' &&
    //           IMPLEMENTED_SERVER_API_QUERIES.includes(apiOperation)) ||
    //         (mainDef?.kind === 'OperationDefinition' &&
    //           mainDef?.operation === 'mutation' &&
    //           IMPLEMENTED_SERVER_API_MUTATIONS.includes(apiOperation));
    //       if (directToRealServer) {
    //         App.debug(
    //           `[Client] - directing ${
    //             (mainDef as OperationDefinitionNode).operation
    //           } ${apiOperation} to resilienceapi endpoint (not frontend mocks)`,
    //         );
    //       }
    //       return directToRealServer;
    //     },
    //     resilienceApiHttpLink,
    //     frontendMocksLink,
    //   );
    //   return partialMocksHttpLink;
    // }
    return frontendMocksLink;
  }
  return resilienceApiHttpLink;
};

export const client: () => Promise<ApolloClient<NormalizedCacheObject>> = async () => {
  const httpLink = setupHttpLink();
  // const mainlink = split(
  //   // split based on whether web socket subscription or query/mutation
  //   ({ query }) => {
  //     const { kind, operation }: IDefinition = getMainDefinition(query);
  //     const directToWebSockets = kind === 'OperationDefinition' && operation === 'subscription';
  //     return directToWebSockets;
  //   },
  //   httpLink,
  // );

  const reportErrors = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        App.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      );
    }
    if (networkError) {
      App.warn(`[Network error]: ${networkError}`);
      const err = networkError as ServerError;
      if (err.statusCode === 401) {
        console.log('Logout!');
      }
    }
  });

  // const defaultLinks = [reportErrors, mainlink];
  const defaultLinks = [reportErrors, httpLink];
  if (App.config.features.enabledebug) {
    const debugLink = new ApolloLink((operation, forward) => {
      App.groupCollapsed(`Call ${operation.operationName}: (click to expand)`);
      App.debug('variables:');
      App.debug(operation.variables);
      App.debug('query:');
      App.debug(print(operation.query).trim());
      App.groupEnd();
      return forward(operation).map((data: FetchResult) => {
        App.groupCollapsed(`Response of ${operation.operationName}: (click to expand)`);
        App.debug('variables:');
        App.debug(operation.variables);
        App.debug('data:');
        App.debug(data.data);
        App.groupEnd();
        return data;
      });
    });
    defaultLinks.unshift(debugLink);
  }

  const link = ApolloLink.from(defaultLinks);
  const ds: any = buildDefaultStore([
    // planViewStore,
    // recentViewStore,
    // navigatorInfoStore,
    // firstToFailStore,
    // locationSearchStore,
    locationUploadFileStore,
  ]);
  const newClient = new ApolloClient({
    link,
    cache,
    resolvers,
    connectToDevTools: App.config.features.enabledebug,
  });

  const clientt = new ApolloClient({
    link,
    cache,
    resolvers,
    connectToDevTools: App.config.features.enabledebug,
  });

  // TODO: Make this better for now let's just do this.
  newClient.onResetStore((): any => {
    cache.writeQuery({
      query: gql`
        {
          locationUploadFile
        }
      `,
      data: ds,
    });
  });

  App.debug('[Client] cache: ', newClient.cache);
  return clientt;
};
