import React from 'react';
import { alpha, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useDropzone } from 'react-dropzone';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
      },
    },
  },
}));

type CustomizedMenusProps = {
  onSelect: (opt: string, file?: File) => void;
  options: Array<{ key: string; name: string; select_file?: boolean; disabled?: boolean }>;
};

const CustomizedMenus: React.FC<CustomizedMenusProps> = ({ onSelect, options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {
    getRootProps,
    getInputProps,
    open: openFunc,
  } = useDropzone({
    accept: {
      'text/csv': [],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles?.length) {
        onSelect('upload_file', acceptedFiles[0]);
      }
    },
  });

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (option: string, file?: File) => {
    handleClose();
    onSelect(option, file);
  };

  return (
    <div>
      <IconButton
        id="demo-customized-button"
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => {
          e.stopPropagation();
          handleOpen(e);
        }}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={(e: any) => {
          e.stopPropagation();
          handleClose();
        }}
      >
        {options.map((o) => {
          if (o.select_file) {
            return (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <MenuItem
                  key={o.key}
                  disabled={o.disabled}
                  onClick={(e) => {
                    e.stopPropagation();
                    openFunc();
                    handleClose();
                  }}
                  disableRipple
                >
                  {o.name}
                </MenuItem>
              </div>
            );
          }
          return (
            <MenuItem
              key={o.key}
              disabled={o.disabled}
              onClick={(e) => {
                e.stopPropagation();
                handleSelect(o.key);
              }}
              disableRipple
            >
              {o.name}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </div>
  );
};

CustomizedMenus.displayName = 'CustomizedMenus';
export default CustomizedMenus;
