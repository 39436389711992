// eslint-disable
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '@apollo/client/react/components';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * Authorization requirements: combine roles and entitlements requirements with or/and
   *
   * Example:
   *     aclCombineRequirements(
   *         combineReq: {
   *             or: [
   *                 {entitlementRequirements: { product: "planning", module: "consultant" feature: "domino-viewer" }},
   *                 {requirements: {roles: ["account:view-profile"]}}
   *             ]
   *         }
   *     )
   */
  CombinedRequirements: any;
  /** Geodesic coordinates are represented by an array of numbers: [longitude, latitude] */
  Coordinates: number[];
  /** DateTime represents a date, formated according to RFC3339 */
  DateTime: string;
  /**
   * Authorization requirements: entitlements style (aka ABAC), to be used as a parameter to the @aclEntitlements directive.
   *
   * The parameter format supports the following attributes (single-values):
   * - product: String
   * - hazard:  String
   * - module:  String
   * - feature: String
   * - write:   Boolean
   *
   * Using the special value '*' (wildcard) means 'any value': the user must be entitled at least one value for the required attribute.
   *
   * Example:
   *   permissionRequirements: {
   *       product: ['response'],
   *       hazard: ['flood'],
   *       feature: ['*']
   *   })
   */
  EntitlementRequirements: any;
  /** Represents a generic json structure */
  JSONObject: any;
  /**
   * Map is a regular json object, which keys may contain any type.
   *
   * Example:
   * {
   *   'velocity': 123.45,
   *   'depth': 123.45,
   *   'overflow': true
   * }
   */
  Map: any;
  /**
   * MapDouble is a json object, which keys hold only number values.
   *
   * Example:
   * {
   *   'velocity': 123.45,
   *   'depth': 123.45
   * }
   */
  MapDouble: { [key: string]: number };
  /**
   * MapString is a json object, which keys hold only string values.
   *
   * Example:
   * {
   *   'overflow': 'true',
   *   'river': 'kiragawa'
   * }
   */
  MapString: { [key: string]: string };
  /**
   * Authorization requirements: RBAC style, to be used as a parameter to the @acl directive.
   *
   * The parameter format supports the following attributes (multiple values):
   *
   * Examples:
   *   requirements: {roles: ['account:view-profile']}
   *
   *   requirements: {anyRole: ['role:manage-my-org-users','role:oneconcern:admin']}
   */
  Requirements: any;
  /** Represents an ISO 8601 formatted date with ms precision. */
  Time: string;
  /** Upload represents a multipart file upload. */
  Upload: any;
};

/**
 * Structured representation of an address including its flat representation as "formattedAddress"
 *
 * NOTE: for Japanese addresses, the 'stateCode' and 'state' fields contain the prefecture (there are no states in Japan) and the
 * 'suburb' field contains the oaza of the city.
 */
export type Address = {
  __typename?: 'Address';
  /**
   * addressDetails return some geocoding API specifics such as: Japanese address parts, relevance, accuracy
   * and the resolved type of place.
   *
   * For Japanese addresses, this map also describes the detailed parts that are specific to Japan:
   * 'prefecture' the Japanese prefecture (also available in the above fields as 'stateCode').
   * 'ward' is the oaza of the city
   * 'block' is the chome
   * 'subblock' is the banchi
   *
   * Current unsupported Japanese address parts are: oaza districts (cho/machi), sub-districts (aza/koaza),
   * parcels and subdivisions (bu/chiwari).
   *
   * For more information about the nomenclature of Japanese address parts, please read:
   * https://help.sap.com/docs/SAP_DATA_QUALITY_MANAGEMENT_SDK/7ac66320ca514acc89396a367db6dba8/92f51e7c799648a394b977fd77160547.html?version=4.2.11
   *
   *
   * More keys may be used to assess the quality of the resolved address (according to the geocoding provider).
   * At the moment, this field provides the following additional keys:
   *
   * * 'relevance_score': a (0,1) text matching score of the resulting address against the input address (forward geocoding only). 1 is a perfect match.
   * * 'accuracy_score': a (0,1) accuracy score, corresponding to the accuracy code of the result (reflects our interpretation of the accuracy field). 1 is better than 0.
   * * 'accuracy': accuracy code (e.g. estimated accuracy of the returned place), e.g. 'rooftop', 'point', 'interpolated', 'approximate'...
   * * 'types': the types of place returned by the geocoding API (e.g. 'address')
   * * 'category': category of the returned place, for POI's (currently not supported by Mapbox permanent places API)
   *
   * Notice that these scores should be taken with a pinch of salt. There are a few reasons for that:
   * 1. Scores are provided by Mapbox. They reflect their vision of the data quality, not ours.
   * 2. The only significant warning is when we get 'interpolated' or 'approximate' results. This is indeed rather uncommon.
   * 3. This is not because we matched a building in Mapbox (hence 'rooftop' or 'point' good scores) that this is the correct building.
   */
  addressDetails?: Maybe<Scalars['Map']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  /** Complete contextualized address */
  formattedAddress: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  /**
   * The name of the state, e.g. 'Texas'.
   *
   * For Japan, the prefecture name.
   */
  state?: Maybe<Scalars['String']>;
  /**
   * Short code of the state, e.g. 'TX' for Texas, US.
   *
   * For Japan, the prefecture name (same as 'state' above). Also in addressDetails['prefecture'].
   */
  stateCode?: Maybe<Scalars['String']>;
  stateDistrict?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  /**
   * Unused for US addresses.
   *
   * For Japan, the oaza of the city (also in addressDetails['ward']).
   */
  suburb?: Maybe<Scalars['String']>;
};

/** AddressInput captures changes to the user's address (unused for now). */
export type AddressInput = {
  formattedAddress: Scalars['String'];
};

/** Input parameters for admins querying existing MIB requests. */
export type AdminMibRequestsInput = {
  /** search by address. */
  address?: InputMaybe<Scalars['String']>;
  /** search by external ID of the mib request. */
  externalID?: InputMaybe<Scalars['String']>;
  /** search by file ID. */
  fileID?: InputMaybe<Scalars['ID']>;
  /** Timeframe at which the request was posted */
  postedAtTimeframe?: InputMaybe<Timeframe>;
  /** search by status of the request. */
  status?: InputMaybe<MibRequestStatus>;
  /** Timeframe at which the request was last updated */
  updatedAtTimeframe?: InputMaybe<Timeframe>;
  /** search by user ID. */
  userID?: InputMaybe<Scalars['ID']>;
};

/** Input parameters for admins updating existing MIB requests. */
export type AdminUpdateMibRequestsInput = {
  /** New address of the request. */
  address?: InputMaybe<EditAddressInput>;
  /** ID of the request to update. */
  id: Scalars['ID'];
  /** New request metadata */
  metadata?: InputMaybe<Scalars['String']>;
  /** New status of the request. */
  status?: InputMaybe<MibRequestStatus>;
};

export type AggregateStats = Stats & {
  __typename?: 'AggregateStats';
  id: Scalars['ID'];
  materialityLoss?: Maybe<Scalars['Float']>;
  mean?: Maybe<Scalars['Int']>;
  /**
   * Recovery curve over time: this defines a curve which pillars are defined
   * by the projected term as a fractional year (rounded down to 2 digits) and
   * the recovery percentage (rounded down to the nearest integer).
   *
   * Example:
   * [ [x1, y1], [x2, y2], ... ]: [  [0.5, 50], [0.8, 75], [1.5, 85] ]
   *
   * Is empty for structuralDamage
   */
  recoveryCurve?: Maybe<Array<Array<Scalars['Float']>>>;
  score?: Maybe<Scalars['String']>;
  stddev?: Maybe<Scalars['Int']>;
};

export type Airport = BasicLifelineAsset & {
  __typename?: 'Airport';
  actualID: Scalars['String'];
  coordinates: Scalars['Coordinates'];
  enplanements: Scalars['Int'];
  id: Scalars['ID'];
  metadata?: Maybe<Array<Scalars['String']>>;
  /** The airport name */
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Array<Stats>>;
  yearBuilt?: Maybe<Scalars['String']>;
};


export type AirportStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** AuditEvent from audit trail. */
export type AuditEvent = {
  __typename?: 'AuditEvent';
  /** Type the event occured. */
  eventTime: Scalars['DateTime'];
  /** Type of event. */
  eventType: AuditEventType;
  /** Metadata used for debugging. */
  extra: AuditEventMetadata;
  message?: Maybe<Scalars['String']>;
  /** Operation type (e.g. ADD, REMOVE, UPDATE). */
  operationType?: Maybe<AuditEventOperationType>;
  /** The user that issue this event. */
  originator?: Maybe<User>;
  realm: Scalars['String'];
  /** Resource type (e.g. User, Organization). */
  resourceType: AuditEventResourceType;
  /** Status is holding an error if is the case. */
  status: Scalars['String'];
  /** The resourse targeted by this event. */
  target: AuditEventTarget;
};

/** Metadate stored for event. */
export type AuditEventMetadata = {
  __typename?: 'AuditEventMetadata';
  originatorClientID?: Maybe<Scalars['String']>;
  originatorIP?: Maybe<Scalars['String']>;
  originatorRealm?: Maybe<Scalars['String']>;
  rawOperationType?: Maybe<Scalars['String']>;
  rawResourceType?: Maybe<Scalars['String']>;
  resourcePath?: Maybe<Scalars['String']>;
};

/** Audit operation type */
export enum AuditEventOperationType {
  Action = 'ACTION',
  Create = 'CREATE',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

/** Audit resource type */
export enum AuditEventResourceType {
  Organization = 'ORGANIZATION',
  Permission = 'PERMISSION',
  User = 'USER'
}

/** An event can target the following: User, Organization or Permission */
export type AuditEventTarget = {
  __typename?: 'AuditEventTarget';
  organization?: Maybe<Organization>;
  permission?: Maybe<Permission>;
  user?: Maybe<User>;
};

/** Audit event type */
export enum AuditEventType {
  Admin = 'ADMIN'
}

/** Audit filter settings */
export type AuditFilter = {
  byAction?: InputMaybe<Array<AuditEventOperationType>>;
  byFromDate?: InputMaybe<Scalars['DateTime']>;
  byOriginatorUser?: InputMaybe<Scalars['String']>;
  byTargetOrganization?: InputMaybe<Scalars['String']>;
  byTargetPermission?: InputMaybe<Scalars['String']>;
  byTargetUser?: InputMaybe<Scalars['String']>;
  byToDate?: InputMaybe<Scalars['DateTime']>;
};

export type AuditLocation = {
  __typename?: 'AuditLocation';
  /**
   * The reverse geocoded address of the resolved built object, from the resolved object coordinates
   * (as a formatted string).
   *
   * This field is intended for audit and forensics analysis, not for machine consumption.
   *
   * For a detailed version, see below type LocationMetadata.builtObjectAddress.
   */
  builtObjectAddress?: Maybe<Scalars['String']>;
  /** The coordinates of the resolved built object, from the BOS database. */
  builtObjectCoordinates?: Maybe<Scalars['Coordinates']>;
  /**
   * The ID of the resolved built object.
   *
   * Attention: a built object ID is not the same as a location ID.
   */
  builtObjectID?: Maybe<Scalars['String']>;
  /** Missing Built Object request status */
  builtObjectRequestStatus?: Maybe<MibRequestStatus>;
  /**
   * The distance in meters between the geocoded coordinates and the built object coordinates.
   *
   * This may be used as an indicator of the quality of the match as it reflects consensus
   * between the BOS database of built objects and Mapbox.
   *
   * The greater the distance, the more likely our match is approximative.
   *
   * Notice that with our current matching settings, this distance is capped to 500m.
   *
   * Other quality indicators are available below (see LocationMetadata.quality).
   *
   * Besides, any detailed address field contains Mapbox' own estimate of the quality of their result.
   */
  distance?: Maybe<Scalars['Float']>;
  /**
   * The normalized geocoded address from mapbox API (as a formatted string).
   *
   * This field is intended for audit and forensics analysis, not for machine consumption.
   *
   * For a detailed version, see below type LocationMetadata.geocodedAddress.
   */
  geocodedAddress?: Maybe<Scalars['String']>;
  /**
   * The coordinates of the geocoded address from mapbox API.
   *
   * Notice that this field is empty whenever the user input comes with explicit coordinates
   * (in that case, forward geocoding is skipped).
   *
   * The system records up to 4 different geodesic coordinates:
   * 1. user-input coordinates (optional). See LocationMetadata.inputCoordinates
   * 2. geocoded coordinates (if 1. is not provided, which is the expected norm)
   * 3. built object coordinates from the BOS database (see below builtObjectCoordinates)
   * 4. reverse-geocoded coordinates
   */
  geocodedCoordinates?: Maybe<Scalars['Coordinates']>;
  /** Synthetic ID made of 'audit|' + file id + '|' + line number */
  id: Scalars['ID'];
  /**
   * User-edit timestamp (nil if never updated).
   *
   * This reflects the time when a user has edited this location line (UTC time).
   */
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  /**
   * The ID of the user location.
   *
   * Attention: a location ID is not the same as a built object ID.
   */
  locationID?: Maybe<Scalars['String']>;
  /**
   * locationMeta reflects the CSV fields in a structured type.
   *
   * For convenience, the audited addresses provided above are provided in details.
   */
  locationMeta?: Maybe<LocationMetadata>;
  /** The integration notice produced by upload. */
  notice?: Maybe<FileNotice>;
  /**
   * The original address submitted in the file (as a formatted string).
   *
   * This field is intended for audit and forensics analysis, not for machine consumption.
   *
   * For a detailed version, see below type LocationMetadata.address.
   */
  originalAddress?: Maybe<Scalars['String']>;
  /**
   * The integration status for this location line.
   *
   * A failed status indicates that an error occured during the upload (or edit) process.
   *
   * To wit, there are six major causes for such failures:
   * 1. could not forward geocode the address into coordinates [mitigation: provide coordinates as hints]
   * 2. could not find a built object with the proper type (commercial property) in our BOS database at the above coordinates [mitigation: edit location and pick built object from map]
   * 3. could not resolve the address for the built object (reverse geocode)
   * 4. could resolve the address for the built object (reverse geocode), but our consistency check (zipcode match the original input) failed [mitigation: edit input zipcode or disable zipcode check].
   * 5. any transitory techical error happening on the platform (timeout, etc...)
   * 6. CSV parsing error (unrecognized header, illegal coordinates, missing required field...)
   *
   * Notice that a successful integration does not guarantee the quality of the match. You'll find various additional quality indicators in the current audit trail type.
   */
  processStatus: LocationStatus;
  /**
   * The coordinates of the reverse-geocoded address from mapbox API.
   *
   * These coordinates are only kept for audit purpose: the final coordinates
   * associated to a user location are the ones from the BOS database.
   */
  reverseCoordinates?: Maybe<Scalars['Coordinates']>;
  /**
   * Upload timestamp.
   *
   * This reflects the time when the user has submitted a CSV to upload (UTC time).
   */
  uploadedAt: Scalars['DateTime'];
};

export type BasicLifelineAsset = {
  /**
   * actualID is the internal database reference.
   *
   * It may be used to query directly a lifeline asset from the built
   * objects repository.
   *
   * NOTE: this ID remains empty (but not null) for residential and highway lifelines,
   * since in these cases, the reported lifelines correspond to aggregates.
   */
  actualID: Scalars['String'];
  /** Synthetic ID used for client-side caching. */
  id: Scalars['ID'];
  metadata?: Maybe<Array<Scalars['String']>>;
  /**
   * Statistics about an individual (or summarized) lifeline asset.
   *
   * Slices rendered as exactly the ones requested (in ascending order).
   *
   * Whenever some requested slices are not available, the resulting collection is padded with
   * empty (but no null) elements.
   *
   * NOTE: The underlying implementation of Stats here is actually LifelineStats. Feel free to
   * file a request for change if the rendering of an interface type makes it difficult to use.
   *
   * If slices are not specified, they are infered from the sibling "resilienceStats" field, if requested.
   * If not, then all slices are selected.
   */
  stats?: Maybe<Array<Stats>>;
};


export type BasicLifelineAssetStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

export type Bridge = BasicLifelineAsset & {
  __typename?: 'Bridge';
  actualID: Scalars['String'];
  bridgeType: Scalars['String'];
  coordinates: Scalars['Coordinates'];
  id: Scalars['ID'];
  /** Span of the bridge in meters */
  length: Scalars['Int'];
  material: Scalars['String'];
  metadata?: Maybe<Array<Scalars['String']>>;
  /** The bridge name */
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Array<Stats>>;
  yearBuilt?: Maybe<Scalars['String']>;
};


export type BridgeStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/**
 * Building is a commercial building
 *
 * Commercial buildings are currently not considered as part of the lifelines.
 *
 * As such, a lot of the following fields are redundant with their parent.
 */
export type Building = BasicLifelineAsset & {
  __typename?: 'Building';
  actualID: Scalars['String'];
  constructionType?: Maybe<Scalars['String']>;
  coordinates: Scalars['Coordinates'];
  id: Scalars['ID'];
  /** extra metadata is not implemented */
  metadata?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  /** Statistics at this level are not implemented: they are redundant with what you get at the higher level */
  stats?: Maybe<Array<Stats>>;
  /** The number of stories (storeys) of the building, that is, the number of floors */
  stories?: Maybe<Scalars['Int']>;
  yearBuilt?: Maybe<Scalars['String']>;
};


/**
 * Building is a commercial building
 *
 * Commercial buildings are currently not considered as part of the lifelines.
 *
 * As such, a lot of the following fields are redundant with their parent.
 */
export type BuildingStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** A built object accessed directly or a built object matched to a user's location. */
export type BuiltObject = BuiltObjectInterface & {
  __typename?: 'BuiltObject';
  address: Address;
  /** Lifeline metadata and statistics */
  airports?: Maybe<Array<Airport>>;
  bridges?: Maybe<Array<Bridge>>;
  /**
   * Number of buildings corresponding to this built object. A built object may cover several buildings
   * over a large area (e.g. airport...). May be 1 or more.
   */
  buildingsCount: Scalars['Int'];
  /**
   * The coordinates of the built object.
   * A built object may actually cover several buildings
   */
  coordinates: Scalars['Coordinates'];
  /** Data for finance users */
  finance?: Maybe<Finance>;
  highways?: Maybe<Array<HighwaySegment>>;
  /** A synthetic ID for safe caching. It may be used for direct access to a single built-object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Subdivision>>;
  ports?: Maybe<Array<Port>>;
  power?: Maybe<Array<Substation>>;
  /**
   * The aggregate statistics over a set of slices.
   *
   * If no array of slices is provided, all supported slices are returned.
   */
  resilienceStats?: Maybe<Array<ResilienceStats>>;
  /** Structural properties of the building */
  structural?: Maybe<Array<Building>>;
};


/** A built object accessed directly or a built object matched to a user's location. */
export type BuiltObjectBridgesArgs = {
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
};


/** A built object accessed directly or a built object matched to a user's location. */
export type BuiltObjectResilienceStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/**
 * BuiltObjectInterface describes the capabilities of anything matched to
 * a built object.
 *
 * This may be a built object accessed directly or a built object matched to a user's location.
 */
export type BuiltObjectInterface = {
  /**
   * The address of this building
   *
   * The address used is the reverse geocoded address (using Mapbox geocoding service)
   */
  address: Address;
  /**
   * Lifeline metadata and statistics
   *
   * - all airports within 50 miles of the built object of interest
   * - all ports within 50 miles of the built object of interest
   * - people: all groups of residential buildings within 10 miles, regrouped by their zip code: the lifeline object is the group of homes in one zip code
   * - all highway names within 30 miles of the built object of interest: the lifeline object is actually the closest highway segment of this highway
   * - all bridges within 10 miles of the built object of interest
   * - power: all power substations within 50 miles of the built object of interest
   */
  airports?: Maybe<Array<Airport>>;
  bridges?: Maybe<Array<Bridge>>;
  /**
   * Number of buildings corresponding to this built object. A built object may cover several buildings
   * over a large area (e.g. airport...). May be 1 or more.
   */
  buildingsCount: Scalars['Int'];
  /**
   * The coordinates of the built object.
   * A built object may actually cover several buildings
   */
  coordinates: Scalars['Coordinates'];
  highways?: Maybe<Array<HighwaySegment>>;
  /** A synthetic ID for safe caching. It may be used for direct access to a single built-object. */
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  people?: Maybe<Array<Subdivision>>;
  ports?: Maybe<Array<Port>>;
  power?: Maybe<Array<Substation>>;
  /**
   * The aggregate statistics over a set of slices.
   *
   * If no array of slices is provided, all supported slices are returned.
   *
   * NOTE: when querying all locations, specifying the requested slices is required.
   * This to guard against the "all locations, all slices" case, which most likely would
   * return a very large data set.
   */
  resilienceStats?: Maybe<Array<ResilienceStats>>;
  /** Structural properties of the building */
  structural?: Maybe<Array<Building>>;
};


/**
 * BuiltObjectInterface describes the capabilities of anything matched to
 * a built object.
 *
 * This may be a built object accessed directly or a built object matched to a user's location.
 */
export type BuiltObjectInterfaceBridgesArgs = {
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
};


/**
 * BuiltObjectInterface describes the capabilities of anything matched to
 * a built object.
 *
 * This may be a built object accessed directly or a built object matched to a user's location.
 */
export type BuiltObjectInterfaceResilienceStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** All built object types. */
export enum BuiltObjectType {
  Airport = 'AIRPORT',
  Bridge = 'BRIDGE',
  Commercial = 'COMMERCIAL',
  Highway = 'HIGHWAY',
  People = 'PEOPLE',
  Port = 'PORT',
  Power = 'POWER'
}

/**
 * CSVLocationsFormat refers to the CSV formats that are supported to upload locations.
 *
 * This corresponds to different CSV headers:
 *     DetailedUS Header     Location name,Street Address,City,State,Zip Code,[Latitude,Longitude,Location type, Location group,Force input address,Skip Zipcode]
 *     DetailedJP-en Header: Location name,Zip Code,Prefecture,City,Street Address,[Latitude,Longitude,Location type, Location group,Force input address,Skip Zipcode]
 *     DetailedJP-ja Header: ロケーション名,郵便番号,都道府県,郡市,区町村番地,[緯度,経度,ロケーションタイプ,ロケーショングループ,入力アドレスを強制します,郵便番号をスキップする]
 *     USSimple Header:      Location name,Address[,Location type, Location group]
 */
export enum CsvLocationsFormat {
  /** JP detailed address (ja or en) */
  JpDetailedAddress = 'JPDetailedAddress',
  /** US detailed address */
  UsDetailedAddress = 'USDetailedAddress',
  /** US simple address (legacy raw text address format, deprecated) */
  UsSimpleAddress = 'USSimpleAddress',
  /** Unknown, unsupported format */
  UnknownFormat = 'UnknownFormat'
}

/** Company from BOS db */
export type Company = {
  __typename?: 'Company';
  builtObjects?: Maybe<Array<BuiltObject>>;
  dataEventId: Scalars['Int'];
  id: Scalars['ID'];
  meta?: Maybe<CompanyMeta>;
  name: Scalars['String'];
  sourceId?: Maybe<Scalars['String']>;
  sourceVendor: Scalars['String'];
};

export type CompanyMeta = {
  __typename?: 'CompanyMeta';
  company1crx?: Maybe<Scalars['Float']>;
  directPvOverMktcapPercent?: Maybe<Scalars['Float']>;
  gicsSector?: Maybe<Scalars['String']>;
  gicsSubIndustry?: Maybe<Scalars['String']>;
  marketCap?: Maybe<Scalars['Float']>;
  numHighRiskBldgs?: Maybe<Scalars['Float']>;
  numTotalBldgs?: Maybe<Scalars['Float']>;
  presentValue?: Maybe<Scalars['Float']>;
  probStockImpactAvgEvent2percent?: Maybe<Scalars['Float']>;
  probStockImpactAvgEvent4percent?: Maybe<Scalars['Float']>;
  probStockImpactAvgEvent5percent?: Maybe<Scalars['Float']>;
  probStockImpactAvgEvent6percent?: Maybe<Scalars['Float']>;
  pvOverMktcapPercent?: Maybe<Scalars['Float']>;
  pvOverMktcapRankInSectorPercentile?: Maybe<Scalars['Float']>;
  pvOverMktcapRankPercentile?: Maybe<Scalars['Float']>;
  revenueUsYearly?: Maybe<Scalars['Float']>;
  revenueYearly?: Maybe<Scalars['Float']>;
  stockDropRatioHistoryMax?: Maybe<Scalars['Float']>;
  stockDropRatioHistoryMin?: Maybe<Scalars['Float']>;
  stockTicker?: Maybe<Scalars['String']>;
};

/** Contact related information */
export type ContactInfo = {
  __typename?: 'ContactInfo';
  /**
   * The email address of the user.
   *
   * NOTE: this field is actually required, even though it has historically been defined as optional.
   */
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/**
 * ContactInfoInput captures changes to the email and phone number.
 *
 * NOTE: email must remain unique across the board.
 */
export type ContactInfoInput = {
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

/** Supported country codes (ISO-3166-1 codes) */
export enum CountryCode {
  Bd = 'BD',
  De = 'DE',
  In = 'IN',
  Jp = 'JP',
  Us = 'US'
}

export type CreateOrganizationAdminInput = {
  /**
   * Admin users of the organization, identified by their user ID (uuid) or email.
   *
   * If such a user is already mentioned in the 'users' field above, it is added as an admin.
   *
   * Listed users become 'organization admin' for that organization (not OneConcern admin).
   * Such an 'organization admin' should only administer one organization.
   *
   * If admin members are specified at organization creation time, they should have already been created.
   *
   * Admins may be in a disabled status, or may have an invitation still pending.
   *
   * To remove admins later, either edit their role or remove them from the list of organization members by editing the organization.
   */
  addAdmins?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Members to add to the organization, identified by user ID (uuid) or email.
   *
   * If members are specified at organization creation time, they should have already been created.
   *
   * The new members may be in a disabled status, or may have an invitation still pending.
   *
   * To remove users later, edit the organization and mention the users to be removed them from the list of organization members.
   */
  addUsers?: InputMaybe<Array<Scalars['String']>>;
  /** Private notes from the administrator about this organization (edited by admin). */
  adminNotes?: InputMaybe<Scalars['String']>;
  /** Generally available description of the organization (edited by admin). */
  description?: InputMaybe<Scalars['String']>;
  /** additional entitlements settings (besides profiles) */
  entitlements?: InputMaybe<EntitlementsEditInput>;
  /**
   * This is the organization's system name.
   *
   * The system name of the organization is immutable. You must recreate an organization to change it.
   *
   * At this moment, this supports only the create of top-level organizations, not sub-organizations.
   */
  name: Scalars['String'];
  /** Sets the localized names of the organization. The map is of the form: {'de': 'das Organisation', 'en': 'the organization'} */
  names?: InputMaybe<Scalars['Map']>;
  /** Sets default preferences for the members of this organization */
  preferences?: InputMaybe<OrganizationPreferencesEditInput>;
  /**
   * Applies some predefined product profiles to the organization.
   * @deprecated superseded by entitlements
   */
  profiles?: InputMaybe<Array<ProductProfileInput>>;
};

/** Informations required by the Datadog widget */
export type Datadog = {
  __typename?: 'Datadog';
  clientToken: Scalars['String'];
};

/** Ordering direction (ascending or descending order). */
export enum Direction {
  Asc = 'Asc',
  Desc = 'Desc'
}

/**
 * DOMINO modules (or 'country of subscription') that expose a specific
 * user experience.
 * Can also represent a company type (e.g. consultant)
 */
export enum DominoModule {
  Consultant = 'CONSULTANT',
  Finance = 'FINANCE',
  Jp = 'JP',
  Materiality = 'MATERIALITY',
  Us = 'US'
}

/** Detailed address from user-input, used to override geocoded addresses. */
export type EditAddressInput = {
  /**
   * Address details for Japanese address details.
   *
   * Supported keys for Japanese addresses are:
   * - ward
   * - block
   * - subblock
   */
  addressDetails?: InputMaybe<Scalars['Map']>;
  city: Scalars['String'];
  country?: InputMaybe<Scalars['String']>;
  /** Country code (US or JP) (required) */
  countryCode: Scalars['String'];
  county?: InputMaybe<Scalars['String']>;
  /** Complete contextualized address (optional). */
  formattedAddress?: InputMaybe<Scalars['String']>;
  houseNumber?: InputMaybe<Scalars['String']>;
  /** zipcode (for US and JP addresses) (required) */
  postCode: Scalars['String'];
  state?: InputMaybe<Scalars['String']>;
  /**
   * State short code for US addresses (e.g. CA, TX) (required).
   *
   * This is the prefecture for Japanese addresses.
   */
  stateCode: Scalars['String'];
  stateDistrict?: InputMaybe<Scalars['String']>;
  /** Optional fields */
  street?: InputMaybe<Scalars['String']>;
  suburb?: InputMaybe<Scalars['String']>;
};

export type EditLocationInput = {
  /** If the override is set, the input adress from the user is used as the address of the location, no questions asked (implies 'skipPostCodeCheck'=true). */
  addressOverride?: InputMaybe<Scalars['Boolean']>;
  /**
   * The new built object ID to match to that location (optional). This reference MUST exists in our Built Objects Database as a user-searchable building.
   *
   * If not provided, the location's built object is resolved from locationCoordinates if present, or locationAddress, in the same way uploaded lines are resolved.
   */
  builtObjectID?: InputMaybe<Scalars['String']>;
  /** The new group of the location (optional). */
  group?: InputMaybe<Scalars['String']>;
  /** Location ID */
  id: Scalars['ID'];
  /**
   * The new user-defined address of the location (optional).
   *
   * By default, this address is used as an input to geocoding, similarly to when uploding a CSV line.
   * The resulting address will result from reverse-geocoding the built object's coordinates may differ from tihs input.
   * In the updated CSV line, this user input is kept.
   *
   * When 'addressOverride' is true, this overrides the address resolved from the coordinates of the built object, in all cases.
   *
   * If not provided or when 'addressOverride' is undefined or false, and a builtObjectID is defined, the new address of the location will be infered from this built object.
   *
   * If provided and no builtObjectID is defined, the address is used to infer a new built object match.
   *
   * If provided together with locationCoordinates and no builtObjectID, coordinates are primarily used to resolve a new built object match.
   */
  locationAddress?: InputMaybe<EditAddressInput>;
  /**
   * Location coordinates supersede the coordinates originally entered by the user (optional).
   *
   * The new locationCoordinates are used whenever no builtObjectID is provided (builtObjectID is then infered primarily from coordinates).
   *
   * If a built object ID is specified, this is archived as the new user-input. However, the coordinates remain determined by the built object.
   */
  locationCoordinates?: InputMaybe<Scalars['Coordinates']>;
  /** The new name of the location (optional). */
  name?: InputMaybe<Scalars['String']>;
  /**
   * If the skip flag is set AND an address is provided AND the 'addressOverride' is undefined or false, then address matching ignores the regular safeguard check on zipcode,
   * which requires the eventually resolved address to have the same zipcode as the original input.
   *
   * By default, 'skipPostCoeCheck' is considered false, so the zip code check is carried out (for Japanese addresses, only the 3 first digits are checked).
   *
   * This flag is ignored whenever:
   * * no address is provided as input
   * * 'addressOverride' is true (implies no check)
   */
  skipPostCodeCheck?: InputMaybe<Scalars['Boolean']>;
  /** The new type of the location (optional). */
  type?: InputMaybe<Scalars['String']>;
  /** The new visibility status of the location (optional). */
  visibility?: InputMaybe<Scalars['Boolean']>;
};

/**
 * Entitlements describe what a user is allowed to do with OneConcern's products.
 *
 * Each backend app may enrich this type with its product-specific description of entitlements.
 */
export type Entitlements = {
  __typename?: 'Entitlements';
  /**
   * List of products listed in entitlements, to enable modules or features for that product.
   *
   * This yields the list of entitled products, but does not provide any information about which of this
   * product's features are actually enabled.
   *
   * When defined at the organization level, all organization members inherit this setting.
   */
  enabledProducts: Array<Product>;
  /**
   * List of products listed in entitlements, to enable modules or features for that product.
   *
   * When defined at the organization level, all organization members inherit this setting.
   */
  enabledProductsModule: Array<ProductModule>;
  /**
   * List of enabled features for this product, for a particular module, or all entitled modules if left unspecified.
   *
   * When defined at the organization level, all organization members inherit this setting.
   */
  hasFeaturesForProduct: Array<ProductFeature>;
  /**
   * List of enabled hazards for this product, for a particular module, or all entitled modules if left unspecified.
   *
   * When defined at the organization level, all organization members inherit this setting.
   */
  hasHazardsForProduct: Array<ProductHazard>;
  /** The identifier for entitlements is the user ID. */
  id: Scalars['ID'];
  /** Whether this user has the organization-admin role enabled. */
  isOrganizationAdmin: Scalars['Boolean'];
  /** Whether this user has the super-admin role enabled. */
  isSuperAdmin: Scalars['Boolean'];
  /** Application scopes that the user is able to sign-in to (e.g. 'DOMINO', 'READY') */
  scopes?: Maybe<Array<Product>>;
};


/**
 * Entitlements describe what a user is allowed to do with OneConcern's products.
 *
 * Each backend app may enrich this type with its product-specific description of entitlements.
 */
export type EntitlementsHasFeaturesForProductArgs = {
  module?: InputMaybe<Scalars['String']>;
  product?: Product;
};


/**
 * Entitlements describe what a user is allowed to do with OneConcern's products.
 *
 * Each backend app may enrich this type with its product-specific description of entitlements.
 */
export type EntitlementsHasHazardsForProductArgs = {
  module?: InputMaybe<Scalars['String']>;
  product?: Product;
};

/**
 * EntitlementsEditInput allows to modify permissions to enable or disable modules or features.
 *
 * Only OneConcern admins may modifiy entitlements (either at the user or organization level).
 *
 * IMPORTANT: inherited entitlements can't be overriden. It is not possible to REVOKE an entitled item
 * from a user who inherits this capabilities from the organization.
 */
export type EntitlementsEditInput = {
  /**
   * Toggles generic roles which do not follow the matrix approach of entitlements: a generic role is either
   * granted or revoked, but it is not tied to a particular product or module.
   *
   * Generic roles are currently being used to assign some extra capabilities to individual users, such as:
   * * super-admin (for OneConcern staff only)
   * * organization-admin (for select users of a customer organization)
   *
   * Although this is technically possible, we normally don't want to assign such capabilities to entire organizations.
   */
  genericRoles?: InputMaybe<RoleEditInput>;
  /**
   * Modules define a subscription and options to that subscription.
   *
   * Modules flagged as 'isMain' by the EntitlementsStructure descriptor are legit subscription identifiers.
   *
   * Examples of subscriptions:
   * [product: READY, module: 'live hazard']
   * [product: DOMINO, module: 'us']
   * [product: DOMINO, module: 'jp']
   *
   * Examples of subscription options:
   * [product: READY, module: 'e2e-test'] (option for QA users)
   * [product: DOMINO, module: 'consultant'] (option to enable risk consultancy features)
   *
   * Specifying modules is now required in order to associate the other dimensions of entitlements
   * to the appropriate subscription.
   *
   * NOTE: a transitory backward-compatibility mode remains supported for mutations that do not specify modules at all.
   * In that case, modules are inferred from the subscriptions currently assigned to the user or organization.
   *
   * Since inferring modules is an imperfect process, it is recommended to upgrade mutations and use explicit modules.
   *
   * IMPORTANT: the absence of a subscription specification will result in its removal.
   */
  modules?: InputMaybe<Array<ModuleEditInput>>;
  /**
   * Toggles a list of features for some products and modules for this organization or user. For an organization, all members will inherit this setting.
   *
   * This supports features not tied to a given hazard.
   */
  productFeatures?: InputMaybe<Array<FeatureEditInput>>;
  /**
   * Toggles a list of features for some products and modules for this organization or user. For an organization, all members will inherit this setting.
   *
   * This supports features tied to a given hazard.
   */
  productHazardFeatures?: InputMaybe<Array<HazardFeatureEditInput>>;
  productHazards?: InputMaybe<Array<HazardEditInput>>;
};

/** EntitlementsStructure provides the combinations of entitlements dimensions that are currently supported. */
export type EntitlementsStructure = {
  __typename?: 'EntitlementsStructure';
  productHasFeatures: Array<ProductFeature>;
  /** Legit hazards for products. */
  productHasHazards: Array<ProductHazard>;
  /**
   * Legit modules for a product.
   *
   * The 'main' attribute of a module indicates whether the module defines
   * a subscription or merely an option of the subscription.
   */
  productHasModules: Array<ProductModule>;
  /** Legit regions for products. */
  productHasRegions: Array<ProductRegion>;
  /** All supported products */
  products: Array<Product>;
};

/** ExtentFilter describes a bounding box for searching something within a certain area. */
export type ExtentFilter = {
  /** Maximum longitude */
  xMax: Scalars['Float'];
  /** Minimum longitude */
  xMin: Scalars['Float'];
  /** Maximum latitude */
  yMax: Scalars['Float'];
  /** Minimum latitude */
  yMin: Scalars['Float'];
};

/**
 * FeatureEditInput toggles a feature for a product and module.
 *
 * Features and modules are just plain strings for this service, which doesn't have knowledge
 * of apps details.
 *
 * If this entitlement is tied to a region, specify a legit region.
 *
 * TODO: enriched versions of this input can be exposed by app-specific services, using enums to identify features.
 */
export type FeatureEditInput = {
  enabled: Scalars['Boolean'];
  feature: Scalars['String'];
  module: Scalars['String'];
  product: Product;
  region?: InputMaybe<Scalars['String']>;
};

/**
 * FeatureEntitlement characterizes a feature (other than lifeline) granted for a given module and hazard type.
 *
 * Notice that a feature that is not specifically assigned to a hazard is implicitly available for all hazards.
 */
export type FeatureEntitlement = {
  __typename?: 'FeatureEntitlement';
  feature: Scalars['String'];
  hazard: SliceHazardType;
  module: DominoModule;
};

export type FileNotice = {
  __typename?: 'FileNotice';
  /** Synthetic unique ID of the individual FileNotice item */
  id: Scalars['ID'];
  /** The label of the column subject to this notice */
  label: Scalars['String'];
  /** The line in the CSV subject to this notice */
  lineNumber: Scalars['Int'];
  /** Whenever a value has been automatically adjusted, the new value being used */
  newValue?: Maybe<Scalars['String']>;
  noticeCode: NoticeCode;
  /** Whenever a value has been automatically adjusted, the original value */
  originalValue?: Maybe<Scalars['String']>;
  /** Detailed error or warning cause */
  reason?: Maybe<Scalars['String']>;
};

export type Finance = {
  __typename?: 'Finance';
  dailyRevenue: Scalars['Float'];
  epDsOccupancy?: Maybe<Scalars['String']>;
  highRisk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  propertyValue: Scalars['Float'];
  stockTicker: Scalars['String'];
};

export type FinanceLosses = {
  __typename?: 'FinanceLosses';
  damageRatio: Scalars['Float'];
  id?: Maybe<Scalars['ID']>;
  revenueLossDays: Scalars['Float'];
};

/**
 * A Feature object represents a spatially bounded thing.  Every Feature
 * object is a GeoJSON object no matter where it occurs in a GeoJSON
 * text.
 *
 * * A Feature object has a "type" member with the value "Feature".
 * * A Feature object has a member with the name "geometry".  The value
 *   of the geometry member SHALL be either a Geometry object as
 *   defined above or, in the case that the Feature is unlocated, a
 *   JSON null value.
 * * A Feature object has a member with the name "properties".  The
 *   value of the properties member is an object (any JSON object or a
 *   JSON null value).
 * * If a Feature has a commonly used identifier, that identifier
 *   SHOULD be included as a member of the Feature object with the name
 *   "id", and the value of this member is either a JSON string or
 *   number.
 */
export type GeoJsonFeature = {
  __typename?: 'GeoJSONFeature';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  geometry?: Maybe<GeoJsonGeometry>;
  id?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['JSONObject']>;
  type: GeoJsonType;
};

/**
 * A GeoJSON object with the type "FeatureCollection" is a
 * FeatureCollection object.  A FeatureCollection object has a member
 * with the name "features".  The value of "features" is a JSON array.
 * Each element of the array is a Feature object as defined above.  It
 * is possible for this array to be empty
 */
export type GeoJsonFeatureCollection = {
  __typename?: 'GeoJSONFeatureCollection';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  features: Array<GeoJsonFeature>;
  type: GeoJsonType;
};

/**
 * Point coordinates are in x, y order (easting, northing for projected
 * coordinates, longitude, and latitude for geographic coordinates):
 *
 * ```json
 * {
 *   "type": "Point",
 *   "coordinates": [100.0, 0.0]
 * }
 * ```
 */
export type GeoJsonGeometry = {
  __typename?: 'GeoJSONGeometry';
  coordinates: Array<Scalars['Float']>;
  type: GeoJsonType;
};

/**
 * Each element in the "geometries" array of a GeometryCollection is one
 * of the Geometry objects described above:
 *
 * ```json
 * {
 *   "type": "GeometryCollection",
 *   "geometries": [{
 *     "type": "Point",
 *     "coordinates": [100.0, 0.0]
 *   }, {
 *     "type": "LineString",
 *     "coordinates": [
 *         [101.0, 0.0],
 *         [102.0, 1.0]
 *     ]
 *   }]
 * }
 * ```
 */
export type GeoJsonGeometryCollection = {
  __typename?: 'GeoJSONGeometryCollection';
  bbox?: Maybe<Array<Maybe<Scalars['Float']>>>;
  geometries: Array<GeoJsonGeometry>;
  type: GeoJsonType;
};

/** Input for geojson geometry type */
export type GeoJsonGeometryInput = {
  coordinates: Array<Scalars['Float']>;
  type: GeoJsonType;
};

/**
 * GeoJSON is a format for encoding a variety of geographic data
 * structures using JavaScript Object Notation (JSON).  A
 * GeoJSON object may represent a region of space (a Geometry), a
 * spatially bounded entity (a Feature), or a list of Features (a
 * FeatureCollection).  GeoJSON supports the following geometry types:
 * Point, LineString, Polygon, MultiPoint, MultiLineString,
 * MultiPolygon, and GeometryCollection.  Features in GeoJSON contain a
 * Geometry object and additional properties, and a FeatureCollection
 * contains a list of Features.
 *
 * The format is concerned with geographic data in the broadest sense;
 * anything with qualities that are bounded in geographical space might
 * be a Feature whether or not it is a physical structure.  The concepts
 * in GeoJSON are not new; they are derived from preexisting open
 * geographic information system standards and have been streamlined to
 * better suit web application development using JSON.
 *
 * GeoJSON comprises the seven concrete geometry types defined in the
 * OpenGIS Simple Features Implementation Specification for SQL:
 * 0-dimensional Point and MultiPoint; 1-dimensional curve LineString
 * and MultiLineString; 2-dimensional surface Polygon and MultiPolygon;
 * and the heterogeneous GeometryCollection.  GeoJSON representations of
 * instances of these geometry types are analogous to the well-known
 * binary (WKB) and well-known text (WKT) representations described in
 * that same specification.
 *
 * GeoJSON also comprises the types Feature and FeatureCollection.
 * Feature objects in GeoJSON contain a Geometry object with one of the
 * above geometry types and additional members.  A FeatureCollection
 * object contains an array of Feature objects.  This structure is
 * analogous to that of the Web Feature Service (WFS) response to
 * GetFeatures requests specified in WFSv1 or to a Keyhole Markup
 * Language (KML) Folder of Placemarks KMLv2.2.  Some implementations
 * of the WFS specification also provide GeoJSON-formatted responses to
 * GetFeature requests, but there is no particular service model or
 * Feature type ontology implied in the GeoJSON format specification.
 */
export enum GeoJsonType {
  /** A Feature object represents a spatially bounded thing. */
  Feature = 'Feature',
  /** Collection of features */
  FeatureCollection = 'FeatureCollection',
  /** Collection of geometries (point, linestring, polygon, ...) */
  GeometryCollection = 'GeometryCollection',
  /** Coordinates of LineString are an array of positions */
  LineString = 'LineString',
  /** Coordinates of a MultiLineString are an array of LineString coordinate arrays */
  MultiLineString = 'MultiLineString',
  /** Coordinates of a MultiPoint are an array of positions */
  MultiPoint = 'MultiPoint',
  /** Coordinates of a MultiPolygon are an array of Polygon coordinate arrays */
  MultiPolygon = 'MultiPolygon',
  /** Point coordinates are positions in x, y order (easting, northing for projected coordinates, longitude, and latitude for geographic coordinates) */
  Point = 'Point',
  /** Coordinates of a Polygon are an array of linear ring coordinate arrays. */
  Polygon = 'Polygon'
}

export type GeoLookupInput = {
  /** Type of built object to search for. */
  builtObjectType?: InputMaybe<BuiltObjectType>;
  /** Center coordinates for the search (long,lat) */
  coordinates: Scalars['Coordinates'];
  /** Maximum distance to the center to be considered (in meters) */
  withinMeters?: InputMaybe<Scalars['Float']>;
};

/**
 * HazardEditInput toggles a hazard type for a product and module.
 *
 * If this entitlement is tied to a region, specify a legit region.
 */
export type HazardEditInput = {
  hazard: Scalars['String'];
  module: Scalars['String'];
  product: Product;
  region?: InputMaybe<Scalars['String']>;
};

/**
 * HazardFeatureEditInput toggles a feature for a product, module and hazard.
 *
 * Features and modules are just plain strings for this service, which doesn't have knowledge
 * of apps details.
 *
 * If this entitlement is tied to a region, specify a legit region.
 */
export type HazardFeatureEditInput = {
  enabled: Scalars['Boolean'];
  feature: Scalars['String'];
  hazard: Scalars['String'];
  module: Scalars['String'];
  product: Product;
  region?: InputMaybe<Scalars['String']>;
};

/** HighwaySegment is a portion of a highway */
export type HighwaySegment = BasicLifelineAsset & {
  __typename?: 'HighwaySegment';
  actualID: Scalars['String'];
  /**
   * Coordinates a the highway segment.
   *
   * For highway lifelines, the coordinates of the closest highway segment.
   */
  coordinates: Scalars['Coordinates'];
  id: Scalars['ID'];
  /**
   * The length of this highway segment, in km.
   *
   * For highway lifelines, the total length of the segments of this highway within the considered radius for lifelines (30 miles).
   */
  length: Scalars['Int'];
  metadata?: Maybe<Array<Scalars['String']>>;
  /** The highway name */
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Array<Stats>>;
};


/** HighwaySegment is a portion of a highway */
export type HighwaySegmentStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

export type HistoricalEvent = {
  __typename?: 'HistoricalEvent';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: SliceHazardType;
  year: Scalars['DateTime'];
};

/** Informations required by the Intercom widget */
export type Intercom = {
  __typename?: 'Intercom';
  /** The root organizations of the user */
  companies?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** User email */
  email: Scalars['String'];
  /** User hash */
  hash: Scalars['String'];
  /** The internal UUID of the User */
  id: Scalars['String'];
  /** The language of the user */
  locale?: Maybe<Scalars['String']>;
  /** The name of the user (given_name + family_name) */
  name?: Maybe<Scalars['String']>;
};

/**
 * Input profile for inviting a user to any product and existing organization.
 *
 * NOTE: the product mentioned is just the first landing product when onboarding.
 * The user may later be added privileges to access other products.
 *
 * NOTE: at this moment, we do not support setting entitlements here, but rather
 * inherit proper entitlements from the assigned organization.
 */
export type InviteProfileInput = {
  /** The organization ID to invite the user to */
  organization: Scalars['ID'];
  /**
   * The product profile to invite the user to. This will determine the landing page of the onboarding workflow.
   *
   * NOTE: this setting has to be consistent with the entitlements of the organization the user is invited to.
   *
   * The API performs a consistency check to verify that the target product is entitled to the target organization.
   */
  product?: InputMaybe<Product>;
};

/** Input for user invitation */
export type InviteUserInput = {
  email: Scalars['String'];
  /** entitlements for a user may only be modified by a OneConcern admin. */
  entitlements?: InputMaybe<EntitlementsEditInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /**
   * Preferrence settings for the invited user (may be modified afterwards). By default, the new user
   * will inherit its preferences from the organization's defaults.
   */
  preferences?: InputMaybe<UserPreferencesEditInput>;
  /** Role of the invited user: if left unspecified, the invited user will be Contributor. */
  role?: InputMaybe<UserRole>;
  /**
   * Viewer details, when inviting a viewer.
   *
   * This is required if the user role above is DominoViewer.
   *
   * If not, this field is ignored.
   */
  viewerInput?: InputMaybe<ViewerInput>;
};

/** Language preferences (as main language tag, without variants) */
export enum LanguagePreferences {
  De = 'de',
  En = 'en',
  Ja = 'ja'
}

/**
 * LifelineEntitlement characterizes a lifeline granted for a given module and hazard type.
 *
 * Notice that a lifeline that is not specifically assigned to a hazard is implicitly available for all hazards.
 */
export type LifelineEntitlement = {
  __typename?: 'LifelineEntitlement';
  hazard: SliceHazardType;
  lifeline: LifelineType;
  module: DominoModule;
};

/**
 * LifelineStats is similar to AggregateStats stats, but contains the computed slice
 * and the name of the statistic reported.
 *
 * This type is currently not exposed to endpoints and is used internally to resolved lifelines statistics.
 */
export type LifelineStats = Stats & {
  __typename?: 'LifelineStats';
  id: Scalars['ID'];
  mean?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  /**
   * Recovery curve over time: this defines a curve which pillars are defined
   * by the projected term as a fractional year (rounded down to 2 digits) and
   * the recovery percentage (rounded down to the nearest integer).
   *
   * Example:
   * [ [x1, y1], [x2, y2], ... ]: [  [0.5, 50], [0.8, 75], [1.5, 85] ]
   *
   * Is empty for structuralDamage
   */
  recoveryCurve?: Maybe<Array<Array<Scalars['Float']>>>;
  /** Slice numerical identifier for the statistics reported for that lifeline */
  slice: Scalars['Int'];
  stddev?: Maybe<Scalars['Int']>;
};

/**
 * Lifeline types.
 *
 * NOTE: 'Structural' is not considered a lifeline for now and the 'commercial'
 * built object type is at this moment not implemented as a lifeline.
 */
export enum LifelineType {
  Airport = 'AIRPORT',
  Bridge = 'BRIDGE',
  Highway = 'HIGHWAY',
  People = 'PEOPLE',
  Port = 'PORT',
  Power = 'POWER'
}

/** Location descriptive information (not implemented) */
export type Location = {
  __typename?: 'Location';
  address: UserAddress;
  /** Type of location. Eg. home */
  type: LocationType;
};

/** The CSV upload report */
export type LocationFile = {
  __typename?: 'LocationFile';
  /** CSV format used to parse this file */
  format?: Maybe<CsvLocationsFormat>;
  id: Scalars['ID'];
  /**
   * If the user never uploaded a locations file, this will be true.
   * If there is a user locations file uploaded of that file was deleted, this will be false.
   */
  isDefault: Scalars['Boolean'];
  /** User update time */
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Original file name */
  name: Scalars['String'];
  notices: Array<FileNotice>;
  /** Locations actually loaded */
  totalAccepted: Scalars['Int'];
  /** Total MIB requests in this file with status 'pending' */
  totalPending: Scalars['Int'];
  /** Rows rejected (rejected + accepted = total) */
  totalRejected: Scalars['Int'];
  /** Total locations rows submitted from the CSV file */
  totalRows: Scalars['Int'];
  /** Rows in a warning state */
  totalWarnings: Scalars['Int'];
  /** Upload time */
  uploadedAt: Scalars['DateTime'];
  warning?: Maybe<Message>;
};

/** LocationFilter matches against multiple criteria when searching locations. */
export type LocationFilter = {
  /**
   * If unset, no filtering on MIB requests status.
   * If false, filters on locations without a pending MIB request.
   * If true, filters on locations with a pending MIB request.
   */
  isPendingMIB?: InputMaybe<Scalars['Boolean']>;
  /**
   * If unset, no filtering on UI visibility status.
   * If true, only visible locations are returned.
   * If false, only not-visible locations are returned.
   */
  isVisible?: InputMaybe<Scalars['Boolean']>;
};

/** LocationInput captures changes in address (unused for now). */
export type LocationInput = {
  address: AddressInput;
  type: LocationType;
};

/**
 * LocationMetadata reflects the information about a location that is captured by the CSV line.
 *
 * This type provides a structured view of the user's CSV (or synthetic CSV line after a location edit).
 */
export type LocationMetadata = {
  __typename?: 'LocationMetadata';
  /**
   * This address reflects the definition of the user input (CSV).
   *
   * Therefore, it does not get all the details (in particular house number, state, country are not hydrated as they don't have a corresponding CSV field).
   * Besides, the Street field is aggregated, as in the CSV view (e.g. {house number} {street} for US addresses).
   *
   * This address may differ from the resolved address, which is made available
   * below in the builtObjectAddress field (whenever a successful match was found).
   */
  address: Address;
  /** The field builtObjectAddress reflects the detailed address of the built object that was matched (for successfully matched lines). */
  builtObjectAddress?: Maybe<Address>;
  /**
   * The field forceInputAddress is an optional field in the CSV that forces the resolved address
   * to what the user has set as input.
   */
  forceInputAddress?: Maybe<Scalars['Boolean']>;
  /**
   * The field geocodedAddress reflects the detailed address that resulted from forward geocoding.
   *
   * Notice that forward geocoding does not apply to CSV lines that are provided with some inputCoordinates.
   */
  geocodedAddress?: Maybe<Address>;
  group?: Maybe<Scalars['String']>;
  /** The location ID */
  id: Scalars['ID'];
  /**
   * Those coordinates reflect a user's hint about coordinates in the uploaded CSV (or edited location), if any.
   *
   * Hinting the system with coordinates is not supposed to be the norm, but rather a convenient way to get better quality
   * matches whenever geocoding does not provide accurate coordinates.
   *
   * They may be different from the eventually resolved coordinates of the built object (in AuditLocation.builtObjectCoordinates) and
   * the geocoded coordinates (in AuditLocation.geocodedCoordinates).
   */
  inputCoordinates?: Maybe<Scalars['Coordinates']>;
  /** The name of the location provided by the user */
  name?: Maybe<Scalars['String']>;
  /** Various quality metrics that may be used to gauge how successful our match was */
  quality: MatchQualityMetrics;
  /**
   * The field skipZipcodeCheck is an optional field in the CSV that skips the consistency check
   * on the zipcode of the resolved address. This is used primarily when we hit some
   * difficult edge cases with zipcodes (small zipcodes or buildings close or overlapping a zipcode boundary).
   */
  skipZipcodeCheck?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** A user's location matched to a built object. */
export type LocationObject = BuiltObjectInterface & {
  __typename?: 'LocationObject';
  address: Address;
  /**
   * Lifelines: essential infrastructure around this location
   *
   * All airports within 50 miles
   */
  airports?: Maybe<Array<Airport>>;
  /** All bridges within 10 miles */
  bridges?: Maybe<Array<Bridge>>;
  buildingsCount: Scalars['Int'];
  builtObjectId: Scalars['ID'];
  coordinates: Scalars['Coordinates'];
  /** Data for finance users */
  finance?: Maybe<Finance>;
  /** The location group, used to organize how the user's locations are displayed. */
  group?: Maybe<Scalars['String']>;
  /** Closest highway segments for each highway within 50 miles */
  highways?: Maybe<Array<HighwaySegment>>;
  id: Scalars['ID'];
  /** The original address provided by the user */
  locationAddress: Scalars['String'];
  /** The coordinates of the location, as geocoded from the address entered by the user */
  locationCoordinates: Scalars['Coordinates'];
  name?: Maybe<Scalars['String']>;
  /** All residential subdivisions within 10 miles (aggregated by zip code) */
  people?: Maybe<Array<Subdivision>>;
  /** All sea ports within 50 miles */
  ports?: Maybe<Array<Port>>;
  /**
   * All power substations within 50 miles
   *
   * NOTE: power substation lifelines are currently not supported by the API
   */
  power?: Maybe<Array<Substation>>;
  /** Resilience statistics: a summarized view of the nearby lifelines */
  resilienceStats?: Maybe<Array<ResilienceStats>>;
  /**
   * Structural properties of the location building
   *
   * Commercial buildings are currently not considered as part of the lifelines.
   *
   * Therefore, only the current location is currently listed in the rendered collection
   * (at this moment we do not extend this reporting to nearby commercial buildings).
   */
  structural?: Maybe<Array<Building>>;
  /** The location type. At the moment, this is a free field, only for display or UI filtering. */
  type?: Maybe<Scalars['String']>;
  /** Is the location visible on the UI? Users may hide locations. */
  visibility: Scalars['Boolean'];
};


/** A user's location matched to a built object. */
export type LocationObjectBridgesArgs = {
  maxLength?: InputMaybe<Scalars['Int']>;
  minLength?: InputMaybe<Scalars['Int']>;
};


/** A user's location matched to a built object. */
export type LocationObjectResilienceStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** Integration status of a location */
export enum LocationStatus {
  /** Failure: the location could not be resolved */
  Failure = 'FAILURE',
  /** Successful integration */
  Success = 'SUCCESS'
}

/** Location types (unused) */
export enum LocationType {
  Home = 'Home',
  Other = 'Other',
  Work = 'Work'
}

/** Input parameters for updating loss rates for a location type. */
export type LocationTypeLossRatesInput = {
  /** Combined annual revenue in USD of all locations of this type */
  annualRevenue: Scalars['Float'];
  /** Location type */
  locationType: Scalars['String'];
  /** Loss rates for various intervals for this location type */
  lossRates?: InputMaybe<Array<LossRatesInput>>;
  /** One time losses for this location types at specific intervals */
  oneTimeLoss?: InputMaybe<Scalars['Float']>;
};

/** Describes the users materiality settings */
export type LocationTypeMaterialitySettings = {
  __typename?: 'LocationTypeMaterialitySettings';
  /** Combined annual revenue in USD of all locations of this location type */
  annualRevenue: Scalars['Float'];
  /** Location type */
  locationType: Scalars['String'];
  /** Loss rates for various lifeline types at given intervals */
  lossRates?: Maybe<Array<LossRates>>;
  /** One time losses for this location types at specific intervals */
  oneTimeLoss?: Maybe<Scalars['Float']>;
};

export type LocationUploadFile = {
  __typename?: 'LocationUploadFile';
  file: Scalars['Upload'];
};

/** Edit the visibility of a location (for users who want to specifically hide one of their locations). */
export type LocationVisibilityInput = {
  id: Scalars['ID'];
  visibility: Scalars['Boolean'];
};

/** LocationsInput is a predicate filter for searching locations. */
export type LocationsInput = {
  /** Filter on locations within some bounds. */
  bounds?: InputMaybe<ExtentFilter>;
  matchCriteria?: InputMaybe<LocationFilter>;
};

/** Result of a CSV address lookup. */
export type LookupResult = {
  __typename?: 'LookupResult';
  /** The CSV upload report */
  file: LocationFile;
  /**
   * The content of the original file, with the matched built object in the audit field,
   * ordered by their original line number.
   */
  lines?: Maybe<Array<ParsedFileLine>>;
};

/** Loss rates for lifeline types at a specific interval. */
export type LossRates = {
  __typename?: 'LossRates';
  /** percentage of hourly revenue lost due to airport lifeline downtime */
  airportsLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to bridge lifeline downtime */
  bridgesLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to community lifeline downtime */
  communityLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to highway lifeline downtime */
  highwaysLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to port lifeline downtime */
  portsLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to power lifeline downtime */
  powerLoss: Scalars['Float'];
  /** Interval in hours for which this loss rate applies */
  timeIntervalHours: Scalars['Int'];
};

/** Input loss rates for lifeline types at a specific interval. */
export type LossRatesInput = {
  /** percentage of hourly revenue lost due to airport lifeline downtime */
  airportsLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to bridge lifeline downtime */
  bridgesLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to community lifeline downtime */
  communityLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to highway lifeline downtime */
  highwaysLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to port lifeline downtime */
  portsLoss: Scalars['Float'];
  /** percentage of hourly revenue lost due to power lifeline downtime */
  powerLoss: Scalars['Float'];
  /** Interval in hours for which this loss rate applies */
  timeIntervalHours: Scalars['Int'];
};

/** Represents an existing MIB request. */
export type MibRequest = {
  __typename?: 'MIBRequest';
  /** Address of the building for which this request was filed. */
  address?: Maybe<Address>;
  /** ExternalID is the externally assigned ID for this MIB request. */
  externalID?: Maybe<Scalars['String']>;
  /** External ID of the user who filed this MIB request. */
  externalUserID: Scalars['ID'];
  /** File ID (internal) of the file that contains the address of the building for which this MIB request was filed. */
  fileID?: Maybe<Scalars['ID']>;
  /** The internal ID of the MIB request. */
  id: Scalars['ID'];
  /** Line number  in the file of the address of the building for which this request was filed. */
  lineNumber?: Maybe<Scalars['Int']>;
  /** Any additional metadata associated with this request. */
  metadata: Scalars['Map'];
  /** PostedAt represents the time when the user has submitted the request. */
  postedAt: Scalars['DateTime'];
  /** The progress status of the MIB request. */
  status: MibRequestStatus;
  /** UpdatedAt represents the time the request was last updated. */
  updatedAt: Scalars['DateTime'];
};

/** Input parameters for creation of a new MIB request. */
export type MibRequestInput = {
  /**
   * Address of the building that is requested to be added as a built object.
   *
   * Optional.
   */
  address?: InputMaybe<EditAddressInput>;
  /**
   * External ID for this request.
   *
   * Optional for now. Will be required in the future.
   */
  externalID?: InputMaybe<Scalars['String']>;
  /** File ID of the file from which this request/address originates. */
  fileID: Scalars['ID'];
  /** Line number in the file from which this request/address originates. */
  lineNumber: Scalars['Int'];
};

/** MIBRequestStatus indicates the status of a request for adding a missing built object. */
export enum MibRequestStatus {
  /** The user has cancelled the request */
  Cancelled = 'CANCELLED',
  /** Request completed: a new building is now available to match, with its associated predicted statistics.. */
  Completed = 'COMPLETED',
  /** No request */
  None = 'NONE',
  /** Request being processed by our teams */
  Pending = 'PENDING',
  /** The request is incomplete or impossible to fullfil. Unfortunately, our teams had to reject it. */
  Rejected = 'REJECTED'
}

/**
 * An audit section dedicated to match quality metrics recorded by the API during the matching process
 * (these metrics are updated whenever the user edits a location).
 *
 * All metrics might not be available for previously uploaded locations.
 */
export type MatchQualityMetrics = {
  __typename?: 'MatchQualityMetrics';
  /**
   * This is a text distance between the formatted original input address and the final resolved address (not account for case).
   *
   * It is not defined if the match failed.
   */
  addressLDistance?: Maybe<Scalars['Float']>;
  /**
   * Address text matching score established from detailed addresses, with a separate
   * (weighted) comparison on the different parts of the address.
   *
   * Not implemented yet.
   */
  addressMatchScore?: Maybe<Scalars['Float']>;
  /**
   * Corresponds to AuditLocation.distance.
   *
   * This is the (planar) distance between the forward-geocoded coordinates and the final coordinates (in meters).
   *
   * Whenever no forward geocoding is applied, coordinates from the user input are used, or the built object coordinates instead.
   *
   *    This distance becomes zero whenever the user forces the location to match a built object, without specific user-defined coordinates:
   *    as in that case, user coordinates and forward-geocoded coordinates are forced to equate those of the resolved built object.
   *
   *    It may not be defined if the line is rejected.
   */
  distance?: Maybe<Scalars['Float']>;
  /**
   * Planar distance between forward and reverse geocoding.
   *
   * This measures the distance in meters between both results from Mapbox.
   *
   * Whenever forward geocoding is not applied, use user coordinates instead, or the built object coordinates instead.
   *
   * Notice that this distance is not defined when the line is rejected.
   */
  geocodedDistance?: Maybe<Scalars['Float']>;
  /**
   * This is a text distance between the formatted original input address and the normalized formatted address provided by the forward geocoding (not accounting for case).
   *
   * The Levenshtein distance is defined by the number of permutations necessary to equalize two character strings.
   *
   * It is not defined if forward-geocoding was skipped (i.e. the user provided coordinates as a hint).
   */
  locationLDistance?: Maybe<Scalars['Float']>;
  /**
   * Area (in m²) of the triangle defined by the 3 following points:
   * * forward geocoded location (or user input)
   * * built object location, as per the BOS database
   * * reverse geocoded location
   *
   * Notice that this distance is not defined when the line is rejected.
   *
   *    The larger this area, the more likely to have matched a building that the user
   *    did not quite expect. This area may be interpreted as a margin of error (or grey zone area)
   *    within which our different data sources are approximately in agreement.
   *
   *    The area is 0 whenever the user edits the location so as to force user input to equate the resolved BOS built object.
   */
  matchingArea?: Maybe<Scalars['Float']>;
  /**
   * Number of built object candidates for matching within a 500m radius
   * around the forward-geocoded coordinates of the input address.
   *
   *    The larger this number, the more likely to have matched a close, but unexpected result.
   *
   *    Whenever the location has been edited to match a single built object, this is reset to 0.
   */
  matchingCandidates?: Maybe<Scalars['Int']>;
  /**
   * The radius of the circle circonscribed by the above triangle (in meters).
   *    The circonsribed circle is the smallest circle touching the vertices of the triangle:
   *    it contains the triangle (unlike the inscribed circle, which is smaller and is contained by the triangle).
   *
   *    The larger this radius, the more likely to have matched a building that the user
   *    did not quite expect.
   *
   *    It is similar to 'matchingArea' but is easier to interpret and less prone to wrongly
   *    interpret as a good result the small area of a large, but very flat triangle.
   *
   *    Contrary to the matchingArea indicator, this radius is in general not zero even if the user input as been
   *    forced to match the built object.
   */
  matchingRadius?: Maybe<Scalars['Float']>;
  /**
   * Planar distance between reverse geocoding and resolved built object.
   *
   * This measures the distance in meters between the built object from the BOS database
   * and the coordinates reported back by the reverse geocoding operation.
   *
   * Notice that this distance is not defined when the line is rejected.
   *
   *    This distance reflects the level of agreement between our BOS database (our reference for coordinates) and Mapbox
   *    (our reference for addresses) when resolving the eventual address of a location.
   *
   *    The coordinates from reverse geocoding are only used for this quality assessment: these are not used
   *    as a reference.
   */
  reverseDistance?: Maybe<Scalars['Float']>;
};

/** All of the users materiality related settings */
export type MaterialitySettings = {
  __typename?: 'MaterialitySettings';
  /** Location type specific materiality settings like losses, etc */
  locationTypeMaterialitySettings: Array<LocationTypeMaterialitySettings>;
  /** The materiality threshold percent above which any loss counts as material loss */
  materialityThreshold: Scalars['Float'];
};

/** Input parameters for updating a users materiality settings */
export type MaterialitySettingsInput = {
  /** Input parameters for updating loss rates for a location type. */
  locationTypeLossRatesInput?: InputMaybe<Array<InputMaybe<LocationTypeLossRatesInput>>>;
  /** The materiality threshold percent above which any loss counts as material loss */
  materialityThreshold?: InputMaybe<Scalars['Float']>;
};

/** Preferred measurements system units */
export enum MeasurementPreferences {
  ImperialSystem = 'ImperialSystem',
  MetricSystem = 'MetricSystem'
}

/** Message with error code */
export type Message = {
  __typename?: 'Message';
  code: Scalars['Int'];
  message: Scalars['String'];
};

/**
 * ModuleEditInput captures a (product,module) parameter to filter or change entitlements.
 *
 * If this entitlement is tied to a region, specify a legit region as provided by the EntitlementsStructure.
 */
export type ModuleEditInput = {
  module: Scalars['String'];
  product: Product;
  region?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** User can get ROI for power downtime as a result of GPT integration */
  calculateROI: RoiResult;
  /**
   * Change user organization as super admin.
   *
   * Only super admin is able to change user organization.
   *
   * CurrentOrganizationId is the path of the current organization.
   *
   * TargetOrganizationId is the path of the new organization.
   */
  changeUserOrganization: Scalars['Boolean'];
  /** Create a new organization. */
  createOrganization: Organization;
  /** Delete the current user's file of locations */
  deleteLocationsFile: Message;
  /** Delete an organization. All its members are automatically disabled, unless they are members of some other organization. */
  deleteOrganization: Scalars['Boolean'];
  /**
   * Delete user is only available to OneConcern admins.
   *
   * Organization admins should use disableUser instead.
   */
  deleteUser: Scalars['Boolean'];
  /**
   * Disable a user in the current user''s organization: that user won''t be able to log in.
   *
   * Only organization admin users may perform this operation.
   */
  disableUser: Scalars['Boolean'];
  /**
   * Update all fields for a location
   *
   * The main use case is assumed to be when the LocationInput points to a new built object ID.
   * In that case, the coordinates are those of this built object.
   *
   * The input address always applies. If missing, the address is resolved like when uploading a CSV (i.e. reverse geocode from the coordinates of the built object).
   *
   * Ancillary supported use-cases:
   * 1. No built object ID, coordinates are provided: behaves like CSV upload (resolves to nearest built object).
   * 2. No built object ID, no coordinates are provided, an address is provided: behaves like CSV upload (geocode address, then resolves to nearest built object).
   */
  editLocation: LocationObject;
  /**
   * Update the current user.
   *
   * Any enabled user may modify its own account information.
   *
   * NOTE: users can't modify their role. This field is ignored if present in the input.
   */
  editUser: User;
  /** Enable a user in the current user's organization. */
  enableUser: Scalars['Boolean'];
  /**
   * 1C admin may invite any user to join an existing organization, with some product and role profile.
   *
   * If the target organization is not entitled to the target product specified in the profile, an error is returned.
   */
  inviteAnyUser: User;
  /**
   * Onboard a new user in the current user's organization.
   *
   * This creates a new enabled user then posts the invitation.
   *
   * The user will receive an email to start the onboarding workflow.
   *
   * Only organization admin users may perform this operation.
   */
  inviteUser: User;
  /**
   * Resend an invitation email for an already created user.
   *
   * This may be used for instance, if the user has been edited with a corrected email address, or if the
   * initial invitation has expired before the recipient could join.
   *
   * At the end of the onboarding workflow, the invitation link provided in the email will redirect the user to the product the user is entitled to.
   * An error is reported and no email send if the organization the user is member of is not entitled to any product.
   *
   * The viewer input argument is required if the re-invited user is a viewer. Otherwise, it is ignored.
   *
   * NOTE: it is possible to resend an invitation to a user that has already joined. In that case, the onboarding workflow may be shortened.
   */
  resendInvite: User;
  /**
   * Reset the password of a user in the current user's organization.
   *
   * The user will receive an email to update the password.
   *
   * Only organization admin users may perform this operation.
   */
  resetUserPassword: Scalars['Boolean'];
  setLocationUploadFile: LocationUploadFile;
  /**
   * Share analysis url to Viewer.
   *
   * This endpoint will send email with analysis url to Viewer
   */
  shareAnalysis: Scalars['Boolean'];
  /** Invite viewer user in order to be able to see a location which was shared for this purpose */
  shareLocationInvite: Scalars['Boolean'];
  /**
   * Resend an invitation email for an already created user.
   *
   * This is a clone of resendInvite but with less restrictions in order to be used in shareLocation flow.
   */
  shareLocationResendInvite: User;
  /** Submit a new mib request */
  submitMIBRequest: MibRequest;
  /**
   * Update any organization, including to change their members.
   *
   * Only OneConcern admin users may edit the settings of another organization.
   *
   * It is possible to alter the profiles of the organization, but the zones (country or region) initially alloted to the profile cannot be modified at this moment.
   */
  updateAnyOrganization: Organization;
  /**
   * Update default vulnerability thresholds
   *
   * This endpoint can only be used by a oneconcern admin (not an organization admin).
   */
  updateDefaultThresholds: User;
  /** Update the visibility status of a location */
  updateLocation: LocationObject;
  /** Update the visibility status of several locations in one go */
  updateLocations?: Maybe<Array<LocationObject>>;
  /** CS admin update for MIB Requests. */
  updateMIBRequests: MibRequest;
  /** Update a users's general materiality settings */
  updateMaterialitySettings: MaterialitySettings;
  /** User update for their MIB requests. */
  updateMyMIBRequests: MibRequest;
  /**
   * Update the current user's organization.
   *
   * Only organization admin users may edit their organization settings.
   *
   * Only OneConcern admin users may edit organization entitlements.
   */
  updateOrganization: Organization;
  /**
   * Update the current user's password
   *
   * Any user may normally change their password.
   */
  updatePassword: Scalars['Boolean'];
  /**
   * Update the user's vulnerability thresholds
   *
   * NOTE: the zero value for any input threshold reverts this value to its default.
   */
  updateThresholds: User;
  /**
   * Update another user in the current user's organization.
   *
   * NOTE: an admin can modify another user's role. This makes it possible to delegate or revoke admin powers over the same organization.
   *
   * Only organization admin users may edit other users in their organization.
   */
  updateUser: User;
  /**
   * Upload a CSV file of locations
   *
   * The CSV fields are separated by a comma (','). Field values containing a comma must be quoted with double quotes.
   * The first line of the file is assumed to contain a line of headings.
   *
   * Upload supports different templates, detected according to the header. At this moment, 2 formats are supported.
   *
   * 1. Simple template - with full-text address
   *
   *   Header: Location name,Address[,Location type, Location group]
   *
   *   The CSV line is then structured as follows:
   *   {location name},{location address},{location type},{location group}
   *
   *   The two first fields (name and address) are required. Group and type are strings freely defined by the user.
   *
   * 2. Full US address template - with detailed address
   *
   *   Header: Location name,Street Address,City,State,Zip Code,[Latitude,Longitude,Location type, Location group, Force input address,Skip Zipcode]
   *
   *   The CSV line is then structured as follows:
   *   {location name},{street address: number and name},{city},{state},{zip code},{latitude},{longitude},{location type},{location group}
   *
   *   The five first fields (name, address, city, state, zipcode) are required.
   *   The state is a 2-letters code.
   *   Latitude and longitude are expressed in degrees. If one of latitude or longitude is specified, both values must be present.
   *
   *   Group and type are strings freely defined by the user.
   *
   * 3. Full JP address template (ja version)
   *
   *   Header: ロケーション名,郵便番号,都道府県,郡市,区町村番地,緯度,経度,ロケーションタイプ,ロケーショングループ,入力アドレスを強制します,郵便番号をスキップする
   *
   * 4. Full JP address template (en version)
   *
   *   Header: Location name,Zip Code,Prefecture,City,Street Address,[Latitude,Longitude,Location type,Location group,Force input address,Skip Zipcode]
   */
  uploadLocationsFile: LocationFile;
  /**
   * Upload a CSV file to define the set of sample locations.
   *
   * These locations belong to a special 'default' user and are attributed by default
   * to any user who hasn't uploaded its own CSV yet.
   *
   * The file formatting rules are the same as for regular user uploads.
   *
   * This endpoint can only be used by a oneconcern admin (not an organization admin).
   */
  uploadSampleFile: LocationFile;
  /** As a 1C customer success admin, upload a CSV file on behalf of a user */
  uploadUserLocationsFile: LocationFile;
};


export type MutationCalculateRoiArgs = {
  input: RoiInput;
};


export type MutationChangeUserOrganizationArgs = {
  currentOrganizationId: Scalars['String'];
  targetOrganizationId: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreateOrganizationArgs = {
  org: CreateOrganizationAdminInput;
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDisableUserArgs = {
  id: Scalars['ID'];
};


export type MutationEditLocationArgs = {
  input: EditLocationInput;
};


export type MutationEditUserArgs = {
  userInput: UserEditInput;
};


export type MutationEnableUserArgs = {
  id: Scalars['ID'];
};


export type MutationInviteAnyUserArgs = {
  invite: InviteUserInput;
  profile?: InputMaybe<InviteProfileInput>;
};


export type MutationInviteUserArgs = {
  invite: InviteUserInput;
};


export type MutationResendInviteArgs = {
  id: Scalars['ID'];
};


export type MutationResetUserPasswordArgs = {
  id: Scalars['ID'];
};


export type MutationSetLocationUploadFileArgs = {
  file: Scalars['Upload'];
};


export type MutationShareAnalysisArgs = {
  input: ShareAnalysisInput;
};


export type MutationShareLocationInviteArgs = {
  invite: InviteUserInput;
};


export type MutationShareLocationResendInviteArgs = {
  id: Scalars['ID'];
  viewer?: InputMaybe<ViewerInput>;
};


export type MutationSubmitMibRequestArgs = {
  input: MibRequestInput;
};


export type MutationUpdateAnyOrganizationArgs = {
  org: OrganizationAdminInput;
};


export type MutationUpdateDefaultThresholdsArgs = {
  thresholds: ResilienceThresholdsInput;
};


export type MutationUpdateLocationArgs = {
  input: LocationVisibilityInput;
};


export type MutationUpdateLocationsArgs = {
  input?: InputMaybe<Array<LocationVisibilityInput>>;
};


export type MutationUpdateMibRequestsArgs = {
  input: AdminUpdateMibRequestsInput;
};


export type MutationUpdateMaterialitySettingsArgs = {
  input: MaterialitySettingsInput;
};


export type MutationUpdateMyMibRequestsArgs = {
  input: UpdateMyMibRequestsInput;
};


export type MutationUpdateOrganizationArgs = {
  org: OrganizationInput;
};


export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUpdateThresholdsArgs = {
  thresholds: ResilienceThresholdsInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  userInput: UserEditInput;
};


export type MutationUploadLocationsFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadSampleFileArgs = {
  file: Scalars['Upload'];
};


export type MutationUploadUserLocationsFileArgs = {
  file: Scalars['Upload'];
  id: Scalars['ID'];
};

/** Input parameters for users querying their existing MIB requests. */
export type MyMibRequestsInput = {
  /** search by address. */
  address?: InputMaybe<Scalars['String']>;
  /** search by external ID for this request. */
  externalID?: InputMaybe<Scalars['String']>;
  /** search by file ID. */
  fileID?: InputMaybe<Scalars['ID']>;
  /** search by status of the request. */
  status?: InputMaybe<MibRequestStatus>;
};

/** File upload notice codes */
export enum NoticeCode {
  /** Address geocoding error */
  ErrorGeocode = 'ERROR_GEOCODE',
  /** An error caused the line to be omitted (e.g. missing field) */
  ErrorOmit = 'ERROR_OMIT',
  /** Other errors */
  ErrorOther = 'ERROR_OTHER',
  /** An error that a field was invalid (e.g. wrong postcode format) */
  ErrorParsing = 'ERROR_PARSING',
  /** Unresolved building */
  ErrorResolve = 'ERROR_RESOLVE',
  /** Interpolated address */
  WarnInterpolate = 'WARN_INTERPOLATE',
  /** A warning that the user original input has been modified (e.g. completed address) */
  WarnModify = 'WARN_MODIFY',
  /** A warning that the user original zipcode did not match the geocoded zipcode and was modified */
  WarnZipcode = 'WARN_ZIPCODE'
}

/** Channels to notify users */
export enum NotificationsChannel {
  Email = 'EMAIL'
}

/** An organization is a group of users. */
export type Organization = {
  __typename?: 'Organization';
  /** Private notes from the administrator about this organization (edited by admin). */
  adminNotes?: Maybe<Scalars['String']>;
  /** Generally available description of the organization (edited by admin). */
  description?: Maybe<Scalars['String']>;
  /** English name (system name). This cannot be modified. Localized names may be modified however. */
  enName: Scalars['String'];
  /**
   * Organization-level entitlements.
   *
   * All organization members inherit this setting.
   */
  entitlements?: Maybe<Entitlements>;
  /**
   * The unique ID of an organization is formed by its path, e.g. '/name'.
   *
   * This field is immutable.
   *
   * NOTE: this IS not the internal UUID of the organization, which is not exposed by this API.
   */
  id: Scalars['ID'];
  /** Localized name, according to the requester's preferred language. Defaults to the English name if no localization is available. */
  name: Scalars['String'];
  /** All localized names */
  names: Scalars['Map'];
  /** Preferences defined at the organization level and that apply as defaults to all its members. */
  preferences?: Maybe<OrganizationPreferences>;
  /**
   * Users that belong to this organization.
   *
   * Normally, only admins may see their organization's users.
   *
   * IMPORTANT: the user entitlements field is not resolved in this collection.
   */
  users: Array<User>;
};


/** An organization is a group of users. */
export type OrganizationUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrdering>>;
  status?: InputMaybe<UserStatus>;
};

export type OrganizationAdminInput = {
  /**
   * Admin users of the organization, identified by their user ID (uuid) or email.
   * If such a user is not already a member of the organization, it is added.
   *
   * The listed users become 'organization admin's for that organization (not OneConcern admins).
   * Such an 'organization admin' should only administer one organization (this constraint is not enforced).
   *
   * Admin users should have already been created. Non-existing users are skipped.
   *
   * The new admins may be in a disabled status, or may have an invitation still pending.
   *
   * To remove admins later, either edit their role or remove them from the list of organization members by editing the organization.
   */
  addAdmins?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Members to add to the organization, identified by their user ID (uuid) or email.
   *
   * New members should have already been created. Non-existing users are skipped.
   *
   * The new members may be in a disabled status, or may have an invitation still pending.
   *
   * If some users are already members of the organization, they are just skipped and there is no error.
   *
   * No users are removed if this list doesn't reflect all members of the organization.
   */
  addUsers?: InputMaybe<Array<Scalars['String']>>;
  /** Private notes from the administrator about this organization (edited by admin). */
  adminNotes?: InputMaybe<Scalars['String']>;
  /** Generally available description of the organization (edited by admin). */
  description?: InputMaybe<Scalars['String']>;
  /** additional entitlements settings (supersedes profiles) */
  entitlements?: InputMaybe<EntitlementsEditInput>;
  /** The path of the organization */
  id: Scalars['String'];
  /**
   * Sets the display name for the current user's locale
   *
   * NOTE:
   *   When updating, this only affects the display name, not the system name.
   *
   *   The system name of the organization is immutable. You must recreate an organization to change it.
   */
  name?: InputMaybe<Scalars['String']>;
  /** Sets localized names. The map is of the form: {'de': 'das Organisation', 'en': 'the organization'} */
  names?: InputMaybe<Scalars['Map']>;
  /** Sets default preferences for the members of this organization */
  preferences?: InputMaybe<OrganizationPreferencesEditInput>;
  /**
   * Applies predefined product profile(s) to the organization. Note that previously applied roles and entitlements are not removed.
   *
   * DEPRECATION NOTICE: specifying the subscriptions for an organization using profiles is now deprecated.
   * The functionality remains supported for backward-compatibility and will be removed in the future.
   * It is recommanded to upgrade mutations that use ProductProfileInput to the more general EntitlementsEditInput specification.
   * @deprecated superseded by entitlements
   */
  profiles?: InputMaybe<Array<ProductProfileInput>>;
  /**
   * Members to remove from the organization, identified by their user ID (uuid) or email.
   *
   * If some users to be removed are not members of the organization, they are just skipped and there is no error.
   */
  removeUsers?: InputMaybe<Array<Scalars['String']>>;
};

export type OrganizationInput = {
  /** Private notes from the administrator about this organization (edited by admin). */
  adminNotes?: InputMaybe<Scalars['String']>;
  /** Generally available description of the organization (edited by admin). */
  description?: InputMaybe<Scalars['String']>;
  /** Sets the display name for the current user's locale */
  name?: InputMaybe<Scalars['String']>;
  /** Sets localized names. The map is of the form: {'de': 'das Organisation', 'en': 'the organization'} */
  names?: InputMaybe<Scalars['Map']>;
  /** Sets default preferences for the members of this organization */
  preferences?: InputMaybe<OrganizationPreferencesEditInput>;
};

/** Preferences set at the level of an organization apply as defaults for all its members. */
export type OrganizationPreferences = {
  __typename?: 'OrganizationPreferences';
  /**
   * All supported preferences
   *
   * This allows for accessing extensions not yet covered by the enum fields above.
   */
  allDefaultPreferences?: Maybe<Scalars['Map']>;
  /**
   * Country name (English name of the country)
   *
   * NOTE: this property is at the moment unconstrained. It should reflect the full name of the country code.
   */
  defaultCountry?: Maybe<Scalars['String']>;
  /**
   * ISO-3166-1 ('alpha-2') country code (e.g. US, JP).
   *
   * If not specifically defined by the user, derived from the language: en assumes US and ja assumes JP by default.
   */
  defaultCountryCode?: Maybe<CountryCode>;
  /**
   * Preferred language (e.g. en, ja, ...).
   *
   * If not specified by the user, the default is 'en'.
   *
   * NOTE: only the main language tag is supported at this moment. Variants (e.g. zh-gan, en-CA) are ignored.
   *
   * Members of the organization will inherit this default language when first joining.
   */
  defaultLanguage?: Maybe<LanguagePreferences>;
  /**
   * Notifications Channels.
   *
   * Specify channels used in order to notify users within organization.
   *
   * NOTE: this capability is only enabled for READY users at the moment.
   */
  defaultNotificationsChannels?: Maybe<Array<NotificationsChannel>>;
  /**
   * Notifications Enabled.
   *
   * Specify if notifications are enabled for users within organization.
   *
   * NOTE: this capability is only enabled for READY users at the moment.
   */
  defaultNotificationsEnabled?: Maybe<Scalars['Boolean']>;
  /**
   * Preferred timezone (e.g. US/Aleutian, PST8PDT...)
   *
   * Members of the organization will inherit this default timezone preference.
   */
  defaultTimezone?: Maybe<Scalars['String']>;
  /**
   * Preferred unit system.
   *
   * If not specifically defined by the user, derived from the country code.
   *
   * If no country code is defined, derived from the language ('ja' assumes a preference for the metrics system, otherwise the imperial system applies).
   */
  defaultUnits?: Maybe<MeasurementPreferences>;
};

/** OrganizationPreferencesEditInput captures changes to the organization's default preferences. */
export type OrganizationPreferencesEditInput = {
  /** Any key in allPreferences that conflicts with other inputs will take precedence. */
  allDefaultPreferences?: InputMaybe<Scalars['Map']>;
  defaultCountry?: InputMaybe<Scalars['String']>;
  defaultCountryCode?: InputMaybe<CountryCode>;
  /**
   * Default language setting for all members of this organization. Individual users may override this default in their user preferences.
   *
   * Notice that language is currently a setting that is defined at user onboarding time.
   * Altering the default for the organization won't change dynamically the settings of users that are already member of this organization.
   */
  defaultLanguage?: InputMaybe<LanguagePreferences>;
  defaultNotificationsChannels?: InputMaybe<Array<NotificationsChannel>>;
  /** An organization may disable email notifications to its members if they don't want to receive these. */
  defaultNotificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  /**
   * Default timezone for all members of this organization. Individual users may override this default in their user preferences.
   *
   * The timezone MUST be a valid IANA-registered timezone or its abbreviation (https://www.iana.org/time-zones), e.g: 'Africa/Cairo', 'America/Los_Angeles', 'PDT', 'EST'...
   */
  defaultTimezone?: InputMaybe<Scalars['String']>;
  defaultUnits?: InputMaybe<MeasurementPreferences>;
};

/**
 * A filter to retrieve the lines from the originally uploaded CSV.
 *
 * Notice that this reflects the changes of subsequent edits by the user.
 */
export type OriginalFileFilter = {
  group?: InputMaybe<Scalars['String']>;
  /** Retrieves the line for a specific location (successfully integrated or not). */
  locationID?: InputMaybe<Scalars['String']>;
  processStatus?: InputMaybe<LocationStatus>;
  type?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<Scalars['Boolean']>;
};

export type ParsedFileLine = {
  __typename?: 'ParsedFileLine';
  /** Audit record of what happened to that line */
  audit: AuditLocation;
  /** Fields for that line (up to 10 are kept) */
  fields?: Maybe<Array<Scalars['String']>>;
  /** Synthetic ID made of file ID + '|' + line number */
  id: Scalars['ID'];
  /** The line in the CSV (without header) */
  lineNumber: Scalars['Int'];
  /** User-defined visibility flag */
  visibility: Scalars['Boolean'];
};

/** Permission targeted by an event. */
export type Permission = {
  __typename?: 'Permission';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** People specific information */
export type PersonInfo = {
  __typename?: 'PersonInfo';
  /** Contact details */
  contact?: Maybe<ContactInfo>;
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  /** Not implemented */
  locations: Array<Location>;
};

/** PersonInfoEditInput captures changes to a user's profile (personal informations). */
export type PersonInfoEditInput = {
  contact?: InputMaybe<ContactInfoInput>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locations?: InputMaybe<Array<InputMaybe<LocationInput>>>;
};

export type Port = BasicLifelineAsset & {
  __typename?: 'Port';
  actualID: Scalars['String'];
  coordinates: Scalars['Coordinates'];
  cranes: Scalars['Int'];
  id: Scalars['ID'];
  metadata?: Maybe<Array<Scalars['String']>>;
  /** The port name */
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Array<Stats>>;
  wharves: Scalars['Int'];
  yearBuilt?: Maybe<Scalars['String']>;
};


export type PortStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** OneConcern products. */
export enum Product {
  Domino = 'DOMINO',
  Ready = 'READY'
}

/**
 * ProductModuleFeature describes a module-driven feature for an organization or user.
 *
 * A module-driven feature pertains to entitlements that are defined only along the
 * (product,module,feature) dimensions (to wit, do not jointly consider the hazards, regions dimensions).
 *
 * Features that are tied to a specific hazard have the hazard field hydrated.
 *
 * Example: the 'notifications' feature for READY is such a feature.
 *
 * Example of modules:
 *  for DOMINO, modules are currently defined as:
 *     * us: the US flavor of the app
 *     * jp: the JP flavor of the app
 *
 *  for READY, modules are:
 *     * live_hazard
 *     * e2e-test: switches to special behavior for QA and e2e test predictions
 *
 * The isInherited flag indicates if this setting is inherited from a parent organization.
 * It is not defined in all use cases.
 *
 * NOTE: strings used are not case-sensitive.
 */
export type ProductFeature = {
  __typename?: 'ProductFeature';
  description: Scalars['String'];
  feature: Scalars['String'];
  hazard?: Maybe<Scalars['String']>;
  /**
   * isInherited determines if the permission was inherited from an organization.
   * This applies only when an admin queries entitlements for other users and remains
   * undefined for the user's own entitlements.
   */
  isInherited?: Maybe<Scalars['Boolean']>;
  module: Scalars['String'];
  /**
   * The localized display names for this feature. All translations may not be supported.
   *
   * The key corresponds to the language short code. Example: {'en': 'Tokyo', 'ja': '東京都区部'}.
   *
   * Names and description are only resolved in the context of a call from 'entitlementsStructure'.
   */
  names: Scalars['Map'];
  product: Product;
  region?: Maybe<Scalars['String']>;
};

/** ProductHazard describes a hazard assigned to a given product. */
export type ProductHazard = {
  __typename?: 'ProductHazard';
  hazard: Scalars['String'];
  isInherited?: Maybe<Scalars['Boolean']>;
  module: Scalars['String'];
  product: Product;
  region?: Maybe<Scalars['String']>;
};

/**
 * ProductModule defines a generic entitlement (i.e. not using product-specific enum types)
 * for modules enabled for a given product.
 */
export type ProductModule = {
  __typename?: 'ProductModule';
  description: Scalars['String'];
  /**
   * isInherited determines if the permission was inherited from an organization.
   * This applies only when an admin queries entitlements for other users and remains
   * undefined for the user's own entitlements.
   */
  isInherited?: Maybe<Scalars['Boolean']>;
  /**
   * Is this module defining a subscription (true) or merely a subscription option (false)?
   *
   * isMain is only resolved in the context of a call from 'entitlementsStructure'.
   */
  isMain: Scalars['Boolean'];
  module: Scalars['String'];
  /**
   * The localized display names for this module. All translations may not be supported.
   *
   * The key corresponds to the language short code. Example: {'en': 'Tokyo', 'ja': '東京都区部'}.
   *
   * Names and description are only resolved in the context of a call from 'entitlementsStructure'.
   */
  names: Scalars['Map'];
  product: Product;
  region?: Maybe<Scalars['String']>;
};

/**
 * ProductProfileInput specifies a product usage profile for an organization.
 *
 * A product profile results in granting predefined roles and entitlements to cover most use cases.
 *
 * DEPRECATION NOTICE: specifying the subscriptions for an organization using profiles is now deprecated.
 * The functionality remains supported for backward-compatibility and will be removed in the future.
 * It is recommanded to upgrade mutations that use ProductProfileInput to the more general EntitlementsEditInput specification.
 */
export type ProductProfileInput = {
  /** Product to be used (e.g. DOMINO, READY) */
  product: Product;
  /**
   * region or country that characterize the profile. This is required.
   *
   * This may be a READY region (e.g. 9_kumamoto) or a DOMINO country (e.g. us or jp).
   *
   * This input is not case-sensitive.
   */
  zones: Array<Scalars['String']>;
};

/** ProductRegion describes regions assigned to a given product. */
export type ProductRegion = {
  __typename?: 'ProductRegion';
  /**
   * The localized display names for this region.
   *
   * The key corresponds to the language short code. Example: {'en': 'Tokyo', 'ja': '東京都区部'}.
   *
   * Names are only resolved in the context of a call from 'entitlementsStructure'.
   */
  names: Scalars['Map'];
  product: Product;
  /** The region unique key communicated across systems. Example: '1_tokyo'. */
  region: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Get all events using the AuditFilter.
   *
   * Only oneconcern admins may request audit trail.
   *
   * If no filter is specify, will get all events we stored in IDP database.
   */
  auditTrail: Array<AuditEvent>;
  /**
   * Finds a built object by its ID.
   *
   * This must be the actualID reported by a query.
   *
   * NOTE: some reported lifelines are NOT actual built objects but aggregates (e.g. residentials, highways)
   * and cannot be queried this way.
   */
  builtObject: BuiltObject;
  /**
   * Not implemented: I don't really know what this was intented for in the first place
   * @deprecated not specified, not used, not implemented: to be removed
   */
  builtObjects?: Maybe<Array<LocationObject>>;
  /** Performs new materiality calculation. This calculation happens for a given slice inside the resilienceStats subquery */
  calculateMateriality?: Maybe<Array<LocationObject>>;
  /** Retrieve the structure of entitlements for all products. */
  entitlementsStructure: EntitlementsStructure;
  /**
   * Retrieve all legit regions or countries for a product. If the product is not specified, all legit values are rendered.
   * @deprecated superseded by entitlementsStructure
   */
  getAllZones: Array<Scalars['String']>;
  /** Get BOS companies */
  getCompanies: Array<Maybe<Company>>;
  getCompany?: Maybe<Company>;
  /** Default vulnerability thresholds */
  getDefaultThresholds: ResilienceThresholds;
  /** Get current materiality settings */
  getMaterialitySettings: MaterialitySettings;
  /**
   * Finds all nearby built objects near (long,lat) coordinates (by default, this search Commercial built objects within 500m).
   *
   * Output is ordered from closest to farthest to the center.
   *
   * Limitation: notice that resilience statistics and lifelines are only supported from commercial built objects (selecting these fields for another built object type yields an error).
   */
  getNearbyBuiltObjects?: Maybe<Array<BuiltObject>>;
  /**
   * Retrieve details about some organization, including the list of its members.
   *
   * NOTE: the ID of the organization corresponds to its path, e.g. '/oneconcern'
   */
  getOrganization: Organization;
  /** Retrieve all organizations with some windowing. Regions are ordered by their ID (organizational path). */
  getOrganizations: Array<Organization>;
  /**
   * Retrieve the details about a user in the current user's organization.
   *
   * NOTE: in particular, we resolve at this point the role of this user.
   */
  getUser: User;
  /** All known historical events */
  historicalEvents: Array<HistoricalEvent>;
  /**
   * Query for a single location object, given an id.
   *
   * NOTE: with the current guards in place, this selection allows for retrieving lifelines.
   */
  location: LocationObject;
  locationUploadFile: LocationUploadFile;
  /**
   * All the user's locations
   *
   * NOTE: with the current guards in place, this selection forbids the retrieval of lifelines.
   */
  locations?: Maybe<Array<LocationObject>>;
  /**
   * Performs a lookup on built objects based on a CSV file of addresses.
   *
   * The file format is the same as for uploadLocations.
   *
   * This resolves built objects and does not alter the current user locations.
   */
  lookupAddresses: LookupResult;
  /** CS admin query for MIB Requests. */
  mibRequests?: Maybe<Array<MibRequest>>;
  /** User query for MIB Requests. */
  myMIBRequests?: Maybe<Array<MibRequest>>;
  /**
   * Retrieve the organization the current user is member of.
   *
   * Any user may normally view their organization (the 'account:view-profile' role is granted by default).
   *
   * NOTE: in the case the user is a member of several organizations in a hierarchy, this yields the topmost one.
   * In the case of ties (e.g. the user is member of several top level organizations), the first in lexicographical order is selected.
   */
  organization: Organization;
  /** reverseGeocode returns a detailed address from geodesic coordinates (long, lat). */
  reverseGeocode: Address;
  /**
   * Search a user's locations given a partial address.
   *
   * This returns up to 5 matches by default.
   *
   * NOTE(1): the location address matched is the geocoded address, not the address initially entered by the user.
   *
   * NOTE(2): with the current guards in place, lifelines may only be queried from there if limit = 1.
   */
  search?: Maybe<Array<LocationObject>>;
  /**
   * Finds the closest building near (long,lat) coordinates (by default, this searches among Commercial built objects within 500m).
   *
   * Limitation: notice that resilience statistics and lifelines are only supported from commercial built objects (selecting these fields for another built object type yields an error).
   */
  searchNearestBuiltObject: BuiltObject;
  /**
   * Get all users from all organizations.
   *
   * Only oneconcern admins may request all users.
   *
   * For filters there can be use: ByEmail, ByLastName.
   * The default ordering is by lexicographical order of the last name.
   */
  searchUsers: Array<User>;
  /**
   * All legit slices used to query statistics
   *
   * A slice corresponds to a specific simulation scenario used to compute statistics.
   */
  sliceIndexes?: Maybe<Array<Slice>>;
  /**
   * Retrieve the current user.
   *
   * Any user may normally view their personal profile (the 'account:view-profile' role is granted by default).
   */
  user: User;
  /**
   * Members of the current user's organization. This is equivalent to querying 'organization { users { ... } }'.
   *
   * A filter on the status may be set. By default, there is no filter.
   * The default ordering is by lexicographical order of the last name.
   *
   * Normally, only organization admins may see their organization's users.
   *
   * NOTE: in the case of a hierarchy of organizations, this lists all the users in the subtree of organizations rooted at the admin's organization.
   *
   * IMPORTANT: the user entitlements field is not resolved in this collection.
   */
  users: Array<User>;
  /**
   * Query for widget informations.
   *
   * 'Widgets' are refered to as any frontend artifact that would require
   * some secret or user information not to be exposed to non-authenticated users.
   *
   * This service should be favored over secrets passed directly to the frontend
   * assets without prior checking for a proper authentication.
   *
   * Examples: Intercom component, Datadog instrumentation ...
   */
  widgetsInfo: Widgets;
};


export type QueryAuditTrailArgs = {
  filters: AuditFilter;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryBuiltObjectArgs = {
  id: Scalars['ID'];
};


export type QueryGetAllZonesArgs = {
  product?: InputMaybe<Product>;
};


export type QueryGetCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryGetNearbyBuiltObjectsArgs = {
  input: GeoLookupInput;
};


export type QueryGetOrganizationArgs = {
  id: Scalars['ID'];
};


export type QueryGetOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  input?: InputMaybe<LocationsInput>;
};


export type QueryLookupAddressesArgs = {
  file: Scalars['Upload'];
  geocoderVersion?: InputMaybe<DominoModule>;
};


export type QueryMibRequestsArgs = {
  input?: InputMaybe<AdminMibRequestsInput>;
};


export type QueryMyMibRequestsArgs = {
  input?: InputMaybe<MyMibRequestsInput>;
};


export type QueryReverseGeocodeArgs = {
  coords: Scalars['Coordinates'];
};


export type QuerySearchArgs = {
  input: SearchInput;
  limit?: Scalars['Int'];
};


export type QuerySearchNearestBuiltObjectArgs = {
  input: GeoLookupInput;
};


export type QuerySearchUsersArgs = {
  filters: Array<UsersFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrdering>>;
};


export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserOrdering>>;
  status?: InputMaybe<UserStatus>;
};

/** Input for ROI downtime calculation */
export type RoiInput = {
  /** Location built object id */
  builtObjectID: Scalars['ID'];
  /** Property type from csv file */
  propertyType?: InputMaybe<Scalars['String']>;
  /** Trigger a refresh of ROI request (remove from cache and run GPT call) */
  refresh?: InputMaybe<Scalars['Boolean']>;
  /** Trigger a retry on ROI request in case of error */
  retry?: InputMaybe<Scalars['Boolean']>;
};

/** ROIOption representing a row from the roi table response */
export type RoiOption = {
  __typename?: 'ROIOption';
  annualSavingsRange: Scalars['String'];
  /** This is represented in tons */
  carbonEmissionReduction: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  initialInvestment: Scalars['String'];
  rangeIRR: Scalars['String'];
  rangeNPV: Scalars['String'];
  rangeROI: Scalars['String'];
};

export type RoiResult = {
  __typename?: 'ROIResult';
  /** Assumptions are calculated locally */
  assumptions: Scalars['String'];
  /** Built object id of the location for which we are calcultaing ROI */
  builtObjectID: Scalars['ID'];
  cache: Scalars['Boolean'];
  /** ChatBotResponse is the GPT response */
  chatBotResponse?: Maybe<Scalars['String']>;
  error?: Maybe<Message>;
  /** Options are calculated locally */
  options: Array<RoiOption>;
  /** This can be from: CSV, BOS DB, use default */
  propertyType: Scalars['String'];
  /** Meters square of the building, from BOS DB */
  squares: Scalars['Float'];
  /** State the building belongs to */
  state: Scalars['String'];
  /** Current status of ROI request */
  status: RoiStatus;
  /** zeroMeanStddevMsg is the text for both of mean and stddev is zero */
  zeroMeanStddevMsg?: Maybe<Scalars['String']>;
};

export enum RoiStatus {
  /** ChatGPT worker has completed with success */
  Completed = 'COMPLETED',
  /** ChatGPT worker has completed with error */
  Error = 'ERROR',
  /** ChatGPT worker is not finish */
  InProgress = 'IN_PROGRESS'
}

export type ResilienceProduct = {
  __typename?: 'ResilienceProduct';
  id: Scalars['ID'];
  /** The CSV upload integration report, from the last file upload. */
  locationFile?: Maybe<LocationFile>;
  /** Materiality settings */
  materialitySettings: MaterialitySettings;
  /**
   * The content of the original file currently mounted (without header),
   * ordered by their original line number.
   *
   * By default, no filtering is applied.
   */
  originalFile?: Maybe<Array<ParsedFileLine>>;
  /** User-defined vulnerability thresholds applying to all locations. */
  thresholds: ResilienceThresholds;
};


export type ResilienceProductOriginalFileArgs = {
  filter?: InputMaybe<OriginalFileFilter>;
};

/**
 * ResilienceStats display three things:
 * - unitary statistics at the current location (structuralDamage, nearbyPower)
 * - aggregate lifeline statistics (nearbyPeople, etc)
 * - materiality loss at the current location (materialityLoss)
 */
export type ResilienceStats = {
  __typename?: 'ResilienceStats';
  /** Sum of max materiality loss per location */
  financeLosses?: Maybe<FinanceLosses>;
  /** A synthetic ID used for caching results */
  id: Scalars['ID'];
  /** Maximum structural downtime for airports within 50 miles from the location */
  nearbyAirport?: Maybe<AggregateStats>;
  /** Maximum structural downtime for bridges within 10 miles from the location */
  nearbyBridge?: Maybe<AggregateStats>;
  /** Maximum structural downtime for highways within 30 miles from the location */
  nearbyHighway?: Maybe<AggregateStats>;
  /** Maximum structural downtime for ingress egress within 50 miles from the location */
  nearbyIngressEgress?: Maybe<AggregateStats>;
  /** Maximum structural downtime for integrated within 50 miles from the location */
  nearbyIntegrated?: Maybe<AggregateStats>;
  /** Maximum weighted combined downtime for residential buildings within 10 miles of the location */
  nearbyPeople?: Maybe<AggregateStats>;
  /** Maximum structural downtime for sea ports within 50 miles from the location */
  nearbyPort?: Maybe<AggregateStats>;
  /**
   * Power downtime at the location
   *
   * NOTE: the "nearby" in the name is misleading here: this not nearby, but at the location
   */
  nearbyPower?: Maybe<AggregateStats>;
  /** Maximum structural downtime for repair time within 50 miles from the location */
  nearbyRepairTime?: Maybe<AggregateStats>;
  /**
   * Slice numerical identifier for the aggregate statistics reported for that location
   *
   * A slice corresponds to a specific simulation scenario used to compute statistics.
   */
  slice: Scalars['Int'];
  /** The damage ratio at the location */
  structuralDamage?: Maybe<AggregateStats>;
  /** Sum of max materiality loss per location */
  sumMaxMaterialityLosses?: Maybe<Scalars['Float']>;
};

/** A selection of slices for stats (a 'slice' corresponds to a resilience computation scenario). */
export type ResilienceStatsInput = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/**
 * User-defined thresholds
 *
 * Values are expressed in hours for downtimes, and as a percentage for damages.
 *
 * Default values are returned if no specific settings have been set by the user.
 */
export type ResilienceThresholds = {
  __typename?: 'ResilienceThresholds';
  airportDowntime: Scalars['Int'];
  bridgeDowntime: Scalars['Int'];
  highwayDowntime: Scalars['Int'];
  peopleDowntime: Scalars['Int'];
  portDowntime: Scalars['Int'];
  powerDowntime: Scalars['Int'];
  /** Vulnerability threshold on structural damages to buildings, in percents for damages */
  structuralDamage: Scalars['Int'];
};

/**
 * User-specific vulnerability thresholds.
 *
 * Values are expressed in hours, unless otherwise mentioned.
 *
 * If a value is set to 0, the user will revert to the default.
 * This may be used to reset a user's thresholds to their defaults.
 */
export type ResilienceThresholdsInput = {
  /** Vulnerabiliy threshold on airport downtime, in hours */
  airportDowntime: Scalars['Int'];
  /** Vulnerabiliy threshold on bridge downtime, in hours */
  bridgeDowntime: Scalars['Int'];
  /** Vulnerabiliy threshold on highway downtime, in hours */
  highwayDowntime: Scalars['Int'];
  /** Vulnerabiliy threshold on people downtime (aka community downtime), in hours */
  peopleDowntime: Scalars['Int'];
  /** Vulnerabiliy threshold on port downtime, in hours */
  portDowntime: Scalars['Int'];
  /** Vulnerabiliy threshold on power downtime, in hours */
  powerDowntime: Scalars['Int'];
  /** Vulnerabiliy threshold on structural damages to buildings, in percents */
  structuralDamage: Scalars['Int'];
};

/**
 * RoleEditInput toggles a role-driven entitlement.
 *
 * Such generic entitlements cannot be tied to a particular product, region, module or hazard.
 */
export type RoleEditInput = {
  isOrganizationAdmin?: InputMaybe<Scalars['Boolean']>;
  isSuperAdmin?: InputMaybe<Scalars['Boolean']>;
};

export type SearchInput = {
  matchCriteria?: InputMaybe<LocationFilter>;
  text: Scalars['String'];
};

/** Input parameters for sharing analysis to Viewer. */
export type ShareAnalysisInput = {
  /** Analysis url */
  analysisUrl: Scalars['String'];
  /** Viewer email to share */
  email: Scalars['String'];
  /** Viewer first name to share */
  firstName: Scalars['String'];
  /** Viewer last name to share */
  lastName: Scalars['String'];
};

export type Slice = {
  __typename?: 'Slice';
  climate?: Maybe<Scalars['Int']>;
  hazardType?: Maybe<SliceHazardType>;
  historicalEventID?: Maybe<Scalars['String']>;
  /** The slice ID as a string (this is just the string representation of the underlying integer) */
  id: Scalars['ID'];
  planningHorizon?: Maybe<Scalars['Int']>;
  returnPeriod?: Maybe<Scalars['Int']>;
  /** Actual: 0, Worse: 1 */
  severityIndex?: Maybe<Scalars['Int']>;
  /** The slice ID as an integer */
  slice: Scalars['Int'];
};

/** SliceHazardEntitlement characterizes a hazard type granted for a given module. */
export type SliceHazardEntitlement = {
  __typename?: 'SliceHazardEntitlement';
  hazard: SliceHazardType;
  module: DominoModule;
};

export enum SliceHazardType {
  /** Slice statistics relative to seismic hazard exposure */
  Earthquake = 'EARTHQUAKE',
  /** Slice statistics relative to flood hazard exposure */
  Flood = 'FLOOD',
  /** Slice statistics relative to hurricane hazard exposure */
  Hurricane = 'HURRICANE',
  /** Slice statistics relative to wind hazard exposure */
  Wind = 'WIND'
}

export type Stats = {
  /** This is a synthetic ID, equals to the parent's id + attribute */
  id: Scalars['ID'];
  mean?: Maybe<Scalars['Int']>;
  stddev?: Maybe<Scalars['Int']>;
};

/**
 * Subdivision summarizes the resilience status of residential buildings over a zipcode.
 *
 * The residential buildings considered lie within 10 miles from the location of interest.
 *
 * If a zipcode contains only one building or less, it is omitted.
 *
 * NOTE: the individual homes considered are actually a sample (~ 10% of the actual buildings)
 */
export type Subdivision = BasicLifelineAsset & {
  __typename?: 'Subdivision';
  actualID: Scalars['String'];
  /** The coordinates of the zipcode of this aggregate of residential buildings */
  coordinates: Scalars['Coordinates'];
  /** Number of residential buildings in the sampled data that lie wihtin this aggregate */
  homes: Scalars['Int'];
  id: Scalars['ID'];
  metadata?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  /** The zipcode of this aggregate */
  postCode: Scalars['String'];
  stats?: Maybe<Array<Stats>>;
  /** Total number of residential units in this aggregate */
  units: Scalars['Int'];
  /**
   * Not implemented
   *
   * NOTE: the entire concept of vulnerability is at the moment handled by the frontend.
   */
  vulnerableHomes: Scalars['Int'];
};


/**
 * Subdivision summarizes the resilience status of residential buildings over a zipcode.
 *
 * The residential buildings considered lie within 10 miles from the location of interest.
 *
 * If a zipcode contains only one building or less, it is omitted.
 *
 * NOTE: the individual homes considered are actually a sample (~ 10% of the actual buildings)
 */
export type SubdivisionStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** Substation is a power station within 50 miles of the location of interest */
export type Substation = BasicLifelineAsset & {
  __typename?: 'Substation';
  actualID: Scalars['String'];
  coordinates: Scalars['Coordinates'];
  id: Scalars['ID'];
  maxVoltage: Scalars['Int'];
  metadata?: Maybe<Array<Scalars['String']>>;
  name?: Maybe<Scalars['String']>;
  stats?: Maybe<Array<Stats>>;
};


/** Substation is a power station within 50 miles of the location of interest */
export type SubstationStatsArgs = {
  slices?: InputMaybe<Array<Scalars['Int']>>;
};

/** Timeframe for querying MIB requests. */
export type Timeframe = {
  end?: InputMaybe<Scalars['Time']>;
  start?: InputMaybe<Scalars['Time']>;
};

/** Input parameters for users updating their existing MIB requests. */
export type UpdateMyMibRequestsInput = {
  /** New address of the request. */
  address?: InputMaybe<EditAddressInput>;
  /** ID of the request to update. */
  id: Scalars['ID'];
  /**
   * New status of the request.
   *
   * Note: can only update to "cancel".
   */
  status?: InputMaybe<MibRequestStatus>;
};

/** User of our products. */
export type User = {
  __typename?: 'User';
  /** entitlements for the current user. */
  entitlements?: Maybe<Entitlements>;
  /** User unique identifier */
  id: Scalars['ID'];
  /** Whether this user has the super-admin role enabled. */
  isSuperAdmin: Scalars['Boolean'];
  personalInfo: PersonInfo;
  preferences?: Maybe<UserPreferences>;
  /**
   * productSettings (a user enrichment known to domino) describes
   * the user personal settings for the domino app.
   *
   * In particular, it defines the thresholds to determine vulnerabilities on locations.
   */
  productSettings?: Maybe<ResilienceProduct>;
  role: UserRole;
  status: UserStatus;
  title?: Maybe<Scalars['String']>;
};

/**
 * Structured representation of an address including its flat representation
 *
 * TODO: should be mutualized with the Address type defined by resilience.
 */
export type UserAddress = {
  __typename?: 'UserAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  /** Flat representation of the address */
  formattedAddress: Scalars['String'];
  houseNumber?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateDistrict?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  suburb?: Maybe<Scalars['String']>;
};

/** UserEditInput captures changes to a user's profile. */
export type UserEditInput = {
  /** entitlements for a user may only be modified by a OneConcern admin. */
  entitlements?: InputMaybe<EntitlementsEditInput>;
  personalInfo?: InputMaybe<PersonInfoEditInput>;
  preferences?: InputMaybe<UserPreferencesEditInput>;
  role?: InputMaybe<UserRole>;
  title?: InputMaybe<Scalars['String']>;
};

/** User ordering settings */
export type UserOrdering = {
  direction: Direction;
  field: UserSortingField;
};

/** User preferences are defined by the user or inherited from the organization the user is member of. */
export type UserPreferences = {
  __typename?: 'UserPreferences';
  /**
   * All supported preferences
   *
   * This allows for accessing extensions not yet covered by the enum fields above.
   */
  allPreferences: Scalars['Map'];
  /** Country name (English name of the country) */
  country?: Maybe<Scalars['String']>;
  /**
   * ISO-3166-1 ('alpha-2') country code (e.g. US, JP).
   *
   * If not specifically defined by the user, derived from the language: en assumes US and ja assumes JP by default.
   */
  countryCode: CountryCode;
  /**
   * Preferred language (e.g. en, ja, ...).
   *
   * If not specified by the user, the default is 'en'.
   *
   * NOTE: only the main language tag is supported at this moment. Variants (e.g. zh-gan, en-CA) are ignored.
   */
  language: LanguagePreferences;
  /**
   * Notifications channels.
   *
   * Specify channels used in order to notify the user.
   *
   * NOTE: this capability is only enabled for READY users at the moment.
   */
  notificationsChannels?: Maybe<Array<NotificationsChannel>>;
  /**
   * Notifications Enabled.
   *
   * Specify if notifications will be send to this user.
   *
   * NOTE: this capability is only enabled for READY users at the moment.
   */
  notificationsEnabled?: Maybe<Scalars['Boolean']>;
  /** Preferred timezone (e.g. US/Aleutian, PST8PDT...) */
  timezone?: Maybe<Scalars['String']>;
  /**
   * Preferred unit system.
   *
   * If not specifically defined by the user, derived from the country code.
   *
   * If no country code is defined, derived from the language ('ja' assumes a preference for the metrics system, otherwise the imperial system applies).
   */
  units: MeasurementPreferences;
  /** @deprecated unused, only for compatibility with previous versions */
  userID: Scalars['String'];
};

/** UserPreferencesEditInput captures changes to the user's personal preferences. */
export type UserPreferencesEditInput = {
  /** Any key in allPreferences that conflicts with other inputs will take precedence. */
  allPreferences?: InputMaybe<Scalars['Map']>;
  /**
   * The country of residence of the user.
   *
   * Notice that at this moment, this field remains unconstrained and purely informative.
   * No consistency check is currently enforced against 'countryCode'.
   */
  country?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  language?: InputMaybe<LanguagePreferences>;
  notificationsChannels?: InputMaybe<Array<NotificationsChannel>>;
  /**
   * Whether READY notifications are enabled.
   *
   * Individual users may disable email notifications if they don't want to receive these.
   */
  notificationsEnabled?: InputMaybe<Scalars['Boolean']>;
  /**
   * The user's preferred timezone to display time.
   *
   * The timezone MUST be a valid IANA-registered timezone or its abbreviation (https://www.iana.org/time-zones), e.g: 'Africa/Cairo', 'America/Los_Angeles', 'PDT', 'EST'...
   */
  timezone?: InputMaybe<Scalars['String']>;
  units?: InputMaybe<MeasurementPreferences>;
};

/**
 * Enum for user role
 *
 * Admin means that the user administrates an organization.
 */
export enum UserRole {
  /** Admin refers to an organization administrator (or a super administrator) for the app. */
  Admin = 'Admin',
  /** Contributor is a regular user of the app. */
  Contributor = 'Contributor',
  /** View is a user with limited privileges on the app. */
  Viewer = 'Viewer'
}

/** Sorting field for users */
export enum UserSortingField {
  FirstName = 'FirstName',
  LastName = 'LastName'
}

/** User onboarding status */
export enum UserStatus {
  /** User is disabled */
  Disabled = 'Disabled',
  /** User has been created but not completed the onboarding yet */
  InvitationPending = 'InvitationPending',
  /** User has joined */
  Joined = 'Joined'
}

/** User filter settings */
export type UsersFilter = {
  field: UsersFilterField;
  value: Scalars['String'];
};

/** Filter field for users */
export enum UsersFilterField {
  ByEmail = 'ByEmail',
  ByLastName = 'ByLastName'
}

/** Input for viewer invite */
export type ViewerInput = {
  /**
   * The URL to redirect the invited viewer.
   * This must be an absolute URL (e.g.https://domino.oneconcern.com/#/analysis/detail/03e10f41-2453-4727-bc7f-535bf6969502)
   */
  redirectUrl: Scalars['String'];
};

/** Informations required by enabled UI widgets */
export type Widgets = {
  __typename?: 'Widgets';
  /** Datadog widget information */
  datadog: Datadog;
  /** Intercom widget information */
  intercom: Intercom;
};

export type ChangeUserOrganizationMutationVariables = Exact<{
  userId: Scalars['ID'];
  currentOrganizationId: Scalars['String'];
  targetOrganizationId: Scalars['String'];
}>;


export type ChangeUserOrganizationMutation = { __typename?: 'Mutation', changeUserOrganization: boolean };

export type CreateOrgMutationVariables = Exact<{
  org: CreateOrganizationAdminInput;
}>;


export type CreateOrgMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'Organization', id: string, name: string, enName: string, names: any, preferences?: { __typename?: 'OrganizationPreferences', defaultUnits?: MeasurementPreferences | null, defaultCountry?: string | null, defaultCountryCode?: CountryCode | null, defaultLanguage?: LanguagePreferences | null, defaultTimezone?: string | null, defaultNotificationsEnabled?: boolean | null, defaultNotificationsChannels?: Array<NotificationsChannel> | null, allDefaultPreferences?: any | null } | null } };

export type DeleteOrgMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteOrgMutation = { __typename?: 'Mutation', deleteOrganization: boolean };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: boolean };

export type DisableUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser: boolean };

export type EnableUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EnableUserMutation = { __typename?: 'Mutation', enableUser: boolean };

export type GetAllZonesQueryVariables = Exact<{
  product?: InputMaybe<Product>;
}>;


export type GetAllZonesQuery = { __typename?: 'Query', getAllZones: Array<string> };

export type GetAuditTrailQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: AuditFilter;
}>;


export type GetAuditTrailQuery = { __typename?: 'Query', auditTrail: Array<{ __typename?: 'AuditEvent', status: string, eventType: AuditEventType, resourceType: AuditEventResourceType, operationType?: AuditEventOperationType | null, realm: string, eventTime: string, message?: string | null, originator?: { __typename?: 'User', id: string, title?: string | null, role: UserRole, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null } } | null, target: { __typename?: 'AuditEventTarget', user?: { __typename?: 'User', id: string, title?: string | null, role: UserRole, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null } } | null, organization?: { __typename?: 'Organization', id: string, name: string } | null, permission?: { __typename?: 'Permission', id: string, name?: string | null } | null } }> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', user: { __typename?: 'User', id: string, role: UserRole, title?: string | null, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null } } };

export type GetEntitlementsStructureQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEntitlementsStructureQuery = { __typename?: 'Query', entitlementsStructure: { __typename?: 'EntitlementsStructure', products: Array<Product>, productHasRegions: Array<{ __typename?: 'ProductRegion', product: Product, region: string, names: any }>, productHasHazards: Array<{ __typename?: 'ProductHazard', product: Product, hazard: string }>, productHasModules: Array<{ __typename?: 'ProductModule', product: Product, module: string, description: string, isMain: boolean, names: any }>, productHasFeatures: Array<{ __typename?: 'ProductFeature', product: Product, hazard?: string | null, feature: string, description: string, names: any }> } };

export type GetLocationUploadFileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationUploadFileQuery = { __typename?: 'Query', locationUploadFile: { __typename?: 'LocationUploadFile', file: any } };

export type GetMibRequestsQueryVariables = Exact<{
  input?: InputMaybe<AdminMibRequestsInput>;
}>;


export type GetMibRequestsQuery = { __typename?: 'Query', mibRequests?: Array<{ __typename?: 'MIBRequest', id: string, externalID?: string | null, externalUserID: string, fileID?: string | null, lineNumber?: number | null, metadata: any, postedAt: string, updatedAt: string, status: MibRequestStatus, address?: { __typename?: 'Address', city?: string | null, country?: string | null, countryCode?: string | null, county?: string | null, formattedAddress: string, houseNumber?: string | null, postCode?: string | null, state?: string | null, stateCode?: string | null, stateDistrict?: string | null, street?: string | null, suburb?: string | null } | null }> | null };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, enName: string, adminNotes?: string | null, names: any, preferences?: { __typename?: 'OrganizationPreferences', defaultUnits?: MeasurementPreferences | null, defaultCountry?: string | null, defaultCountryCode?: CountryCode | null, defaultLanguage?: LanguagePreferences | null, defaultTimezone?: string | null, defaultNotificationsEnabled?: boolean | null, defaultNotificationsChannels?: Array<NotificationsChannel> | null, allDefaultPreferences?: any | null } | null, entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, scopes?: Array<Product> | null, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }>, hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null, users: Array<{ __typename?: 'User', id: string, role: UserRole, title?: string | null, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null }, preferences?: { __typename?: 'UserPreferences', country?: string | null, language: LanguagePreferences, timezone?: string | null, units: MeasurementPreferences, notificationsEnabled?: boolean | null, notificationsChannels?: Array<NotificationsChannel> | null } | null }> } };

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrganizationsQuery = { __typename?: 'Query', getOrganizations: Array<{ __typename?: 'Organization', id: string, name: string, enName: string, adminNotes?: string | null, names: any, preferences?: { __typename?: 'OrganizationPreferences', defaultUnits?: MeasurementPreferences | null, defaultCountry?: string | null, defaultCountryCode?: CountryCode | null, defaultLanguage?: LanguagePreferences | null, defaultTimezone?: string | null, defaultNotificationsEnabled?: boolean | null, defaultNotificationsChannels?: Array<NotificationsChannel> | null, allDefaultPreferences?: any | null } | null, entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, module: string, isInherited?: boolean | null }> } | null }> };

export type GetOrganizationAllDominoFeaturesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationAllDominoFeaturesQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, entitlements?: { __typename?: 'Entitlements', hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetOrganizationDominoJpQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationDominoJpQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, entitlements?: { __typename?: 'Entitlements', hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetOrganizationDominoUsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationDominoUsQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, entitlements?: { __typename?: 'Entitlements', hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetOrganizationAllReadyFeaturesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetOrganizationAllReadyFeaturesQuery = { __typename?: 'Query', getOrganization: { __typename?: 'Organization', id: string, name: string, entitlements?: { __typename?: 'Entitlements', hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', id: string, title?: string | null, role: UserRole, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null }, preferences?: { __typename?: 'UserPreferences', country?: string | null, language: LanguagePreferences, timezone?: string | null, units: MeasurementPreferences, notificationsEnabled?: boolean | null, notificationsChannels?: Array<NotificationsChannel> | null } | null, entitlements?: { __typename?: 'Entitlements', scopes?: Array<Product> | null, enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }>, hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, role: UserRole, title?: string | null, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null }, preferences?: { __typename?: 'UserPreferences', country?: string | null, language: LanguagePreferences, timezone?: string | null, units: MeasurementPreferences, notificationsEnabled?: boolean | null, notificationsChannels?: Array<NotificationsChannel> | null } | null }> };

export type GetUserDominoFeaturesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDominoFeaturesQuery = { __typename?: 'Query', getUser: { __typename?: 'User', entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }>, hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetUserDominoJpQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDominoJpQuery = { __typename?: 'Query', getUser: { __typename?: 'User', entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }>, hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetUserDominoUsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserDominoUsQuery = { __typename?: 'Query', getUser: { __typename?: 'User', entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }>, hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type GetUserEntitlementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserEntitlementsQuery = { __typename?: 'Query', user: { __typename: 'User', id: string, entitlements?: { __typename: 'Entitlements', id: string } | null } };

export type GetUserReadyFeaturesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetUserReadyFeaturesQuery = { __typename?: 'Query', getUser: { __typename?: 'User', entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }>, hasHazardsForProduct: Array<{ __typename?: 'ProductHazard', product: Product, module: string, region?: string | null, hazard: string, isInherited?: boolean | null }>, hasFeaturesForProduct: Array<{ __typename?: 'ProductFeature', product: Product, region?: string | null, module: string, hazard?: string | null, feature: string, isInherited?: boolean | null }> } | null } };

export type InviteUserMutationVariables = Exact<{
  invite: InviteUserInput;
  profile?: InputMaybe<InviteProfileInput>;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteAnyUser: { __typename?: 'User', id: string, role: UserRole, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null } | null } } };

export type ResendInvitationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResendInvitationMutation = { __typename?: 'Mutation', resendInvite: { __typename?: 'User', id: string } };

export type SearchUsersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  filters: Array<UsersFilter>;
  orderBy?: InputMaybe<Array<UserOrdering>>;
}>;


export type SearchUsersQuery = { __typename?: 'Query', searchUsers: Array<{ __typename?: 'User', id: string, role: UserRole, title?: string | null, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null }, preferences?: { __typename?: 'UserPreferences', country?: string | null, language: LanguagePreferences, timezone?: string | null, units: MeasurementPreferences, notificationsEnabled?: boolean | null, notificationsChannels?: Array<NotificationsChannel> | null } | null }> };

export type SetLocationUploadFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type SetLocationUploadFileMutation = { __typename?: 'Mutation', setLocationUploadFile: { __typename?: 'LocationUploadFile', file: any } };

export type ThresholdsFragmentFragment = { __typename?: 'ResilienceThresholds', structuralDamage: number, powerDowntime: number, peopleDowntime: number, highwayDowntime: number, bridgeDowntime: number, portDowntime: number, airportDowntime: number };

export type UpdateAnyOrganizationMutationVariables = Exact<{
  org: OrganizationAdminInput;
}>;


export type UpdateAnyOrganizationMutation = { __typename?: 'Mutation', updateAnyOrganization: { __typename?: 'Organization', id: string, name: string, enName: string, adminNotes?: string | null, names: any, preferences?: { __typename?: 'OrganizationPreferences', defaultUnits?: MeasurementPreferences | null, defaultCountry?: string | null, defaultCountryCode?: CountryCode | null, defaultLanguage?: LanguagePreferences | null, defaultTimezone?: string | null, defaultNotificationsEnabled?: boolean | null, defaultNotificationsChannels?: Array<NotificationsChannel> | null, allDefaultPreferences?: any | null } | null, users: Array<{ __typename?: 'User', id: string, role: UserRole, title?: string | null, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null }, preferences?: { __typename?: 'UserPreferences', country?: string | null, language: LanguagePreferences, timezone?: string | null, notificationsEnabled?: boolean | null, notificationsChannels?: Array<NotificationsChannel> | null } | null }> } };

export type UpdateMibRequestsMutationVariables = Exact<{
  input: AdminUpdateMibRequestsInput;
}>;


export type UpdateMibRequestsMutation = { __typename?: 'Mutation', updateMIBRequests: { __typename?: 'MIBRequest', id: string, externalID?: string | null, externalUserID: string, fileID?: string | null, lineNumber?: number | null, metadata: any, postedAt: string, updatedAt: string, status: MibRequestStatus, address?: { __typename?: 'Address', city?: string | null, country?: string | null, countryCode?: string | null, county?: string | null, formattedAddress: string, houseNumber?: string | null, postCode?: string | null, state?: string | null, stateCode?: string | null, stateDistrict?: string | null, street?: string | null, suburb?: string | null } | null } };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  userInput: UserEditInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, title?: string | null, role: UserRole, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null }, preferences?: { __typename?: 'UserPreferences', country?: string | null, language: LanguagePreferences, timezone?: string | null, units: MeasurementPreferences, notificationsEnabled?: boolean | null, notificationsChannels?: Array<NotificationsChannel> | null } | null, entitlements?: { __typename?: 'Entitlements', enabledProducts: Array<Product>, enabledProductsModule: Array<{ __typename?: 'ProductModule', product: Product, region?: string | null, module: string, isMain: boolean, isInherited?: boolean | null }> } | null } };

export type UploadUserLocationsFileMutationVariables = Exact<{
  file: Scalars['Upload'];
  id: Scalars['ID'];
}>;


export type UploadUserLocationsFileMutation = { __typename?: 'Mutation', uploadUserLocationsFile: { __typename?: 'LocationFile', id: string, name: string, uploadedAt: string, totalRows: number, totalAccepted: number, totalRejected: number, warning?: { __typename?: 'Message', code: number, message: string } | null, notices: Array<{ __typename?: 'FileNotice', id: string, lineNumber: number, label: string, noticeCode: NoticeCode, originalValue?: string | null, newValue?: string | null, reason?: string | null }> } };

export type UserDetailsFragment = { __typename?: 'User', id: string, role: UserRole, title?: string | null, status: UserStatus, personalInfo: { __typename?: 'PersonInfo', firstName: string, lastName?: string | null, contact?: { __typename?: 'ContactInfo', email?: string | null, phoneNumber?: string | null } | null } };

export const ThresholdsFragmentFragmentDoc = gql`
    fragment thresholdsFragment on ResilienceThresholds {
  structuralDamage
  powerDowntime
  peopleDowntime
  highwayDowntime
  bridgeDowntime
  portDowntime
  airportDowntime
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment userDetails on User {
  id
  role
  title
  personalInfo {
    firstName
    lastName
    contact {
      email
      phoneNumber
    }
  }
  status
}
    `;
export const ChangeUserOrganizationDocument = gql`
    mutation ChangeUserOrganization($userId: ID!, $currentOrganizationId: String!, $targetOrganizationId: String!) {
  changeUserOrganization(
    userId: $userId
    currentOrganizationId: $currentOrganizationId
    targetOrganizationId: $targetOrganizationId
  )
}
    `;
export type ChangeUserOrganizationMutationFn = Apollo.MutationFunction<ChangeUserOrganizationMutation, ChangeUserOrganizationMutationVariables>;
export type ChangeUserOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ChangeUserOrganizationMutation, ChangeUserOrganizationMutationVariables>, 'mutation'>;

    export const ChangeUserOrganizationComponent = (props: ChangeUserOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<ChangeUserOrganizationMutation, ChangeUserOrganizationMutationVariables> mutation={ChangeUserOrganizationDocument} {...props} />
    );
    

/**
 * __useChangeUserOrganizationMutation__
 *
 * To run a mutation, you first call `useChangeUserOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserOrganizationMutation, { data, loading, error }] = useChangeUserOrganizationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      currentOrganizationId: // value for 'currentOrganizationId'
 *      targetOrganizationId: // value for 'targetOrganizationId'
 *   },
 * });
 */
export function useChangeUserOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserOrganizationMutation, ChangeUserOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserOrganizationMutation, ChangeUserOrganizationMutationVariables>(ChangeUserOrganizationDocument, options);
      }
export type ChangeUserOrganizationMutationHookResult = ReturnType<typeof useChangeUserOrganizationMutation>;
export type ChangeUserOrganizationMutationResult = Apollo.MutationResult<ChangeUserOrganizationMutation>;
export type ChangeUserOrganizationMutationOptions = Apollo.BaseMutationOptions<ChangeUserOrganizationMutation, ChangeUserOrganizationMutationVariables>;
export const CreateOrgDocument = gql`
    mutation CreateOrg($org: CreateOrganizationAdminInput!) {
  createOrganization(org: $org) {
    id
    name
    enName
    preferences {
      defaultUnits
      defaultCountry
      defaultCountryCode
      defaultLanguage
      defaultTimezone
      defaultNotificationsEnabled
      defaultNotificationsChannels
      allDefaultPreferences
    }
    names
  }
}
    `;
export type CreateOrgMutationFn = Apollo.MutationFunction<CreateOrgMutation, CreateOrgMutationVariables>;
export type CreateOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgMutation, CreateOrgMutationVariables>, 'mutation'>;

    export const CreateOrgComponent = (props: CreateOrgComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgMutation, CreateOrgMutationVariables> mutation={CreateOrgDocument} {...props} />
    );
    

/**
 * __useCreateOrgMutation__
 *
 * To run a mutation, you first call `useCreateOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMutation, { data, loading, error }] = useCreateOrgMutation({
 *   variables: {
 *      org: // value for 'org'
 *   },
 * });
 */
export function useCreateOrgMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrgMutation, CreateOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrgMutation, CreateOrgMutationVariables>(CreateOrgDocument, options);
      }
export type CreateOrgMutationHookResult = ReturnType<typeof useCreateOrgMutation>;
export type CreateOrgMutationResult = Apollo.MutationResult<CreateOrgMutation>;
export type CreateOrgMutationOptions = Apollo.BaseMutationOptions<CreateOrgMutation, CreateOrgMutationVariables>;
export const DeleteOrgDocument = gql`
    mutation DeleteOrg($id: ID!) {
  deleteOrganization(id: $id)
}
    `;
export type DeleteOrgMutationFn = Apollo.MutationFunction<DeleteOrgMutation, DeleteOrgMutationVariables>;
export type DeleteOrgComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrgMutation, DeleteOrgMutationVariables>, 'mutation'>;

    export const DeleteOrgComponent = (props: DeleteOrgComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrgMutation, DeleteOrgMutationVariables> mutation={DeleteOrgDocument} {...props} />
    );
    

/**
 * __useDeleteOrgMutation__
 *
 * To run a mutation, you first call `useDeleteOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgMutation, { data, loading, error }] = useDeleteOrgMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOrgMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOrgMutation, DeleteOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOrgMutation, DeleteOrgMutationVariables>(DeleteOrgDocument, options);
      }
export type DeleteOrgMutationHookResult = ReturnType<typeof useDeleteOrgMutation>;
export type DeleteOrgMutationResult = Apollo.MutationResult<DeleteOrgMutation>;
export type DeleteOrgMutationOptions = Apollo.BaseMutationOptions<DeleteOrgMutation, DeleteOrgMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export type DeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserMutation, DeleteUserMutationVariables>, 'mutation'>;

    export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserMutation, DeleteUserMutationVariables> mutation={DeleteUserDocument} {...props} />
    );
    

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DisableUserDocument = gql`
    mutation DisableUser($id: ID!) {
  disableUser(id: $id)
}
    `;
export type DisableUserMutationFn = Apollo.MutationFunction<DisableUserMutation, DisableUserMutationVariables>;
export type DisableUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableUserMutation, DisableUserMutationVariables>, 'mutation'>;

    export const DisableUserComponent = (props: DisableUserComponentProps) => (
      <ApolloReactComponents.Mutation<DisableUserMutation, DisableUserMutationVariables> mutation={DisableUserDocument} {...props} />
    );
    

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableUserMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, options);
      }
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<DisableUserMutation, DisableUserMutationVariables>;
export const EnableUserDocument = gql`
    mutation EnableUser($id: ID!) {
  enableUser(id: $id)
}
    `;
export type EnableUserMutationFn = Apollo.MutationFunction<EnableUserMutation, EnableUserMutationVariables>;
export type EnableUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableUserMutation, EnableUserMutationVariables>, 'mutation'>;

    export const EnableUserComponent = (props: EnableUserComponentProps) => (
      <ApolloReactComponents.Mutation<EnableUserMutation, EnableUserMutationVariables> mutation={EnableUserDocument} {...props} />
    );
    

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableUserMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, options);
      }
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>;
export type EnableUserMutationResult = Apollo.MutationResult<EnableUserMutation>;
export type EnableUserMutationOptions = Apollo.BaseMutationOptions<EnableUserMutation, EnableUserMutationVariables>;
export const GetAllZonesDocument = gql`
    query GetAllZones($product: Product) {
  getAllZones(product: $product)
}
    `;
export type GetAllZonesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAllZonesQuery, GetAllZonesQueryVariables>, 'query'>;

    export const GetAllZonesComponent = (props: GetAllZonesComponentProps) => (
      <ApolloReactComponents.Query<GetAllZonesQuery, GetAllZonesQueryVariables> query={GetAllZonesDocument} {...props} />
    );
    

/**
 * __useGetAllZonesQuery__
 *
 * To run a query within a React component, call `useGetAllZonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllZonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllZonesQuery({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useGetAllZonesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllZonesQuery, GetAllZonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllZonesQuery, GetAllZonesQueryVariables>(GetAllZonesDocument, options);
      }
export function useGetAllZonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllZonesQuery, GetAllZonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllZonesQuery, GetAllZonesQueryVariables>(GetAllZonesDocument, options);
        }
export type GetAllZonesQueryHookResult = ReturnType<typeof useGetAllZonesQuery>;
export type GetAllZonesLazyQueryHookResult = ReturnType<typeof useGetAllZonesLazyQuery>;
export type GetAllZonesQueryResult = Apollo.QueryResult<GetAllZonesQuery, GetAllZonesQueryVariables>;
export const GetAuditTrailDocument = gql`
    query GetAuditTrail($limit: Int, $offset: Int, $filters: AuditFilter!) {
  auditTrail(limit: $limit, offset: $offset, filters: $filters) {
    status
    eventType
    resourceType
    operationType
    realm
    eventTime
    originator {
      id
      title
      role
      personalInfo {
        firstName
        lastName
        contact {
          email
          phoneNumber
        }
      }
      status
    }
    target {
      user {
        id
        title
        role
        personalInfo {
          firstName
          lastName
          contact {
            email
            phoneNumber
          }
        }
        status
      }
      organization {
        id
        name
      }
      permission {
        id
        name
      }
    }
    message
  }
}
    `;
export type GetAuditTrailComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetAuditTrailQuery, GetAuditTrailQueryVariables>, 'query'> & ({ variables: GetAuditTrailQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetAuditTrailComponent = (props: GetAuditTrailComponentProps) => (
      <ApolloReactComponents.Query<GetAuditTrailQuery, GetAuditTrailQueryVariables> query={GetAuditTrailDocument} {...props} />
    );
    

/**
 * __useGetAuditTrailQuery__
 *
 * To run a query within a React component, call `useGetAuditTrailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuditTrailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuditTrailQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useGetAuditTrailQuery(baseOptions: Apollo.QueryHookOptions<GetAuditTrailQuery, GetAuditTrailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuditTrailQuery, GetAuditTrailQueryVariables>(GetAuditTrailDocument, options);
      }
export function useGetAuditTrailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuditTrailQuery, GetAuditTrailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuditTrailQuery, GetAuditTrailQueryVariables>(GetAuditTrailDocument, options);
        }
export type GetAuditTrailQueryHookResult = ReturnType<typeof useGetAuditTrailQuery>;
export type GetAuditTrailLazyQueryHookResult = ReturnType<typeof useGetAuditTrailLazyQuery>;
export type GetAuditTrailQueryResult = Apollo.QueryResult<GetAuditTrailQuery, GetAuditTrailQueryVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  user {
    ...userDetails
  }
}
    ${UserDetailsFragmentDoc}`;
export type GetCurrentUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>, 'query'>;

    export const GetCurrentUserComponent = (props: GetCurrentUserComponentProps) => (
      <ApolloReactComponents.Query<GetCurrentUserQuery, GetCurrentUserQueryVariables> query={GetCurrentUserDocument} {...props} />
    );
    

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetEntitlementsStructureDocument = gql`
    query getEntitlementsStructure {
  entitlementsStructure {
    products
    productHasRegions {
      product
      region
      names
    }
    productHasHazards {
      product
      hazard
    }
    productHasModules {
      product
      module
      description
      isMain
      names
    }
    productHasFeatures {
      product
      hazard
      feature
      description
      names
    }
  }
}
    `;
export type GetEntitlementsStructureComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables>, 'query'>;

    export const GetEntitlementsStructureComponent = (props: GetEntitlementsStructureComponentProps) => (
      <ApolloReactComponents.Query<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables> query={GetEntitlementsStructureDocument} {...props} />
    );
    

/**
 * __useGetEntitlementsStructureQuery__
 *
 * To run a query within a React component, call `useGetEntitlementsStructureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntitlementsStructureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntitlementsStructureQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEntitlementsStructureQuery(baseOptions?: Apollo.QueryHookOptions<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables>(GetEntitlementsStructureDocument, options);
      }
export function useGetEntitlementsStructureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables>(GetEntitlementsStructureDocument, options);
        }
export type GetEntitlementsStructureQueryHookResult = ReturnType<typeof useGetEntitlementsStructureQuery>;
export type GetEntitlementsStructureLazyQueryHookResult = ReturnType<typeof useGetEntitlementsStructureLazyQuery>;
export type GetEntitlementsStructureQueryResult = Apollo.QueryResult<GetEntitlementsStructureQuery, GetEntitlementsStructureQueryVariables>;
export const GetLocationUploadFileDocument = gql`
    query GetLocationUploadFile {
  locationUploadFile @client {
    file
  }
}
    `;
export type GetLocationUploadFileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables>, 'query'>;

    export const GetLocationUploadFileComponent = (props: GetLocationUploadFileComponentProps) => (
      <ApolloReactComponents.Query<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables> query={GetLocationUploadFileDocument} {...props} />
    );
    

/**
 * __useGetLocationUploadFileQuery__
 *
 * To run a query within a React component, call `useGetLocationUploadFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationUploadFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationUploadFileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationUploadFileQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables>(GetLocationUploadFileDocument, options);
      }
export function useGetLocationUploadFileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables>(GetLocationUploadFileDocument, options);
        }
export type GetLocationUploadFileQueryHookResult = ReturnType<typeof useGetLocationUploadFileQuery>;
export type GetLocationUploadFileLazyQueryHookResult = ReturnType<typeof useGetLocationUploadFileLazyQuery>;
export type GetLocationUploadFileQueryResult = Apollo.QueryResult<GetLocationUploadFileQuery, GetLocationUploadFileQueryVariables>;
export const GetMibRequestsDocument = gql`
    query GetMibRequests($input: AdminMIBRequestsInput) {
  mibRequests(input: $input) {
    id
    externalID
    externalUserID
    fileID
    lineNumber
    address {
      city
      country
      countryCode
      county
      formattedAddress
      houseNumber
      postCode
      state
      stateCode
      stateDistrict
      street
      suburb
    }
    metadata
    postedAt
    updatedAt
    status
  }
}
    `;
export type GetMibRequestsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetMibRequestsQuery, GetMibRequestsQueryVariables>, 'query'>;

    export const GetMibRequestsComponent = (props: GetMibRequestsComponentProps) => (
      <ApolloReactComponents.Query<GetMibRequestsQuery, GetMibRequestsQueryVariables> query={GetMibRequestsDocument} {...props} />
    );
    

/**
 * __useGetMibRequestsQuery__
 *
 * To run a query within a React component, call `useGetMibRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMibRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMibRequestsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetMibRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetMibRequestsQuery, GetMibRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMibRequestsQuery, GetMibRequestsQueryVariables>(GetMibRequestsDocument, options);
      }
export function useGetMibRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMibRequestsQuery, GetMibRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMibRequestsQuery, GetMibRequestsQueryVariables>(GetMibRequestsDocument, options);
        }
export type GetMibRequestsQueryHookResult = ReturnType<typeof useGetMibRequestsQuery>;
export type GetMibRequestsLazyQueryHookResult = ReturnType<typeof useGetMibRequestsLazyQuery>;
export type GetMibRequestsQueryResult = Apollo.QueryResult<GetMibRequestsQuery, GetMibRequestsQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    enName
    adminNotes
    preferences {
      defaultUnits
      defaultCountry
      defaultCountryCode
      defaultLanguage
      defaultTimezone
      defaultNotificationsEnabled
      defaultNotificationsChannels
      allDefaultPreferences
    }
    names
    entitlements {
      enabledProducts
      scopes
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
      hasHazardsForProduct(product: READY, module: "live hazard") {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: READY, module: "live hazard") {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
    users {
      id
      role
      title
      personalInfo {
        firstName
        lastName
        contact {
          email
          phoneNumber
        }
      }
      status
      preferences {
        country
        language
        timezone
        units
        notificationsEnabled
        notificationsChannels
      }
    }
  }
}
    `;
export type GetOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationQuery, GetOrganizationQueryVariables>, 'query'> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationComponent = (props: GetOrganizationComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationQuery, GetOrganizationQueryVariables> query={GetOrganizationDocument} {...props} />
    );
    

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetOrganizationsDocument = gql`
    query GetOrganizations {
  getOrganizations {
    id
    name
    enName
    adminNotes
    preferences {
      defaultUnits
      defaultCountry
      defaultCountryCode
      defaultLanguage
      defaultTimezone
      defaultNotificationsEnabled
      defaultNotificationsChannels
      allDefaultPreferences
    }
    names
    entitlements {
      enabledProducts
      enabledProductsModule {
        product
        module
        isInherited
      }
    }
  }
}
    `;
export type GetOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>, 'query'>;

    export const GetOrganizationsComponent = (props: GetOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationsQuery, GetOrganizationsQueryVariables> query={GetOrganizationsDocument} {...props} />
    );
    

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
      }
export function useGetOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationsQuery, GetOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(GetOrganizationsDocument, options);
        }
export type GetOrganizationsQueryHookResult = ReturnType<typeof useGetOrganizationsQuery>;
export type GetOrganizationsLazyQueryHookResult = ReturnType<typeof useGetOrganizationsLazyQuery>;
export type GetOrganizationsQueryResult = Apollo.QueryResult<GetOrganizationsQuery, GetOrganizationsQueryVariables>;
export const GetOrganizationAllDominoFeaturesDocument = gql`
    query GetOrganizationAllDominoFeatures($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    entitlements {
      hasFeaturesForProduct(product: DOMINO) {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetOrganizationAllDominoFeaturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables>, 'query'> & ({ variables: GetOrganizationAllDominoFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationAllDominoFeaturesComponent = (props: GetOrganizationAllDominoFeaturesComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables> query={GetOrganizationAllDominoFeaturesDocument} {...props} />
    );
    

/**
 * __useGetOrganizationAllDominoFeaturesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAllDominoFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAllDominoFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAllDominoFeaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationAllDominoFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables>(GetOrganizationAllDominoFeaturesDocument, options);
      }
export function useGetOrganizationAllDominoFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables>(GetOrganizationAllDominoFeaturesDocument, options);
        }
export type GetOrganizationAllDominoFeaturesQueryHookResult = ReturnType<typeof useGetOrganizationAllDominoFeaturesQuery>;
export type GetOrganizationAllDominoFeaturesLazyQueryHookResult = ReturnType<typeof useGetOrganizationAllDominoFeaturesLazyQuery>;
export type GetOrganizationAllDominoFeaturesQueryResult = Apollo.QueryResult<GetOrganizationAllDominoFeaturesQuery, GetOrganizationAllDominoFeaturesQueryVariables>;
export const GetOrganizationDominoJpDocument = gql`
    query GetOrganizationDominoJP($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    entitlements {
      hasHazardsForProduct(product: DOMINO, module: "jp") {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: DOMINO, module: "jp") {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetOrganizationDominoJpComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables>, 'query'> & ({ variables: GetOrganizationDominoJpQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationDominoJpComponent = (props: GetOrganizationDominoJpComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables> query={GetOrganizationDominoJpDocument} {...props} />
    );
    

/**
 * __useGetOrganizationDominoJpQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDominoJpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDominoJpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDominoJpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDominoJpQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables>(GetOrganizationDominoJpDocument, options);
      }
export function useGetOrganizationDominoJpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables>(GetOrganizationDominoJpDocument, options);
        }
export type GetOrganizationDominoJpQueryHookResult = ReturnType<typeof useGetOrganizationDominoJpQuery>;
export type GetOrganizationDominoJpLazyQueryHookResult = ReturnType<typeof useGetOrganizationDominoJpLazyQuery>;
export type GetOrganizationDominoJpQueryResult = Apollo.QueryResult<GetOrganizationDominoJpQuery, GetOrganizationDominoJpQueryVariables>;
export const GetOrganizationDominoUsDocument = gql`
    query GetOrganizationDominoUS($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    entitlements {
      hasHazardsForProduct(product: DOMINO, module: "us") {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: DOMINO, module: "us") {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetOrganizationDominoUsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables>, 'query'> & ({ variables: GetOrganizationDominoUsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationDominoUsComponent = (props: GetOrganizationDominoUsComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables> query={GetOrganizationDominoUsDocument} {...props} />
    );
    

/**
 * __useGetOrganizationDominoUsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDominoUsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDominoUsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDominoUsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDominoUsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables>(GetOrganizationDominoUsDocument, options);
      }
export function useGetOrganizationDominoUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables>(GetOrganizationDominoUsDocument, options);
        }
export type GetOrganizationDominoUsQueryHookResult = ReturnType<typeof useGetOrganizationDominoUsQuery>;
export type GetOrganizationDominoUsLazyQueryHookResult = ReturnType<typeof useGetOrganizationDominoUsLazyQuery>;
export type GetOrganizationDominoUsQueryResult = Apollo.QueryResult<GetOrganizationDominoUsQuery, GetOrganizationDominoUsQueryVariables>;
export const GetOrganizationAllReadyFeaturesDocument = gql`
    query GetOrganizationAllReadyFeatures($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    entitlements {
      hasFeaturesForProduct(product: READY) {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetOrganizationAllReadyFeaturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables>, 'query'> & ({ variables: GetOrganizationAllReadyFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetOrganizationAllReadyFeaturesComponent = (props: GetOrganizationAllReadyFeaturesComponentProps) => (
      <ApolloReactComponents.Query<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables> query={GetOrganizationAllReadyFeaturesDocument} {...props} />
    );
    

/**
 * __useGetOrganizationAllReadyFeaturesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAllReadyFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAllReadyFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAllReadyFeaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationAllReadyFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables>(GetOrganizationAllReadyFeaturesDocument, options);
      }
export function useGetOrganizationAllReadyFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables>(GetOrganizationAllReadyFeaturesDocument, options);
        }
export type GetOrganizationAllReadyFeaturesQueryHookResult = ReturnType<typeof useGetOrganizationAllReadyFeaturesQuery>;
export type GetOrganizationAllReadyFeaturesLazyQueryHookResult = ReturnType<typeof useGetOrganizationAllReadyFeaturesLazyQuery>;
export type GetOrganizationAllReadyFeaturesQueryResult = Apollo.QueryResult<GetOrganizationAllReadyFeaturesQuery, GetOrganizationAllReadyFeaturesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    title
    role
    personalInfo {
      firstName
      lastName
      contact {
        email
        phoneNumber
      }
    }
    status
    preferences {
      country
      language
      timezone
      units
      notificationsEnabled
      notificationsChannels
    }
    entitlements {
      scopes
      enabledProducts
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
      hasHazardsForProduct(product: READY, module: "live hazard") {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: READY, module: "live hazard") {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserQuery, GetUserQueryVariables>, 'query'> & ({ variables: GetUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserComponent = (props: GetUserComponentProps) => (
      <ApolloReactComponents.Query<GetUserQuery, GetUserQueryVariables> query={GetUserDocument} {...props} />
    );
    

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    id
    role
    title
    personalInfo {
      firstName
      lastName
      contact {
        email
        phoneNumber
      }
    }
    status
    preferences {
      country
      language
      timezone
      units
      notificationsEnabled
      notificationsChannels
    }
  }
}
    `;
export type GetUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUsersQuery, GetUsersQueryVariables>, 'query'>;

    export const GetUsersComponent = (props: GetUsersComponentProps) => (
      <ApolloReactComponents.Query<GetUsersQuery, GetUsersQueryVariables> query={GetUsersDocument} {...props} />
    );
    

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDominoFeaturesDocument = gql`
    query GetUserDominoFeatures($id: ID!) {
  getUser(id: $id) {
    entitlements {
      enabledProducts
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
      hasHazardsForProduct(product: DOMINO) {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: DOMINO) {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetUserDominoFeaturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables>, 'query'> & ({ variables: GetUserDominoFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserDominoFeaturesComponent = (props: GetUserDominoFeaturesComponentProps) => (
      <ApolloReactComponents.Query<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables> query={GetUserDominoFeaturesDocument} {...props} />
    );
    

/**
 * __useGetUserDominoFeaturesQuery__
 *
 * To run a query within a React component, call `useGetUserDominoFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDominoFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDominoFeaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDominoFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables>(GetUserDominoFeaturesDocument, options);
      }
export function useGetUserDominoFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables>(GetUserDominoFeaturesDocument, options);
        }
export type GetUserDominoFeaturesQueryHookResult = ReturnType<typeof useGetUserDominoFeaturesQuery>;
export type GetUserDominoFeaturesLazyQueryHookResult = ReturnType<typeof useGetUserDominoFeaturesLazyQuery>;
export type GetUserDominoFeaturesQueryResult = Apollo.QueryResult<GetUserDominoFeaturesQuery, GetUserDominoFeaturesQueryVariables>;
export const GetUserDominoJpDocument = gql`
    query GetUserDominoJp($id: ID!) {
  getUser(id: $id) {
    entitlements {
      enabledProducts
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
      hasHazardsForProduct(product: DOMINO, module: "jp") {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: DOMINO, module: "jp") {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetUserDominoJpComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserDominoJpQuery, GetUserDominoJpQueryVariables>, 'query'> & ({ variables: GetUserDominoJpQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserDominoJpComponent = (props: GetUserDominoJpComponentProps) => (
      <ApolloReactComponents.Query<GetUserDominoJpQuery, GetUserDominoJpQueryVariables> query={GetUserDominoJpDocument} {...props} />
    );
    

/**
 * __useGetUserDominoJpQuery__
 *
 * To run a query within a React component, call `useGetUserDominoJpQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDominoJpQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDominoJpQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDominoJpQuery(baseOptions: Apollo.QueryHookOptions<GetUserDominoJpQuery, GetUserDominoJpQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDominoJpQuery, GetUserDominoJpQueryVariables>(GetUserDominoJpDocument, options);
      }
export function useGetUserDominoJpLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDominoJpQuery, GetUserDominoJpQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDominoJpQuery, GetUserDominoJpQueryVariables>(GetUserDominoJpDocument, options);
        }
export type GetUserDominoJpQueryHookResult = ReturnType<typeof useGetUserDominoJpQuery>;
export type GetUserDominoJpLazyQueryHookResult = ReturnType<typeof useGetUserDominoJpLazyQuery>;
export type GetUserDominoJpQueryResult = Apollo.QueryResult<GetUserDominoJpQuery, GetUserDominoJpQueryVariables>;
export const GetUserDominoUsDocument = gql`
    query GetUserDominoUs($id: ID!) {
  getUser(id: $id) {
    entitlements {
      enabledProducts
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
      hasHazardsForProduct(product: DOMINO, module: "us") {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: DOMINO, module: "us") {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetUserDominoUsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserDominoUsQuery, GetUserDominoUsQueryVariables>, 'query'> & ({ variables: GetUserDominoUsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserDominoUsComponent = (props: GetUserDominoUsComponentProps) => (
      <ApolloReactComponents.Query<GetUserDominoUsQuery, GetUserDominoUsQueryVariables> query={GetUserDominoUsDocument} {...props} />
    );
    

/**
 * __useGetUserDominoUsQuery__
 *
 * To run a query within a React component, call `useGetUserDominoUsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDominoUsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDominoUsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserDominoUsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDominoUsQuery, GetUserDominoUsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDominoUsQuery, GetUserDominoUsQueryVariables>(GetUserDominoUsDocument, options);
      }
export function useGetUserDominoUsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDominoUsQuery, GetUserDominoUsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDominoUsQuery, GetUserDominoUsQueryVariables>(GetUserDominoUsDocument, options);
        }
export type GetUserDominoUsQueryHookResult = ReturnType<typeof useGetUserDominoUsQuery>;
export type GetUserDominoUsLazyQueryHookResult = ReturnType<typeof useGetUserDominoUsLazyQuery>;
export type GetUserDominoUsQueryResult = Apollo.QueryResult<GetUserDominoUsQuery, GetUserDominoUsQueryVariables>;
export const GetUserEntitlementsDocument = gql`
    query GetUserEntitlements {
  user {
    id
    entitlements {
      id
      __typename
    }
    __typename
  }
}
    `;
export type GetUserEntitlementsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables>, 'query'>;

    export const GetUserEntitlementsComponent = (props: GetUserEntitlementsComponentProps) => (
      <ApolloReactComponents.Query<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables> query={GetUserEntitlementsDocument} {...props} />
    );
    

/**
 * __useGetUserEntitlementsQuery__
 *
 * To run a query within a React component, call `useGetUserEntitlementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserEntitlementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserEntitlementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserEntitlementsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables>(GetUserEntitlementsDocument, options);
      }
export function useGetUserEntitlementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables>(GetUserEntitlementsDocument, options);
        }
export type GetUserEntitlementsQueryHookResult = ReturnType<typeof useGetUserEntitlementsQuery>;
export type GetUserEntitlementsLazyQueryHookResult = ReturnType<typeof useGetUserEntitlementsLazyQuery>;
export type GetUserEntitlementsQueryResult = Apollo.QueryResult<GetUserEntitlementsQuery, GetUserEntitlementsQueryVariables>;
export const GetUserReadyFeaturesDocument = gql`
    query GetUserReadyFeatures($id: ID!) {
  getUser(id: $id) {
    entitlements {
      enabledProducts
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
      hasHazardsForProduct(product: READY) {
        product
        module
        region
        hazard
        isInherited
      }
      hasFeaturesForProduct(product: READY) {
        product
        region
        module
        hazard
        feature
        isInherited
      }
    }
  }
}
    `;
export type GetUserReadyFeaturesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables>, 'query'> & ({ variables: GetUserReadyFeaturesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetUserReadyFeaturesComponent = (props: GetUserReadyFeaturesComponentProps) => (
      <ApolloReactComponents.Query<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables> query={GetUserReadyFeaturesDocument} {...props} />
    );
    

/**
 * __useGetUserReadyFeaturesQuery__
 *
 * To run a query within a React component, call `useGetUserReadyFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserReadyFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserReadyFeaturesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserReadyFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables>(GetUserReadyFeaturesDocument, options);
      }
export function useGetUserReadyFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables>(GetUserReadyFeaturesDocument, options);
        }
export type GetUserReadyFeaturesQueryHookResult = ReturnType<typeof useGetUserReadyFeaturesQuery>;
export type GetUserReadyFeaturesLazyQueryHookResult = ReturnType<typeof useGetUserReadyFeaturesLazyQuery>;
export type GetUserReadyFeaturesQueryResult = Apollo.QueryResult<GetUserReadyFeaturesQuery, GetUserReadyFeaturesQueryVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($invite: InviteUserInput!, $profile: InviteProfileInput) {
  inviteAnyUser(invite: $invite, profile: $profile) {
    id
    role
    personalInfo {
      firstName
      lastName
      contact {
        email
      }
    }
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;
export type InviteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InviteUserMutation, InviteUserMutationVariables>, 'mutation'>;

    export const InviteUserComponent = (props: InviteUserComponentProps) => (
      <ApolloReactComponents.Mutation<InviteUserMutation, InviteUserMutationVariables> mutation={InviteUserDocument} {...props} />
    );
    

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      invite: // value for 'invite'
 *      profile: // value for 'profile'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const ResendInvitationDocument = gql`
    mutation ResendInvitation($id: ID!) {
  resendInvite(id: $id) {
    id
  }
}
    `;
export type ResendInvitationMutationFn = Apollo.MutationFunction<ResendInvitationMutation, ResendInvitationMutationVariables>;
export type ResendInvitationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResendInvitationMutation, ResendInvitationMutationVariables>, 'mutation'>;

    export const ResendInvitationComponent = (props: ResendInvitationComponentProps) => (
      <ApolloReactComponents.Mutation<ResendInvitationMutation, ResendInvitationMutationVariables> mutation={ResendInvitationDocument} {...props} />
    );
    

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendInvitationMutation(baseOptions?: Apollo.MutationHookOptions<ResendInvitationMutation, ResendInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(ResendInvitationDocument, options);
      }
export type ResendInvitationMutationHookResult = ReturnType<typeof useResendInvitationMutation>;
export type ResendInvitationMutationResult = Apollo.MutationResult<ResendInvitationMutation>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<ResendInvitationMutation, ResendInvitationMutationVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($limit: Int, $offset: Int, $filters: [UsersFilter!]!, $orderBy: [UserOrdering!]) {
  searchUsers(
    limit: $limit
    offset: $offset
    filters: $filters
    orderBy: $orderBy
  ) {
    id
    role
    title
    personalInfo {
      firstName
      lastName
      contact {
        email
        phoneNumber
      }
    }
    status
    preferences {
      country
      language
      timezone
      units
      notificationsEnabled
      notificationsChannels
    }
  }
}
    `;
export type SearchUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchUsersQuery, SearchUsersQueryVariables>, 'query'> & ({ variables: SearchUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchUsersComponent = (props: SearchUsersComponentProps) => (
      <ApolloReactComponents.Query<SearchUsersQuery, SearchUsersQueryVariables> query={SearchUsersDocument} {...props} />
    );
    

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      filters: // value for 'filters'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions: Apollo.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
      }
export function useSearchUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, options);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = Apollo.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const SetLocationUploadFileDocument = gql`
    mutation setLocationUploadFile($file: Upload!) {
  setLocationUploadFile(file: $file) @client {
    file
  }
}
    `;
export type SetLocationUploadFileMutationFn = Apollo.MutationFunction<SetLocationUploadFileMutation, SetLocationUploadFileMutationVariables>;
export type SetLocationUploadFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetLocationUploadFileMutation, SetLocationUploadFileMutationVariables>, 'mutation'>;

    export const SetLocationUploadFileComponent = (props: SetLocationUploadFileComponentProps) => (
      <ApolloReactComponents.Mutation<SetLocationUploadFileMutation, SetLocationUploadFileMutationVariables> mutation={SetLocationUploadFileDocument} {...props} />
    );
    

/**
 * __useSetLocationUploadFileMutation__
 *
 * To run a mutation, you first call `useSetLocationUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationUploadFileMutation, { data, loading, error }] = useSetLocationUploadFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSetLocationUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<SetLocationUploadFileMutation, SetLocationUploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLocationUploadFileMutation, SetLocationUploadFileMutationVariables>(SetLocationUploadFileDocument, options);
      }
export type SetLocationUploadFileMutationHookResult = ReturnType<typeof useSetLocationUploadFileMutation>;
export type SetLocationUploadFileMutationResult = Apollo.MutationResult<SetLocationUploadFileMutation>;
export type SetLocationUploadFileMutationOptions = Apollo.BaseMutationOptions<SetLocationUploadFileMutation, SetLocationUploadFileMutationVariables>;
export const UpdateAnyOrganizationDocument = gql`
    mutation UpdateAnyOrganization($org: OrganizationAdminInput!) {
  updateAnyOrganization(org: $org) {
    id
    name
    enName
    adminNotes
    preferences {
      defaultUnits
      defaultCountry
      defaultCountryCode
      defaultLanguage
      defaultTimezone
      defaultNotificationsEnabled
      defaultNotificationsChannels
      allDefaultPreferences
    }
    names
    users {
      id
      role
      title
      personalInfo {
        firstName
        lastName
        contact {
          email
          phoneNumber
        }
      }
      status
      preferences {
        country
        language
        timezone
        notificationsEnabled
        notificationsChannels
      }
    }
  }
}
    `;
export type UpdateAnyOrganizationMutationFn = Apollo.MutationFunction<UpdateAnyOrganizationMutation, UpdateAnyOrganizationMutationVariables>;
export type UpdateAnyOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAnyOrganizationMutation, UpdateAnyOrganizationMutationVariables>, 'mutation'>;

    export const UpdateAnyOrganizationComponent = (props: UpdateAnyOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAnyOrganizationMutation, UpdateAnyOrganizationMutationVariables> mutation={UpdateAnyOrganizationDocument} {...props} />
    );
    

/**
 * __useUpdateAnyOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateAnyOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnyOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnyOrganizationMutation, { data, loading, error }] = useUpdateAnyOrganizationMutation({
 *   variables: {
 *      org: // value for 'org'
 *   },
 * });
 */
export function useUpdateAnyOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnyOrganizationMutation, UpdateAnyOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnyOrganizationMutation, UpdateAnyOrganizationMutationVariables>(UpdateAnyOrganizationDocument, options);
      }
export type UpdateAnyOrganizationMutationHookResult = ReturnType<typeof useUpdateAnyOrganizationMutation>;
export type UpdateAnyOrganizationMutationResult = Apollo.MutationResult<UpdateAnyOrganizationMutation>;
export type UpdateAnyOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateAnyOrganizationMutation, UpdateAnyOrganizationMutationVariables>;
export const UpdateMibRequestsDocument = gql`
    mutation UpdateMIBRequests($input: AdminUpdateMIBRequestsInput!) {
  updateMIBRequests(input: $input) {
    id
    externalID
    externalUserID
    fileID
    lineNumber
    address {
      city
      country
      countryCode
      county
      formattedAddress
      houseNumber
      postCode
      state
      stateCode
      stateDistrict
      street
      suburb
    }
    metadata
    postedAt
    updatedAt
    status
  }
}
    `;
export type UpdateMibRequestsMutationFn = Apollo.MutationFunction<UpdateMibRequestsMutation, UpdateMibRequestsMutationVariables>;
export type UpdateMibRequestsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMibRequestsMutation, UpdateMibRequestsMutationVariables>, 'mutation'>;

    export const UpdateMibRequestsComponent = (props: UpdateMibRequestsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMibRequestsMutation, UpdateMibRequestsMutationVariables> mutation={UpdateMibRequestsDocument} {...props} />
    );
    

/**
 * __useUpdateMibRequestsMutation__
 *
 * To run a mutation, you first call `useUpdateMibRequestsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMibRequestsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMibRequestsMutation, { data, loading, error }] = useUpdateMibRequestsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMibRequestsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMibRequestsMutation, UpdateMibRequestsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMibRequestsMutation, UpdateMibRequestsMutationVariables>(UpdateMibRequestsDocument, options);
      }
export type UpdateMibRequestsMutationHookResult = ReturnType<typeof useUpdateMibRequestsMutation>;
export type UpdateMibRequestsMutationResult = Apollo.MutationResult<UpdateMibRequestsMutation>;
export type UpdateMibRequestsMutationOptions = Apollo.BaseMutationOptions<UpdateMibRequestsMutation, UpdateMibRequestsMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $userInput: UserEditInput!) {
  updateUser(id: $id, userInput: $userInput) {
    id
    title
    role
    personalInfo {
      firstName
      lastName
      contact {
        email
        phoneNumber
      }
    }
    status
    preferences {
      country
      language
      timezone
      units
      notificationsEnabled
      notificationsChannels
    }
    entitlements {
      enabledProducts
      enabledProductsModule {
        product
        region
        module
        isMain
        isInherited
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      userInput: // value for 'userInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadUserLocationsFileDocument = gql`
    mutation UploadUserLocationsFile($file: Upload!, $id: ID!) {
  uploadUserLocationsFile(file: $file, id: $id) {
    id
    name
    uploadedAt
    totalRows
    totalAccepted
    totalRejected
    warning {
      code
      message
    }
    notices {
      id
      lineNumber
      label
      noticeCode
      originalValue
      newValue
      reason
    }
  }
}
    `;
export type UploadUserLocationsFileMutationFn = Apollo.MutationFunction<UploadUserLocationsFileMutation, UploadUserLocationsFileMutationVariables>;
export type UploadUserLocationsFileComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UploadUserLocationsFileMutation, UploadUserLocationsFileMutationVariables>, 'mutation'>;

    export const UploadUserLocationsFileComponent = (props: UploadUserLocationsFileComponentProps) => (
      <ApolloReactComponents.Mutation<UploadUserLocationsFileMutation, UploadUserLocationsFileMutationVariables> mutation={UploadUserLocationsFileDocument} {...props} />
    );
    

/**
 * __useUploadUserLocationsFileMutation__
 *
 * To run a mutation, you first call `useUploadUserLocationsFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadUserLocationsFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadUserLocationsFileMutation, { data, loading, error }] = useUploadUserLocationsFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUploadUserLocationsFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadUserLocationsFileMutation, UploadUserLocationsFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadUserLocationsFileMutation, UploadUserLocationsFileMutationVariables>(UploadUserLocationsFileDocument, options);
      }
export type UploadUserLocationsFileMutationHookResult = ReturnType<typeof useUploadUserLocationsFileMutation>;
export type UploadUserLocationsFileMutationResult = Apollo.MutationResult<UploadUserLocationsFileMutation>;
export type UploadUserLocationsFileMutationOptions = Apollo.BaseMutationOptions<UploadUserLocationsFileMutation, UploadUserLocationsFileMutationVariables>;