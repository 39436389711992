import React, { useState } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Stack from '@mui/material/Stack';
import { useNavigate, useParams } from 'react-router-dom';
import DropdownButton from '../../CommonComponents/DropdownButton';
import ChangeOrgModal from './Components/ChangeOrgModal';
import {
  GetOrganizationQuery,
  NotificationsChannel,
  useDisableUserMutation,
  useEnableUserMutation,
  useResendInvitationMutation,
  UserRole,
  useUpdateUserMutation,
} from '../../../__generated__/graphql';

type UserProps = {
  id: string;
  title: string;
  status: string;
  role: string;
  personalInfo: {
    firstName: string;
    lastName: string;
    contact: { email: string; phoneNumber: string };
  };
  preferences: {
    country: string;
    language: string;
    timezone: string;
    notificationsEnabled: boolean;
    notificationsChannels: Array<NotificationsChannel>;
  };
};
type UsersState = { view: string; userIdDetails: string };
type UsersListProps = {
  users: Array<UserProps>;
  handleState: (handleState: UsersState) => void;
  organization: GetOrganizationQuery['getOrganization'];
  onUpdateOrgData: () => void;
};

const Header: React.FC = () => {
  const width = 100 / 7;
  return (
    <TableHead>
      <TableRow>
        <TableCell width={`${width}%`} align="left">
          Id
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          First Name
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Last Name
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Email
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Role
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Status
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Options
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const UsersList: React.FC<UsersListProps> = ({
  users,
  handleState,
  organization,
  onUpdateOrgData,
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [enableUserMutation] = useEnableUserMutation();
  const [disableUserMutation] = useDisableUserMutation();
  const [updateUserMutation] = useUpdateUserMutation();
  const [resendInvitationMutation] = useResendInvitationMutation();

  const [changeOrgModalData, setChangeOrgModalData] = useState<{ userId: string; isOpen: boolean }>(
    {
      userId: '',
      isOpen: false,
    },
  );

  const onUserClick = (user: UserProps) => {
    handleState({ view: 'userDetails', userIdDetails: user.id });
  };

  const onOptionSelect = (opt: string, id: string) => {
    switch (opt) {
      case 'enable':
        enableUserMutation({ variables: { id } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'disable':
        disableUserMutation({ variables: { id } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'add_viewer':
        updateUserMutation({ variables: { id, userInput: { role: UserRole.Viewer } } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'add_admin':
        updateUserMutation({ variables: { id, userInput: { role: UserRole.Admin } } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'remove_admin':
        updateUserMutation({ variables: { id, userInput: { role: UserRole.Contributor } } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'remove_viewer':
        updateUserMutation({ variables: { id, userInput: { role: UserRole.Contributor } } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'resend_invitation':
        resendInvitationMutation({ variables: { id } })
          .then(() => {
            onUpdateOrgData();
          })
          .catch((e) => console.log('newOrgError', e.graphQLErrors[0]));
        break;
      case 'change_organization':
        setChangeOrgModalData({ userId: id, isOpen: true });
        break;
      default:
        break;
    }
  };

  const displayData = () => {
    return (
      <>
        {users.map((row) => {
          const opts = [];
          if (row.status !== 'Disabled') {
            opts.push({ key: 'disable', name: 'Disable' });
          } else {
            opts.push({ key: 'enable', name: 'Enable' });
          }
          switch (row.role) {
            case 'Admin':
              opts.push({ key: 'remove_admin', name: 'Remove Org Admin' });
              break;
            case 'Viewer':
              opts.push({ key: 'remove_viewer', name: 'Remove Viewer' });
              break;
            case 'Contributor':
              opts.push({ key: 'add_admin', name: 'Set Org Admin' });
              opts.push({ key: 'add_viewer', name: 'Set Viewer' });
              break;
            default:
              break;
          }
          opts.push({ key: 'resend_invitation', name: 'Resend Invite' });
          opts.push({ key: 'change_organization', name: 'Change Organization' });

          return (
            <TableRow
              hover={row.status !== 'Disabled'}
              key={row.id}
              onClick={() => {
                if (row.status !== 'Disabled') {
                  onUserClick(row);
                }
              }}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.personalInfo.firstName}</TableCell>
              <TableCell align="left">{row.personalInfo.lastName}</TableCell>
              <TableCell align="left">{row.personalInfo.contact.email}</TableCell>
              <TableCell align="left">{row.role}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">
                <Button>
                  <DropdownButton
                    onSelect={(opt: string) => {
                      onOptionSelect(opt, row.id);
                    }}
                    options={opts}
                  />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <ChangeOrgModal
        isOpen={changeOrgModalData.isOpen}
        userId={changeOrgModalData.userId}
        currentOrganization={organization.id}
        onSave={() => {
          setChangeOrgModalData({ userId: '', isOpen: false });
          onUpdateOrgData();
        }}
        onCancel={() => setChangeOrgModalData({ userId: '', isOpen: false })}
      />
      {/* <InviteUserModal organization={organization} onSave={onUpdateOrgData} /> */}
      <Stack marginBottom="10px" direction="row" justifyContent="flex-end">
        <Button size="small" onClick={() => navigate(`/orgs/${encodeURIComponent(id)}/user/new`)}>
          + Invite User
        </Button>
      </Stack>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <Header />
            <TableBody>{displayData()}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

UsersList.displayName = 'UsersList';
export default UsersList;
