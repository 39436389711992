import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Dropdown from '../../../CommonComponents/Form/Dropdown';
import {
  GetOrganizationQuery,
  LanguagePreferences,
  Product,
  useGetEntitlementsStructureQuery,
  useGetOrganizationAllDominoFeaturesQuery,
  useGetOrganizationAllReadyFeaturesQuery,
  useGetOrganizationDominoJpQuery,
  useGetOrganizationDominoUsQuery,
  useGetOrganizationQuery,
  useInviteUserMutation,
  UserRole,
} from '../../../../__generated__/graphql';
import { languages, roles } from '../../constants';
import { getErrorFromGraphqlError } from '../../../../util/errors';
import { getInviteUserPayload } from '../utils/payloads';
import {
  organizationAllDominoFeatures,
  organizationAllReadyFeatures,
  organizationDominoJP,
  organizationDominoUS,
  organization as organizationn,
  userEntitlementsStructure,
} from '../../../../mocks/organization';
import {
  createOrgEntsPayload,
  createProductItems,
} from '../../Organizations/OrganizationsTab/utils/converter';
import ProductsList from '../../../CommonComponents/ProductsList';

const useStyles = makeStyles({
  container: {
    marginTop: '30px',
  },
});

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type InviteUserModalState = {
  email: string;
  firstName: string;
  lastName: string;
  product: Product;
  language: LanguagePreferences;
  role: UserRole;
  products?: Array<ProductItemProps>;
};

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  product: Product.Domino,
  language: LanguagePreferences.En,
  role: UserRole.Contributor,
};

const InviteUserModal = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = React.useState<InviteUserModalState>(
    JSON.parse(JSON.stringify(initialState)),
  );
  const [organization, setOrganization] = React.useState<GetOrganizationQuery['getOrganization']>();
  const [displayError, setDisplayError] = React.useState(null);
  const [inviteUserMutation] = useInviteUserMutation();

  // TODO: Need clean up
  let {
    data: dataOrganization,
    loading,
    error,
  } = useGetOrganizationQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  let {
    data: dataDominoUs,
    loading: loadingDominoUs,
    error: errorDominoUs,
  } = useGetOrganizationDominoUsQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  let {
    data: dataDominoJp,
    loading: loadingDominoJp,
    error: errorDominoJp,
  } = useGetOrganizationDominoJpQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  let {
    data: dataAllDominoFeature,
    loading: loadingAllDominoFeature,
    error: errorAllDominoFeature,
  } = useGetOrganizationAllDominoFeaturesQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  let {
    data: dataAllReadyFeature,
    loading: loadingAllReadyFeature,
    error: errorAllReadyFeature,
  } = useGetOrganizationAllReadyFeaturesQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  let {
    data: structData,
    loading: structLoading,
    error: structError,
  } = useGetEntitlementsStructureQuery({ fetchPolicy: 'no-cache' });

  if (process.env.REACT_APP_ENV === 'local') {
    dataOrganization = { getOrganization: organizationn };
    loading = false;
    error = null;

    dataDominoUs = { getOrganization: organizationDominoUS };
    loadingDominoUs = false;
    errorDominoUs = null;

    dataDominoJp = { getOrganization: organizationDominoJP };
    loadingDominoJp = false;
    errorDominoJp = null;

    dataAllDominoFeature = { getOrganization: organizationAllDominoFeatures };
    loadingAllDominoFeature = false;
    errorAllDominoFeature = null;

    dataAllReadyFeature = { getOrganization: organizationAllReadyFeatures };
    loadingAllReadyFeature = false;
    errorAllReadyFeature = null;

    structData = { entitlementsStructure: userEntitlementsStructure };
    structLoading = false;
    structError = null;
  }

  useEffect(() => {
    const newDataRes = dataOrganization;
    const newDataDominoUsRes = dataDominoUs;
    const newDataDominoJpRes = dataDominoJp;
    const newDataAllDominoFeature = dataAllDominoFeature;
    const newDataAllReadyFeature = dataAllReadyFeature;
    if (
      newDataRes &&
      newDataDominoUsRes &&
      newDataDominoJpRes &&
      newDataAllDominoFeature &&
      newDataAllReadyFeature
    ) {
      const entitlements: GetOrganizationQuery['getOrganization']['entitlements'] = {
        scopes: [],
        enabledProducts: [],
        enabledProductsModule: [],
        hasHazardsForProduct: [],
        hasFeaturesForProduct: [],
      };
      if (newDataRes.getOrganization.entitlements) {
        entitlements.scopes = [
          ...entitlements.scopes,
          ...newDataRes.getOrganization.entitlements.scopes,
        ];
        entitlements.enabledProducts = [
          ...entitlements.enabledProducts,
          ...newDataRes.getOrganization.entitlements.enabledProducts,
        ];
        entitlements.enabledProductsModule = [
          ...entitlements.enabledProductsModule,
          ...newDataRes.getOrganization.entitlements.enabledProductsModule,
        ];
        entitlements.hasHazardsForProduct = [
          ...entitlements.hasHazardsForProduct,
          ...newDataRes.getOrganization.entitlements.hasHazardsForProduct,
        ];
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataRes.getOrganization.entitlements.hasFeaturesForProduct,
        ];
      }
      if (newDataDominoUsRes.getOrganization.entitlements) {
        entitlements.hasHazardsForProduct = [
          ...entitlements.hasHazardsForProduct,
          ...newDataDominoUsRes.getOrganization.entitlements.hasHazardsForProduct,
        ];
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataDominoUsRes.getOrganization.entitlements.hasFeaturesForProduct,
        ];
      }
      if (newDataDominoJpRes.getOrganization.entitlements) {
        entitlements.hasHazardsForProduct = [
          ...entitlements.hasHazardsForProduct,
          ...newDataDominoJpRes.getOrganization.entitlements.hasHazardsForProduct,
        ];
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataDominoJpRes.getOrganization.entitlements.hasFeaturesForProduct,
        ];
      }

      if (newDataAllDominoFeature.getOrganization.entitlements) {
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataAllDominoFeature.getOrganization.entitlements.hasFeaturesForProduct.filter(
            (f) => !f.hazard,
          ),
        ];
      }

      if (newDataAllReadyFeature.getOrganization.entitlements) {
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataAllReadyFeature.getOrganization.entitlements.hasFeaturesForProduct.filter(
            (f) => !f.hazard,
          ),
        ];
      }

      const newOrg = {
        ...dataOrganization.getOrganization,
        entitlements,
      };
      setOrganization(newOrg);
    }
  }, [dataOrganization, dataDominoUs, dataDominoJp, dataAllDominoFeature, dataAllReadyFeature]);
  // }, []);

  useEffect(() => {
    if (organization) {
      let defaultLanguage = LanguagePreferences.En;
      if (organization.preferences?.defaultLanguage) {
        defaultLanguage = organization.preferences.defaultLanguage;
      } else if (
        organization.entitlements.enabledProducts.length === 1 &&
        organization.entitlements.enabledProducts[0] === Product.Ready
      ) {
        defaultLanguage = LanguagePreferences.Ja;
      } else {
        defaultLanguage = LanguagePreferences.En;
      }
      const orgProducts: { [key: string]: Product } = {};
      organization.entitlements.enabledProducts.forEach((p) => {
        if (!orgProducts[p.toString()]) {
          orgProducts[p.toString()] = p;
        }
      });
      const productsList = Object.keys(orgProducts).map((k) => {
        const productName = k.toString().charAt(0).toUpperCase() + k.toString().slice(1);
        return { name: orgProducts[k], value: productName };
      });
      const selectedProduct = productsList.length > 0 ? productsList[0] : { name: Product.Domino };

      setData((d) => ({ ...d, product: selectedProduct.name, language: defaultLanguage }));
    }
  }, [organization]);

  useEffect(() => {
    if (structData && organization?.entitlements) {
      const newData = createProductItems(
        structData.entitlementsStructure,
        organization.entitlements,
        { inviteUser: true },
      );
      setData((o) => ({ ...o, products: newData }));
    }
  }, [structData, organization?.entitlements]);
  // }, [organization?.entitlements]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setData({ ...data, [name]: value });
  };

  const onDropdownChange = (e: { name: string; value: string }) => {
    const { name, value } = e;
    setData({ ...data, [name]: value });
  };

  const onProductsChange = (value: Array<ProductItemProps>) => {
    setData({ ...data, products: value });
  };

  const onSubmit = () => {
    const payload = getInviteUserPayload(data, organization);
    const { ents } = createOrgEntsPayload(data.products);
    payload.invite.entitlements = ents;
    console.log('newUser', JSON.stringify(payload));

    inviteUserMutation({ variables: payload })
      .then(() => {
        navigate(`/orgs/${encodeURIComponent(id)}`);
      })
      .catch((e) => {
        console.log('newUserError', e.graphQLErrors[0]);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError(convertedError);
      });
  };

  const goBack = () => {
    navigate(-1);
  };

  const availableRoles = [...roles];

  if (
    loading ||
    loadingDominoUs ||
    loadingDominoJp ||
    loadingAllDominoFeature ||
    loadingAllReadyFeature ||
    structLoading
  ) {
    return <div>Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  const convertedDominoUsError = getErrorFromGraphqlError(errorDominoUs);
  const convertedDominoJpError = getErrorFromGraphqlError(errorDominoJp);
  const convertedAllDominoFeatureError = getErrorFromGraphqlError(errorAllDominoFeature);
  const convertedAllReadyFeatureError = getErrorFromGraphqlError(errorAllReadyFeature);
  const convertedStructError = getErrorFromGraphqlError(structError);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }
  if (convertedDominoUsError) {
    return <div>{convertedDominoUsError}</div>;
  }
  if (convertedDominoJpError) {
    return <div>{convertedDominoJpError}</div>;
  }
  if (convertedAllDominoFeatureError) {
    return <div>{convertedAllDominoFeatureError}</div>;
  }
  if (convertedAllReadyFeatureError) {
    return <div>{convertedAllReadyFeatureError}</div>;
  }
  if (convertedStructError) {
    return <div>{convertedStructError}</div>;
  }

  if (!organization) {
    return null;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper sx={{ width: '100%', overflow: 'hidden', padding: '20px' }}>
        <Box sx={{ width: '100%' }}>
          <Typography
            id="InviteUser-Title"
            variant="h6"
            component="h2"
            data-test-id="InviteUser-Title"
          >
            Invite User
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <TextField
                id="email"
                label="Email"
                name="email"
                fullWidth
                value={data.email}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: data.email ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'Email-Input',
                }}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop="10px">
            <Grid item xs={6}>
              <TextField
                id="firstName"
                label="First Name"
                name="firstName"
                fullWidth
                value={data.firstName}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: data.firstName ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'FirstName-Input',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="lastName"
                label="Last Name"
                name="lastName"
                fullWidth
                value={data.lastName}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: data.lastName ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'LastName-Input',
                }}
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop="10px">
            <Grid item xs={12}>
              <Dropdown
                fullWidth
                selectedValue={data.language}
                onChange={onDropdownChange}
                id="language"
                name="language"
                label="Language"
                list={languages}
                data-test-id="UserLanguage-Select"
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} marginTop="10px">
            <Grid item xs={12}>
              <Dropdown
                fullWidth
                selectedValue={data.role}
                onChange={onDropdownChange}
                id="role"
                name="role"
                label="Role"
                list={availableRoles}
                data-test-id="UserRole-Select"
              />
            </Grid>
          </Grid>

          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <Typography
                sx={{ marginLeft: '10px', marginTop: '30px' }}
                variant="subtitle1"
                gutterBottom
                component="div"
                data-test-id="Subscriptions-SectionTitle"
              >
                Subscriptions
              </Typography>
              <ProductsList value={data.products} onChange={onProductsChange} forUser />
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button variant="outlined" onClick={goBack} data-test-id="CancelInvite-Btn">
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit} data-test-id="SendInvite-Btn">
              Send Invite
            </Button>
          </Stack>
          {displayError ? (
            <InputLabel sx={{ marginTop: '10px', color: 'red' }} data-test-id="InviteUser-Error">
              {displayError}
            </InputLabel>
          ) : null}
        </Box>
      </Paper>
    </Container>
  );
};

InviteUserModal.displayName = 'InviteUserModal';
export default InviteUserModal;
