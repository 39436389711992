import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Dropdown from '../../../../../CommonComponents/Form/Dropdown';
import {
  countries,
  countriesCode,
  languages,
  notificationsChannels,
  notificationsEnabled,
  timezones,
  unitsPref,
} from '../../../../constants';
import {
  GetOrganizationQuery,
  LanguagePreferences,
  NotificationsChannel,
  Product,
  useDeleteOrgMutation,
  useGetEntitlementsStructureQuery,
  useUpdateAnyOrganizationMutation,
} from '../../../../../../__generated__/graphql';
import DeleteConfirmationPopover from '../../Components/DeleteConfirmation/Popover';
import MultipleSelect from '../../../../../CommonComponents/MultiSelect';
import { getErrorFromGraphqlError } from '../../../../../../util/errors';
import { convertOrgRes, createProductItems } from '../../utils/converter';
import updateOrganizationPayload from '../../utils/payloads';
import ProductsList from '../../../../../CommonComponents/ProductsList';

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type OrganizationDetailsState = {
  systemName: string;
  path: string;
  adminNotes: string;
  country: string;
  language: string;
  timezone: string;
  countryCode: string;
  notificationsEnabled: boolean;
  notificationsChannels: Array<NotificationsChannel>;
  unitsPref: string;
  names: GetOrganizationQuery['getOrganization']['names'];
  enabledProducts: Array<Product>;
  products?: Array<ProductItemProps>;
  entitlements?: GetOrganizationQuery['getOrganization']['entitlements'];
};

type OrganizationDataProps = {
  org: GetOrganizationQuery['getOrganization'];
  onUpdateOrgData: () => void;
};

const OrganizationData: React.FC<OrganizationDataProps> = ({ org, onUpdateOrgData }) => {
  const navigate = useNavigate();
  const [currentOrg, setCurrentOrg] = useState<OrganizationDetailsState>({
    systemName: 'SystemName',
    path: 'Path',
    adminNotes: '',
    country: '',
    language: 'en',
    timezone: '',
    countryCode: 'US',
    notificationsEnabled: true,
    notificationsChannels: [NotificationsChannel.Email],
    unitsPref: 'ImperialSystem',
    names: null,
    enabledProducts: [],
  });
  const [displayError, setDisplayError] = useState('');
  const [disableSave, setDisableSave] = useState(true);
  const [updateAnyOrganizationMutation] = useUpdateAnyOrganizationMutation();
  const [deleteOrgMutation] = useDeleteOrgMutation();

  const { data, loading, error } = useGetEntitlementsStructureQuery({ fetchPolicy: 'no-cache' });

  // TODO: Need clean up
  useEffect(() => {
    if (data && currentOrg.entitlements) {
      const newData = createProductItems(data.entitlementsStructure, currentOrg.entitlements);
      setCurrentOrg((o) => ({ ...o, products: newData }));
    }
    // }, [currentOrg?.entitlements]);
  }, [data, currentOrg?.entitlements]);

  useEffect(() => {
    if (org) {
      const newOrg = convertOrgRes(org);
      setCurrentOrg(newOrg);
    }
  }, [org]);
  // }, []);

  const handleDisableSave = () => {
    if (disableSave) {
      setDisableSave(false);
    }
  };

  const onDropdownChange = (e: { name: string; value: string }) => {
    const { name, value } = e;
    setCurrentOrg({ ...currentOrg, [name]: value });
    handleDisableSave();
  };

  const handleNamesChanges = (name: string, value: string, index: number) => {
    const newNames = [...currentOrg.names];
    const currentName = newNames[index];
    const oldKey = Object.keys(currentName)[0];
    if (name === 'nameKey') {
      newNames[index] = { [value]: currentName[oldKey] };
    } else {
      newNames[index] = { [oldKey]: value };
    }
    setCurrentOrg({ ...currentOrg, names: newNames });
    handleDisableSave();
  };

  const onInputNameChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
  ) => {
    const {
      target: { name, value },
    } = e;
    handleNamesChanges(name, value, index);
    handleDisableSave();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setCurrentOrg({ ...currentOrg, [name]: value });
    handleDisableSave();
  };

  const onDropdownNameChange = (e: { name: string; value: string }, index: number) => {
    const { name, value } = e;
    handleNamesChanges(name, value, index);
    handleDisableSave();
  };

  const onInputNameDelete = (index: number) => {
    const newNames = [...currentOrg.names];
    setCurrentOrg({
      ...currentOrg,
      names: [...newNames.slice(0, index), ...newNames.slice(index + 1)],
    });
    handleDisableSave();
  };

  const addDisplayName = () => {
    const newNames = [...currentOrg.names, { [LanguagePreferences.En.toString()]: '' }];
    setCurrentOrg({ ...currentOrg, names: newNames });
  };

  const onMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { name, value },
    } = event;

    setCurrentOrg({ ...currentOrg, [name]: typeof value === 'string' ? value.split(',') : value });
    handleDisableSave();
  };

  const onUpdate = () => {
    const { payload, error } = updateOrganizationPayload(org, currentOrg);
    if (error) {
      setDisplayError(error);
      return;
    }
    updateAnyOrganizationMutation({ variables: { org: payload } })
      .then(() => {
        onUpdateOrgData();
      })
      .catch((e) => {
        console.log('error', e.graphQLErrors[0].message);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError(convertedError);
      });
    console.log('newData', payload);
  };

  const onDelete = () => {
    deleteOrgMutation({ variables: { id: org.id } })
      .then(() => {
        navigate('/orgs');
      })
      .catch((e) => {
        console.log('error', e.graphQLErrors[0].message);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError(convertedError);
      });
  };

  const onProductsChange = (value: Array<ProductItemProps>) => {
    setCurrentOrg({ ...currentOrg, products: value });
    handleDisableSave();
  };

  const displayNamePair = (pairName: { [key: string]: string }, index: number) => {
    const key = Object.keys(pairName)[0];
    const value = pairName[key];
    return (
      <Box key={`${key}-${index}`} sx={{ width: '100%', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={5}>
            <Dropdown
              fullWidth
              selectedValue={key}
              onChange={(e) => onDropdownNameChange(e, index)}
              label="Language"
              name="nameKey"
              list={languages}
              id={`Language-${index}`}
              data-test-id={`LanguageSelect-${index}`}
            />
          </Grid>
          <Grid item xs={5}>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                label="Localized Name"
                name="nameValue"
                fullWidth
                value={value}
                onChange={(e) => onInputNameChange(e, index)}
                InputLabelProps={{
                  style: { color: value ? null : '#D0D0D0' },
                }}
                id={`LocalizedName-${index}`}
                inputProps={{
                  'data-test-id': `LocalizedName-Input-${index}`,
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl sx={{ m: 1 }}>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => onInputNameDelete(index)}
                data-test-id={`DeleteDisplayName-${index}`}
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    );
  };

  const displayNames = () => {
    if (!currentOrg.names) {
      return null;
    }
    return currentOrg.names.map((pairName: { [key: string]: string }, index: number) => {
      return displayNamePair(pairName, index);
    });
  };

  if (process.env.REACT_APP_ENV !== 'local') {
    if (loading) {
      return <div>Loading..</div>;
    }

    if (error) {
      return <div>Error</div>;
    }
  }

  return (
    <div>
      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <TextField
              id="systemName"
              label="System Name"
              name="systemName"
              disabled
              fullWidth
              value={currentOrg.systemName}
              inputProps={{
                'data-test-id': 'OrgSystemName-Input',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="path"
              label="Path"
              name="path"
              disabled
              fullWidth
              value={currentOrg.path}
              inputProps={{
                'data-test-id': 'OrgPath-Input',
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="profile"
              label="Profile"
              name="profile"
              disabled
              fullWidth
              value={currentOrg.enabledProducts?.join(',')}
              inputProps={{
                'data-test-id': 'OrgProfile-Input',
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <TextField
              label="Admin Notes"
              // placeholder="Admin notes"
              value={currentOrg.adminNotes}
              id="adminNotes"
              name="adminNotes"
              onChange={(e) => onInputChange(e)}
              multiline
              rows={4}
              maxRows={20}
              fullWidth
              InputLabelProps={{
                style: { color: currentOrg.adminNotes ? null : '#D0D0D0' },
              }}
              inputProps={{
                'data-test-id': 'OrgAdminNotes-Input',
              }}
              sx={{ ml: 1.25 }}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography
        sx={{ marginLeft: '10px', marginTop: '30px' }}
        variant="subtitle1"
        gutterBottom
        component="div"
        data-test-id="DefaultPreferences-SectionTitle"
      >
        Organization Default Preferences
      </Typography>
      <Box sx={{ width: '100%', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Dropdown
              data-test-id="OrgCountry-Select"
              fullWidth
              selectedValue={currentOrg.country}
              onChange={onDropdownChange}
              id="country"
              name="country"
              label="Country"
              list={countries}
            />
            <Dropdown
              data-test-id="OrgCountryCode-Select"
              fullWidth
              selectedValue={currentOrg.countryCode}
              onChange={onDropdownChange}
              id="countryCode"
              name="countryCode"
              label="Country code"
              list={countriesCode}
            />
          </Grid>
          <Grid item xs={6}>
            <Dropdown
              data-test-id="OrgTimezone-Select"
              fullWidth
              selectedValue={currentOrg.timezone}
              onChange={onDropdownChange}
              id="timezone"
              name="timezone"
              label="Timezone"
              list={timezones}
            />
            <Dropdown
              data-test-id="OrgLanguage-Select"
              fullWidth
              selectedValue={currentOrg.language}
              onChange={onDropdownChange}
              id="language"
              name="language"
              label="Language"
              list={languages}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Dropdown
              data-test-id="OrgUnitsPref-Select"
              fullWidth
              selectedValue={currentOrg.unitsPref}
              onChange={onDropdownChange}
              id="unitsPref"
              name="unitsPref"
              label="Units"
              list={unitsPref}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            {/* TODO: Change to checkbox instead of dropdown */}
            <Dropdown
              data-test-id="OrgNotificationsEnabled"
              fullWidth
              selectedValue={currentOrg.notificationsEnabled ? 'true' : 'false'}
              onChange={(e) => {
                const { name, value } = e;
                setCurrentOrg({ ...currentOrg, [name]: value === 'true' });
              }}
              id="notificationsEnabled"
              name="notificationsEnabled"
              label="READY notifications enabled"
              list={notificationsEnabled}
            />
          </Grid>
          <Grid item xs={6}>
            <MultipleSelect
              data-test-id="OrgNotificationsChannels-Select"
              name="notificationsChannels"
              label="Notifications channels"
              value={currentOrg.notificationsChannels}
              fullWidth
              options={notificationsChannels}
              onChange={(e) => {
                onMultiSelectChange(e);
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {currentOrg.names?.length ? (
        <>
          <Typography
            sx={{ marginLeft: '10px', marginTop: '20px' }}
            variant="subtitle1"
            gutterBottom
            component="div"
            data-test-id="DisplayNames-SectionTitle"
          >
            Display Names
          </Typography>
          {displayNames()}
          <Stack marginBottom="20px" direction="row" justifyContent="flex-end">
            <Button size="small" onClick={addDisplayName} data-test-id="ShowDisplayName-Btn">
              + Display Name
            </Button>
          </Stack>
        </>
      ) : (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            minWidth: '100%',
            minHeight: '80px',
            backgroundColor: '#F0F0F0',
            cursor: 'pointer',
            border: '1px solid gray',
            borderRadius: '3px',
            marginLeft: '10px',
          }}
          onClick={addDisplayName}
        >
          <Grid item xs={3} onClick={addDisplayName}>
            <Typography>+ Display Name</Typography>
          </Grid>
        </Grid>
      )}

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ marginLeft: '10px', marginTop: '30px' }}
            variant="subtitle1"
            gutterBottom
            component="div"
            data-test-id="Subscriptions-SectionTitle"
          >
            Subscriptions
          </Typography>
          <ProductsList value={currentOrg.products} onChange={onProductsChange} />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="space-between" marginTop="20px">
        <DeleteConfirmationPopover onConfirm={onDelete} />
        <Button
          disabled={disableSave}
          variant="contained"
          onClick={onUpdate}
          data-test-id="OrgUpdate-Btn"
        >
          Update
        </Button>
      </Box>
      {displayError ? (
        <InputLabel sx={{ marginTop: '10px', color: 'red' }}>{displayError}</InputLabel>
      ) : null}
    </div>
  );
};

OrganizationData.displayName = 'OrganizationData';
export default OrganizationData;
