import {
  EntitlementsEditInput,
  GetEntitlementsStructureQuery,
  GetOrganizationQuery,
  GetUserQuery,
  NotificationsChannel,
  Product,
} from '../../../../../__generated__/graphql';

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type OrganizationDetailsState = {
  systemName: string;
  path: string;
  adminNotes: string;
  country: string;
  language: string;
  timezone: string;
  countryCode: string;
  notificationsEnabled: boolean;
  notificationsChannels: Array<NotificationsChannel>;
  unitsPref: string;
  names: Array<{ [key: string]: string }>;
  enabledProducts: Array<Product>;
  products?: Array<ProductItemProps>;
  entitlements: GetOrganizationQuery['getOrganization']['entitlements'];
};

const convertOrgRes = (org: GetOrganizationQuery['getOrganization']): OrganizationDetailsState => {
  const newNames = Object.keys(org.names).map((k) => {
    return { [k]: org.names[k] };
  });
  const entitlements: {
    enabledProducts: Array<Product>;
  } = {
    enabledProducts: [],
  };
  if (org.entitlements) {
    entitlements.enabledProducts = org.entitlements?.enabledProducts;
  }

  return {
    systemName: org.name,
    path: org.id,
    adminNotes: org.adminNotes,
    country: org.preferences?.defaultCountry,
    language: org.preferences?.defaultLanguage,
    timezone: org.preferences?.defaultTimezone,
    countryCode: org.preferences?.defaultCountryCode,
    notificationsEnabled: org.preferences?.defaultNotificationsEnabled,
    notificationsChannels: org.preferences?.defaultNotificationsChannels,
    unitsPref: org.preferences?.defaultUnits,
    names: newNames,
    enabledProducts: entitlements.enabledProducts,
    entitlements: org.entitlements,
  };
};

const convertEntitlementsRes = (
  entitlements: GetEntitlementsStructureQuery['entitlementsStructure'],
): ProductItemProps => {
  const productOptions = entitlements.products.map((p) => ({ key: p, value: p.toString() }));
  const entitlementsItem: ProductItemProps = {};
  entitlementsItem.product = {
    options: productOptions,
    selected: null,
  };
  const subscriptionDomino: ProductItemProps['subscription']['options']['DOMINO'] = [];
  const subscriptionOptionsDomino: ProductItemProps['subscriptionOptions']['options']['DOMINO'] =
    [];
  const subscriptionReady: ProductItemProps['subscription']['options']['READY'] = [];
  const subscriptionOptionsReady: ProductItemProps['subscriptionOptions']['options']['READY'] = [];
  entitlements.productHasModules.forEach((m) => {
    if (m.isMain) {
      switch (m.product) {
        case Product.Domino:
          subscriptionDomino.push({ key: m.module, value: m.names.en, desc: m.description });
          break;
        case Product.Ready:
          subscriptionReady.push({ key: m.module, value: m.names.en, desc: m.description });
          break;
        default:
          break;
      }
    } else {
      switch (m.product) {
        case Product.Domino:
          subscriptionOptionsDomino.push({
            key: m.module,
            label: m.names.en,
            value: false,
            desc: m.description,
            disabled: false,
          });
          break;
        case Product.Ready:
          subscriptionOptionsReady.push({
            key: m.module,
            label: m.names.en,
            value: false,
            desc: m.description,
            disabled: false,
          });
          break;
        default:
          break;
      }
    }
  });
  entitlementsItem.subscription = {
    options: {
      READY: subscriptionReady,
      DOMINO: subscriptionDomino,
    },
    selected: null,
  };
  entitlementsItem.subscriptionOptions = {
    options: {
      READY: subscriptionOptionsReady,
      DOMINO: subscriptionOptionsDomino,
    },
  };
  const regionsReady = entitlements.productHasRegions
    .filter((r) => r.product === Product.Ready)
    .map((r) => ({ key: r.region, value: r.names.en }));
  entitlementsItem.region = {
    options: {
      READY: regionsReady,
    },
    selected: [],
  };

  const featureDomino: ProductItemProps['feature']['options']['DOMINO'] = { allHazards: [] };
  const featureReady: ProductItemProps['feature']['options']['READY'] = { allHazards: [] };
  entitlements.productHasFeatures.forEach((f) => {
    switch (f.product) {
      case Product.Domino:
        if (f.hazard) {
          if (featureDomino[f.hazard]) {
            featureDomino[f.hazard] = [
              ...featureDomino[f.hazard],
              {
                key: f.feature,
                label: f.names.en,
                desc: f.description,
                value: false,
                disabled: false,
              },
            ];
          } else {
            featureDomino[f.hazard] = [
              {
                key: f.feature,
                label: f.names.en,
                desc: f.description,
                value: false,
                disabled: false,
              },
            ];
          }
        } else {
          featureDomino.allHazards = [
            ...featureDomino.allHazards,
            {
              key: f.feature,
              label: f.names.en,
              desc: f.description,
              value: false,
              disabled: false,
            },
          ];
        }
        break;
      case Product.Ready:
        if (f.hazard) {
          if (featureReady[f.hazard]) {
            featureReady[f.hazard] = [
              ...featureReady[f.hazard],
              {
                key: f.feature,
                label: f.names.en,
                desc: f.description,
                value: false,
                disabled: false,
              },
            ];
          } else {
            featureReady[f.hazard] = [
              {
                key: f.feature,
                label: f.names.en,
                desc: f.description,
                value: false,
                disabled: false,
              },
            ];
          }
        } else {
          featureReady.allHazards = [
            ...featureReady.allHazards,
            {
              key: f.feature,
              label: f.names.en,
              desc: f.description,
              value: false,
              disabled: false,
            },
          ];
        }
        break;
      default:
        break;
    }
  });

  entitlementsItem.feature = {
    options: {
      DOMINO: featureDomino,
      READY: featureReady,
    },
  };

  const hazardsReady: ProductItemProps['hazard']['options']['READY'] = [];
  const hazardsDomino: ProductItemProps['hazard']['options']['DOMINO'] = [];
  entitlements.productHasHazards.forEach((h) => {
    switch (h.product) {
      case Product.Ready:
        hazardsReady.push({ key: h.hazard, label: h.hazard, value: false, disabled: false });
        break;
      case Product.Domino:
        hazardsDomino.push({ key: h.hazard, label: h.hazard, value: false, disabled: false });
        break;
      default:
        break;
    }
  });

  entitlementsItem.hazard = {
    options: {
      READY: hazardsReady,
      DOMINO: hazardsDomino,
    },
  };

  return entitlementsItem;
};

const entitlementsHazardDefaults = (
  p: string,
  h: Array<string>,
  e: ProductItemProps,
): ProductItemProps => {
  switch (p) {
    case 'READY':
      Object.keys(e.feature.options.READY).forEach((f) => {
        if (f === 'allHazards') {
          // e.feature.options.READY[f] = e.feature.options.READY[f].map((f) => ({
          //   ...f,
          //   value: false,
          //   disabled: false,
          // }));
        } else if (h.find((hazard) => hazard === f)) {
          e.feature.options.READY[f] = e.feature.options.READY[f].map((f) => ({
            ...f,
            value: true,
            disabled: false,
          }));
        } else {
          e.feature.options.READY[f] = e.feature.options.READY[f].map((f) => ({
            ...f,
            value: false,
            disabled: true,
          }));
        }
      });
      break;
    case 'DOMINO':
      Object.keys(e.feature.options.DOMINO).forEach((f) => {
        if (f === 'allHazards') {
          // e.feature.options.DOMINO[f] = e.feature.options.DOMINO[f].map((f) => ({
          //   ...f,
          //   value: false,
          //   disabled: false,
          // }));
        } else if (h.find((hazard) => hazard === f)) {
          e.feature.options.DOMINO[f] = e.feature.options.DOMINO[f].map((f) => ({
            ...f,
            value: true,
            disabled: false,
          }));
        } else {
          e.feature.options.DOMINO[f] = e.feature.options.DOMINO[f].map((f) => ({
            ...f,
            value: false,
            disabled: true,
          }));
        }
      });
      break;
    default:
      break;
  }
  return e;
};

const entitlementsHazardChanged = (
  p: string,
  h: { key: string; value: boolean },
  e: ProductItemProps,
): ProductItemProps => {
  switch (p) {
    case 'READY':
      Object.keys(e.feature.options.READY).forEach((f) => {
        if (h.key === f) {
          if (h.value) {
            e.feature.options.READY[f] = e.feature.options.READY[f].map((f) => ({
              ...f,
              value: true,
              disabled: false,
            }));
          } else {
            e.feature.options.READY[f] = e.feature.options.READY[f].map((f) => ({
              ...f,
              value: false,
              disabled: true,
            }));
          }
        }
      });
      break;
    case 'DOMINO':
      Object.keys(e.feature.options.DOMINO).forEach((f) => {
        if (h.key === f) {
          if (h.value) {
            e.feature.options.DOMINO[f] = e.feature.options.DOMINO[f].map((f) => ({
              ...f,
              value: true,
              disabled: false,
            }));
          } else {
            e.feature.options.DOMINO[f] = e.feature.options.DOMINO[f].map((f) => ({
              ...f,
              value: false,
              disabled: true,
            }));
          }
        }
      });
      break;
    default:
      break;
  }
  return e;
};

const entitlementsSubscriptionDefaults = (s: string, e: ProductItemProps): ProductItemProps => {
  let newEntitlements: ProductItemProps = null;
  switch (s) {
    case 'live hazard':
      e.hazard.options.READY = e.hazard.options.READY.map((h) => {
        if (h.key === 'flood') {
          return { ...h, value: true };
        }
        return { ...h, value: false };
      });
      newEntitlements = entitlementsHazardDefaults('READY', ['flood'], e);
      break;
    case 'us':
      e.hazard.options.DOMINO = e.hazard.options.DOMINO.map((h) => ({ ...h, value: true }));
      newEntitlements = entitlementsHazardDefaults('DOMINO', ['flood', 'seismic', 'wind'], e);
      break;
    case 'jp':
      e.hazard.options.DOMINO = e.hazard.options.DOMINO.map((h) =>
        h.key === 'flood' ? { ...h, value: true } : { ...h, value: false },
      );
      newEntitlements = entitlementsHazardDefaults('DOMINO', ['flood'], e);
      break;
    default:
      break;
  }
  return newEntitlements;
};

const entitlementsProductDefaults = (p: string, e: ProductItemProps): ProductItemProps => {
  // let newEntitlements: ProductItemProps = null;
  switch (p) {
    case 'READY':
      e.product.selected = e.product.options.find((p) => p.key === Product.Ready);
      // e.subscription.selected = e.subscription.options.READY.find((s) => s.key === 'live hazard');
      e.subscription.selected = null;
      e.subscriptionOptions.options.READY = e.subscriptionOptions.options.READY.map((s) => ({
        ...s,
        value: false,
      }));
      // newEntitlements = entitlementsSubscriptionDefaults('live hazard', e);
      break;
    case 'DOMINO':
      e.product.selected = e.product.options.find((p) => p.key === Product.Domino);
      e.subscription.selected = null;
      // e.subscription.selected = e.subscription.options.DOMINO.find((s) => s.key === 'us');
      e.subscriptionOptions.options.DOMINO = e.subscriptionOptions.options.DOMINO.map((s) => ({
        ...s,
        value: false,
      }));
      // newEntitlements = entitlementsSubscriptionDefaults('us', e);
      break;
    default:
      break;
  }
  return e;
};

const entitlementsProductModule = (
  p: 'DOMINO' | 'READY',
  m: string,
  subOpts: Array<{ sub: string; isInherited: boolean }>,
  allHaz: Array<{ haz: string; isInherited: boolean }>,
  e: ProductItemProps,
  initData:
    | GetOrganizationQuery['getOrganization']['entitlements']
    | GetUserQuery['getUser']['entitlements'],
  opts?: { inviteUser?: boolean; editUser?: boolean },
): ProductItemProps => {
  // let newEntitlements: ProductItemProps = null;
  let product: Product;
  switch (p) {
    case 'READY':
      product = Product.Ready;
      break;
    case 'DOMINO':
      product = Product.Domino;
      break;
    default:
      break;
  }
  switch (p) {
    case 'READY':
      e.product.selected = e.product.options.find((p) => p.key === Product.Ready);
      // e.subscription.selected = e.subscription.options.READY.find((s) => s.key === 'live hazard');
      e.subscription.selected = e.subscription.options[p].find((mm) => mm.key === m);
      e.subscriptionOptions.options.READY = e.subscriptionOptions.options.READY.map((s) => {
        const exists = subOpts.find((ss) => ss.sub === s.key);
        if (exists) {
          return {
            ...s,
            value: true,
            disabled: opts && (opts.inviteUser || (opts.editUser && exists.isInherited)),
          };
        }
        return { ...s, value: false };
      });
      e.region.selected = e.region.options.READY.filter((r) => {
        const exists = initData.enabledProductsModule.find((mm) => mm.region === r.key);
        return !!exists;
      });
      // newEntitlements = entitlementsSubscriptionDefaults('live hazard', e);
      break;
    case 'DOMINO':
      e.product.selected = e.product.options.find((p) => p.key === Product.Domino);
      e.subscription.selected = e.subscription.options[p].find((mm) => mm.key === m);
      // e.subscription.selected = e.subscription.options.DOMINO.find((s) => s.key === 'us');
      e.subscriptionOptions.options.DOMINO = e.subscriptionOptions.options.DOMINO.map((s) => {
        const exists = subOpts.find((ss) => ss.sub === s.key);
        if (exists) {
          return {
            ...s,
            value: true,
            disabled: opts && (opts.inviteUser || (opts.editUser && exists.isInherited)),
          };
        }
        return { ...s, value: false };
      });
      // newEntitlements = entitlementsSubscriptionDefaults('us', e);
      break;
    default:
      break;
  }
  e.hazard.options[p] = e.hazard.options[p].map((h) => {
    const exists = initData.hasHazardsForProduct.find(
      (haz) => haz.product === product && haz.module === m && haz.hazard === h.key,
    );
    if (exists) {
      return {
        ...h,
        value: true,
        disabled: opts && (opts.inviteUser || (opts.editUser && exists.isInherited)),
      };
    }
    return { ...h, value: false };
  });
  initData.hasFeaturesForProduct.forEach((f) => {
    if (f.product === product && f.module === m && f.hazard) {
      e.feature.options[p][f.hazard] = e.feature.options[p][f.hazard].map((haz) => {
        if (haz.key === f.feature) {
          return {
            ...haz,
            value: true,
            disabled: opts && (opts.inviteUser || (opts.editUser && f.isInherited)),
          };
        }
        return haz;
      });
    }
  });

  Object.keys(e.feature.options[p]).forEach((k) => {
    if (k !== 'allHazards') {
      const haz = e.hazard.options[p].find((h) => h.key === k);
      if (!haz.value) {
        e.feature.options[p][haz.key] = e.feature.options[p][haz.key].map((haz) => ({
          ...haz,
          disabled: true,
        }));
      }
    }
  });

  e.feature.options[p].allHazards = e.feature.options[p].allHazards.map((haz) => {
    const exists = allHaz.find((h) => h.haz === haz.key);
    if (exists) {
      return {
        ...haz,
        value: true,
        disabled: opts && (opts.inviteUser || (opts.editUser && exists.isInherited)),
      };
    }
    return { ...haz, value: false };
  });

  return e;
};

type CreateOrgPayload = {
  profiles: Array<{ product: Product; zones: string[] }>;
  ents: EntitlementsEditInput;
};

const createOrgEntsPayload = (data: Array<ProductItemProps>): CreateOrgPayload => {
  const profiles = [];
  const ents: EntitlementsEditInput = {
    modules: [],
    productHazards: [],
    productFeatures: [],
    productHazardFeatures: [],
  };
  for (let i = 0; i < data.length; i += 1) {
    const p = data[i];
    // product is mandatory
    if (!p.product.selected?.key) {
      continue;
    }
    // subscription is mandatory
    if (!p.subscription.selected?.key) {
      continue;
    }
    let product: Product;
    let regions: string[];
    let mainModules;
    let modules;
    let hazards: string[];
    let features: string[];
    switch (p.product.selected.key) {
      case Product.Ready.toString():
        product = Product.Ready;
        regions = p.region.selected.map((k) => k.key);
        // regions is mandatory
        if (!regions.length) {
          continue;
        }
        mainModules = [p.subscription.selected.key];
        modules = [...p.subscriptionOptions.options.READY.filter((s) => s.value).map((s) => s.key)];
        hazards = p.hazard.options.READY.filter((h) => h.value).map((h) => h.key);
        features = p.feature.options.READY.allHazards.filter((f) => f.value).map((f) => f.key);
        break;
      case Product.Domino.toString():
        product = Product.Domino;
        regions = [p.subscription.selected.key];
        mainModules = [p.subscription.selected.key];
        modules = [
          ...p.subscriptionOptions.options.DOMINO.filter((s) => s.value).map((s) => s.key),
        ];
        hazards = p.hazard.options.DOMINO.filter((h) => h.value).map((h) => h.key);
        features = p.feature.options.DOMINO.allHazards.filter((f) => f.value).map((f) => f.key);
        break;
      default:
    }

    profiles.push({ product, zones: regions });
    modules.forEach((m) => {
      switch (product) {
        case Product.Ready:
          regions.forEach((r) => {
            ents.modules.push({ product, module: m, region: r });
          });
          break;
        case Product.Domino:
          ents.modules.push({ product, module: m });
          break;
        default:
      }
    });
    mainModules.forEach((m) => {
      switch (product) {
        case Product.Ready:
          regions.forEach((r) => {
            ents.modules.push({ product, module: m, region: r });
            features.forEach((f) => {
              ents.productFeatures.push({
                product,
                module: m,
                region: r,
                feature: f,
                enabled: true,
              });
            });
            hazards.forEach((h) => {
              ents.productHazards.push({ product, module: m, hazard: h, region: r });
              p.feature.options.READY[h]
                .filter((f) => f.value)
                .forEach((f) => {
                  ents.productHazardFeatures.push({
                    product,
                    module: m,
                    hazard: h,
                    region: r,
                    feature: f.key,
                    enabled: true,
                  });
                });
            });
          });
          break;
        case Product.Domino:
          ents.modules.push({ product, module: m });
          features.forEach((f) => {
            ents.productFeatures.push({ product, module: m, feature: f, enabled: true });
          });
          hazards.forEach((h) => {
            ents.productHazards.push({ product, module: m, hazard: h });
            p.feature.options.DOMINO[h]
              .filter((f) => f.value)
              .forEach((f) => {
                ents.productHazardFeatures.push({
                  product,
                  module: m,
                  hazard: h,
                  feature: f.key,
                  enabled: true,
                });
              });
          });
          break;
        default:
      }
    });
  }
  return { profiles, ents };
};

const createProductItems = (
  entitlementsStructure: GetEntitlementsStructureQuery['entitlementsStructure'],
  entitlements:
    | GetOrganizationQuery['getOrganization']['entitlements']
    | GetUserQuery['getUser']['entitlements'],
  opts?: { inviteUser?: boolean; editUser?: boolean },
): Array<ProductItemProps> => {
  const ent = convertEntitlementsRes(entitlementsStructure);
  console.log('ent', ent);
  const availableSubs: { [key: string]: { product: 'DOMINO' | 'READY'; module: string } } = {};
  const availableSubOpts: {
    [key: string]: { [key: string]: { sub: string; isInherited: boolean } };
  } = {};
  const availableAllHazard: { [key: string]: Array<{ haz: string; isInherited: boolean }> } = {};
  entitlements.hasFeaturesForProduct.forEach((f) => {
    let allHaz;
    switch (f.product.toString()) {
      case 'DOMINO':
        allHaz = ent.feature.options.DOMINO.allHazards;
        if (allHaz.find((o) => o.key === f.feature)) {
          if (!f.hazard) {
            if (availableAllHazard.DOMINO) {
              availableAllHazard.DOMINO = [
                ...availableAllHazard.DOMINO,
                { haz: f.feature, isInherited: f.isInherited },
              ];
            } else {
              availableAllHazard.DOMINO = [{ haz: f.feature, isInherited: f.isInherited }];
            }
          }
        }
        break;
      case 'READY':
        allHaz = ent.feature.options.READY.allHazards;
        if (allHaz.find((o) => o.key === f.feature)) {
          if (!f.hazard) {
            if (availableAllHazard.READY) {
              availableAllHazard.READY = [
                ...availableAllHazard.READY,
                { haz: f.feature, isInherited: f.isInherited },
              ];
            } else {
              availableAllHazard.READY = [{ haz: f.feature, isInherited: f.isInherited }];
            }
          }
        }
        break;
      default:
        break;
    }
  });
  entitlements.enabledProductsModule.forEach((m) => {
    let entSubscriptions;
    let entSubOpts;
    switch (m.product.toString()) {
      case 'DOMINO':
        entSubscriptions = ent.subscription.options.DOMINO;
        entSubOpts = ent.subscriptionOptions.options.DOMINO;
        if (entSubscriptions.find((mm) => mm.key === m.module)) {
          availableSubs[`${m.product.toString()},${m.module}`] = {
            product: 'DOMINO',
            module: m.module,
          };
        }
        if (entSubOpts.find((o) => o.key === m.module)) {
          if (availableSubOpts.DOMINO) {
            availableSubOpts.DOMINO[`${m.product.toString()},${m.module}`] = {
              sub: m.module,
              isInherited: m.isInherited,
            };
          } else {
            availableSubOpts.DOMINO = {
              [`${m.product.toString()},${m.module}`]: {
                sub: m.module,
                isInherited: m.isInherited,
              },
            };
          }
        }
        break;
      case 'READY':
        entSubscriptions = ent.subscription.options.READY;
        entSubOpts = ent.subscriptionOptions.options.READY;
        if (entSubscriptions.find((mm) => mm.key === m.module)) {
          availableSubs[`${m.product.toString()},${m.module}`] = {
            product: 'READY',
            module: m.module,
          };
        }
        if (entSubOpts.find((o) => o.key === m.module)) {
          if (availableSubOpts.READY) {
            availableSubOpts.READY[`${m.product.toString()},${m.module}`] = {
              sub: m.module,
              isInherited: m.isInherited,
            };
          } else {
            availableSubOpts.READY = {
              [`${m.product.toString()},${m.module}`]: {
                sub: m.module,
                isInherited: m.isInherited,
              },
            };
          }
        }
        break;
      default:
        break;
    }
  });
  const newData: Array<ProductItemProps> = [];
  Object.keys(availableSubs).forEach((k) => {
    const { product, module } = availableSubs[k];
    const et = convertEntitlementsRes(entitlementsStructure);
    const sOpts = availableSubOpts[product] || {};
    const subOpts = Object.keys(sOpts).map((k) => sOpts[k]);

    const allHaz = availableAllHazard[product] || [];
    newData.push(
      entitlementsProductModule(product, module, subOpts, allHaz, et, entitlements, opts),
    );
  });
  return newData;
};

export {
  convertOrgRes,
  convertEntitlementsRes,
  entitlementsProductDefaults,
  entitlementsSubscriptionDefaults,
  entitlementsHazardDefaults,
  entitlementsHazardChanged,
  createOrgEntsPayload,
  entitlementsProductModule,
  createProductItems,
};
