import * as React from 'react';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import InputLabel from '@mui/material/InputLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function getStyles(name: string, personName: readonly string[], theme: Theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

type MultipleSelectProps = {
  name: string;
  value: Array<string>;
  width?: number;
  options: Array<string>;
  onChange: (event: SelectChangeEvent<string[]>) => void;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
};

const MultipleSelect: React.FC<MultipleSelectProps> = ({
  name,
  value,
  options,
  onChange,
  width,
  label,
  disabled,
  fullWidth,
  // including data-test-id, etc.
  ...otherProps
}) => {
  // const theme = useTheme();

  return (
    <FormControl sx={{ m: 1, width, minWidth: fullWidth ? '100%' : '' }}>
      {label && (
        <InputLabel
          id="demo-simple-select-label"
          style={{ color: value.length ? null : '#D0D0D0' }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        name={name}
        labelId="demo-multiple-chip-label"
        multiple
        value={value}
        disabled={disabled}
        onChange={onChange}
        input={<OutlinedInput id="select-multiple-chip" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        {...otherProps}
      >
        {options.map((name) => (
          // <MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const defaultProps: MultipleSelectProps = {
  // eslint-disable-next-line
  options: [],
  // eslint-disable-next-line
  onChange: () => {},
  // eslint-disable-next-line
  value: [],

  label: '',
  // eslint-disable-next-line
  name: '',
  width: 350,

  disabled: false,
  fullWidth: false,
};

MultipleSelect.displayName = 'MultipleSelect';
MultipleSelect.defaultProps = defaultProps;
export default MultipleSelect;
