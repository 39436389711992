import React from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { App, AppConfigProps } from './CSAdmin/AppConfig';
import CSAdminContainer from './CSAdmin/CSAdminContainer';

const root = document.getElementById('root');

declare global {
  interface Window {
    oneconcern: AppConfigProps;
  }
}

App.getConfig().then((data: AppConfigProps) => {
  // Setting global namespace oneconcern for all ui configuration if required
  window.oneconcern = data;
  App.config = data;

  render(
    <HashRouter>
      <CSAdminContainer />
    </HashRouter>,
    root,
  );
});
