import {
  CountryCode,
  GetAllZonesDocument,
  GetAllZonesQuery,
  GetEntitlementsStructureQuery,
  GetOrganizationAllDominoFeaturesDocument,
  GetOrganizationAllDominoFeaturesQuery,
  GetOrganizationAllReadyFeaturesDocument,
  GetOrganizationAllReadyFeaturesQuery,
  GetOrganizationDocument,
  GetOrganizationDominoJpDocument,
  GetOrganizationDominoJpQuery,
  GetOrganizationDominoUsDocument,
  GetOrganizationDominoUsQuery,
  GetOrganizationQuery,
  GetOrganizationsDocument,
  GetOrganizationsQuery,
  LanguagePreferences,
  MeasurementPreferences,
  NotificationsChannel,
  Product,
  UserRole,
  UserStatus,
} from '../__generated__/graphql';

const organizations: GetOrganizationsQuery['getOrganizations'] = [
  {
    id: '/AZ DEMA',
    name: 'AZ DEMA',
    enName: 'AZ DEMA',
    preferences: {
      defaultUnits: null,
      defaultCountry: null,
      defaultCountryCode: null,
      defaultLanguage: null,
      defaultTimezone: 'PDT',
      allDefaultPreferences: {
        key: 'value',
        other: 'other',
      },
    },
    entitlements: {
      enabledProducts: [Product.Ready],
      enabledProductsModule: [],
    },
    names: {
      en: 'AZ DEMA',
    },
  },

  {
    id: '/Chiba',
    name: 'Chiba',
    enName: 'Chiba',
    preferences: null,
    names: {
      en: 'Chiba',
      ja: '千葉市',
    },
    entitlements: {
      enabledProducts: [Product.Domino],
      enabledProductsModule: [],
    },
  },
  {
    id: '/Kawasaki',
    name: 'Kawasaki',
    enName: 'Kawasaki',
    preferences: null,
    names: {
      en: 'Kawasaki',
      ja: '川崎市',
    },
    entitlements: {
      enabledProducts: [Product.Domino, Product.Ready],
      enabledProductsModule: [],
    },
  },
  {
    id: '/Koriyama',
    name: 'Koriyama',
    enName: 'Koriyama',
    preferences: null,
    names: {
      en: 'Koriyama',
      ja: '郡山市',
    },
  },
  {
    id: '/Kumamoto City',
    name: 'Kumamoto City',
    enName: 'Kumamoto City',
    preferences: null,
    names: {
      en: 'Kumamoto City',
      ja: '熊本市',
    },
  },
  {
    id: '/Nagano',
    name: 'Nagano',
    enName: 'Nagano',
    preferences: null,
    names: {
      en: 'Nagano',
      ja: '長野市',
    },
  },
  {
    id: '/Okayama',
    name: 'Okayama',
    enName: 'Okayama',
    preferences: null,
    names: {
      en: 'Okayama',
      ja: '岡山市',
    },
  },
  {
    id: '/Okazaki',
    name: 'Okazaki',
    enName: 'Okazaki',
    preferences: null,
    names: {
      en: 'Okazaki',
      ja: '岡崎市',
    },
  },
  {
    id: '/SOMPO',
    name: 'SOMPO',
    enName: 'SOMPO',
    preferences: null,
    names: {
      en: 'SOMPO',
      ja: '損害保険ジャパン',
    },
  },
  {
    id: '/domino-jp-users',
    name: 'domino-jp-users',
    enName: 'domino-jp-users',
    preferences: null,
    names: {
      en: 'domino-jp-users',
    },
  },
  {
    id: '/domino-users',
    name: 'domino-users',
    enName: 'domino-users',
    preferences: null,
    names: {
      en: 'domino users for DEMO',
    },
  },
  {
    id: '/e2e-test',
    name: 'e2e-test',
    enName: 'e2e-test',
    preferences: null,
    names: {
      en: 'end-to-end test',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/e2e-test-chiba',
    name: 'e2e-test-chiba',
    enName: 'e2e-test-chiba',
    preferences: null,
    names: {
      en: 'end-to-end test for Chiba',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/e2e-test-kawasaki',
    name: 'e2e-test-kawasaki',
    enName: 'e2e-test-kawasaki',
    preferences: null,
    names: {
      en: 'end-to-end test for Kawasaki',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/e2e-test-kc',
    name: 'e2e-test-kc',
    enName: 'e2e-test-kc',
    preferences: null,
    names: {
      en: 'end-to-end test for KC',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/e2e-test-koriyama',
    name: 'e2e-test-koriyama',
    enName: 'e2e-test-koriyama',
    preferences: null,
    names: {
      en: 'end-to-end test for Koriyama',
      ja: '郡山のエンドツーエンドテスト',
    },
  },
  {
    id: '/e2e-test-nagano',
    name: 'e2e-test-nagano',
    enName: 'e2e-test-nagano',
    preferences: null,
    names: {
      en: 'end-to-end test for Nagano',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/e2e-test-okayama',
    name: 'e2e-test-okayama',
    enName: 'e2e-test-okayama',
    preferences: null,
    names: {
      en: 'end-to-end test for Okayama',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/e2e-test-okazaki',
    name: 'e2e-test-okazaki',
    enName: 'e2e-test-okazaki',
    preferences: null,
    names: {
      en: 'end-to-end test for Okazaki',
      ja: 'エンドツーエンドのテスト',
    },
  },
  {
    id: '/fake_seismic_monitor',
    name: 'fake_seismic_monitor',
    enName: 'fake_seismic_monitor',
    preferences: null,
    names: {
      en: 'fake_seismic_monitor',
    },
  },
  {
    id: '/jake test group',
    name: 'jake test group',
    enName: 'jake test group',
    preferences: null,
    names: {
      en: 'jake test group',
    },
  },
  {
    id: '/oneconcern',
    name: 'oneconcern',
    enName: 'oneconcern',
    preferences: null,
    names: {
      en: 'oneconcern',
    },
  },
  {
    id: '/resilience-dev',
    name: 'resilience-dev',
    enName: 'resilience-dev',
    preferences: {
      defaultUnits: null,
      defaultCountry: null,
      defaultCountryCode: null,
      defaultLanguage: null,
      defaultTimezone: 'PDT',
      allDefaultPreferences: {
        key: 'value',
        other: 'other',
      },
    },
    names: {
      en: 'resilience-dev',
    },
  },
  {
    id: '/test',
    name: 'test',
    enName: 'test',
    preferences: {
      defaultUnits: MeasurementPreferences.MetricSystem,
      defaultCountry: 'France',
      defaultCountryCode: CountryCode.Us,
      defaultLanguage: null,
      defaultTimezone: 'Asia/Tokyo',
      allDefaultPreferences: {
        extra: 'new',
        units: 'MetricSystem',
      },
    },
    names: {
      de: 'das Organization',
      en: 'new test org',
      it: 'organizazzione test',
      ja: '熊本市',
    },
  },
];

const organizationsFixture = {
  request: {
    query: GetOrganizationsDocument,
  },
  result: {
    data: {
      getOrganizations: organizations,
    },
  },
};

const dominoZones: GetAllZonesQuery['getAllZones'] = [
  '100_Domino',
  '101_Domino',
  '102_Domino',
  '103_Domino',
  '104_Domino',
];
const readyZones: GetAllZonesQuery['getAllZones'] = [
  '100_ichino',
  '101_yokkai',
  '102_ibarak',
  '103_kishiw',
];

const getAllZonesDominoFixture = {
  request: {
    query: GetAllZonesDocument,
    variables: {
      product: Product.Domino,
    },
  },
  result: {
    data: {
      getAllZones: dominoZones,
    },
  },
};

const getAllZonesReadyFixture = {
  request: {
    query: GetAllZonesDocument,
    variables: {
      product: Product.Ready,
    },
  },
  result: {
    data: {
      getAllZones: readyZones,
    },
  },
};

const organization: GetOrganizationQuery['getOrganization'] = {
  id: '/test',
  name: 'test',
  enName: 'test',
  adminNotes: 'adminNotesText',
  preferences: {
    defaultUnits: MeasurementPreferences.MetricSystem,
    defaultCountry: 'US',
    defaultCountryCode: CountryCode.Us,
    defaultLanguage: LanguagePreferences.En,
    defaultTimezone: '',
    defaultNotificationsEnabled: true,
    defaultNotificationsChannels: [NotificationsChannel.Email],
    allDefaultPreferences: {
      extra: 'new',
      units: 'MetricSystem',
    },
  },
  names: {
    de: 'das Organization',
    en: 'new test org',
    it: 'organizazzione test',
    ja: '熊本市',
  },
  entitlements: {
    scopes: [Product.Domino, Product.Ready],
    enabledProducts: [Product.Domino, Product.Ready],
    enabledProductsModule: [
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        isMain: false,
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        isMain: false,
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        isMain: false,
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'e2e-test',
        isMain: false,
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'live hazard',
        isMain: false,
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'e2e-test',
        isMain: false,
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'live hazard',
        isMain: false,
        isInherited: false,
      },
    ],
    hasHazardsForProduct: [
      {
        product: Product.Ready,
        module: 'live hazard',
        region: '2_chiba',
        hazard: 'flood',
        isInherited: false,
      },
      {
        product: Product.Ready,
        module: 'live hazard',
        region: '3_fukuoka',
        hazard: 'flood',
        isInherited: false,
      },
    ],
    hasFeaturesForProduct: [
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'notifications',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'river_gauges',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'notifications',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'river_gauges',
        isInherited: false,
      },
    ],
  },
  users: [
    {
      id: '4ea525b7-0c9a-4c2e-9179-60a80b8ab49f',
      role: UserRole.Contributor,
      title: null,
      personalInfo: {
        firstName: 'Frédéric',
        lastName: 'BIDON [alternate email]',
        contact: {
          email: 'fredbi@yahoo.com',
          phoneNumber: null,
        },
      },
      status: UserStatus.InvitationPending,
      preferences: {
        country: 'United States',
        language: LanguagePreferences.En,
        timezone: 'Asia/Tokyo',
        units: MeasurementPreferences.MetricSystem,
        notificationsEnabled: true,
        notificationsChannels: [NotificationsChannel.Email],
      },
    },
    {
      id: 'cf6ec8e9-0de2-46be-b9a5-d99cb5ad13a2',
      role: UserRole.Admin,
      title: null,
      personalInfo: {
        firstName: 'Vickie',
        lastName: 'Chen',
        contact: {
          email: 'vchen+jp@oneconcern.com',
          phoneNumber: null,
        },
      },
      status: UserStatus.Disabled,
      preferences: {
        country: 'United States',
        language: LanguagePreferences.En,
        timezone: 'America/Los_Angeles',
        units: MeasurementPreferences.MetricSystem,
        notificationsEnabled: true,
        notificationsChannels: [NotificationsChannel.Email],
      },
    },
    {
      id: 'eb2f8538-a8bb-4112-a8d4-a5097eb6a8d4',
      role: UserRole.Contributor,
      title: 'builder2',
      personalInfo: {
        firstName: 'Frederic',
        lastName: 'test',
        contact: {
          email: 'fred@example.com',
          phoneNumber: null,
        },
      },
      status: UserStatus.Joined,
      preferences: {
        country: 'United States',
        language: LanguagePreferences.Ja,
        timezone: 'Asia/Tokyo',
        units: MeasurementPreferences.MetricSystem,
        notificationsEnabled: true,
        notificationsChannels: [NotificationsChannel.Email],
      },
    },
  ],
};

const organizationFixture = {
  request: {
    query: GetOrganizationDocument,
    variables: {
      id: 'test-org',
    },
  },
  result: {
    data: {
      getOrganization: organization,
    },
  },
};

const organizationDominoUS: GetOrganizationDominoUsQuery['getOrganization'] = {
  id: '/test',
  name: 'test',
  entitlements: {
    hasHazardsForProduct: [
      {
        product: Product.Domino,
        module: 'us',
        region: null,
        hazard: 'flood',
        isInherited: false,
      },
      {
        product: Product.Domino,
        module: 'us',
        region: null,
        hazard: 'seismic',
        isInherited: false,
      },
    ],
    hasFeaturesForProduct: [
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-power',
        isInherited: false,
      },
    ],
  },
};

const organizationDominoUSFixture = {
  request: {
    query: GetOrganizationDominoUsDocument,
    variables: {
      id: 'test-org',
    },
  },
  result: {
    data: {
      getOrganization: organizationDominoUS,
    },
  },
};

const organizationDominoJP: GetOrganizationDominoJpQuery['getOrganization'] = {
  id: '/test',
  name: 'test',
  entitlements: {
    hasHazardsForProduct: [
      {
        product: Product.Domino,
        module: 'jp',
        region: null,
        hazard: 'flood',
        isInherited: false,
      },
      {
        product: Product.Domino,
        module: 'jp',
        region: null,
        hazard: 'wind',
        isInherited: false,
      },
    ],
    hasFeaturesForProduct: [
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-highway',
        isInherited: false,
      },
    ],
  },
};

const organizationDominoJPFixture = {
  request: {
    query: GetOrganizationDominoJpDocument,
    variables: {
      id: 'test-org',
    },
  },
  result: {
    data: {
      getOrganization: organizationDominoJP,
    },
  },
};

const organizationAllDominoFeatures: GetOrganizationAllDominoFeaturesQuery['getOrganization'] = {
  id: '/test',
  name: 'test',
  entitlements: {
    hasFeaturesForProduct: [
      {
        product: Product.Domino,
        region: null,
        module: '',
        hazard: null,
        feature: 'preview1',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: '',
        hazard: null,
        feature: 'preview2',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        hazard: null,
        feature: 'domino-viewer',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'flood',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-highway',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-people',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'wind',
        feature: 'lifeline-bridge',
        isInherited: false,
      },
    ],
  },
};

const organizationAllDominoFeaturesFixture = {
  request: {
    query: GetOrganizationAllDominoFeaturesDocument,
    variables: {
      id: 'test-org',
    },
  },
  result: {
    data: {
      getOrganization: organizationAllDominoFeatures,
    },
  },
};

const organizationAllReadyFeatures: GetOrganizationAllReadyFeaturesQuery['getOrganization'] = {
  id: '/test',
  name: 'test',
  entitlements: {
    hasFeaturesForProduct: [
      {
        product: Product.Ready,
        region: null,
        module: '',
        hazard: null,
        feature: 'preview1',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'notifications',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'river_gauges',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'e2e-test',
        hazard: 'flood',
        feature: 'notifications',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '2_chiba',
        module: 'e2e-test',
        hazard: 'flood',
        feature: 'river_gauges',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'river_gauges',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'e2e-test',
        hazard: 'flood',
        feature: 'notifications',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'e2e-test',
        hazard: 'flood',
        feature: 'river_gauges',
        isInherited: false,
      },
      {
        product: Product.Ready,
        region: '3_fukuoka',
        module: 'live hazard',
        hazard: 'flood',
        feature: 'notifications',
        isInherited: false,
      },
    ],
  },
};

const organizationAllReadyFeaturesFixture = {
  request: {
    query: GetOrganizationAllReadyFeaturesDocument,
    variables: {
      id: 'test-org',
    },
  },
  result: {
    data: {
      getOrganization: organizationAllReadyFeatures,
    },
  },
};

const entitlementsStructure: GetEntitlementsStructureQuery['entitlementsStructure'] = {
  products: [Product.Ready, Product.Domino],
  productHasRegions: [
    {
      product: Product.Ready,
      region: '1_tokyo',
      names: {
        en: 'Tokyo',
        ja: '東京都区部',
      },
    },
    {
      product: Product.Ready,
      region: '2_chiba',
      names: {
        en: 'Chiba',
        ja: '千葉市',
      },
    },
    {
      product: Product.Ready,
      region: '3_fukuoka',
      names: {
        en: 'Fukuoka',
        ja: '福岡市',
      },
    },
    {
      product: Product.Ready,
      region: '4_hamamats',
      names: {
        en: 'Hamamatsu',
        ja: '浜松市',
      },
    },
    {
      product: Product.Ready,
      region: '5_hiroshim',
      names: {
        en: 'Hiroshima',
        ja: '広島市',
      },
    },
    {
      product: Product.Ready,
      region: '6_kawasaki',
      names: {
        en: 'Kawasaki',
        ja: '川崎市',
      },
    },
    {
      product: Product.Ready,
      region: '7_kitakyus',
      names: {
        en: 'Kitakyushu',
        ja: '北九州市',
      },
    },
    {
      product: Product.Ready,
      region: '8_kobe',
      names: {
        en: 'Kobe',
        ja: '神戸市',
      },
    },
    {
      product: Product.Ready,
      region: '9_kumamoto',
      names: {
        en: 'Kumamoto',
        ja: '熊本市',
      },
    },
    {
      product: Product.Ready,
      region: '10_kyoto',
      names: {
        en: 'Kyoto',
        ja: '京都市',
      },
    },
    {
      product: Product.Ready,
      region: '11_nagoya',
      names: {
        en: 'Nagoya',
        ja: '名古屋市',
      },
    },
    {
      product: Product.Ready,
      region: '12_niigata',
      names: {
        en: 'Niigata',
        ja: '新潟市',
      },
    },
    {
      product: Product.Ready,
      region: '13_okayama',
      names: {
        en: 'Okayama',
        ja: '岡山市',
      },
    },
    {
      product: Product.Ready,
      region: '14_osaka',
      names: {
        en: 'Osaka',
        ja: '大阪市',
      },
    },
    {
      product: Product.Ready,
      region: '15_sagamih',
      names: {
        en: 'Sagamihara',
        ja: '相模原市',
      },
    },
    {
      product: Product.Ready,
      region: '16_saitama',
      names: {
        en: 'Saitama',
        ja: 'さいたま市',
      },
    },
    {
      product: Product.Ready,
      region: '17_sakai',
      names: {
        en: 'Sakai',
        ja: '堺市',
      },
    },
    {
      product: Product.Ready,
      region: '18_sapporo',
      names: {
        en: 'Sapporo',
        ja: '札幌市',
      },
    },
    {
      product: Product.Ready,
      region: '19_sendai',
      names: {
        en: 'Sendai',
        ja: '仙台市',
      },
    },
    {
      product: Product.Ready,
      region: '20_shizuok',
      names: {
        en: 'Shizuoka',
        ja: '静岡市',
      },
    },
    {
      product: Product.Ready,
      region: '21_yokoham',
      names: {
        en: 'Yokohama',
        ja: '横浜市',
      },
    },
    {
      product: Product.Ready,
      region: '22_akashi',
      names: {
        en: 'Akashi',
        ja: '明石市',
      },
    },
    {
      product: Product.Ready,
      region: '23_akita',
      names: {
        en: 'Akita',
        ja: '秋田市',
      },
    },
    {
      product: Product.Ready,
      region: '24_amagasa',
      names: {
        en: 'Amagasaki',
        ja: '尼崎市',
      },
    },
    {
      product: Product.Ready,
      region: '25_aomori',
      names: {
        en: 'Aomori',
        ja: '青森市',
      },
    },
    {
      product: Product.Ready,
      region: '26_asahika',
      names: {
        en: 'Asahikawa',
        ja: '旭川市',
      },
    },
    {
      product: Product.Ready,
      region: '27_fukui',
      names: {
        en: 'Fukui',
        ja: '福井市',
      },
    },
    {
      product: Product.Ready,
      region: '28_fukushi',
      names: {
        en: 'Fukushima',
        ja: '福島市',
      },
    },
    {
      product: Product.Ready,
      region: '29_fukuyam',
      names: {
        en: 'Fukuyama',
        ja: '福山市',
      },
    },
    {
      product: Product.Ready,
      region: '30_funabas',
      names: {
        en: 'Funabashi',
        ja: '船橋市',
      },
    },
    {
      product: Product.Ready,
      region: '31_gifu',
      names: {
        en: 'Gifu',
        ja: '岐阜市',
      },
    },
    {
      product: Product.Ready,
      region: '32_hachino',
      names: {
        en: 'Hachinohe',
        ja: '八戸市',
      },
    },
    {
      product: Product.Ready,
      region: '33_hachioj',
      names: {
        en: 'Hachiōji',
        ja: '八王子市',
      },
    },
    {
      product: Product.Ready,
      region: '34_hakodat',
      names: {
        en: 'Hakodate',
        ja: '函館市',
      },
    },
    {
      product: Product.Ready,
      region: '35_higashi',
      names: {
        en: 'Higashiōsaka',
        ja: '東大阪市',
      },
    },
    {
      product: Product.Ready,
      region: '36_himeji',
      names: {
        en: 'Himeji',
        ja: '姫路市',
      },
    },
    {
      product: Product.Ready,
      region: '37_hirakat',
      names: {
        en: 'Hirakata',
        ja: '枚方市',
      },
    },
    {
      product: Product.Ready,
      region: '38_iwaki',
      names: {
        en: 'Iwaki',
        ja: 'いわき市',
      },
    },
    {
      product: Product.Ready,
      region: '39_kagoshi',
      names: {
        en: 'Kagoshima',
        ja: '鹿児島市',
      },
    },
    {
      product: Product.Ready,
      region: '40_kashiwa',
      names: {
        en: 'Kashiwa',
        ja: '柏市',
      },
    },
    {
      product: Product.Ready,
      region: '41_kanazaw',
      names: {
        en: 'Kanazawa',
        ja: '金沢市',
      },
    },
    {
      product: Product.Ready,
      region: '42_kawagoe',
      names: {
        en: 'Kawagoe',
        ja: '川越市',
      },
    },
    {
      product: Product.Ready,
      region: '43_kawaguc',
      names: {
        en: 'Kawaguchi',
        ja: '川口市',
      },
    },
    {
      product: Product.Ready,
      region: '44_kochi',
      names: {
        en: 'Kōchi',
        ja: '高知市',
      },
    },
    {
      product: Product.Ready,
      region: '45_kofu',
      names: {
        en: 'Kōfu',
        ja: '甲府市',
      },
    },
    {
      product: Product.Ready,
      region: '46_koriyam',
      names: {
        en: 'Kōriyama',
        ja: '郡山市',
      },
    },
    {
      product: Product.Ready,
      region: '47_koshiga',
      names: {
        en: 'Koshigaya',
        ja: '越谷市',
      },
    },
    {
      product: Product.Ready,
      region: '48_kurashi',
      names: {
        en: 'Kurashiki',
        ja: '倉敷市',
      },
    },
    {
      product: Product.Ready,
      region: '49_kure',
      names: {
        en: 'Kure',
        ja: '呉市',
      },
    },
    {
      product: Product.Ready,
      region: '50_kurume',
      names: {
        en: 'Kurume',
        ja: '久留米市',
      },
    },
    {
      product: Product.Ready,
      region: '51_maebash',
      names: {
        en: 'Maebashi',
        ja: '前橋市',
      },
    },
    {
      product: Product.Ready,
      region: '52_matsue',
      names: {
        en: 'Matsue',
        ja: '松江市',
      },
    },
    {
      product: Product.Ready,
      region: '53_matsuya',
      names: {
        en: 'Matsuyama',
        ja: '松山市',
      },
    },
    {
      product: Product.Ready,
      region: '54_mito',
      names: {
        en: 'Mito',
        ja: '水戸市',
      },
    },
    {
      product: Product.Ready,
      region: '55_miyazak',
      names: {
        en: 'Miyazaki',
        ja: '宮崎市',
      },
    },
    {
      product: Product.Ready,
      region: '56_morioka',
      names: {
        en: 'Morioka',
        ja: '盛岡市',
      },
    },
    {
      product: Product.Ready,
      region: '57_naha',
      names: {
        en: 'Naha',
        ja: '那覇市',
      },
    },
    {
      product: Product.Ready,
      region: '58_nagano',
      names: {
        en: 'Nagano',
        ja: '長野市',
      },
    },
    {
      product: Product.Ready,
      region: '59_nagasak',
      names: {
        en: 'Nagasaki',
        ja: '長崎市',
      },
    },
    {
      product: Product.Ready,
      region: '60_nara',
      names: {
        en: 'Nara',
        ja: '奈良市',
      },
    },
    {
      product: Product.Ready,
      region: '61_neyagaw',
      names: {
        en: 'Neyagawa',
        ja: '寝屋川市',
      },
    },
    {
      product: Product.Ready,
      region: '62_nishino',
      names: {
        en: 'Nishinomiya',
        ja: '西宮市',
      },
    },
    {
      product: Product.Ready,
      region: '63_oita',
      names: {
        en: 'Ōita',
        ja: '大分市',
      },
    },
    {
      product: Product.Ready,
      region: '64_okazaki',
      names: {
        en: 'Okazaki',
        ja: '岡崎市',
      },
    },
    {
      product: Product.Ready,
      region: '65_otsu',
      names: {
        en: 'Ōtsu',
        ja: '大津市',
      },
    },
    {
      product: Product.Ready,
      region: '66_sasebo',
      names: {
        en: 'Sasebo',
        ja: '佐世保市',
      },
    },
    {
      product: Product.Ready,
      region: '67_shimono',
      names: {
        en: 'Shimonoseki',
        ja: '下関市',
      },
    },
    {
      product: Product.Ready,
      region: '68_suita',
      names: {
        en: 'Suita',
        ja: '吹田市',
      },
    },
    {
      product: Product.Ready,
      region: '69_takamat',
      names: {
        en: 'Takamatsu',
        ja: '高松市',
      },
    },
    {
      product: Product.Ready,
      region: '70_takasak',
      names: {
        en: 'Takasaki',
        ja: '高崎市',
      },
    },
    {
      product: Product.Ready,
      region: '71_takatsu',
      names: {
        en: 'Takatsuki',
        ja: '高槻市',
      },
    },
    {
      product: Product.Ready,
      region: '72_tottori',
      names: {
        en: 'Tottori',
        ja: '鳥取市',
      },
    },
    {
      product: Product.Ready,
      region: '73_toyama',
      names: {
        en: 'Toyama',
        ja: '富山市',
      },
    },
    {
      product: Product.Ready,
      region: '74_toyohas',
      names: {
        en: 'Toyohashi',
        ja: '豊橋市',
      },
    },
    {
      product: Product.Ready,
      region: '75_toyonak',
      names: {
        en: 'Toyonaka',
        ja: '豊中市',
      },
    },
    {
      product: Product.Ready,
      region: '76_toyota',
      names: {
        en: 'Toyota',
        ja: '豊田市',
      },
    },
    {
      product: Product.Ready,
      region: '77_utsunom',
      names: {
        en: 'Utsunomiya',
        ja: '宇都宮市',
      },
    },
    {
      product: Product.Ready,
      region: '78_wakayam',
      names: {
        en: 'Wakayama',
        ja: '和歌山市',
      },
    },
    {
      product: Product.Ready,
      region: '79_yamagat',
      names: {
        en: 'Yamagata',
        ja: '山形市',
      },
    },
    {
      product: Product.Ready,
      region: '80_yao',
      names: {
        en: 'Yao',
        ja: '八尾市',
      },
    },
    {
      product: Product.Ready,
      region: '81_yokosuk',
      names: {
        en: 'Yokosuka',
        ja: '横須賀市',
      },
    },
    {
      product: Product.Ready,
      region: '82_tsukuba',
      names: {
        en: 'Tsukuba',
        ja: 'つくば市',
      },
    },
    {
      product: Product.Ready,
      region: '83_isesaki',
      names: {
        en: 'Isesaki',
        ja: '伊勢崎市',
      },
    },
    {
      product: Product.Ready,
      region: '84_ota',
      names: {
        en: 'Ota',
        ja: '太田市',
      },
    },
    {
      product: Product.Ready,
      region: '85_tokoroz',
      names: {
        en: 'Tokorozawa',
        ja: '所沢市',
      },
    },
    {
      product: Product.Ready,
      region: '86_soka',
      names: {
        en: 'Soka',
        ja: '草加市',
      },
    },
    {
      product: Product.Ready,
      region: '87_kasukab',
      names: {
        en: 'Kasukabe',
        ja: '春日部市',
      },
    },
    {
      product: Product.Ready,
      region: '88_kumagay',
      names: {
        en: 'Kumagaya',
        ja: '熊谷市',
      },
    },
    {
      product: Product.Ready,
      region: '89_odawara',
      names: {
        en: 'Odawara',
        ja: '小田原市',
      },
    },
    {
      product: Product.Ready,
      region: '90_yamato',
      names: {
        en: 'Yamato',
        ja: '大和市',
      },
    },
    {
      product: Product.Ready,
      region: '91_hiratsu',
      names: {
        en: 'Hiratsuka',
        ja: '平塚市',
      },
    },
    {
      product: Product.Ready,
      region: '92_atsugi',
      names: {
        en: 'Atsugi',
        ja: '厚木市',
      },
    },
    {
      product: Product.Ready,
      region: '93_chigasa',
      names: {
        en: 'Chigasaki',
        ja: '茅ヶ崎市',
      },
    },
    {
      product: Product.Ready,
      region: '94_nagaoka',
      names: {
        en: 'Nagaoka',
        ja: '長岡市',
      },
    },
    {
      product: Product.Ready,
      region: '95_joetsu',
      names: {
        en: 'Joetsu',
        ja: '上越市',
      },
    },
    {
      product: Product.Ready,
      region: '96_matsumo',
      names: {
        en: 'Matsumoto',
        ja: '松本市',
      },
    },
    {
      product: Product.Ready,
      region: '97_numazu',
      names: {
        en: 'Numazu',
        ja: '沼津市',
      },
    },
    {
      product: Product.Ready,
      region: '98_fuji',
      names: {
        en: 'Fuji',
        ja: '富士市',
      },
    },
    {
      product: Product.Ready,
      region: '99_kasugai',
      names: {
        en: 'Kasugai',
        ja: '春日井市',
      },
    },
    {
      product: Product.Ready,
      region: '100_ichino',
      names: {
        en: 'Ichinomiya',
        ja: '一宮市',
      },
    },
    {
      product: Product.Ready,
      region: '101_yokkai',
      names: {
        en: 'Yokkaichi',
        ja: '四日市市',
      },
    },
    {
      product: Product.Ready,
      region: '102_ibarak',
      names: {
        en: 'Ibaraki',
        ja: '茨木市',
      },
    },
    {
      product: Product.Ready,
      region: '103_kishiw',
      names: {
        en: 'Kishiwada',
        ja: '岸和田市',
      },
    },
    {
      product: Product.Ready,
      region: '104_kakoga',
      names: {
        en: 'Kakogawa',
        ja: '加古川市',
      },
    },
    {
      product: Product.Ready,
      region: '105_takara',
      names: {
        en: 'Takarazuka',
        ja: '宝塚市',
      },
    },
    {
      product: Product.Ready,
      region: '106_saga',
      names: {
        en: 'Saga',
        ja: '佐賀市',
      },
    },
    {
      product: Product.Ready,
      region: '107_kumaga',
      names: {
        en: 'Kumagawa',
        ja: '',
      },
    },
    {
      product: Product.Domino,
      region: 'jp',
      names: {
        en: 'Japan',
        ja: '日本',
      },
    },
    {
      product: Product.Domino,
      region: 'us',
      names: {
        en: 'United States',
        ja: '米国',
      },
    },
  ],
  productHasHazards: [
    {
      product: Product.Ready,
      hazard: 'flood',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
    },
  ],
  productHasModules: [
    {
      product: Product.Ready,
      module: 'live hazard',
      description: 'Base READY functionality, per hazard and region',
      isMain: true,
      names: {
        en: 'Standard',
      },
    },
    {
      product: Product.Ready,
      module: 'e2e-test',
      description: 'QA users worldview, made of simulated hazardeous events',
      isMain: false,
      names: {
        en: 'E2E Test (QA)',
      },
    },
    {
      product: Product.Domino,
      module: 'jp',
      description: 'DOMINO JP subscription (covers Japan)',
      isMain: true,
      names: {
        en: 'DOMINO JP',
      },
    },
    {
      product: Product.Domino,
      module: 'us',
      description: 'DOMINO US subscription (covers the United States)',
      isMain: true,
      names: {
        en: 'DOMINO US',
      },
    },
    {
      product: Product.Domino,
      module: 'consultant',
      description: 'Risk consulting organization may share views with their clients',
      isMain: false,
      names: {
        en: 'Consultant',
      },
    },
  ],
  productHasFeatures: [
    {
      product: Product.Ready,
      hazard: null,
      feature: 'preview1',
      names: {
        en: 'Preview features (QA)',
      },
      description: 'Provides access to features released in preview mode for QA',
    },
    {
      product: Product.Ready,
      hazard: null,
      feature: 'preview2',
      names: {
        en: 'Preview features (beta)',
      },
      description: 'Provides access to features released in preview mode for beta users',
    },
    {
      product: Product.Domino,
      hazard: null,
      feature: 'domino-viewer',
      names: {
        en: 'Viewer (client)',
      },
      description: 'A client of a risk consulting organization, allowed to see shared views',
    },
    {
      product: Product.Domino,
      hazard: null,
      feature: 'preview1',
      names: {
        en: 'Preview features (QA)',
      },
      description: 'Provides access to features released in preview mode for QA',
    },
    {
      product: Product.Domino,
      hazard: null,
      feature: 'preview2',
      names: {
        en: 'Preview features (beta)',
      },
      description: 'Provides access to features released in preview mode for beta users',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-port',
      names: {
        en: 'Lifelines ports',
      },
      description: 'See ports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-airport',
      names: {
        en: 'Lifelines airports',
      },
      description: 'See airports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-highway',
      names: {
        en: 'Lifelines highways',
      },
      description: 'See highway segments as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-people',
      names: {
        en: 'Lifelines community',
      },
      description: 'See community groupings as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-power',
      names: {
        en: 'Lifelines power',
      },
      description: 'See power substations as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-bridge',
      names: {
        en: 'Lifelines bridges',
      },
      description: 'See bridges as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-port',
      names: {
        en: 'Lifelines ports',
      },
      description: 'See ports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-airport',
      names: {
        en: 'Lifelines airports',
      },
      description: 'See airports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-highway',
      names: {
        en: 'Lifelines highways',
      },
      description: 'See highway segments as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-people',
      names: {
        en: 'Lifelines community',
      },
      description: 'See community groupings as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-power',
      names: {
        en: 'Lifelines power',
      },
      description: 'See power substations as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-bridge',
      names: {
        en: 'Lifelines bridges',
      },
      description: 'See bridges as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-port',
      names: {
        en: 'Lifelines ports',
      },
      description: 'See ports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-airport',
      names: {
        en: 'Lifelines airports',
      },
      description: 'See airports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-highway',
      names: {
        en: 'Lifelines highways',
      },
      description: 'See highway segments as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-people',
      names: {
        en: 'Lifelines community',
      },
      description: 'See community groupings as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-power',
      names: {
        en: 'Lifelines power',
      },
      description: 'See power substations as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-bridge',
      names: {
        en: 'Lifelines bridges',
      },
      description: 'See bridges as lifelines and their associated stats',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
      feature: 'seismicimpactgrid',
      names: {
        en: 'Seismic impact grid',
      },
      description: 'Seismic impact grid (hex map with impacted buildings and populations)',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
      feature: 'simulation',
      names: {
        en: 'Simulation',
      },
      description: 'Seismic simulation (visible across the organization)',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
      feature: 'notifications',
      names: {
        en: 'Notifications',
      },
      description: 'hazard notifications (emails)',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'floodmodel_moi',
      names: {
        en: 'Inundation model MOI',
      },
      description: 'See flood predictions produced by the MOI inundation model (deprecated)',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'floodmodel_schism',
      names: {
        en: 'Inundation model SCHISM',
      },
      description: 'See flood predictions produced by the SCHISM inundation model',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'river_gauges',
      names: {
        en: 'River gauges',
      },
      description: 'See river gauges and predicted levels for a region',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'notifications',
      names: {
        en: 'Notifications',
      },
      description: 'hazard notifications (emails)',
    },
  ],
};

const userEntitlementsStructure: GetEntitlementsStructureQuery['entitlementsStructure'] = {
  products: [Product.Ready, Product.Domino],
  productHasRegions: [
    {
      product: Product.Ready,
      region: '1_tokyo',
      names: {
        en: 'Tokyo',
        ja: '東京都区部',
      },
    },
    {
      product: Product.Ready,
      region: '2_chiba',
      names: {
        en: 'Chiba',
        ja: '千葉市',
      },
    },
    {
      product: Product.Ready,
      region: '3_fukuoka',
      names: {
        en: 'Fukuoka',
        ja: '福岡市',
      },
    },
    {
      product: Product.Ready,
      region: '4_hamamats',
      names: {
        en: 'Hamamatsu',
        ja: '浜松市',
      },
    },
    {
      product: Product.Ready,
      region: '5_hiroshim',
      names: {
        en: 'Hiroshima',
        ja: '広島市',
      },
    },
    {
      product: Product.Ready,
      region: '6_kawasaki',
      names: {
        en: 'Kawasaki',
        ja: '川崎市',
      },
    },
    {
      product: Product.Ready,
      region: '7_kitakyus',
      names: {
        en: 'Kitakyushu',
        ja: '北九州市',
      },
    },
    {
      product: Product.Ready,
      region: '8_kobe',
      names: {
        en: 'Kobe',
        ja: '神戸市',
      },
    },
    {
      product: Product.Ready,
      region: '9_kumamoto',
      names: {
        en: 'Kumamoto',
        ja: '熊本市',
      },
    },
    {
      product: Product.Ready,
      region: '10_kyoto',
      names: {
        en: 'Kyoto',
        ja: '京都市',
      },
    },
    {
      product: Product.Ready,
      region: '11_nagoya',
      names: {
        en: 'Nagoya',
        ja: '名古屋市',
      },
    },
    {
      product: Product.Ready,
      region: '12_niigata',
      names: {
        en: 'Niigata',
        ja: '新潟市',
      },
    },
    {
      product: Product.Ready,
      region: '13_okayama',
      names: {
        en: 'Okayama',
        ja: '岡山市',
      },
    },
    {
      product: Product.Ready,
      region: '14_osaka',
      names: {
        en: 'Osaka',
        ja: '大阪市',
      },
    },
    {
      product: Product.Ready,
      region: '15_sagamih',
      names: {
        en: 'Sagamihara',
        ja: '相模原市',
      },
    },
    {
      product: Product.Ready,
      region: '16_saitama',
      names: {
        en: 'Saitama',
        ja: 'さいたま市',
      },
    },
    {
      product: Product.Ready,
      region: '17_sakai',
      names: {
        en: 'Sakai',
        ja: '堺市',
      },
    },
    {
      product: Product.Ready,
      region: '18_sapporo',
      names: {
        en: 'Sapporo',
        ja: '札幌市',
      },
    },
    {
      product: Product.Ready,
      region: '19_sendai',
      names: {
        en: 'Sendai',
        ja: '仙台市',
      },
    },
    {
      product: Product.Ready,
      region: '20_shizuok',
      names: {
        en: 'Shizuoka',
        ja: '静岡市',
      },
    },
    {
      product: Product.Ready,
      region: '21_yokoham',
      names: {
        en: 'Yokohama',
        ja: '横浜市',
      },
    },
    {
      product: Product.Ready,
      region: '22_akashi',
      names: {
        en: 'Akashi',
        ja: '明石市',
      },
    },
    {
      product: Product.Ready,
      region: '23_akita',
      names: {
        en: 'Akita',
        ja: '秋田市',
      },
    },
    {
      product: Product.Ready,
      region: '24_amagasa',
      names: {
        en: 'Amagasaki',
        ja: '尼崎市',
      },
    },
    {
      product: Product.Ready,
      region: '25_aomori',
      names: {
        en: 'Aomori',
        ja: '青森市',
      },
    },
    {
      product: Product.Ready,
      region: '26_asahika',
      names: {
        en: 'Asahikawa',
        ja: '旭川市',
      },
    },
    {
      product: Product.Ready,
      region: '27_fukui',
      names: {
        en: 'Fukui',
        ja: '福井市',
      },
    },
    {
      product: Product.Ready,
      region: '28_fukushi',
      names: {
        en: 'Fukushima',
        ja: '福島市',
      },
    },
    {
      product: Product.Ready,
      region: '29_fukuyam',
      names: {
        en: 'Fukuyama',
        ja: '福山市',
      },
    },
    {
      product: Product.Ready,
      region: '30_funabas',
      names: {
        en: 'Funabashi',
        ja: '船橋市',
      },
    },
    {
      product: Product.Ready,
      region: '31_gifu',
      names: {
        en: 'Gifu',
        ja: '岐阜市',
      },
    },
    {
      product: Product.Ready,
      region: '32_hachino',
      names: {
        en: 'Hachinohe',
        ja: '八戸市',
      },
    },
    {
      product: Product.Ready,
      region: '33_hachioj',
      names: {
        en: 'Hachiōji',
        ja: '八王子市',
      },
    },
    {
      product: Product.Ready,
      region: '34_hakodat',
      names: {
        en: 'Hakodate',
        ja: '函館市',
      },
    },
    {
      product: Product.Ready,
      region: '35_higashi',
      names: {
        en: 'Higashiōsaka',
        ja: '東大阪市',
      },
    },
    {
      product: Product.Ready,
      region: '36_himeji',
      names: {
        en: 'Himeji',
        ja: '姫路市',
      },
    },
    {
      product: Product.Ready,
      region: '37_hirakat',
      names: {
        en: 'Hirakata',
        ja: '枚方市',
      },
    },
    {
      product: Product.Ready,
      region: '38_iwaki',
      names: {
        en: 'Iwaki',
        ja: 'いわき市',
      },
    },
    {
      product: Product.Ready,
      region: '39_kagoshi',
      names: {
        en: 'Kagoshima',
        ja: '鹿児島市',
      },
    },
    {
      product: Product.Ready,
      region: '40_kashiwa',
      names: {
        en: 'Kashiwa',
        ja: '柏市',
      },
    },
    {
      product: Product.Ready,
      region: '41_kanazaw',
      names: {
        en: 'Kanazawa',
        ja: '金沢市',
      },
    },
    {
      product: Product.Ready,
      region: '42_kawagoe',
      names: {
        en: 'Kawagoe',
        ja: '川越市',
      },
    },
    {
      product: Product.Ready,
      region: '43_kawaguc',
      names: {
        en: 'Kawaguchi',
        ja: '川口市',
      },
    },
    {
      product: Product.Ready,
      region: '44_kochi',
      names: {
        en: 'Kōchi',
        ja: '高知市',
      },
    },
    {
      product: Product.Ready,
      region: '45_kofu',
      names: {
        en: 'Kōfu',
        ja: '甲府市',
      },
    },
    {
      product: Product.Ready,
      region: '46_koriyam',
      names: {
        en: 'Kōriyama',
        ja: '郡山市',
      },
    },
    {
      product: Product.Ready,
      region: '47_koshiga',
      names: {
        en: 'Koshigaya',
        ja: '越谷市',
      },
    },
    {
      product: Product.Ready,
      region: '48_kurashi',
      names: {
        en: 'Kurashiki',
        ja: '倉敷市',
      },
    },
    {
      product: Product.Ready,
      region: '49_kure',
      names: {
        en: 'Kure',
        ja: '呉市',
      },
    },
    {
      product: Product.Ready,
      region: '50_kurume',
      names: {
        en: 'Kurume',
        ja: '久留米市',
      },
    },
    {
      product: Product.Ready,
      region: '51_maebash',
      names: {
        en: 'Maebashi',
        ja: '前橋市',
      },
    },
    {
      product: Product.Ready,
      region: '52_matsue',
      names: {
        en: 'Matsue',
        ja: '松江市',
      },
    },
    {
      product: Product.Ready,
      region: '53_matsuya',
      names: {
        en: 'Matsuyama',
        ja: '松山市',
      },
    },
    {
      product: Product.Ready,
      region: '54_mito',
      names: {
        en: 'Mito',
        ja: '水戸市',
      },
    },
    {
      product: Product.Ready,
      region: '55_miyazak',
      names: {
        en: 'Miyazaki',
        ja: '宮崎市',
      },
    },
    {
      product: Product.Ready,
      region: '56_morioka',
      names: {
        en: 'Morioka',
        ja: '盛岡市',
      },
    },
    {
      product: Product.Ready,
      region: '57_naha',
      names: {
        en: 'Naha',
        ja: '那覇市',
      },
    },
    {
      product: Product.Ready,
      region: '58_nagano',
      names: {
        en: 'Nagano',
        ja: '長野市',
      },
    },
    {
      product: Product.Ready,
      region: '59_nagasak',
      names: {
        en: 'Nagasaki',
        ja: '長崎市',
      },
    },
    {
      product: Product.Ready,
      region: '60_nara',
      names: {
        en: 'Nara',
        ja: '奈良市',
      },
    },
    {
      product: Product.Ready,
      region: '61_neyagaw',
      names: {
        en: 'Neyagawa',
        ja: '寝屋川市',
      },
    },
    {
      product: Product.Ready,
      region: '62_nishino',
      names: {
        en: 'Nishinomiya',
        ja: '西宮市',
      },
    },
    {
      product: Product.Ready,
      region: '63_oita',
      names: {
        en: 'Ōita',
        ja: '大分市',
      },
    },
    {
      product: Product.Ready,
      region: '64_okazaki',
      names: {
        en: 'Okazaki',
        ja: '岡崎市',
      },
    },
    {
      product: Product.Ready,
      region: '65_otsu',
      names: {
        en: 'Ōtsu',
        ja: '大津市',
      },
    },
    {
      product: Product.Ready,
      region: '66_sasebo',
      names: {
        en: 'Sasebo',
        ja: '佐世保市',
      },
    },
    {
      product: Product.Ready,
      region: '67_shimono',
      names: {
        en: 'Shimonoseki',
        ja: '下関市',
      },
    },
    {
      product: Product.Ready,
      region: '68_suita',
      names: {
        en: 'Suita',
        ja: '吹田市',
      },
    },
    {
      product: Product.Ready,
      region: '69_takamat',
      names: {
        en: 'Takamatsu',
        ja: '高松市',
      },
    },
    {
      product: Product.Ready,
      region: '70_takasak',
      names: {
        en: 'Takasaki',
        ja: '高崎市',
      },
    },
    {
      product: Product.Ready,
      region: '71_takatsu',
      names: {
        en: 'Takatsuki',
        ja: '高槻市',
      },
    },
    {
      product: Product.Ready,
      region: '72_tottori',
      names: {
        en: 'Tottori',
        ja: '鳥取市',
      },
    },
    {
      product: Product.Ready,
      region: '73_toyama',
      names: {
        en: 'Toyama',
        ja: '富山市',
      },
    },
    {
      product: Product.Ready,
      region: '74_toyohas',
      names: {
        en: 'Toyohashi',
        ja: '豊橋市',
      },
    },
    {
      product: Product.Ready,
      region: '75_toyonak',
      names: {
        en: 'Toyonaka',
        ja: '豊中市',
      },
    },
    {
      product: Product.Ready,
      region: '76_toyota',
      names: {
        en: 'Toyota',
        ja: '豊田市',
      },
    },
    {
      product: Product.Ready,
      region: '77_utsunom',
      names: {
        en: 'Utsunomiya',
        ja: '宇都宮市',
      },
    },
    {
      product: Product.Ready,
      region: '78_wakayam',
      names: {
        en: 'Wakayama',
        ja: '和歌山市',
      },
    },
    {
      product: Product.Ready,
      region: '79_yamagat',
      names: {
        en: 'Yamagata',
        ja: '山形市',
      },
    },
    {
      product: Product.Ready,
      region: '80_yao',
      names: {
        en: 'Yao',
        ja: '八尾市',
      },
    },
    {
      product: Product.Ready,
      region: '81_yokosuk',
      names: {
        en: 'Yokosuka',
        ja: '横須賀市',
      },
    },
    {
      product: Product.Ready,
      region: '82_tsukuba',
      names: {
        en: 'Tsukuba',
        ja: 'つくば市',
      },
    },
    {
      product: Product.Ready,
      region: '83_isesaki',
      names: {
        en: 'Isesaki',
        ja: '伊勢崎市',
      },
    },
    {
      product: Product.Ready,
      region: '84_ota',
      names: {
        en: 'Ota',
        ja: '太田市',
      },
    },
    {
      product: Product.Ready,
      region: '85_tokoroz',
      names: {
        en: 'Tokorozawa',
        ja: '所沢市',
      },
    },
    {
      product: Product.Ready,
      region: '86_soka',
      names: {
        en: 'Soka',
        ja: '草加市',
      },
    },
    {
      product: Product.Ready,
      region: '87_kasukab',
      names: {
        en: 'Kasukabe',
        ja: '春日部市',
      },
    },
    {
      product: Product.Ready,
      region: '88_kumagay',
      names: {
        en: 'Kumagaya',
        ja: '熊谷市',
      },
    },
    {
      product: Product.Ready,
      region: '89_odawara',
      names: {
        en: 'Odawara',
        ja: '小田原市',
      },
    },
    {
      product: Product.Ready,
      region: '90_yamato',
      names: {
        en: 'Yamato',
        ja: '大和市',
      },
    },
    {
      product: Product.Ready,
      region: '91_hiratsu',
      names: {
        en: 'Hiratsuka',
        ja: '平塚市',
      },
    },
    {
      product: Product.Ready,
      region: '92_atsugi',
      names: {
        en: 'Atsugi',
        ja: '厚木市',
      },
    },
    {
      product: Product.Ready,
      region: '93_chigasa',
      names: {
        en: 'Chigasaki',
        ja: '茅ヶ崎市',
      },
    },
    {
      product: Product.Ready,
      region: '94_nagaoka',
      names: {
        en: 'Nagaoka',
        ja: '長岡市',
      },
    },
    {
      product: Product.Ready,
      region: '95_joetsu',
      names: {
        en: 'Joetsu',
        ja: '上越市',
      },
    },
    {
      product: Product.Ready,
      region: '96_matsumo',
      names: {
        en: 'Matsumoto',
        ja: '松本市',
      },
    },
    {
      product: Product.Ready,
      region: '97_numazu',
      names: {
        en: 'Numazu',
        ja: '沼津市',
      },
    },
    {
      product: Product.Ready,
      region: '98_fuji',
      names: {
        en: 'Fuji',
        ja: '富士市',
      },
    },
    {
      product: Product.Ready,
      region: '99_kasugai',
      names: {
        en: 'Kasugai',
        ja: '春日井市',
      },
    },
    {
      product: Product.Ready,
      region: '100_ichino',
      names: {
        en: 'Ichinomiya',
        ja: '一宮市',
      },
    },
    {
      product: Product.Ready,
      region: '101_yokkai',
      names: {
        en: 'Yokkaichi',
        ja: '四日市市',
      },
    },
    {
      product: Product.Ready,
      region: '102_ibarak',
      names: {
        en: 'Ibaraki',
        ja: '茨木市',
      },
    },
    {
      product: Product.Ready,
      region: '103_kishiw',
      names: {
        en: 'Kishiwada',
        ja: '岸和田市',
      },
    },
    {
      product: Product.Ready,
      region: '104_kakoga',
      names: {
        en: 'Kakogawa',
        ja: '加古川市',
      },
    },
    {
      product: Product.Ready,
      region: '105_takara',
      names: {
        en: 'Takarazuka',
        ja: '宝塚市',
      },
    },
    {
      product: Product.Ready,
      region: '106_saga',
      names: {
        en: 'Saga',
        ja: '佐賀市',
      },
    },
    {
      product: Product.Ready,
      region: '107_kumaga',
      names: {
        en: 'Kumagawa',
        ja: '',
      },
    },
    {
      product: Product.Domino,
      region: 'jp',
      names: {
        en: 'Japan',
        ja: '日本',
      },
    },
    {
      product: Product.Domino,
      region: 'us',
      names: {
        en: 'United States',
        ja: '米国',
      },
    },
  ],
  productHasHazards: [
    {
      product: Product.Ready,
      hazard: 'flood',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
    },
  ],
  productHasModules: [
    {
      product: Product.Ready,
      module: 'live hazard',
      description: 'Base READY functionality, per hazard and region',
      isMain: true,
      names: {
        en: 'Standard',
      },
    },
    {
      product: Product.Ready,
      module: 'e2e-test',
      description: 'QA users worldview, made of simulated hazardeous events',
      isMain: false,
      names: {
        en: 'E2E Test (QA)',
      },
    },
    {
      product: Product.Domino,
      module: 'us',
      description: 'DOMINO US subscription (covers the United States)',
      isMain: true,
      names: {
        en: 'DOMINO US',
      },
    },
    {
      product: Product.Domino,
      module: 'consultant',
      description: 'Risk consulting organization may share views with their clients',
      isMain: false,
      names: {
        en: 'Consultant',
      },
    },
  ],
  productHasFeatures: [
    {
      product: Product.Ready,
      hazard: null,
      feature: 'preview1',
      names: {
        en: 'Preview features (QA)',
      },
      description: 'Provides access to features released in preview mode for QA',
    },
    {
      product: Product.Ready,
      hazard: null,
      feature: 'preview2',
      names: {
        en: 'Preview features (beta)',
      },
      description: 'Provides access to features released in preview mode for beta users',
    },
    {
      product: Product.Domino,
      hazard: null,
      feature: 'domino-viewer',
      names: {
        en: 'Viewer (client)',
      },
      description: 'A client of a risk consulting organization, allowed to see shared views',
    },
    {
      product: Product.Domino,
      hazard: null,
      feature: 'preview1',
      names: {
        en: 'Preview features (QA)',
      },
      description: 'Provides access to features released in preview mode for QA',
    },
    {
      product: Product.Domino,
      hazard: null,
      feature: 'preview2',
      names: {
        en: 'Preview features (beta)',
      },
      description: 'Provides access to features released in preview mode for beta users',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-port',
      names: {
        en: 'Lifelines ports',
      },
      description: 'See ports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-airport',
      names: {
        en: 'Lifelines airports',
      },
      description: 'See airports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-highway',
      names: {
        en: 'Lifelines highways',
      },
      description: 'See highway segments as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-people',
      names: {
        en: 'Lifelines community',
      },
      description: 'See community groupings as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-power',
      names: {
        en: 'Lifelines power',
      },
      description: 'See power substations as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'seismic',
      feature: 'lifeline-bridge',
      names: {
        en: 'Lifelines bridges',
      },
      description: 'See bridges as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-port',
      names: {
        en: 'Lifelines ports',
      },
      description: 'See ports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-airport',
      names: {
        en: 'Lifelines airports',
      },
      description: 'See airports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-highway',
      names: {
        en: 'Lifelines highways',
      },
      description: 'See highway segments as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-people',
      names: {
        en: 'Lifelines community',
      },
      description: 'See community groupings as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-power',
      names: {
        en: 'Lifelines power',
      },
      description: 'See power substations as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'flood',
      feature: 'lifeline-bridge',
      names: {
        en: 'Lifelines bridges',
      },
      description: 'See bridges as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-port',
      names: {
        en: 'Lifelines ports',
      },
      description: 'See ports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-airport',
      names: {
        en: 'Lifelines airports',
      },
      description: 'See airports as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-highway',
      names: {
        en: 'Lifelines highways',
      },
      description: 'See highway segments as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-people',
      names: {
        en: 'Lifelines community',
      },
      description: 'See community groupings as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-power',
      names: {
        en: 'Lifelines power',
      },
      description: 'See power substations as lifelines and their associated stats',
    },
    {
      product: Product.Domino,
      hazard: 'wind',
      feature: 'lifeline-bridge',
      names: {
        en: 'Lifelines bridges',
      },
      description: 'See bridges as lifelines and their associated stats',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
      feature: 'seismicimpactgrid',
      names: {
        en: 'Seismic impact grid',
      },
      description: 'Seismic impact grid (hex map with impacted buildings and populations)',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
      feature: 'simulation',
      names: {
        en: 'Simulation',
      },
      description: 'Seismic simulation (visible across the organization)',
    },
    {
      product: Product.Ready,
      hazard: 'seismic',
      feature: 'notifications',
      names: {
        en: 'Notifications',
      },
      description: 'hazard notifications (emails)',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'floodmodel_moi',
      names: {
        en: 'Inundation model MOI',
      },
      description: 'See flood predictions produced by the MOI inundation model (deprecated)',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'floodmodel_schism',
      names: {
        en: 'Inundation model SCHISM',
      },
      description: 'See flood predictions produced by the SCHISM inundation model',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'river_gauges',
      names: {
        en: 'River gauges',
      },
      description: 'See river gauges and predicted levels for a region',
    },
    {
      product: Product.Ready,
      hazard: 'flood',
      feature: 'notifications',
      names: {
        en: 'Notifications',
      },
      description: 'hazard notifications (emails)',
    },
  ],
};

export {
  organizations,
  organizationsFixture,
  organization,
  organizationDominoUS,
  organizationDominoJP,
  organizationFixture,
  organizationDominoUSFixture,
  organizationDominoJPFixture,
  getAllZonesDominoFixture,
  dominoZones,
  getAllZonesReadyFixture,
  readyZones,
  entitlementsStructure,
  organizationAllDominoFeaturesFixture,
  organizationAllReadyFeaturesFixture,
  organizationAllReadyFeatures,
  organizationAllDominoFeatures,
  userEntitlementsStructure,
};
