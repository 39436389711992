import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

type DeleteConfirmationProps = {
  onConfirm: () => void;
};

const DeleteConfirmationPopover: React.FC<DeleteConfirmationProps> = ({ onConfirm }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Tooltip title="Disabled Operation">
        <Button
          aria-describedby={id}
          variant="contained"
          // onClick={handleClick}
          // color="error"
          style={{
            background: 'gray',
          }}
          data-test-id="OrgDelete-Btn"
          // disabled
        >
          Delete
        </Button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ px: '5px', py: '5px' }}>
          <Typography
            sx={{ p: 2 }}
          >{`If the organization contains some users:\nAll members of this are going to be left without organization. Are you sure?`}</Typography>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleClose();
              onConfirm();
            }}
            data-test-id="ConfirmDelete-Btn"
          >
            Confirm Delete
          </Button>
        </Box>
      </Popover>
    </div>
  );
};

DeleteConfirmationPopover.displayName = 'DeleteConfirmationPopover';
export default DeleteConfirmationPopover;
