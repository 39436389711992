import React from 'react';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import { MibRequestStatus } from '../../../../__generated__/graphql';

type Request = {
  id?: string;
  externalID?: string;
  externalUserID: string;
  fileID?: string;
  lineNumber?: number;
  metadata: any;
  postedAt: any;
  updatedAt: any;
  status: MibRequestStatus;
  address?: {
    city?: string;
    country?: string;
    countryCode?: string;
    county?: string;
    formattedAddress: string;
    houseNumber?: string;
    postCode?: string;
    state?: string;
    stateCode?: string;
    stateDistrict?: string;
    street?: string;
    suburb?: string;
  };
};

type RequestsState = { view: string; requestDetails: Request };
type RequestsList = {
  requests: Array<Request>;
  handleState: (handleState: RequestsState) => void;
};

const Header: React.FC = () => {
  const width = 100 / 6;
  return (
    <TableHead>
      <TableRow>
        <TableCell width={`${width}%`} align="left">
          ExternalId
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          ExternalUserId
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Status
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          FileId
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          LineNumber
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Address
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const RequestsList: React.FC<RequestsList> = ({ requests, handleState }) => {
  const onRequestClick = (req: Request) => {
    handleState({ view: 'requestDetails', requestDetails: req });
  };

  const displayData = () => {
    return (
      <>
        {requests.map((row: any) => (
          <TableRow
            hover
            key={row.id}
            onClick={() => {
              onRequestClick(row);
            }}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell align="left">{row.externalID}</TableCell>
            <TableCell align="left">{row.externalUserID}</TableCell>
            <TableCell align="left">{row.status}</TableCell>
            <TableCell align="left">{row.fileID}</TableCell>
            <TableCell align="left">{row.lineNumber}</TableCell>
            <TableCell align="left">{row.address?.formattedAddress}</TableCell>
          </TableRow>
        ))}
      </>
    );
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table stickyHeader aria-label="sticky table">
          <Header />
          <TableBody>{displayData()}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RequestsList;
