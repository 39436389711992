import { GetMibRequestsQuery, MibRequestStatus } from '../__generated__/graphql';

const requestsRes: { mibRequests: GetMibRequestsQuery['mibRequests'] } = {
  mibRequests: [
    {
      id: 'id1',
      externalID: 'externalID1',
      externalUserID: 'externalUserID1',
      status: MibRequestStatus.Pending,
      fileID: 'fileID1',
      lineNumber: 1,
      address: {
        city: 'city1',
        country: 'country1',
        countryCode: 'countryCode1',
        county: 'county1',
        formattedAddress: 'formattedAddress1',
        houseNumber: 'houseNumber1',
        postCode: 'postCode1',
        state: 'state1',
        stateCode: 'stateCode1',
        stateDistrict: 'stateDistrict1',
        street: 'street1',
        suburb: 'suburb1',
      },
      metadata: 'metadata',
      postedAt: '2022-09-15T08:02:44.000Z',
      updatedAt: '2022-09-15T08:02:44.000Z',
    },
    {
      id: 'id2',
      externalID: 'externalID2',
      externalUserID: 'externalUserID2',
      status: MibRequestStatus.Pending,
      fileID: 'fileID2',
      lineNumber: 1,
      address: {
        city: 'city2',
        country: 'country2',
        countryCode: 'countryCode2',
        county: 'county2',
        formattedAddress: 'formattedAddress2',
        houseNumber: 'houseNumber2',
        postCode: 'postCode2',
        state: 'state2',
        stateCode: 'stateCode2',
        stateDistrict: 'stateDistrict2',
        street: 'street2',
        suburb: 'suburb2',
      },
      metadata: 'metadata',
      postedAt: '2022-09-15T08:02:44.000Z',
      updatedAt: '2022-09-15T08:02:44.000Z',
    },
    {
      id: 'id3',
      externalID: 'externalID3',
      externalUserID: 'externalUserID3',
      status: MibRequestStatus.Pending,
      fileID: 'fileID3',
      lineNumber: 1,
      address: {
        city: 'city3',
        country: 'country3',
        countryCode: 'countryCode3',
        county: 'county3',
        formattedAddress: 'formattedAddress3',
        houseNumber: 'houseNumber3',
        postCode: 'postCode3',
        state: 'state3',
        stateCode: 'stateCode3',
        stateDistrict: 'stateDistrict3',
        street: 'street3',
        suburb: 'suburb3',
      },
      metadata: 'metadata',
      postedAt: '2022-09-15T08:02:44.000Z',
      updatedAt: '2022-09-15T08:02:44.000Z',
    },
  ],
};

export default requestsRes;
