import * as React from 'react';
import { AuthContext, AuthInfo } from './Authorizer';
import { AuthError } from './AuthError';
import { App } from '../AppConfig';

export interface AuthableProps {
  authcontext: AuthContext;
  onAuthInfoChange: (e: any) => void;
}

// Authable is a generic component characterized by an authcontext={AuthContext} property.
export class Authable extends React.Component<any, any> {
  static displayName = 'Authable';

  constructor(props: any) {
    super(props);
    this.state = {
      failed: false,
      error: null,
    };

    this.fail = this.fail.bind(this);
    this.failed = this.failed.bind(this);
  }

  // fail handles errors: we let the rendering process finish, then raise issues if needed.
  // The failed and error internal state is here to allow propagation of errors as needed:
  // we may route exceptions to a specific handler then render null to discard the faulty
  // element. Alternatively, we might want to capture exceptions with react's ErrorBoundaries.
  // Since atm ErrorBoundaries do not catch exceptions in handlers, we set the internal state
  // then raise the error in the next call to render().
  fail(e: AuthError) {
    const { error } = this.state;
    const { authcontext } = this.props;
    if (error instanceof AuthError && error.hasUnauthorized()) {
      // this is specifically an authN error: we handle it like session expired
      const newAuthinfo = new AuthInfo({ didLogout: true });
      authcontext.onAuthInfoChange(newAuthinfo);
    }
    App.debug(e);

    // propagates error if an external error handler is provided in the context
    if (authcontext.onError && typeof authcontext.onError === 'function') {
      return authcontext.onError(e);
    }
    return Promise.reject(e);
  }

  failed(): boolean {
    return this.state.failed;
  }

  render(): React.ReactNode {
    const { failed, error } = this.state;
    // opt-in: crash component on error. This is useful in dev mode,
    // but best handled separately in production
    if (failed && error) {
      if (error instanceof AuthError && error.hasUnauthorized()) {
        // this is specifically an authN error: assumed to be already handled properly
        return null;
      }

      const { onError, authcontext } = this.props;
      if (onError || !authcontext.config.get('failOnError')) {
        // error handled externally
        return null;
      }

      // throw this to be caught by ErrorBoundary
      throw error;
    }
    return null;
  }
}
