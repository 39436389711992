import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import MenuDrawer from '../MenuDrawer';

type ButtonAppBarProps = { child?: React.ReactNode; onClickLogout?: () => void };

const ButtonAppBar: React.FC<ButtonAppBarProps> = ({ child, onClickLogout }) => {
  const [title, setTitle] = useState('Organizations');
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <MenuDrawer onClickLogout={onClickLogout} onItemSelected={(item) => setTitle(item)} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      {child}
    </Box>
  );
};

ButtonAppBar.displayName = 'ButtonAppBar';
export default ButtonAppBar;
