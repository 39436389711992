import * as React from 'react';
import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { Translation } from 'react-i18next';

import { AuthConfig, AuthContext, AuthInfo } from './auth/Authorizer';
import { Login } from './auth/Login';
import LoginPoller from './auth/LoginPoller';
import { LogoutErr } from './auth/AuthError';
import { Admin } from './auth/admin';
import { App } from './AppConfig';
import { client } from './client/Client';
import CSAdmin from './CSAdmin';
import { useGetCurrentUserQuery } from '../__generated__/graphql';

type CSAdminContainerInnerProps = {
  handleLogout: () => void;
};

export const CSAdminContainerInner: React.FC<CSAdminContainerInnerProps> = ({ handleLogout }) => {
  const { data, loading, error } = useGetCurrentUserQuery();

  const onClickLogout = React.useCallback(() => {
    handleLogout();
  }, [handleLogout]);

  let user = null;
  if (process.env.REACT_APP_ENV !== 'local') {
    if (loading) {
      return <div>Loading..</div>;
    }

    user = data?.user;

    if (error || !user) {
      return <div>GetCurrentUserAndSlicesQuery: no data returned</div>;
    }
  }

  return <CSAdmin user={user} onClickLogout={onClickLogout} />;
};

type CSAdminContainerState = {
  expired: boolean;
  showLogin: boolean;
  authcontext: AuthContext;
  poller: LoginPoller;
  apolloClient: ApolloClient<NormalizedCacheObject>;
  apolloClientLoaded: boolean;
};

class CSAdminContainer extends React.Component<unknown, CSAdminContainerState> {
  static displayName = 'CSAdminContainer';

  constructor(props: unknown) {
    super(props);

    const authConfig = new AuthConfig({
      loginURL: App.config.authconfig.loginurl,
      homeURL: App.config.authconfig.homeurl,
      clientID: App.config.authconfig.clientid,
      cookieDomain: App.config.authconfig.cookiedomain,
    });

    let poller = null;
    if (process.env.REACT_APP_ENV !== 'local') {
      poller = new LoginPoller(
        authConfig.tokenURL,
        App.config.authconfig.refreshtokeninterval * 1000,
      );
    }

    this.state = {
      expired: false,
      showLogin: true,
      // state handed over to specialized components provided by our SDK
      authcontext: new AuthContext({
        config: authConfig,
      }),
      poller,
      apolloClient: null,
      apolloClientLoaded: false,
    };

    // tell the authcontext how to call use back on context change
    // TODO: support being called like this.state.authcontext.registerStateHandler(this);
    this.state.authcontext.registerStateHandler(this.handleAuthChange);
  }

  async componentDidMount() {
    try {
      const newClient = await client();
      this.setState((prevState) => ({
        ...prevState,
        apolloClient: newClient,
        apolloClientLoaded: true,
      }));
    } catch (error) {
      App.error('[CSAdminContainer]  Error restoring Apollo cache', error);
    }
    // start polling to keep session alive
    const { poller } = this.state;
    if (poller) {
      poller.start();
    }
  }

  componentWillUnmount(): void {
    // stop polling
    const { poller } = this.state;
    if (poller && poller.started) {
      poller.stop();
    }
  }

  // handleAuthChange maintains state for managed components.
  //
  // This is used as a callback by managed components to lift
  // state and share important properties such as the login status,
  // user information or the value of the latest CSRF token.
  //
  // @param managedState is an AuthContext object.
  // TODO: we could do this more straightforward for SDK user
  // e.g. if passed a react object then the setState method is invoked
  handleAuthChange: (managedState: AuthContext) => void = (managedState) => {
    // app-specific logic here:
    // in this demo, after logout we want to just have a login button, not
    // to get the iframe again automatically. Hence the
    // showLogin state change. Remove this state change
    // if you want to automatically show the authentication iframe.
    this.setState({
      // this must be done to maintain state
      authcontext: managedState,

      // this is custom state property
      // TODO - we can probably remove the last line to get the app to navigate away from the
      // page to the login screen when the session times out.
      showLogin: managedState.isLogged(),
    });
  };

  // handleHasLogged is a custom (optional) login handler catching the
  // login-done event (e.g. saying hello)
  handleHasLogged = (authctx: AuthInfo): void => {
    const claims = authctx.getClaims();
    const username = claims.preferred_username || claims.email || claims.name;
    App.debug(`[CSAdmin] user is now logged in as username: '${username}'`);
  };

  handleLogout = (): void => {
    const { authcontext, apolloClient } = this.state;
    sessionStorage.removeItem('impersonator');

    const adm = new Admin(authcontext.config);
    adm
      .logout()
      .catch((e: any) => {
        // non blocking error
        App.error('[CSAdminContainer] handleLogout warning: ', new LogoutErr(e));
      })
      .then(() => {
        this.setState({
          showLogin: false,
        });
      })
      .finally(() => {
        this.setState({
          showLogin: false,
        });
        authcontext.onAuthInfoChange(new AuthInfo({ didLogout: true }));
        // reset CSRF state
        if (authcontext.onCsrfChange && typeof authcontext.onCsrfChange === 'function') {
          authcontext.onCsrfChange('');
        }
        apolloClient.resetStore();
      });
  };

  toggleExpiry: () => void = () => {
    this.setState((prevState) => {
      return {
        ...prevState,
        expired: !prevState.expired,
      };
    });
  };

  // showExpiry displays some specific content when session has expired
  // TODO jake test session expiry
  showExpiry = (): React.ReactNode => {
    return (
      <Translation>
        {(t) => (
          <div>
            <div>{t('err:sessionExpired')}</div>
            <div>
              <button type="button" onClick={this.toggleExpiry}>
                {t('err:loginAgain')}
              </button>
            </div>
          </div>
        )}
      </Translation>
    );
  };

  render(): React.ReactNode {
    const { expired, authcontext, apolloClient, apolloClientLoaded } = this.state;

    if (process.env.REACT_APP_ENV !== 'local') {
      if (expired) {
        return this.showExpiry();
      }

      if (!authcontext.isLogged()) {
        return <Login authcontext={authcontext} onChange={this.handleHasLogged} />;
      }
    }

    if (!apolloClientLoaded) {
      return <div>Loading..</div>;
    }

    return (
      <ApolloProvider client={apolloClient}>
        <CSAdminContainerInner handleLogout={this.handleLogout} />
      </ApolloProvider>
    );
  }
}

export default CSAdminContainer;
