import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import {
  SearchUsersQuery,
  useGetLocationUploadFileLazyQuery,
  UsersFilter,
  UsersFilterField,
  useSearchUsersLazyQuery,
  useSetLocationUploadFileMutation,
  useUploadUserLocationsFileMutation,
} from '../../../../__generated__/graphql';
import { getErrorFromGraphqlError } from '../../../../util/errors';
import DropdownButton from '../../../CommonComponents/DropdownButton';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type SnackbarTypes = 'success' | 'error';

const useStyles = makeStyles({
  container: {
    marginTop: '30px',
  },
});

const Header: React.FC = () => {
  const width = 100 / 7;
  return (
    <TableHead>
      <TableRow>
        <TableCell width={`${width}%`} align="left">
          Id
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          First Name
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Last Name
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Email
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Role
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Status
        </TableCell>
        <TableCell width={`${width}%`} align="left">
          Options
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const Users = () => {
  const classes = useStyles();
  const [filters, setFilters] = useState<{ byEmail: string; byLastName: string }>({
    byEmail: '',
    byLastName: '',
  });
  const [users, setUsers] = useState<SearchUsersQuery['searchUsers']>([]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [uploadResp, setUploadResp] = useState<{ type: SnackbarTypes; message: string }>({
    type: 'success',
    message: '',
  });

  const [setLocationUploadFile] = useSetLocationUploadFileMutation();
  const [getLocationUploadFileLazyQuery] = useGetLocationUploadFileLazyQuery();
  const [uploadUserLocationsFileMutation] = useUploadUserLocationsFileMutation();
  const [searchUsersLazyQuery, searchUsersLazyQueryResult] = useSearchUsersLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { data, loading, error } = searchUsersLazyQueryResult;

  useEffect(() => {
    if (data) {
      setUsers(data.searchUsers);
    }
  }, [data]);

  const showSnackbar = () => {
    setOpen(true);
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setUploadResp({ type: 'success', message: '' });
    setOpen(false);
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setFilters({ ...filters, [name]: value });
  };

  const onSearchUsers = () => {
    const filtersParam: Array<UsersFilter> = [
      { field: UsersFilterField.ByEmail, value: filters.byEmail },
      { field: UsersFilterField.ByLastName, value: filters.byLastName },
    ];
    (async () => {
      await searchUsersLazyQuery({ variables: { filters: filtersParam }, fetchPolicy: 'no-cache' });
    })();
  };

  const onOptionSelect = async (opt: string, userId: string, file?: File) => {
    switch (opt) {
      case 'upload_file':
        console.log('onOptionSelect');
        setLoader(true);
        setLocationUploadFile({
          variables: {
            file,
          },
        })
          .then((r) => {
            console.log('result', r);
            getLocationUploadFileLazyQuery()
              .then((f) => {
                console.log('new file', f);
                const {
                  data: { locationUploadFile },
                } = f;
                uploadUserLocationsFileMutation({
                  variables: {
                    file: locationUploadFile.file,
                    id: userId,
                  },
                })
                  .then((r) => {
                    console.log('result', r);
                    setLoader(false);
                    setUploadResp({ type: 'success', message: 'Upload Successful' });
                    showSnackbar();
                  })
                  .catch((e) => {
                    console.log('errorr', e);
                    setLoader(false);
                    setUploadResp({ type: 'error', message: 'Upload Failed' });
                    showSnackbar();
                  });
              })
              .catch((e) => {
                console.log('errorr', e);
              });
          })
          .catch((e) => {
            console.log('errorr', e);
          });
        break;
      default:
        break;
    }
  };

  const displayData = () => {
    return (
      <>
        {users.map((row) => {
          const opts = [{ key: 'upload_file', name: 'UploadFile', select_file: true }];

          return (
            <TableRow
              data-test-id="users-row"
              hover
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.personalInfo.firstName}</TableCell>
              <TableCell align="left">{row.personalInfo.lastName}</TableCell>
              <TableCell align="left">{row.personalInfo.contact.email}</TableCell>
              <TableCell align="left">{row.role}</TableCell>
              <TableCell align="left">{row.status}</TableCell>
              <TableCell align="left">
                <Button>
                  <DropdownButton
                    onSelect={async (opt: string, file?: File) => {
                      await onOptionSelect(opt, row.id, file);
                    }}
                    options={opts}
                  />
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  if (loading) {
    return <div data-test-id="users-loading">Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Typography data-test-id="UploadLocations-Heading" id="UploadLocations-Heading" variant="h6">
        Upload Locations file for a user. Search for the user, select and upload file.
      </Typography>
      <Typography
        data-test-id="UploadLocations-Description"
        id="UploadLocations-Description"
        variant="body1"
        sx={{ mb: 2.5 }}
      >
        Warning: All users are displayed below (Domino/Ready)
      </Typography>
      <Snackbar open={open} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={uploadResp.type} sx={{ width: '100%' }}>
          {uploadResp.message}
        </Alert>
      </Snackbar>
      <Paper sx={{ width: '1000px', overflow: 'hidden' }}>
        <Box sx={{ width: '100%', margin: '10px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={5}>
              <TextField
                id="email"
                label="Email"
                name="byEmail"
                fullWidth
                value={filters.byEmail}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: filters.byEmail ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'Email-Input',
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                id="lastName"
                label="Last Name"
                name="byLastName"
                fullWidth
                value={filters.byLastName}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: filters.byLastName ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'LastName-Input',
                }}
              />
            </Grid>
            <Grid item xs={2} style={{ paddingRight: '20px' }}>
              <FormControl
                fullWidth
                sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }}
                variant="standard"
              >
                <Button
                  disabled={loader}
                  variant="contained"
                  onClick={onSearchUsers}
                  data-test-id="Search-Btn"
                >
                  Search
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        {loader ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress sx={{ margin: '15px' }} />
          </Box>
        ) : (
          <TableContainer sx={{ maxHeight: 800, marginTop: '10px' }}>
            <Table stickyHeader aria-label="User Table" data-test-id="UserTable">
              <Header />
              <TableBody>{displayData()}</TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Container>
  );
};

Users.displayName = 'Users';
export default Users;
