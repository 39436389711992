import { InMemoryCache, InMemoryCacheConfig, makeVar, ReactiveVar } from '@apollo/client';

// When locsInViewportIdsVar is null, the "all locations" source layer has never been loaded,
// most likely because the Mapbox map is still initializing
export const locsInViewportIdsVar: ReactiveVar<Set<string>> = makeVar(null);

export const initializeLocsInViewportIds: () => void = () => {
  locsInViewportIdsVar(new Set());
};

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        locations: {
          merge: false,
        },
      },
    },
    FileNotice: {
      keyFields: ['id', 'label', 'lineNumber'],
    },
    ResilienceStats: {
      // Need to specify keyFields unless id is guaranteed unique for each sliceNum - locId combo
      keyFields: ['slice', 'id'],
    },
    ResilienceProduct: {
      merge: true,
      fields: {
        locationFile: {
          merge: false,
        },
      },
    },
    BuiltObject: {
      keyFields: ['id'],
      fields: {
        // Sometimes when getting a builtObject via search, the API returns null for its name.
        // To get around this, useGetLocOrBobj overwrites the builtobject's null name with the
        // location name from the Mapbox search result. Thus, we need a cache type policy to keep
        // a cached BuiltObject's non-null name (from the user's mapbox search) from getting
        // overwritten with null by susbsequent builtObject API calls.
        name: {
          merge(existing, incoming) {
            return !incoming ? existing : incoming;
          },
        },
        address: {
          merge: true,
        },
      },
    },
    LocationObject: {
      keyFields: ['id'],
      fields: {
        address: {
          merge: true,
        },
      },
    },
    Address: {
      fields: {
        countryCode: {
          read: (code) => code.toUpperCase(),
        },
      },
    },
  },
};

const cache = new InMemoryCache(cacheConfig);
export default cache;
