import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import { ErrorBoundary } from 'react-error-boundary';
import Users from '../../../Users';
import OrganizationData from './OrganizationData';
import {
  GetOrganizationQuery,
  useGetOrganizationAllDominoFeaturesLazyQuery,
  useGetOrganizationAllDominoFeaturesQuery,
  useGetOrganizationAllReadyFeaturesLazyQuery,
  useGetOrganizationAllReadyFeaturesQuery,
  useGetOrganizationDominoJpLazyQuery,
  useGetOrganizationDominoJpQuery,
  useGetOrganizationDominoUsLazyQuery,
  useGetOrganizationDominoUsQuery,
  useGetOrganizationLazyQuery,
  useGetOrganizationQuery,
} from '../../../../../__generated__/graphql';
import { getErrorFromGraphqlError } from '../../../../../util/errors';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  container: {
    marginTop: '30px',
  },
});

function ErrorFallback({ error }: { error: Error }): JSX.Element {
  return (
    <div role="alert">
      <div>Something went wrong:</div>
      <div>{error.message}</div>
    </div>
  );
}

const OrganizationDetails = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(0);
  const [org, setOrg] = useState<GetOrganizationQuery['getOrganization']>(null);

  const { id } = useParams();
  // TODO: Need cleanup
  const [getOrganizationsLazyQuery, getOrganizationLazyResult] = useGetOrganizationLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [getOrganizationDominoUsLazyQuery, getOrganizationDominoUsLazyResult] =
    useGetOrganizationDominoUsLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const [getOrganizationDominoJpLazyQuery, getOrganizationDominoJpLazyResult] =
    useGetOrganizationDominoJpLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const [getOrganizationAllDominoFeaturesLazyQuery, getOrganizationAllDominoFeaturesLazyResult] =
    useGetOrganizationAllDominoFeaturesLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const [getOrganizationAllReadyFeaturesLazyQuery, getOrganizationAllReadyFeaturesLazyResult] =
    useGetOrganizationAllReadyFeaturesLazyQuery({
      fetchPolicy: 'no-cache',
    });
  const { data, loading, error } = useGetOrganizationQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const {
    data: dataDominoUs,
    loading: loadingDominoUs,
    error: errorDominoUs,
  } = useGetOrganizationDominoUsQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const {
    data: dataDominoJp,
    loading: loadingDominoJp,
    error: errorDominoJp,
  } = useGetOrganizationDominoJpQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const {
    data: dataAllDominoFeature,
    loading: loadingAllDominoFeature,
    error: errorAllDominoFeature,
  } = useGetOrganizationAllDominoFeaturesQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const {
    data: dataAllReadyFeature,
    loading: loadingAllReadyFeature,
    error: errorAllReadyFeature,
  } = useGetOrganizationAllReadyFeaturesQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const { data: lazyData, loading: lazyLoading, error: lazyError } = getOrganizationLazyResult;
  const {
    data: lazyDataDominoUs,
    loading: lazyLoadingDominoUs,
    error: lazyErrorDominoUs,
  } = getOrganizationDominoUsLazyResult;
  const {
    data: lazyDataDominoJp,
    loading: lazyLoadingDominoJp,
    error: lazyErrorDominoJp,
  } = getOrganizationDominoJpLazyResult;
  const {
    data: lazyDataAllDominoFeature,
    loading: lazyLoadingAllDominoFeature,
    error: lazyErrorAllDominoFeature,
  } = getOrganizationAllDominoFeaturesLazyResult;
  const {
    data: lazyDataAllReadyFeature,
    loading: lazyLoadingAllReadyFeature,
    error: lazyErrorAllReadyFeature,
  } = getOrganizationAllReadyFeaturesLazyResult;

  useEffect(() => {
    const newDataRes = lazyData || data;
    const newDataDominoUsRes = lazyDataDominoUs || dataDominoUs;
    const newDataDominoJpRes = lazyDataDominoJp || dataDominoJp;
    const newDataAllDominoFeature = lazyDataAllDominoFeature || dataAllDominoFeature;
    const newDataAllReadyFeature = lazyDataAllReadyFeature || dataAllReadyFeature;
    if (
      newDataRes &&
      newDataDominoUsRes &&
      newDataDominoJpRes &&
      newDataAllDominoFeature &&
      newDataAllReadyFeature
    ) {
      const entitlements: GetOrganizationQuery['getOrganization']['entitlements'] = {
        scopes: [],
        enabledProducts: [],
        enabledProductsModule: [],
        hasHazardsForProduct: [],
        hasFeaturesForProduct: [],
      };
      if (newDataRes.getOrganization.entitlements) {
        entitlements.scopes = [
          ...entitlements.scopes,
          ...newDataRes.getOrganization.entitlements.scopes,
        ];
        entitlements.enabledProducts = [
          ...entitlements.enabledProducts,
          ...newDataRes.getOrganization.entitlements.enabledProducts,
        ];
        entitlements.enabledProductsModule = [
          ...entitlements.enabledProductsModule,
          ...newDataRes.getOrganization.entitlements.enabledProductsModule,
        ];
        entitlements.hasHazardsForProduct = [
          ...entitlements.hasHazardsForProduct,
          ...newDataRes.getOrganization.entitlements.hasHazardsForProduct,
        ];
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataRes.getOrganization.entitlements.hasFeaturesForProduct,
        ];
      }
      if (newDataDominoUsRes.getOrganization.entitlements) {
        entitlements.hasHazardsForProduct = [
          ...entitlements.hasHazardsForProduct,
          ...newDataDominoUsRes.getOrganization.entitlements.hasHazardsForProduct,
        ];
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataDominoUsRes.getOrganization.entitlements.hasFeaturesForProduct,
        ];
      }
      if (newDataDominoJpRes.getOrganization.entitlements) {
        entitlements.hasHazardsForProduct = [
          ...entitlements.hasHazardsForProduct,
          ...newDataDominoJpRes.getOrganization.entitlements.hasHazardsForProduct,
        ];
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataDominoJpRes.getOrganization.entitlements.hasFeaturesForProduct,
        ];
      }

      if (newDataAllDominoFeature.getOrganization.entitlements) {
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataAllDominoFeature.getOrganization.entitlements.hasFeaturesForProduct.filter(
            (f) => !f.hazard,
          ),
        ];
      }

      if (newDataAllReadyFeature.getOrganization.entitlements) {
        entitlements.hasFeaturesForProduct = [
          ...entitlements.hasFeaturesForProduct,
          ...newDataAllReadyFeature.getOrganization.entitlements.hasFeaturesForProduct.filter(
            (f) => !f.hazard,
          ),
        ];
      }

      const newOrg = {
        ...newDataRes.getOrganization,
        entitlements,
      };
      console.log('called');
      setOrg(newOrg);
    }
  }, [
    data,
    dataDominoUs,
    dataDominoJp,
    dataAllDominoFeature,
    dataAllReadyFeature,
    lazyData,
    lazyDataDominoUs,
    lazyDataDominoJp,
    lazyDataAllDominoFeature,
    lazyDataAllReadyFeature,
  ]);
  // }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const onUpdateOrgData = () => {
    (async () => {
      await getOrganizationsLazyQuery({ variables: { id: org.id }, fetchPolicy: 'no-cache' });
      await getOrganizationDominoUsLazyQuery({
        variables: { id: org.id },
        fetchPolicy: 'no-cache',
      });
      await getOrganizationDominoJpLazyQuery({
        variables: { id: org.id },
        fetchPolicy: 'no-cache',
      });
      await getOrganizationAllDominoFeaturesLazyQuery({
        variables: { id: org.id },
        fetchPolicy: 'no-cache',
      });
      await getOrganizationAllReadyFeaturesLazyQuery({
        variables: { id: org.id },
        fetchPolicy: 'no-cache',
      });
    })();
  };

  if (
    loading ||
    loadingDominoUs ||
    loadingDominoJp ||
    loadingAllDominoFeature ||
    loadingAllReadyFeature ||
    lazyLoading ||
    lazyLoadingDominoUs ||
    lazyLoadingDominoJp ||
    lazyLoadingAllDominoFeature ||
    lazyLoadingAllReadyFeature
  ) {
    return <div>Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  const convertedDominoUsError = getErrorFromGraphqlError(errorDominoUs);
  const convertedDominoJpError = getErrorFromGraphqlError(errorDominoJp);
  const convertedAllDominoFeatureError = getErrorFromGraphqlError(errorAllDominoFeature);
  const convertedAllReadyFeatureError = getErrorFromGraphqlError(errorAllReadyFeature);
  const convertedLazyError = getErrorFromGraphqlError(lazyError);
  const convertedLazyErrorDominoUs = getErrorFromGraphqlError(lazyErrorDominoUs);
  const convertedLazyErrorDominoJp = getErrorFromGraphqlError(lazyErrorDominoJp);
  const convertedLazyErrorAllDominoFeature = getErrorFromGraphqlError(lazyErrorAllDominoFeature);
  const convertedLazyErrorAllReadyFeature = getErrorFromGraphqlError(lazyErrorAllReadyFeature);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }
  if (convertedDominoUsError) {
    return <div>{convertedDominoUsError}</div>;
  }
  if (convertedDominoJpError) {
    return <div>{convertedDominoJpError}</div>;
  }
  if (convertedAllDominoFeatureError) {
    return <div>{convertedAllDominoFeatureError}</div>;
  }
  if (convertedAllReadyFeatureError) {
    return <div>{convertedAllReadyFeatureError}</div>;
  }
  if (convertedLazyError) {
    return <div>{convertedLazyError}</div>;
  }
  if (convertedLazyErrorDominoUs) {
    return <div>{convertedLazyErrorDominoUs}</div>;
  }
  if (convertedLazyErrorDominoJp) {
    return <div>{convertedLazyErrorDominoJp}</div>;
  }
  if (convertedLazyErrorAllDominoFeature) {
    return <div>{convertedLazyErrorAllDominoFeature}</div>;
  }
  if (convertedLazyErrorAllReadyFeature) {
    return <div>{convertedLazyErrorAllReadyFeature}</div>;
  }

  if (!org) {
    return null;
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container justifyContent="flex-end">
        <IconButton onClick={() => navigate(-1)}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Org Details" {...a11yProps(0)} />
            <Tab data-test-id="organization-details-tab-users" label="Users" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <TabPanel value={tab} index={0}>
            <OrganizationData
              data-test-id="organization-details-data"
              org={org}
              onUpdateOrgData={onUpdateOrgData}
            />
          </TabPanel>
        </ErrorBoundary>

        <TabPanel value={tab} index={1}>
          <Users
            data-test-id="organization-details-users"
            org={org}
            onUpdateOrgData={onUpdateOrgData}
          />
        </TabPanel>
      </Box>
    </Container>
  );
};

OrganizationDetails.displayName = 'OrganizationDetails';
export default OrganizationDetails;
