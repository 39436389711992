/* eslint-disable max-classes-per-file */
import url from 'url-parse';
// eslint-disable-next-line import/no-cycle
import { Admin } from './admin'; // get keycloak admin toolbox

import { AuthError, BadInputErr } from './AuthError';

// AuthContext bundles the whole authN/authZ context to be used by Authable components.
//
// You may register a handler for state management.
//
// Apps don't have to, and anyway should not serialize this to local storage, as userinfo
// context is refreshed from existing credentials (cookies) when components are reinitialized
// (e.g. the app is reloaded).
//
// Please note that the csrfToken is a sensitive information that at any rate,
// MUST NOT be serialized
// to local storage. This will get refreshed on demand anyway. Notice that past csrf tokens emitted
// remain valid for the duration of the session so these should not leak outside of the component.
//
// For cases some local storage (e.g. redux or the like) is needed, please use the filterSensitive()
// method to remove CSRF token from the object.
interface AuthConfig {
  [key: string]: any;
}

type AuthContextProps = {
  config: AuthConfig;
};

type CsrfContextProps = {
  csrfTokenHeader: string;
  csrfToken?: string;
};
class CsrfContext {
  csrfTokenHeader: string;

  csrfToken: string;

  constructor(props?: CsrfContextProps) {
    this.csrfTokenHeader = undefined;
    this.csrfToken = undefined;
    Object.assign(
      this,
      {
        csrfToken: '',
        csrfTokenHeader: 'x-csrf-token',
      },
      props,
    );
  }
}

class AuthInfo {
  impersonator: any;

  isLogged: boolean;

  userinfo: any;

  constructor(props?: {
    didLogout?: boolean;
    userinfo?: any;
    isLogged?: boolean;
    impersonator?: any;
  }) {
    Object.assign(
      this,
      {
        isLogged: false,
        userinfo: {},
        impersonator: {},
        didLogout: false,
      },
      props,
    );

    try {
      const data = JSON.parse(sessionStorage.getItem('impersonator'));
      if (data) {
        this.impersonator = data;
      }
    } catch (e) {
      sessionStorage.removeItem('impersonator');
    }
  }

  // withCurrentAsImpersonator clones current userinfo as impersonator info.
  // This is used to display information about the original user impersonating the
  // current session.
  // TODO: currently, this does not survice a reload... (not persisted)
  withCurrentAsImpersonator() {
    this.impersonator = JSON.parse(JSON.stringify(this.userinfo));
    sessionStorage.setItem('impersonator', JSON.stringify(this.impersonator));
    return this;
  }

  // clearImpersonator removes impersonator information
  clearImpersonator() {
    this.impersonator = {};
    sessionStorage.removeItem('impersonator');
    return this;
  }

  // returns all claims in the current user token
  getClaims() {
    return this.userinfo;
  }
}
class AuthContext {
  // eslint-disable-next-line @typescript-eslint/ban-types
  stateHandler: Function;

  config: AuthConfig;

  authInfo: AuthInfo;

  csrf: CsrfContext;

  constructor(props: AuthContextProps) {
    this.isLogged = this.isLogged.bind(this);
    this.onCsrfChange = this.onCsrfChange.bind(this);
    this.onAuthInfoChange = this.onAuthInfoChange.bind(this);
    this.onError = this.onError.bind(this);
    this.getCsrfToken = this.getCsrfToken.bind(this);
    this.getCsrfTokenHeader = this.getCsrfTokenHeader.bind(this);
    this.getUserinfo = this.getUserinfo.bind(this);
    this.getAuthInfo = this.getAuthInfo.bind(this);
    this.registerStateHandler = this.registerStateHandler.bind(this);
    this.ensureCsrf = this.ensureCsrf.bind(this);

    this.stateHandler = null;
    this.config = undefined;

    Object.assign(
      this,
      {
        config: new AuthConfig(),
        authInfo: new AuthInfo(),
        csrf: new CsrfContext(),
        onCsrfChange: this.onCsrfChange,
        onAuthInfoChange: this.onAuthInfoChange,
        onError: this.onError,
      },
      props,
    );
  }

  // registerStateHandler registers an external state handler for this context
  // TODO: put in here the required parts - actual handler should only have
  // to deal with custom state
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerStateHandler(handler: Function) {
    if (!(typeof handler === 'function')) {
      throw new BadInputErr('expected handler arg to be a function function({AuthContext})');
    }
    this.stateHandler = handler;
  }

  isLogged() {
    return this.authInfo.isLogged;
  }

  // getUserinfo returns the userinfo claims in AuthInfo object from the context
  getUserinfo() {
    return this.authInfo.userinfo;
  }

  // getAuthInfo returns a AuthInfo object from the context
  getAuthInfo() {
    return this.authInfo;
  }

  // ensureCsrf hydrates the CSRF token
  ensureCsrf() {
    const csrfAdmin = new Admin(this.config);
    csrfAdmin.setOnCsrfChange(this.onCsrfChange);
    return csrfAdmin.ensureCsrf();
  }

  // onCsrfChange is the default handler for CSRF state change.
  // This may be overridden.
  onCsrfChange(csrfCtx: CsrfContext | string) {
    if (!(csrfCtx instanceof CsrfContext || typeof csrfCtx === 'string')) {
      throw new BadInputErr('expected arg {CsrfContext|string}');
    }
    if (typeof csrfCtx === 'string') {
      this.csrf.csrfToken = csrfCtx;
    } else {
      this.csrf = csrfCtx;
    }
    if (this.stateHandler) {
      this.stateHandler(this);
    }
  }

  // onAuthInfoChange is the default handler for AuthInfo state change.
  // This may be overridden.
  onAuthInfoChange(authinfoCtx: AuthInfo) {
    if (!(authinfoCtx instanceof AuthInfo)) {
      throw new BadInputErr('expected arg {AuthInfo}');
    }
    this.authInfo = authinfoCtx;
    if (this.stateHandler) {
      this.stateHandler(this);
    }
  }

  // onError is the default error handler for context.
  // It handles 401 HTTP errors to trigger. This may be overridden.
  onError(e: { hasUnauthorized: () => void }) {
    if (e instanceof AuthError && e.hasUnauthorized()) {
      // resets the login state, activate state change handlers then
      // let outside components deal with this
      this.onAuthInfoChange(new AuthInfo());
      return;
    }
    // eslint-disable-next-line consistent-return
    return Promise.reject(e);
  }

  getCsrfToken() {
    return this.csrf.csrfToken;
  }

  getCsrfTokenHeader() {
    return this.csrf.csrfTokenHeader;
  }
}

type AuthConfigProps = {
  loginURL: string;
  homeURL: string;
  clientID: string;
  cookieDomain: string;
};

// AuthConfig represents a static data model for Login, Logout and Userinfo configuration.
class AuthConfig {
  loginURL: string;

  homeURL: string;

  logoutURL: string;

  tokenURL: string;

  adminURL: string;

  authApiURL: string;

  cookieDomain: string;

  implicitAuthURL: string;

  impersonateURL: string;

  loginStatusURL: string;

  logoutKeycloakURL: string;

  signInConfirmationURL: string;

  constructor(props?: AuthConfigProps) {
    Object.assign(
      this,
      {
        loginURL: '',
        logoutURL: '',
        tokenURL: '',
        signInConfirmationURL: '',
        csrfTokenHeader: 'x-csrf-token',
        cookieDomain: '',
        csrfToken: '',
        callbackPath: '/oauth/callback',
        adminURL: '',
        authApiURL: '',
        homeURL: '',
        consoleURL: '',
        implicitAuthURL: '',
        impersonateURL: '',
        loginStatusURL: '',
        logoutKeycloakURL: '',
        clientID: '',
        loglevel: 'debug',
        failOnError: true, // dev mode: exceptions crash component

        // TODO(fredbi): need here to build custom fined-grained roles
        rolesToImpersonate: ['realm-management:impersonation'],
      },
      props,
    );

    if (!props) {
      // transitory construct
      return;
    }

    // deduce props from minimal config
    if (
      typeof this.loginURL !== 'string' ||
      this.loginURL.length === 0 ||
      typeof this.homeURL !== 'string' ||
      this.homeURL.length === 0
    ) {
      // return;
      throw new BadInputErr('expected config to contain at least loginURL, homeURL');
    }

    // "https://apis.oneconcern-test.com:4455/oauth/authorize"
    const oauthURL = url(this.loginURL);
    // [ '', 'oauth', 'authorize' ]
    const splitPath = oauthURL.pathname.split('/');
    // [ '', 'oauth' ]
    const oauthBasePath = splitPath.slice(0, splitPath.length - 1);

    if (typeof this.logoutURL !== 'string' || this.logoutURL.length === 0) {
      // "https://apis.oneconcern-test.com:4455/oauth/logout"
      const path = oauthBasePath.concat('logout');
      this.logoutURL = oauthURL.set('pathname', path.join('/')).toString();
    }
    if (typeof this.tokenURL !== 'string' || this.tokenURL.length === 0) {
      // "https://apis.oneconcern-test.com:4455/oauth/token"
      const path = oauthBasePath.concat('token');
      this.tokenURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.adminURL !== 'string' || this.adminURL.length === 0) {
      // "https://apis.oneconcern-test.com:4455/keycloak/auth/admin/realms/oneconcern"
      const path = ['keycloak', 'auth', 'admin', 'realms', 'oneconcern'];
      this.adminURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.authApiURL !== 'string' || this.authApiURL.length === 0) {
      // "https://apis.oneconcern-test.com:4455/keycloak/auth/realms/oneconcern"
      const path = ['keycloak', 'auth', 'realms', 'oneconcern'];
      this.authApiURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.cookieDomain !== 'string' || this.cookieDomain.length === 0) {
      // "{PRODUCT}.oneconcern-test.com"
      this.cookieDomain = oauthURL.hostname;
    }

    if (typeof this.implicitAuthURL !== 'string' || this.implicitAuthURL.length === 0) {
      // "https://oauth2.oneconcern-test.com:8443/auth/realms/oneconcern/protocol/openid-connect/auth"
      const path = [oauthBasePath[1]].concat(
        'realms',
        'oneconcern',
        'protocol',
        'openid-connect',
        'auth',
      );
      this.implicitAuthURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.impersonateURL !== 'string' || this.impersonateURL.length === 0) {
      // "https://oauth2.oneconcern-test.com:8443/auth/admin/realms/oneconcern/users"
      const path = [oauthBasePath[1]].concat('admin', 'realms', 'oneconcern', 'users');
      this.impersonateURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.loginStatusURL !== 'string' || this.loginStatusURL.length === 0) {
      // "https://oauth2.oneconcern-test.com:8443/auth/realms/oneconcern/protocol/openid-connect/login-status-iframe.html"
      const path = [oauthBasePath[1]].concat(
        'realms',
        'oneconcern',
        'protocol',
        'openid-connect',
        'login-status-iframe.html',
      );

      this.loginStatusURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.logoutKeycloakURL !== 'string' || this.logoutKeycloakURL.length === 0) {
      // "https://oauth2.oneconcern-test.com:8443/auth/realms/oneconcern/protocol/openid-connect/logout"
      const path = [oauthBasePath[1]].concat(
        'realms',
        'oneconcern',
        'protocol',
        'openid-connect',
        'logout',
      );
      this.logoutKeycloakURL = oauthURL.set('pathname', path.join('/')).toString();
    }

    if (typeof this.signInConfirmationURL !== 'string' || this.signInConfirmationURL.length === 0) {
      // "https://app-react.oneconcern-test.com:3000/signed.html"
      const path = ['signed.html'];
      this.signInConfirmationURL = url(this.homeURL).set('pathname', path.join('/')).toString();
    }
  }

  get(key: string) {
    return this[key] || '';
  }
}

// AuthInfo represents a state data model for Login, Logout and Userinfo components.

export { AuthContext, AuthConfig, AuthInfo, CsrfContext };
