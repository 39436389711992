import * as React from 'react';
import { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

type DatePickerProps = {
  id?: string;
  label: string;
  value: Dayjs | null;
  handleChange: (newValue: Dayjs | null) => void;
};

const DatePicker: React.FC<DatePickerProps> = ({
  id,
  label,
  value,
  handleChange,
  // including data-test-id, etc.
  ...otherProps
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        inputFormat="MM/DD/YYYY"
        value={value}
        onChange={handleChange}
        renderInput={(params: any) => <TextField {...params} id={id} {...otherProps} />}
      />
    </LocalizationProvider>
  );
};

DatePicker.displayName = 'DatePicker';
export default DatePicker;
