import React, { useEffect, useState } from 'react';
import UsersList from './UsersList';
import UserDetails from './UserDetails';
import {
  GetOrganizationQuery,
  LanguagePreferences,
  MeasurementPreferences,
  NotificationsChannel,
} from '../../../__generated__/graphql';

type UserProps = {
  id: string;
  title?: string;
  status: string;
  role: string;
  personalInfo: {
    firstName: string;
    lastName?: string;
    contact?: { email?: string; phoneNumber?: string };
  };
  preferences?: {
    country?: string;
    language: LanguagePreferences;
    timezone?: string;
    units: MeasurementPreferences;
    notificationsEnabled?: boolean;
    notificationsChannels?: Array<NotificationsChannel>;
  };
};
type UsersProps = {
  org: GetOrganizationQuery['getOrganization'];
  onUpdateOrgData: () => void;
};
type UsersState = { view: string; userIdDetails: string; users: Array<UserProps> };

const Users: React.FC<UsersProps> = ({ org, onUpdateOrgData }) => {
  const [state, setState] = useState({
    view: 'usersList',
    userIdDetails: '',
    users: null,
  });

  useEffect(() => {
    if (org?.users) {
      setState((s) => ({ ...s, users: org.users }));
    }
  }, [org?.users]);

  const handleState = (newState: UsersState) => {
    setState({ ...state, ...newState });
  };

  if (!state.users) {
    return null;
  }

  return (
    <div>
      {state.view === 'usersList' && (
        <UsersList
          users={state.users}
          handleState={handleState}
          organization={org}
          onUpdateOrgData={onUpdateOrgData}
        />
      )}
      {state.view === 'userDetails' && (
        <UserDetails
          userId={state.userIdDetails}
          handleState={handleState}
          onUpdateOrgData={onUpdateOrgData}
          org={org}
        />
      )}
    </div>
  );
};
Users.displayName = 'Users';
export default Users;
