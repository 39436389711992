import {
  GetOrganizationQuery,
  InputMaybe,
  InviteProfileInput,
  InviteUserInput,
  LanguagePreferences,
  MeasurementPreferences,
  NotificationsChannel,
  Product,
  UpdateUserMutationVariables,
  UserRole,
} from '../../../../__generated__/graphql';
import { INHERITED_DEFAULT_VALUE } from '../../constants';

type UserDetailsState = {
  id: string;
  title: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  phoneNumber: string;
  country: string;
  language: string;
  timezone: string;
  units: string;
  notificationsEnabled: string;
  notificationsChannels: Array<string>;
  enable: boolean;
};

type InviteUserState = {
  email: string;
  firstName: string;
  lastName: string;
  product: Product;
  language: LanguagePreferences;
  role: UserRole;
};

type InviteUserPayload = {
  invite: InviteUserInput;
  profile?: InputMaybe<InviteProfileInput>;
};

const getUserDetailsPayload = (currentUser: UserDetailsState): UpdateUserMutationVariables => {
  console.log('newUser', currentUser);
  let language;
  switch (currentUser.language) {
    case LanguagePreferences.En.toString():
      language = LanguagePreferences.En;
      break;
    case LanguagePreferences.Ja.toString():
      language = LanguagePreferences.Ja;
      break;
    case LanguagePreferences.De.toString():
      language = LanguagePreferences.De;
      break;
    default:
      language = null;
  }
  let notificationsEnabled;
  switch (currentUser.notificationsEnabled) {
    case 'true':
      notificationsEnabled = true;
      break;
    case 'false':
      notificationsEnabled = false;
      break;
    default:
      notificationsEnabled = null;
  }
  const notificationsChannels: Array<NotificationsChannel> = [];
  if (notificationsEnabled) {
    const removeChannels = currentUser.notificationsChannels.find(
      (v) => v === INHERITED_DEFAULT_VALUE,
    );
    if (!removeChannels) {
      currentUser.notificationsChannels.forEach((n) => {
        let notification = null;
        switch (n) {
          case NotificationsChannel.Email.toString():
            notification = NotificationsChannel.Email;
            break;
          default:
            break;
        }
        if (notification) {
          notificationsChannels.push(notification);
        }
      });
    }
  }
  let units;
  switch (currentUser.units) {
    case MeasurementPreferences.MetricSystem.toString():
      units = MeasurementPreferences.MetricSystem;
      break;
    case MeasurementPreferences.ImperialSystem.toString():
      units = MeasurementPreferences.ImperialSystem;
      break;
    default:
      units = null;
  }
  let role;
  switch (currentUser.role) {
    case UserRole.Admin.toString():
      role = UserRole.Admin;
      break;
    case UserRole.Contributor.toString():
      role = UserRole.Contributor;
      break;
    case UserRole.Viewer.toString():
      role = UserRole.Viewer;
      break;
    default:
      role = null;
  }

  const payload = {
    id: currentUser.id,
    userInput: {
      personalInfo: {
        firstName: currentUser.firstName,
        lastName: currentUser.lastName,
        contact: {
          email: currentUser.email,
          phoneNumber: currentUser.phoneNumber,
        },
      },
      title: currentUser.title,
      role,
      preferences: {
        country: currentUser.country === INHERITED_DEFAULT_VALUE ? null : currentUser.country,
        language,
        timezone: currentUser.timezone === INHERITED_DEFAULT_VALUE ? null : currentUser.timezone,
        units,
        notificationsEnabled,
        notificationsChannels,
      },
    },
  };
  return payload;
};

const getInviteUserPayload = (
  data: InviteUserState,
  organization: GetOrganizationQuery['getOrganization'],
): InviteUserPayload => {
  const payload: InviteUserPayload = {
    invite: {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      role: data.role,
      preferences: {
        language: data.language,
      },
    },
    profile: {
      // product: data.product,
      organization: organization.id,
    },
  };
  // if (payload.invite.role === UserRole.Viewer) {
  //   let redirectUrl;
  //   if (window.location.hostname.includes('dev')) {
  //     redirectUrl = 'https://domino.dev.onec.co/';
  //   } else if (window.location.hostname.includes('staging')) {
  //     redirectUrl = 'https://domino.staging.onec.co/';
  //   } else if (window.location.hostname.includes('domino.oneconcern')) {
  //     redirectUrl = 'https://domino.oneconcern.com/';
  //   }
  //   payload.invite.viewerInput = { redirectUrl };
  // }
  return payload;
};

export { getUserDetailsPayload, getInviteUserPayload };
