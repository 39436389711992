import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import {
  useChangeUserOrganizationMutation,
  useGetOrganizationsQuery,
} from '../../../../../__generated__/graphql';
import { getErrorFromGraphqlError } from '../../../../../util/errors';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

type ChangeOrgModalProps = {
  userId: string;
  currentOrganization: string;
  isOpen: boolean;
  onSave: () => void;
  onCancel: () => void;
};

type ChangeOrgModalState = {
  targetOrganization: { id: string; name: string };
};

const initialState = {
  targetOrganization: {},
};

const ChangeOrgModal: React.FC<ChangeOrgModalProps> = ({
  isOpen,
  userId,
  currentOrganization,
  onSave,
  onCancel,
}) => {
  const [orgs, setOrgs] = useState<Array<{ id: string; name: string }>>([]);
  const [state, setState] = React.useState<ChangeOrgModalState>(
    JSON.parse(JSON.stringify(initialState)),
  );
  const [displayError, setDisplayError] = React.useState(null);
  const [changeUserOrganizationMutation] = useChangeUserOrganizationMutation();

  const { data, loading, error } = useGetOrganizationsQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (data) {
      const newOrgs = data.getOrganizations.map((o) => ({ id: o.id, name: o.name }));
      setState({ targetOrganization: newOrgs[0] });
      setOrgs(newOrgs);
    }
    // }, []);
  }, [data]);

  const handleClose = () => {
    setState(JSON.parse(JSON.stringify(initialState)));
  };

  const onAutocompleteChange = (name: string, value: string | { id: string; name: string }) => {
    setState({ ...state, [name]: value });
  };

  const onSubmit = () => {
    const payload = {
      userId,
      currentOrganizationId: currentOrganization,
      targetOrganizationId: state.targetOrganization.id,
    };
    console.log('newPayload', payload);

    changeUserOrganizationMutation({ variables: payload })
      .then(() => {
        handleClose();
        onSave();
      })
      .catch((e) => {
        console.log('newChangeUserOrgError', e.graphQLErrors[0]);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError(convertedError);
      });
  };

  if (loading) {
    return <div data-test-id="organizations-loading">Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Change Organization
          </Typography>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }}
                variant="standard"
              >
                <Autocomplete
                  id="organizations-autocomplete-search"
                  data-test-id="Organizations-Autocomplete-Search"
                  options={orgs}
                  freeSolo
                  getOptionLabel={(option: { id: string; name: string }) => option.name}
                  renderTags={(value: Array<{ id: string; name: string }>, getTagProps) =>
                    value.map((option: { id: string; name: string }, index: number) => (
                      <Chip variant="outlined" label={option.name} {...getTagProps({ index })} />
                    ))
                  }
                  value={state.targetOrganization}
                  onChange={(e, value) => onAutocompleteChange('targetOrganization', value)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Organizations"
                      placeholder="Select Organizations"
                      InputLabelProps={{
                        style: { color: state.targetOrganization ? null : '#D0D0D0' },
                      }}
                      inputProps={{
                        ...params.inputProps,
                        'data-test-id': 'Organizations-Input',
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => {
                handleClose();
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Change Organization
            </Button>
          </Stack>
          {displayError ? (
            <InputLabel sx={{ marginTop: '10px', color: 'red' }}>{displayError}</InputLabel>
          ) : null}
        </Box>
      </Modal>
    </div>
  );
};

ChangeOrgModal.displayName = 'ChangeOrgModal';
export default ChangeOrgModal;
