import * as React from 'react';
import { Translation } from 'react-i18next';
import { Admin } from './admin'; // get keycloak admin toolbox
import { AuthContext, AuthInfo } from './Authorizer';
import { Authable } from './Authable';
import { IframeBadLoginErr, IframeNonceErr, PropRequiredErr, UserinfoErr } from './AuthError';

export interface LoginStyleProps {
  height: string;
  width: string;
  position: string;
  top: string;
  left: string;
  zIndex: number;
  border: string;
}

export interface LoginProps {
  authcontext: AuthContext;
  className: string;
  title: string;
  style: LoginStyleProps;
  onChange: (e: any) => void;
}

export class Login extends Authable {
  admin: Admin;

  static defaultProps: LoginProps = {
    authcontext: null,
    onChange: null,
    title: 'signIn',
    className: '',
    style: {
      height: '100%',
      width: '100%',
      position: 'absolute',
      top: '0px',
      left: '0px',
      zIndex: 5,
      border: 'none',
    },
  };

  static displayName = 'Login';

  constructor(props: LoginProps) {
    super(props);
    const newNonce = Login.getNonce();
    this.state = {
      nonce: newNonce,
    };

    if (this.props.onChange) {
      if (!(typeof this.props.onChange === 'function')) {
        throw new PropRequiredErr('onChange={function(Object)}');
      }
    }

    this.admin = new Admin(this.props.authcontext.config);
    this.admin.setOnCsrfChange(this.props.authcontext.onCsrfChange);

    // this is a client initiated cookie telling
    // the API gateway where to redirect after login has completed
    this.admin.clearClientCookie();

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.handleLoginFrame = this.handleLoginFrame.bind(this);
    // this.inheritProps = this.inheritProps.bind(this);
    this.fail = this.fail.bind(this);
  }

  // getNonce is a helper function to generate a random numeric string.
  // Verifying nonce ensures that the iframe - parent communication channel (js messaging)
  // has not been tampered with.
  static getNonce() {
    return Admin.getNonce();
  }

  componentDidMount(): void {
    window.addEventListener('message', this.handleLoginFrame, false);
  }

  componentWillUnmount(): void {
    window.removeEventListener('message', this.handleLoginFrame);
  }

  handleLoginFrame(evt: MessageEvent) {
    if (!evt.data.signed) {
      // bubbled message from another frame: skip it
      return null;
    }

    if (evt.data.nonce === this.state.nonce && !evt.data.error) {
      // ok, end up the handshake:
      // check nonce from child iframe
      // so we have an all-round state check, covering the iframe child-parent communication channel
      return this.admin
        .userinfo()
        .then((userinfo) => {
          this.admin.clearClientCookie();
          const newAuthinfo = new AuthInfo({
            userinfo,
            isLogged: true,
            // add possible impersonator
            // TODO jake has not been tested
            impersonator: this.props.authcontext.getAuthInfo().impersonator,
          });
          this.setState({ failed: false, error: null });
          return newAuthinfo;
        })
        .then((newAuthinfo) => {
          // notify external state handler of the new userinfo
          this.props.authcontext.onAuthInfoChange(newAuthinfo);
          return newAuthinfo;
        })
        .then((newAuthinfo) => {
          // optional callback
          if (this.props.onChange) {
            this.props.onChange(newAuthinfo);
          }
        })
        .catch((e) => {
          return this.fail(new UserinfoErr(e));
        });
    }
    if (evt.data.error) {
      return this.fail(new IframeNonceErr(evt.data.error));
    }
    return this.fail(new IframeBadLoginErr('while trying to login'));
  }

  // render displays the login iframe
  render(): React.ReactNode {
    // does not return anything but a possible exception
    super.render();
    if (this.failed()) {
      return null;
    }

    // NOTE(fredbi) keycloak gatekeeper does not currently check the value of the state
    // but nonetheless this value must be non-empty. Hence the 'state=none' value.
    //
    // This is a todo in keycloak-gatekeeper.
    // For the record, the state SHOULD be checked, with some secret random value,
    // to ensure that the sequence of redirects has not been tampered with.
    // At this moment, gatekeeper checks session_state param instead.
    //
    const loginWithState = `${this.props.authcontext.config.get('loginURL')}?state=none`;

    // now we need to pass this cookie to the login url in the below iframe.
    // The domain is the api gateway.
    // This cookie is sent exclusively to the gateway's callback entrypoint, hence the path
    // add here a nonce parameter (a random string) to be checked when called back
    this.admin.makeClientCookie(this.state.nonce);

    const style = {
      top: this.props.top,
      left: this.props.left,
      height: this.props.height,
      width: this.props.width,
      position: this.props.position,
      zIndex: this.props.zindex,
      ...this.props.style,
    };

    // We restrict the iframe with sandboxing, but with extreme care:
    // - scripts must be allowed (a js runs to signal the parent to close)
    // - form must be allowed
    // NOTE: this component from react-iframe package
    // exhibits a warning in strict mode because of deprecated refs
    // TODO: best to make our own version of this
    return (
      <Translation>
        {(t) => (
          <div title={t(this.props.title)} style={style}>
            <iframe
              id="login-iframe"
              className={this.props.className}
              src={loginWithState}
              sandbox="allow-forms allow-same-origin allow-scripts"
              frameBorder="none"
              style={style}
              title={t(this.props.title)}
            />
          </div>
        )}
      </Translation>
    );
  }
}
