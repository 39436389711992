import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import { mibRequestStatuses } from '../../constants';
import Dropdown from '../../../CommonComponents/Form/Dropdown';
import { MibRequestStatus, useUpdateMibRequestsMutation } from '../../../../__generated__/graphql';
import { getErrorFromGraphqlError, updateMIBRequestError } from '../../../../util/errors';

type Request = {
  id?: string;
  externalID?: string;
  externalUserID: string;
  fileID?: string;
  lineNumber?: number;
  metadata: any;
  postedAt: any;
  updatedAt: any;
  status: MibRequestStatus;
  address?: {
    city?: string;
    country?: string;
    countryCode?: string;
    county?: string;
    formattedAddress: string;
    houseNumber?: string;
    postCode?: string;
    state?: string;
    stateCode?: string;
    stateDistrict?: string;
    street?: string;
    suburb?: string;
  };
};
type RequestDetailsState = {
  id?: string;
  externalID?: string;
  externalUserID: string;
  fileID?: string;
  lineNumber?: number;
  metadata: any;
  postedAt: any;
  updatedAt: any;
  status: MibRequestStatus;
  city?: string;
  country?: string;
  countryCode?: string;
  county?: string;
  formattedAddress: string;
  houseNumber?: string;
  postCode?: string;
  state?: string;
  stateCode?: string;
  stateDistrict?: string;
  street?: string;
  suburb?: string;
};

type RequestsState = { view: string; requestDetails: Request };
type RequestDetails = {
  request: Request;
  handleState: (handleState: RequestsState) => void;
  onUpdateRequestsData: () => void;
};

const RequestDetails: React.FC<RequestDetails> = ({
  request,
  handleState,
  onUpdateRequestsData,
}) => {
  const [currentRequest, setCurrentRequest] = useState<RequestDetailsState>(null);
  const [displayError, setDisplayError] = useState({
    city: '',
    countryCode: '',
    stateCode: '',
    postCode: '',
    generic: '',
  });
  const [disableSave, setDisableSave] = useState(true);
  const [updateMibRequestsMutation] = useUpdateMibRequestsMutation({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (request) {
      const newRequest = {
        ...request,
        city: request.address.city,
        country: request.address.country,
        countryCode: request.address.countryCode,
        county: request.address.county,
        formattedAddress: request.address.formattedAddress,
        houseNumber: request.address.houseNumber,
        postCode: request.address.postCode,
        state: request.address.state,
        stateCode: request.address.stateCode,
        stateDistrict: request.address.stateDistrict,
        street: request.address.street,
        suburb: request.address.suburb,
      };
      setCurrentRequest(newRequest);
    }
  }, [request]);

  const handleDisableSave = () => {
    if (disableSave) {
      setDisableSave(false);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setCurrentRequest({ ...currentRequest, [name]: value });
    handleDisableSave();
  };

  const onDropdownChange = (e: { name: string; value: string }) => {
    const { name, value } = e;
    setCurrentRequest({ ...currentRequest, [name]: value });
    handleDisableSave();
  };

  const onSubmit = () => {
    console.log('currentRequest', currentRequest);
    let address = {
      city: currentRequest.city,
      country: currentRequest.country,
      countryCode: currentRequest.countryCode,
      county: currentRequest.county,
      formattedAddress: currentRequest.formattedAddress,
      houseNumber: currentRequest.houseNumber,
      postCode: currentRequest.postCode,
      state: currentRequest.state,
      stateCode: currentRequest.stateCode,
      stateDistrict: currentRequest.stateDistrict,
      street: currentRequest.street,
      suburb: currentRequest.suburb,
    };
    if (
      !currentRequest.city &&
      !currentRequest.country &&
      !currentRequest.countryCode &&
      !currentRequest.county &&
      !currentRequest.formattedAddress &&
      !currentRequest.houseNumber &&
      !currentRequest.postCode &&
      !currentRequest.state &&
      !currentRequest.stateCode &&
      !currentRequest.stateDistrict &&
      !currentRequest.street &&
      currentRequest.suburb
    ) {
      address = null;
    }
    const payload = {
      id: currentRequest.id,
      status: currentRequest.status,
      // metadata: currentRequest.metadata,
      address,
    };
    updateMibRequestsMutation({
      variables: {
        input: payload,
      },
    })
      .then(() => {
        onUpdateRequestsData();
        handleState({ requestDetails: null, view: 'requestsList' });
      })
      .catch((e) => {
        console.log('error', e?.graphQLErrors[0]?.message);
        const updateMIBRequestConvertedError = updateMIBRequestError(e);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError({
          ...displayError,
          ...updateMIBRequestConvertedError,
          generic: convertedError,
        });
      });
  };

  if (!currentRequest) {
    return null;
  }

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <IconButton onClick={() => handleState({ requestDetails: null, view: 'requestsList' })}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Grid>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              label="ExternalID"
              name="externalID"
              value={currentRequest.externalID}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              disabled
              label="ExternalUserID"
              name="externalUserID"
              value={currentRequest.externalUserID}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <TextField
              label="LineNumber"
              name="lineNumber"
              value={currentRequest.lineNumber}
              fullWidth
              disabled
              sx={{ marginTop: '8px' }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="FileID"
              name="fileID"
              value={currentRequest.fileID}
              fullWidth
              disabled
              sx={{ marginTop: '8px' }}
            />
          </Grid>
          <Grid item xs={4}>
            <Dropdown
              fullWidth
              selectedValue={currentRequest.status}
              onChange={onDropdownChange}
              name="status"
              label="status"
              list={mibRequestStatuses}
            />
          </Grid>
        </Grid>
      </Box>

      <Typography
        sx={{ marginLeft: '10px', marginTop: '30px' }}
        variant="subtitle1"
        gutterBottom
        component="div"
      >
        Address
      </Typography>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              label="Country"
              name="country"
              value={currentRequest.country}
              fullWidth
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.country ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="CountryCode"
              name="countryCode"
              value={currentRequest.countryCode}
              fullWidth
              required
              error={!!displayError.countryCode}
              helperText={displayError.countryCode}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.countryCode ? null : '#D0D0D0' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <TextField
              label="State"
              name="state"
              value={currentRequest.state}
              fullWidth
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.state ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="StateCode"
              name="stateCode"
              value={currentRequest.stateCode}
              fullWidth
              required
              error={!!displayError.stateCode}
              helperText={displayError.stateCode}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.stateCode ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="StateDistrict"
              name="stateDistrict"
              value={currentRequest.stateDistrict}
              fullWidth
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.stateDistrict ? null : '#D0D0D0' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              label="City"
              name="city"
              value={currentRequest.city}
              fullWidth
              required
              error={!!displayError.city}
              helperText={displayError.city}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.city ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="PostCode"
              name="postCode"
              value={currentRequest.postCode}
              fullWidth
              required
              error={!!displayError.postCode}
              helperText={displayError.postCode}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.postCode ? null : '#D0D0D0' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <TextField
              label="Street"
              name="street"
              value={currentRequest.street}
              fullWidth
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.street ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Suburb"
              name="suburb"
              value={currentRequest.suburb}
              fullWidth
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.street ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="HouseNumber"
              name="houseNumber"
              value={currentRequest.houseNumber}
              fullWidth
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentRequest.houseNumber ? null : '#D0D0D0' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            handleState({ requestDetails: null, view: 'requestsList' });
          }}
        >
          Cancel
        </Button>
        <Button disabled={disableSave} variant="contained" onClick={() => onSubmit()}>
          Save
        </Button>
      </Stack>
      {displayError && displayError.generic ? (
        <InputLabel sx={{ marginTop: '10px', color: 'red' }}>{displayError.generic}</InputLabel>
      ) : null}
    </div>
  );
};

export default RequestDetails;
