/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable max-classes-per-file */
/*
 * Errors returned by the auth.js and admin.js modules
 * Taken from
 * https://github.com/oneconcern/authservice/blob/oauth-provider/sdk/react/src/lib/errors.js
 */

function details(message: any) {
  if (message instanceof Error || message instanceof Number) {
    return `: ${message.toString()}`;
  }
  if (message instanceof String) {
    return `: ${message}`;
  }
  if (message instanceof Object) {
    return `: ${JSON.stringify(message)}`;
  }
  return '';
}
class AuthError extends Error {
  from: any[];

  constructor(e: any) {
    super(e);
    this.from = [];
    if (e instanceof AuthError) {
      this.from = [e].concat(e.from);
    } else if (e instanceof Error) {
      this.from.push(e);
    }
  }

  hasUnauthorized(): boolean {
    return this.from.some((e) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return e instanceof UnauthorizedErr || e.message.includes('Unauthorized');
    });
  }
}

class UnauthorizedErr extends AuthError {
  __proto__: UnauthorizedErr;

  constructor(message: any) {
    super(message);
    this.constructor = UnauthorizedErr;
    this.__proto__ = UnauthorizedErr.prototype;
    this.message = `401 Unauthorized ${details(message)}`;
  }

  // eslint-disable-next-line class-methods-use-this
  hasUnauthorized(): boolean {
    return true;
  }
}

class ForbiddenErr extends AuthError {
  __proto__: ForbiddenErr;

  constructor(message: string) {
    super(message);
    this.constructor = ForbiddenErr;
    this.__proto__ = ForbiddenErr.prototype;
    this.message = `403 Forbidden ${details(message)}`;
  }
}

class NoScopeGrantedErr extends AuthError {
  __proto__: NoScopeGrantedErr;

  constructor(message: string) {
    super(message);
    this.constructor = NoScopeGrantedErr;
    this.__proto__ = NoScopeGrantedErr.prototype;
    this.message = `no scope was granted ${details(message)}`;
  }
}

class RequiredScopeNotGrantedErr extends AuthError {
  __proto__: RequiredScopeNotGrantedErr;

  constructor(message: string) {
    super(message);
    this.constructor = RequiredScopeNotGrantedErr;
    this.__proto__ = RequiredScopeNotGrantedErr.prototype;
    this.message = `required scope not granted ${details(message)}`;
  }
}

class ExpiredSessionErr extends AuthError {
  __proto__: ExpiredSessionErr;

  constructor(message: string) {
    super(message);
    this.constructor = ExpiredSessionErr;
    this.__proto__ = ExpiredSessionErr.prototype;
    this.message = `your session has expired. You should sign in again  ${details(message)}`;
  }
}

class CannotRevokeTokensErr extends AuthError {
  __proto__: CannotRevokeTokensErr;

  constructor(message: string) {
    super(message);
    this.constructor = CannotRevokeTokensErr;
    this.__proto__ = CannotRevokeTokensErr.prototype;
    this.message = `warning: could not properly revoke tokens ${details(message)}`;
  }
}

class UserinfoErr extends AuthError {
  __proto__: UserinfoErr;

  constructor(message: string) {
    super(message);
    this.constructor = UserinfoErr;
    this.__proto__ = UserinfoErr.prototype;
    this.message = `error while retrieving userinfo ${details(message)}`;
  }
}

class GroupsFetchErr extends AuthError {
  __proto__: GroupsFetchErr;

  constructor(message: string) {
    super(message);
    this.constructor = GroupsFetchErr;
    this.__proto__ = GroupsFetchErr.prototype;
    this.message = `failed while retrieving groups ${details(message)}`;
  }
}

class GroupDetailsFetchErr extends AuthError {
  __proto__: GroupDetailsFetchErr;

  constructor(message: string) {
    super(message);
    this.constructor = GroupDetailsFetchErr;
    this.__proto__ = GroupDetailsFetchErr.prototype;
    this.message = `failed while fetching group details ${details(message)}`;
  }
}

class InvalidBodyParamErr extends AuthError {
  __proto__: InvalidBodyParamErr;

  constructor(message: string) {
    super(message);
    this.constructor = InvalidBodyParamErr;
    this.__proto__ = InvalidBodyParamErr.prototype;
    this.message = `invalid parameters in body ${details(message)}`;
  }
}

class InvalidAPIResponseErr extends AuthError {
  __proto__: InvalidAPIResponseErr;

  constructor(message: string) {
    super(message);
    this.constructor = InvalidAPIResponseErr;
    this.__proto__ = InvalidAPIResponseErr.prototype;
    this.message = `invalid response from keycloak API ${details(message)}`;
  }
}

class GroupMembersErr extends AuthError {
  __proto__: GroupMembersErr;

  constructor(message: string) {
    super(message);
    this.constructor = GroupMembersErr;
    this.__proto__ = GroupMembersErr.prototype;
    this.message = `failed while retrieving group members ${details(message)}`;
  }
}

class ImpersonateOnlyOneErr extends AuthError {
  __proto__: ImpersonateOnlyOneErr;

  constructor(message: string) {
    super(message);
    this.constructor = ImpersonateOnlyOneErr;
    this.__proto__ = ImpersonateOnlyOneErr.prototype;
    this.message = `you may only impersonate one user ${details(message)}`;
  }
}

class UserActionErr extends AuthError {
  __proto__: UserActionErr;

  constructor(message: string) {
    super(message);
    this.constructor = UserActionErr;
    this.__proto__ = UserActionErr.prototype;
    this.message = `failed while proceeding to some user action ${details(message)}`;
  }
}

class RolesFetchErr extends AuthError {
  __proto__: RolesFetchErr;

  constructor(message: string) {
    super(message);
    this.constructor = RolesFetchErr;
    this.__proto__ = RolesFetchErr.prototype;
    this.message = `failed while retrieving roles ${details(message)}`;
  }
}

class UserCreateErr extends AuthError {
  __proto__: UserCreateErr;

  constructor(message: string) {
    super(message);
    this.constructor = UserCreateErr;
    this.__proto__ = UserCreateErr.prototype;
    this.message = `could not create user ${details(message)}`;
  }
}

class AttachUserToGroupErr extends AuthError {
  __proto__: AttachUserToGroupErr;

  constructor(message: string) {
    super(message);
    this.constructor = AttachUserToGroupErr;
    this.__proto__ = AttachUserToGroupErr.prototype;
    this.message = `could not attach user to group ${details(message)}`;
  }
}

class UpdateUserGroupErr extends AuthError {
  __proto__: UpdateUserGroupErr;

  constructor(message: string) {
    super(message);
    this.constructor = UpdateUserGroupErr;
    this.__proto__ = UpdateUserGroupErr.prototype;
    this.message = `failed while updated user group ${details(message)}`;
  }
}

class AttachUserToRoleErr extends AuthError {
  __proto__: AttachUserToRoleErr;

  constructor(message: string) {
    super(message);
    this.constructor = AttachUserToRoleErr;
    this.__proto__ = AttachUserToRoleErr.prototype;
    this.message = `could not attach user to role ${details(message)}`;
  }
}

class UpdateUserRoleErr extends AuthError {
  __proto__: UpdateUserRoleErr;

  constructor(message: string) {
    super(message);
    this.constructor = UpdateUserRoleErr;
    this.__proto__ = UpdateUserRoleErr.prototype;
    this.message = `failed while updated user roles ${details(message)}`;
  }
}

class VerifyEmailErr extends AuthError {
  __proto__: VerifyEmailErr;

  constructor(message: string) {
    super(message);
    this.constructor = VerifyEmailErr;
    this.__proto__ = VerifyEmailErr.prototype;
    this.message = `failed while attempting to post verification email to user ${details(message)}`;
  }
}

class InviteUserErr extends AuthError {
  __proto__: InviteUserErr;

  constructor(message: string) {
    super(message);
    this.constructor = InviteUserErr;
    this.__proto__ = InviteUserErr.prototype;
    this.message = `failed while inviting new user ${details(message)}`;
  }
}

class ResetPasswordErr extends AuthError {
  __proto__: ResetPasswordErr;

  constructor(message: string) {
    super(message);
    this.constructor = ResetPasswordErr;
    this.__proto__ = ResetPasswordErr.prototype;
    this.message = `failed while resetting password for user ${details(message)}`;
  }
}

class RemoveTOTPErr extends AuthError {
  __proto__: RemoveTOTPErr;

  constructor(message: string) {
    super(message);
    this.constructor = RemoveTOTPErr;
    this.__proto__ = RemoveTOTPErr.prototype;
    this.message = `failed while removing TOTP for user ${details(message)}`;
  }
}

class ImpersonateErr extends AuthError {
  __proto__: ImpersonateErr;

  constructor(message: string) {
    super(message);
    this.constructor = ImpersonateErr;
    this.__proto__ = ImpersonateErr.prototype;
    this.message = `failed while impersonating user ${details(message)}`;
  }
}

class TokenExchangeErr extends AuthError {
  __proto__: TokenExchangeErr;

  constructor(message: string) {
    super(message);
    this.constructor = TokenExchangeErr;
    this.__proto__ = TokenExchangeErr.prototype;
    this.message = `failed while attempting to exchange token for another user ${details(message)}`;
  }
}

class PropRequiredErr extends AuthError {
  __proto__: PropRequiredErr;

  constructor(message: string) {
    super(message);
    this.constructor = PropRequiredErr;
    this.__proto__ = PropRequiredErr.prototype;
    this.message = `component required a property ${details(message)}`;
  }
}

class IframeNonceErr extends AuthError {
  __proto__: IframeNonceErr;

  constructor(message: string) {
    super(message);
    this.constructor = IframeNonceErr;
    this.__proto__ = IframeNonceErr.prototype;
    this.message = `wrong nonce from iframe message ${details(message)}`;
  }
}

class IframeBadLoginErr extends AuthError {
  __proto__: IframeBadLoginErr;

  constructor(message: string) {
    super(message);
    this.constructor = IframeBadLoginErr;
    this.__proto__ = IframeBadLoginErr.prototype;
    this.message = `unexpected login iframe message ${details(message)}`;
  }
}

class ConfigCheckErr extends AuthError {
  __proto__: ConfigCheckErr;

  constructor(message: string) {
    super(message);
    this.constructor = ConfigCheckErr;
    this.__proto__ = ConfigCheckErr.prototype;
    this.message = `invalid configuration ${details(message)}`;
  }
}

class LogoutErr extends AuthError {
  __proto__: LogoutErr;

  constructor(message: string) {
    super(message);
    this.constructor = LogoutErr;
    this.__proto__ = LogoutErr.prototype;
    this.message = `warning: an error occured while logging out ${details(message)}`;
  }
}

class UnsupportedActionErr extends AuthError {
  __proto__: UnsupportedActionErr;

  constructor(message: string) {
    super(message);
    this.constructor = UnsupportedActionErr;
    this.__proto__ = UnsupportedActionErr.prototype;
    this.message = `unsupported user admin action ${details(message)}`;
  }
}

class KeycloakApiErr extends AuthError {
  __proto__: KeycloakApiErr;

  constructor(message: string) {
    super(message);
    this.constructor = KeycloakApiErr;
    this.__proto__ = KeycloakApiErr.prototype;
    this.message = `error while calling Keycloak admin API ${details(message)}`;
  }
}

class BadInputErr extends AuthError {
  __proto__: BadInputErr;

  constructor(message: string) {
    super(message);
    this.constructor = BadInputErr;
    this.__proto__ = BadInputErr.prototype;
    this.message = `bad input (dev error) ${details(message)}`;
  }
}

export {
  AuthError,
  // UnauthorizedErr,
  // ForbiddenErr,
  // NoScopeGrantedErr,
  // ExpiredSessionErr,
  // RequiredScopeNotGrantedErr,
  // CannotRevokeTokensErr,
  UserinfoErr,
  // GroupsFetchErr,
  // GroupDetailsFetchErr,
  InvalidBodyParamErr,
  // InvalidAPIResponseErr,
  // GroupMembersErr,
  // ImpersonateOnlyOneErr,
  // UserActionErr,
  // RolesFetchErr,
  // UserCreateErr,
  // AttachUserToGroupErr,
  // UpdateUserGroupErr,
  // AttachUserToRoleErr,
  // UpdateUserRoleErr,
  // VerifyEmailErr,
  // InviteUserErr,
  // ResetPasswordErr,
  // RemoveTOTPErr,
  ImpersonateErr,
  // TokenExchangeErr,
  PropRequiredErr,
  IframeNonceErr,
  IframeBadLoginErr,
  // ConfigCheckErr,
  LogoutErr,
  // UnsupportedActionErr,
  BadInputErr,
  KeycloakApiErr,
};
