import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import {
  convertEntitlementsRes,
  entitlementsHazardChanged,
  entitlementsProductDefaults,
  entitlementsSubscriptionDefaults,
} from '../../ProductComponents/Organizations/OrganizationsTab/utils/converter';
import { Product, useGetEntitlementsStructureQuery } from '../../../__generated__/graphql';

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type ProductsListProps = {
  value?: Array<ProductItemProps>;
  onChange?: (v: Array<ProductItemProps>) => void;
  forUser?: boolean;
};

type AvailableProductsSubs = {
  options: {
    DOMINO: Array<{ key: string; value: string; desc: string }>;
    READY: Array<{ key: string; value: string; desc: string }>;
  };
  productsSubs?: {
    [key: string]: {
      enabled: boolean;
      desc: string;
      value: string;
    };
  };
  products: Array<{ key: Product; value: string }>;
};

const getAvailableProducts = (ents: Array<ProductItemProps>): AvailableProductsSubs => {
  const availability: AvailableProductsSubs = {
    productsSubs: {
      'DOMINO/us': {
        enabled: true,
        desc: '',
        value: '',
      },
      'DOMINO/jp': {
        enabled: true,
        desc: '',
        value: '',
      },
      'READY/live hazard': {
        enabled: true,
        desc: '',
        value: '',
      },
    },
    options: {
      DOMINO: [],
      READY: [],
    },
    products: [],
  };

  ents.forEach((e) => {
    if (e.product.selected) {
      switch (e.product.selected.key) {
        case Product.Domino:
          if (e.subscription.selected) {
            const key = `DOMINO/${e.subscription.selected.key}`;
            if (availability.productsSubs[key].enabled) {
              availability.productsSubs[key].enabled = false;
            }
          }
          break;
        case Product.Ready:
          if (e.subscription.selected) {
            const key = `READY/${e.subscription.selected.key}`;
            if (availability.productsSubs[key].enabled) {
              availability.productsSubs[key].enabled = false;
            }
          }
          break;
        default:
          break;
      }
    }
  });

  if (ents.length) {
    const ent = ents[0];
    ent.subscription.options.DOMINO.forEach((s) => {
      if (availability.productsSubs[`DOMINO/${s.key}`].enabled) {
        availability.productsSubs[`DOMINO/${s.key}`].desc = s.desc;
        availability.productsSubs[`DOMINO/${s.key}`].value = s.value;
      }
    });
    ent.subscription.options.READY.forEach((s) => {
      if (availability.productsSubs[`READY/${s.key}`].enabled) {
        availability.productsSubs[`READY/${s.key}`].desc = s.desc;
        availability.productsSubs[`READY/${s.key}`].value = s.value;
      }
    });
  }

  Object.keys(availability.productsSubs).forEach((k) => {
    if (availability.productsSubs[k].enabled) {
      const v = availability.productsSubs[k];
      const productSub = k.split('/');
      switch (productSub[0]) {
        case 'DOMINO':
          availability.options.DOMINO.push({ key: productSub[1], value: v.value, desc: v.desc });
          break;
        case 'READY':
          availability.options.READY.push({ key: productSub[1], value: v.value, desc: v.desc });
          break;
        default:
          break;
      }
    }
  });

  if (availability.options.DOMINO.length) {
    availability.products.push({ key: Product.Domino, value: 'Domino' });
  }
  if (availability.options.READY.length) {
    availability.products.push({ key: Product.Ready, value: 'Ready' });
  }

  return availability;
};

const ProductsList: React.FC<ProductsListProps> = ({ value, onChange, forUser }) => {
  const [firstUpdate, setFirstUpdate] = useState(true);
  const [localData, setLocalData] = useState<Array<ProductItemProps>>([]);

  const { data, loading, error } = useGetEntitlementsStructureQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data && !localData.length) {
      if (firstUpdate) {
        const ent = convertEntitlementsRes(data.entitlementsStructure);
        setLocalData([entitlementsProductDefaults('READY', ent)]);
        setFirstUpdate(false);
      }
    }
    if (firstUpdate) {
      setFirstUpdate(false);
    }
  }, [data, localData, firstUpdate]);

  useEffect(() => {
    if (value) {
      setLocalData(value);
    }
  }, [value]);

  const newLocalData = (v: ProductsListProps['value']) => {
    setLocalData(v);
    if (onChange) {
      onChange(v);
    }
  };

  const onAutocompleteChange = (
    name: string,
    index: number,
    value: string | { key: string; value: string },
  ) => {
    const copyLocalData = JSON.parse(JSON.stringify(localData));
    const aux = copyLocalData[index];
    aux[name].selected = value;
    switch (name) {
      case 'product':
        entitlementsProductDefaults(aux[name].selected?.key || null, aux);
        break;
      case 'subscription':
        entitlementsSubscriptionDefaults(aux[name].selected?.key || null, aux);
        break;
      default:
        break;
    }

    copyLocalData[index] = aux;

    newLocalData(copyLocalData);
  };

  const onMultiAutocompleteChange = (
    name: string,
    index: number,
    value: Array<string | { key: string; value: string }>,
  ) => {
    const copyLocalData = JSON.parse(JSON.stringify(localData));
    const aux = copyLocalData[index];
    aux[name].selected = value;
    if (name === 'product') {
      aux.subscription.selected = {};
    }
    copyLocalData[index] = aux;

    newLocalData(copyLocalData);
  };

  const addDefaultProduct = () => {
    if (data) {
      const ent = convertEntitlementsRes(data.entitlementsStructure);
      newLocalData([...localData, ent]);
    }
  };

  const removeProduct = (index: number) => {
    if (localData.length === 1) {
      newLocalData([]);
      return;
    }
    newLocalData([...localData.slice(0, index), ...localData.slice(index + 1)]);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    groupName: string,
    index: number,
  ) => {
    const {
      target: { checked, name },
    } = event;

    const copyLocalData = JSON.parse(JSON.stringify(localData));
    const aux = copyLocalData[index];
    const product = aux.product.selected.key;
    switch (product) {
      case 'DOMINO':
        aux[groupName].options.DOMINO = aux[groupName].options.DOMINO.map(
          (o: { key: string; value: boolean }) => {
            if (o.key === name) {
              return { ...o, value: checked };
            }
            return o;
          },
        );
        if (groupName === 'hazard') {
          entitlementsHazardChanged(
            'DOMINO',
            { key: event.target.name, value: event.target.checked },
            aux,
          );
        }
        break;
      case 'READY':
        aux[groupName].options.READY = aux[groupName].options.READY.map(
          (o: { key: string; value: boolean }) => {
            if (o.key === name) {
              return { ...o, value: checked };
            }
            return o;
          },
        );
        if (groupName === 'hazard') {
          entitlementsHazardChanged(
            'READY',
            { key: event.target.name, value: event.target.checked },
            aux,
          );
        }
        break;
      default:
        break;
    }

    newLocalData(copyLocalData);
  };

  const handleChangeFeature = (
    event: React.ChangeEvent<HTMLInputElement>,
    groupName: string,
    featureName: string,
    index: number,
  ) => {
    const {
      target: { checked, name },
    } = event;

    const copyLocalData = JSON.parse(JSON.stringify(localData));
    const aux = copyLocalData[index];
    const product = aux.product.selected.key;
    switch (product) {
      case 'DOMINO':
        aux[groupName].options.DOMINO[featureName] = aux[groupName].options.DOMINO[featureName].map(
          (o: { key: string; value: boolean }) => {
            if (o.key === name) {
              return { ...o, value: checked };
            }
            return o;
          },
        );
        break;
      case 'READY':
        aux[groupName].options.READY[featureName] = aux[groupName].options.READY[featureName].map(
          (o: { key: string; value: boolean }) => {
            if (o.key === name) {
              return { ...o, value: checked };
            }
            return o;
          },
        );
        break;
      default:
        break;
    }

    newLocalData(copyLocalData);
  };

  const displayData = (availableProdSub: AvailableProductsSubs) => {
    const { products, options } = availableProdSub;
    return (
      <>
        {localData.map((row, index) => {
          const { product } = row;
          let subscriptions: Array<{ key: string; value: string }> = [];
          let regions: Array<{ key: string; value: string }> = [];
          let hazards: Array<{ key: string; label: string; value: boolean; disabled: boolean }> =
            [];
          let subscriptionOptions: Array<{
            key: string;
            label: string;
            desc: string;
            value: boolean;
            disabled: boolean;
          }> = [];
          let features: {
            [key: string]: Array<{
              key: string;
              label: string;
              desc: string;
              value: boolean;
              disabled: boolean;
            }>;
          } = {};
          if (product.selected) {
            switch (product.selected.key) {
              case 'DOMINO':
                // subscriptions = row.subscription.options.DOMINO;
                subscriptions = options.DOMINO;
                regions = row.region.options.DOMINO;
                hazards = row.hazard.options.DOMINO;
                subscriptionOptions = row.subscriptionOptions.options.DOMINO;
                features = row.feature.options.DOMINO;
                break;
              case 'READY':
                // subscriptions = row.subscription.options.READY;
                subscriptions = options.READY;
                regions = row.region.options.READY;
                hazards = row.hazard.options.READY;
                subscriptionOptions = row.subscriptionOptions.options.READY;
                features = row.feature.options.READY;
                break;
              default:
                break;
            }
          }

          let gridItemWidth = 12;
          if (row.product.selected) {
            gridItemWidth = row.product.selected.key === 'DOMINO' ? 4 : 2;
          }

          return (
            <div
              style={{
                border: '1px solid darkgray',
                padding: '15px',
                borderRadius: '5px',
                marginBottom: '10px',
              }}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            >
              {!forUser ? (
                <Grid container justifyContent="flex-end">
                  <IconButton
                    onClick={() => removeProduct(index)}
                    data-test-id={`RemoveProduct-${index}`}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Grid>
              ) : null}
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={gridItemWidth}>
                  <Autocomplete
                    id={`product-${index}`}
                    data-test-id={`Product-${index}`}
                    disabled={forUser}
                    options={products}
                    freeSolo
                    getOptionLabel={(option: { key: Product; value: string }) => option.value}
                    renderTags={(value: Array<{ key: Product; value: string }>, getTagProps) =>
                      value.map((option: { key: Product; value: string }, index: number) => (
                        <Chip
                          variant="outlined"
                          key={option.key}
                          label={option.value}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    value={product.selected}
                    onChange={(e, value) => onAutocompleteChange('product', index, value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Product"
                        placeholder="Select Product"
                        InputLabelProps={{
                          style: { color: product.selected ? null : '#D0D0D0' },
                        }}
                      />
                    )}
                  />
                </Grid>

                {product.selected ? (
                  <>
                    <Grid item xs={gridItemWidth}>
                      <Autocomplete
                        id={`subscription-${index}`}
                        data-test-id={`Subscription-${index}`}
                        disabled={forUser}
                        options={subscriptions}
                        freeSolo
                        getOptionLabel={(option: { key: string; value: string }) => option.value}
                        renderTags={(value: Array<{ key: string; value: string }>, getTagProps) =>
                          value.map((option: { key: string; value: string }, index: number) => (
                            <Chip
                              key={option.key}
                              variant="outlined"
                              label={option.value}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        value={row.subscription.selected}
                        onChange={(e, value) => onAutocompleteChange('subscription', index, value)}
                        renderInput={(params) => {
                          if (row.subscription.selected?.desc) {
                            return (
                              <Tooltip title={row.subscription.selected.desc}>
                                <TextField
                                  {...params}
                                  label="Subscription"
                                  placeholder="Select Subscription"
                                  InputLabelProps={{
                                    style: { color: row.subscription.selected ? null : '#D0D0D0' },
                                  }}
                                />
                              </Tooltip>
                            );
                          }
                          return (
                            <TextField
                              {...params}
                              label="Subscription"
                              placeholder="Select Subscription"
                              InputLabelProps={{
                                style: { color: row.subscription.selected ? null : '#D0D0D0' },
                              }}
                            />
                          );
                        }}
                      />
                    </Grid>

                    {product.selected.key === 'READY' ? (
                      <Grid item xs={gridItemWidth}>
                        <Autocomplete
                          disabled={forUser}
                          multiple
                          options={regions}
                          getOptionLabel={(option: { key: string; value: string }) => option.value}
                          freeSolo
                          id={`region-${index}`}
                          data-test-id={`Region-${index}`}
                          value={row.region.selected}
                          renderTags={(value: Array<{ key: string; value: string }>, getTagProps) =>
                            value.map((option: { key: string; value: string }, index: number) => (
                              <Chip
                                key={option.key}
                                variant="outlined"
                                label={option.value}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          onChange={(e, value) => onMultiAutocompleteChange('region', index, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Regions"
                              placeholder="Select Regions"
                              InputLabelProps={{
                                style: { color: row.region.selected ? null : '#D0D0D0' },
                              }}
                            />
                          )}
                        />
                      </Grid>
                    ) : null}

                    <Grid item xs={2}>
                      <FormLabel component="legend">Hazards</FormLabel>
                      <FormGroup>
                        {hazards.map((h) => {
                          return (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={h.value}
                                  onChange={(e) => handleChange(e, 'hazard', index)}
                                  name={h.key}
                                  disabled={h.disabled}
                                  data-test-id={`Hazard-${h.key}`}
                                />
                              }
                              key={h.key}
                              label={h.label}
                            />
                          );
                        })}
                      </FormGroup>
                    </Grid>

                    <Grid item xs={2}>
                      <FormLabel component="legend">Subscription Options</FormLabel>
                      <FormGroup>
                        {subscriptionOptions.map((h) => {
                          return (
                            // eslint-disable-next-line react/jsx-key
                            <Tooltip title={h.desc}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={h.value}
                                    onChange={(e) => handleChange(e, 'subscriptionOptions', index)}
                                    name={h.key}
                                    disabled={h.disabled}
                                    data-test-id={`SubscriptionOption-${h.key}`}
                                  />
                                }
                                label={h.label}
                                key={h.key}
                              />
                            </Tooltip>
                          );
                        })}
                      </FormGroup>
                    </Grid>
                  </>
                ) : null}
              </Grid>

              {product.selected && row.subscription.selected ? (
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  {Object.keys(features)
                    .sort()
                    .map((k: string) => {
                      const feature = features[k];

                      const label =
                        k === 'allHazards' ? 'All Hazards' : k.charAt(0).toUpperCase() + k.slice(1);
                      return (
                        <Grid item xs={3} key={label}>
                          <FormLabel component="legend">{label}</FormLabel>
                          <FormGroup>
                            {feature.map((h) => {
                              return (
                                <Tooltip title={h.desc} key={h.key}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        disabled={h.disabled}
                                        checked={h.value}
                                        onChange={(e) =>
                                          handleChangeFeature(e, 'feature', k, index)
                                        }
                                        name={h.key}
                                        data-test-id={`${k}-${h.key}`}
                                      />
                                    }
                                    label={h.label}
                                  />
                                </Tooltip>
                              );
                            })}
                          </FormGroup>
                        </Grid>
                      );
                    })}
                </Grid>
              ) : null}
            </div>
          );
        })}
      </>
    );
  };

  if (process.env.REACT_APP_ENV !== 'local') {
    if (loading) {
      return <div>Loading..</div>;
    }

    if (error) {
      return <div>Error</div>;
    }
  }

  const { products, options } = getAvailableProducts(localData);

  return (
    <div>
      <Box sx={{ width: '100%', margin: '10px' }}>
        {localData.length !== 0 ? displayData({ products, options }) : null}
        {!forUser ? (
          <Stack marginBottom="20px" direction="row" justifyContent="flex-end">
            {products.length > 0 ? (
              <Button size="small" onClick={addDefaultProduct} data-test-id="AddProduct-Btn">
                + Product
              </Button>
            ) : null}
          </Stack>
        ) : null}
      </Box>
    </div>
  );
};

ProductsList.displayName = 'ProductsList';
export default ProductsList;
