import { ApolloError } from '@apollo/client/errors';
import { ServerError } from '@apollo/client/link/utils';

const getErrorFromGraphqlError = (errors: ApolloError): string => {
  if (!errors || errors.graphQLErrors.length === 0) {
    return null;
  }

  const err = errors.graphQLErrors[0];
  if (err.message === '403 Forbidden') {
    return 'Error: 403 Forbidden (admin role required)';
  }

  if (err.message.includes('[409] postUsersConflict')) {
    return 'user with the same email address has already exists';
  }

  if (err.message.includes('[409] postGroupsConflict')) {
    return 'organization with the same name has already exists';
  }

  return err.message;
};

const updateMIBRequestError = (
  errors: ApolloError,
): { postCode: string; city: string; countryCode: string; stateCode: string } => {
  if (!errors) {
    return null;
  }

  const result = {
    postCode: '',
    city: '',
    countryCode: '',
    stateCode: '',
  };

  const a = errors.networkError;
  (a as ServerError).result?.errors?.forEach((err: { message: string }) => {
    if (err.message.includes(`got invalid value null at "input.address.postCode"`)) {
      result.postCode = 'PostCode is required';
    }
    if (err.message.includes(`got invalid value null at "input.address.stateCode"`)) {
      result.stateCode = 'StateCode is required';
    }
    if (err.message.includes(`got invalid value null at "input.address.countryCode"`)) {
      result.countryCode = 'CountryCode is required';
    }
    if (err.message.includes(`got invalid value null at "input.address.city"`)) {
      result.city = 'City is required';
    }
  });

  return result;
};

export { getErrorFromGraphqlError, updateMIBRequestError };
