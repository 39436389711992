import React, { useEffect, useState } from 'react';
import {
  MibRequestStatus,
  useGetMibRequestsLazyQuery,
  useGetMibRequestsQuery,
} from '../../../../__generated__/graphql';
import RequestsList from './RequestsList';
import RequestDetails from './RequestDetails';
import { getErrorFromGraphqlError } from '../../../../util/errors';
// import requestsRes from '../../../../mocks/mibRequests';

type Request = {
  id: string;
  externalID?: string;
  externalUserID: string;
  fileID?: string;
  lineNumber?: number;
  metadata: any;
  postedAt: any;
  updatedAt: any;
  status: MibRequestStatus;
  address?: {
    city?: string;
    country?: string;
    countryCode?: string;
    county?: string;
    formattedAddress: string;
    houseNumber?: string;
    postCode?: string;
    state?: string;
    stateCode?: string;
    stateDistrict?: string;
    street?: string;
    suburb?: string;
  };
};

type RequestsState = { view: string; requestDetails: Request; requests: Array<Request> };

const MBIRequests: React.FC = () => {
  const [state, setState] = useState({
    view: 'requestsList',
    requestDetails: null,
    requests: null,
  });
  const { data, loading, error } = useGetMibRequestsQuery({
    fetchPolicy: 'no-cache',
  });
  const [getMibRequestsLazyQuery, { data: lazyData, loading: lazyLoading, error: lazyError }] =
    useGetMibRequestsLazyQuery({ fetchPolicy: 'no-cache' });
  // const data = requestsRes;
  // const lazyData = requestsRes

  useEffect(() => {
    if (data || lazyData) {
      const newResponse = lazyData || data;
      setState((s) => ({ ...s, requests: newResponse.mibRequests }));
    }
  }, [data, lazyData]);

  const handleState = (newState: RequestsState) => {
    setState({ ...state, ...newState });
  };

  const onUpdateRequestsData = () => {
    console.log('onUpdateRequestsData');
    (async () => {
      await getMibRequestsLazyQuery({ fetchPolicy: 'no-cache' });
    })();
  };

  if (loading || lazyLoading) {
    return <div>Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  const convertedLazyError = getErrorFromGraphqlError(lazyError);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }
  if (convertedLazyError) {
    return <div>{convertedLazyError}</div>;
  }

  if (!state.requests) {
    return null;
  }

  return (
    <div>
      {state.view === 'requestsList' ? (
        <RequestsList requests={state.requests} handleState={handleState} />
      ) : null}
      {state.view === 'requestDetails' ? (
        <RequestDetails
          request={state.requestDetails}
          handleState={handleState}
          onUpdateRequestsData={onUpdateRequestsData}
        />
      ) : null}
    </div>
  );
};

export default MBIRequests;
