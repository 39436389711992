import {
  GetUserQuery,
  LanguagePreferences,
  MeasurementPreferences,
  Product,
  SearchUsersQuery,
  UserRole,
  UserStatus,
} from '../__generated__/graphql';

const userDominoUsRes: GetUserQuery['getUser'] = {
  id: 'id',
  role: UserRole.Admin,
  status: UserStatus.Joined,
  personalInfo: {
    firstName: 'AmericanFirstName',
    lastName: 'AmericanLastName',
    contact: {
      email: 'usa@test.com',
      phoneNumber: '99999',
      __typename: 'ContactInfo',
    },
    __typename: 'PersonInfo',
  },
  entitlements: {
    enabledProducts: [Product.Domino, Product.Ready],
    enabledProductsModule: [
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        isMain: false,
        isInherited: true,
      },
    ],
    hasHazardsForProduct: [
      {
        product: Product.Domino,
        module: 'us',
        region: null,
        hazard: 'seismic',
        isInherited: true,
      },
    ],
    hasFeaturesForProduct: [
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-airport',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-bridge',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-highway',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-people',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-port',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-power',
        isInherited: true,
      },
    ],
  },
};

const userDominoJpRes: GetUserQuery['getUser'] = {
  id: 'id',
  role: UserRole.Admin,
  status: UserStatus.Joined,
  personalInfo: {
    firstName: 'AmericanFirstName',
    lastName: 'AmericanLastName',
    contact: {
      email: 'usa@test.com',
      phoneNumber: '99999',
      __typename: 'ContactInfo',
    },
    __typename: 'PersonInfo',
  },
  entitlements: {
    enabledProducts: [Product.Domino, Product.Ready],
    enabledProductsModule: [
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        isMain: false,
        isInherited: true,
      },
    ],
    hasHazardsForProduct: [
      {
        product: Product.Domino,
        module: 'jp',
        region: null,
        hazard: 'flood',
        isInherited: true,
      },
    ],
    hasFeaturesForProduct: [
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-airport',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-bridge',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-highway',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-people',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-port',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-power',
        isInherited: false,
      },
    ],
  },
};

const userDominoFeaturesRes: GetUserQuery['getUser'] = {
  id: 'id',
  role: UserRole.Admin,
  status: UserStatus.Joined,
  personalInfo: {
    firstName: 'AmericanFirstName',
    lastName: 'AmericanLastName',
    contact: {
      email: 'usa@test.com',
      phoneNumber: '99999',
      __typename: 'ContactInfo',
    },
    __typename: 'PersonInfo',
  },
  entitlements: {
    enabledProducts: [Product.Domino, Product.Ready],
    enabledProductsModule: [
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        isMain: false,
        isInherited: true,
      },
    ],
    hasHazardsForProduct: [],
    hasFeaturesForProduct: [
      {
        product: Product.Domino,
        region: null,
        module: '',
        hazard: null,
        feature: 'preview1',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: '',
        hazard: null,
        feature: 'preview2',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        hazard: null,
        feature: 'domino-viewer',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-airport',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-power',
        isInherited: false,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-port',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-people',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-highway',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        hazard: 'flood',
        feature: 'lifeline-bridge',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-port',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-people',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-highway',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-bridge',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-airport',
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        hazard: 'seismic',
        feature: 'lifeline-power',
        isInherited: true,
      },
    ],
  },
};

const userReadyFeaturesRes: GetUserQuery['getUser'] = {
  id: 'id',
  role: UserRole.Admin,
  status: UserStatus.Joined,
  personalInfo: {
    firstName: 'AmericanFirstName',
    lastName: 'AmericanLastName',
    contact: {
      email: 'usa@test.com',
      phoneNumber: '99999',
      __typename: 'ContactInfo',
    },
    __typename: 'PersonInfo',
  },
  entitlements: {
    enabledProducts: [Product.Domino, Product.Ready],
    enabledProductsModule: [
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        isMain: false,
        isInherited: true,
      },
    ],
    hasHazardsForProduct: [],
    hasFeaturesForProduct: [
      {
        product: Product.Ready,
        region: null,
        module: '',
        hazard: null,
        feature: 'preview1',
        isInherited: true,
      },
    ],
  },
};

const userRes: GetUserQuery['getUser'] = {
  id: '6b2153ac-5dca-4fef-8fd0-0c185f4c5443',
  role: UserRole.Admin,
  title: '',
  personalInfo: {
    firstName: 'AmericanFirstName',
    lastName: 'AmericanLastName',
    contact: {
      email: 'usa@test.com',
      phoneNumber: '99999',
      __typename: 'ContactInfo',
    },
    __typename: 'PersonInfo',
  },
  status: UserStatus.Joined,
  preferences: {
    language: LanguagePreferences.En,
    country: 'United States',
    timezone: 'America/Los_Angeles',
    units: MeasurementPreferences.ImperialSystem,
    __typename: 'UserPreferences',
  },
  entitlements: {
    scopes: [Product.Domino],
    enabledProducts: [Product.Domino, Product.Ready],
    enabledProductsModule: [
      {
        product: Product.Domino,
        region: null,
        module: 'consultant',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'jp',
        isMain: false,
        isInherited: true,
      },
      {
        product: Product.Domino,
        region: null,
        module: 'us',
        isMain: false,
        isInherited: true,
      },
    ],
    hasHazardsForProduct: [],
    hasFeaturesForProduct: [],
  },
  __typename: 'User',
};

const searchUsersRes: SearchUsersQuery['searchUsers'] = [
  {
    id: '771f27e7-ee20-4f60-ba63-1b98200f0b0e',
    role: UserRole.Contributor,
    title: null,
    personalInfo: {
      firstName: 'Gabi',
      lastName: 'Gabi',
      contact: {
        email: 'gbotos+test17@oneconcern.com',
        phoneNumber: null,
      },
    },
    status: UserStatus.Joined,
    preferences: {
      country: 'Japan',
      language: LanguagePreferences.Ja,
      timezone: 'Asia/Tokyo',
      units: MeasurementPreferences.MetricSystem,
      notificationsEnabled: null,
      notificationsChannels: null,
    },
  },
  {
    id: '221421bd-2482-4f52-89a3-701ccaf1cd7b',
    role: UserRole.Contributor,
    title: null,
    personalInfo: {
      firstName: 'Gabi',
      lastName: 'Gabi',
      contact: {
        email: 'gbotos+test17@test.com',
        phoneNumber: null,
      },
    },
    status: UserStatus.Joined,
    preferences: {
      country: 'United States',
      language: LanguagePreferences.En,
      timezone: 'America/Los_Angeles',
      units: MeasurementPreferences.MetricSystem,
      notificationsEnabled: null,
      notificationsChannels: null,
    },
  },
  {
    id: 'cb951a54-b6b6-4ecc-9851-911fb1ad57a2',
    role: UserRole.Admin,
    title: null,
    personalInfo: {
      firstName: 'Gabi',
      lastName: 'Gabi',
      contact: {
        email: 'gbotos+test18@oneconcern.com',
        phoneNumber: null,
      },
    },
    status: UserStatus.InvitationPending,
    preferences: {
      country: 'Japan',
      language: LanguagePreferences.Ja,
      timezone: 'Asia/Tokyo',
      units: MeasurementPreferences.MetricSystem,
      notificationsEnabled: null,
      notificationsChannels: null,
    },
  },
  {
    id: '7c3fdab8-1763-4cd0-8a11-c0a39958b381',
    role: UserRole.Contributor,
    title: null,
    personalInfo: {
      firstName: 'Gabi',
      lastName: 'Gabi',
      contact: {
        email: 'gbotos+test19@oneconcern.com',
        phoneNumber: null,
      },
    },
    status: UserStatus.Disabled,
    preferences: {
      country: 'United States',
      language: LanguagePreferences.En,
      timezone: 'America/Los_Angeles',
      units: MeasurementPreferences.MetricSystem,
      notificationsEnabled: null,
      notificationsChannels: null,
    },
  },
  {
    id: 'e134b269-19d9-4975-8a24-3f9931aeeca6',
    role: UserRole.Contributor,
    title: null,
    personalInfo: {
      firstName: 'Gabi',
      lastName: 'Gabi',
      contact: {
        email: 'gbotos+testinvite1@oneconcern.com',
        phoneNumber: null,
      },
    },
    status: UserStatus.Joined,
    preferences: {
      country: 'United States',
      language: LanguagePreferences.En,
      timezone: 'America/Los_Angeles',
      units: MeasurementPreferences.MetricSystem,
      notificationsEnabled: null,
      notificationsChannels: null,
    },
  },
];
export {
  userRes,
  searchUsersRes,
  userDominoUsRes,
  userDominoJpRes,
  userDominoFeaturesRes,
  userReadyFeaturesRes,
};
