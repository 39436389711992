import {
  CountryCode,
  GetOrganizationQuery,
  LanguagePreferences,
  MeasurementPreferences,
  NotificationsChannel,
  OrganizationAdminInput,
  Product,
} from '../../../../../__generated__/graphql';
import { createOrgEntsPayload } from './converter';

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type OrganizationDetailsState = {
  systemName: string;
  path: string;
  adminNotes: string;
  country: string;
  language: string;
  timezone: string;
  countryCode: string;
  notificationsEnabled: boolean;
  notificationsChannels: Array<NotificationsChannel>;
  unitsPref: string;
  names: Array<{ [key: string]: string }>;
  products?: Array<ProductItemProps>;
};

const updateOrganizationPayload = (
  org: GetOrganizationQuery['getOrganization'],
  currentOrg: OrganizationDetailsState,
): { error: string; payload: OrganizationAdminInput } => {
  const { profiles, ents } = createOrgEntsPayload(currentOrg.products);
  if (!profiles.length) {
    return { error: 'At least one product needs to be selected', payload: null };
  }
  const allDefaultPreferences: { [key: string]: string } = {};
  if (org.preferences?.allDefaultPreferences) {
    Object.keys(org.preferences?.allDefaultPreferences).forEach((k) => {
      allDefaultPreferences[k] = org.preferences.allDefaultPreferences[k];
    });
  }
  let error = '';
  const names: { [key: string]: string } = {};
  currentOrg.names.forEach((item: { [key: string]: string }) => {
    const key = Object.keys(item)[0];
    if (names[key] !== undefined) {
      error = "Can't have identical keys in display names!";
      return;
    }
    names[key] = item[key];
  });
  if (error !== '') {
    return { error, payload: null };
    // setDisplayError(error);
    // return;
  }
  let notificationsChannels;
  if (!currentOrg.notificationsEnabled || currentOrg.notificationsChannels.length === 0) {
    notificationsChannels = null;
  } else {
    notificationsChannels = currentOrg.notificationsChannels;
  }
  let countryCode;
  switch (currentOrg.countryCode) {
    case CountryCode.Us.toString():
      countryCode = CountryCode.Us;
      break;
    case CountryCode.Jp.toString():
      countryCode = CountryCode.Jp;
      break;
    default:
      break;
  }
  let measurement;
  switch (currentOrg.unitsPref) {
    case MeasurementPreferences.ImperialSystem.toString():
      measurement = MeasurementPreferences.ImperialSystem;
      break;
    case MeasurementPreferences.MetricSystem.toString():
      measurement = MeasurementPreferences.MetricSystem;
      break;
    default:
      break;
  }
  let language;
  switch (currentOrg.language) {
    case LanguagePreferences.En.toString():
      language = LanguagePreferences.En;
      break;
    case LanguagePreferences.Ja.toString():
      language = LanguagePreferences.Ja;
      break;
    default:
      break;
  }
  const payload = {
    id: currentOrg.path,
    adminNotes: currentOrg.adminNotes,
    name: currentOrg.systemName,
    preferences: {
      defaultLanguage: language,
      defaultTimezone: currentOrg.timezone,
      defaultCountry: currentOrg.country,
      defaultCountryCode: countryCode,
      defaultNotificationsEnabled: currentOrg.notificationsEnabled,
      defaultNotificationsChannels: notificationsChannels,
      defaultUnits: measurement,
      allDefaultPreferences,
    },
    names,
    entitlements: ents,
  };
  return { payload, error: '' };
};

export default updateOrganizationPayload;
