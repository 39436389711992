import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Dropdown from '../../CommonComponents/Form/Dropdown';
import {
  countries,
  INHERITED_DEFAULT_NAME,
  INHERITED_DEFAULT_VALUE,
  languages,
  notificationsChannels,
  notificationsEnabled,
  timezones,
  unitsPref,
} from '../constants';
import {
  GetOrganizationQuery,
  GetUserQuery,
  Product,
  useGetEntitlementsStructureQuery,
  useGetUserDominoFeaturesQuery,
  useGetUserDominoJpQuery,
  useGetUserDominoUsQuery,
  useGetUserQuery,
  useGetUserReadyFeaturesQuery,
  useUpdateUserMutation,
} from '../../../__generated__/graphql';
import MultipleSelect from '../../CommonComponents/MultiSelect';
import { getErrorFromGraphqlError } from '../../../util/errors';
import convertUserResponse from './utils/converter';
import { getUserDetailsPayload } from './utils/payloads';
import ProductsList from '../../CommonComponents/ProductsList';
import {
  createOrgEntsPayload,
  createProductItems,
} from '../Organizations/OrganizationsTab/utils/converter';

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type UsersState = { view: string; userIdDetails: string };
type UserDetails = {
  userId: string;
  handleState: (handleState: UsersState) => void;
  onUpdateOrgData: () => void;
  org: GetOrganizationQuery['getOrganization'];
};
type UserDetailsState = {
  id: string;
  title: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  phoneNumber: string;
  country: string;
  language: string;
  timezone: string;
  units: string;
  notificationsEnabled: string;
  notificationsChannels: Array<string>;
  enable: boolean;
  entitlements?: GetUserQuery['getUser']['entitlements'];
  products?: Array<ProductItemProps>;
};

const UserDetails: React.FC<UserDetails> = ({ userId, org, handleState, onUpdateOrgData }) => {
  const [currentUser, setCurrentUser] = useState<UserDetailsState>(null);
  const [displayError, setDisplayError] = useState('');
  const [disableSave, setDisableSave] = useState(true);
  const [updateUserMutation] = useUpdateUserMutation();

  const { data, loading, error } = useGetUserQuery({
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const {
    data: dataDominoUs,
    loading: loadingDominoUs,
    error: errorDominoUs,
  } = useGetUserDominoUsQuery({
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const {
    data: dataDominoJp,
    loading: loadingDominoJp,
    error: errorDominoJp,
  } = useGetUserDominoJpQuery({
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const {
    data: dataDominoFeatures,
    loading: loadingDominoFeatures,
    error: errorDominoFeatures,
  } = useGetUserDominoFeaturesQuery({
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });
  const {
    data: dataReadyFeatures,
    loading: loadingReadyFeatures,
    error: errorReadyFeatures,
  } = useGetUserReadyFeaturesQuery({
    fetchPolicy: 'no-cache',
    variables: { id: userId },
  });

  const {
    data: dataEntitlementsStruct,
    loading: loadingEntitlementsStruct,
    error: errorEntitlementsStruct,
  } = useGetEntitlementsStructureQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (
      data &&
      dataDominoUs &&
      dataDominoJp &&
      dataDominoFeatures &&
      dataReadyFeatures &&
      dataEntitlementsStruct
    ) {
      const newUser = convertUserResponse({
        data,
        dataDominoUs: dataDominoUs.getUser.entitlements,
        dataDominoJp: dataDominoJp.getUser.entitlements,
        dataDominoFeatures: dataDominoFeatures.getUser.entitlements,
        dataReadyFeatures: dataReadyFeatures.getUser.entitlements,
      });
      const newProducts = createProductItems(
        dataEntitlementsStruct.entitlementsStructure,
        newUser.entitlements,
        { editUser: true },
      );
      newUser.products = newProducts;
      setCurrentUser(newUser);
    }
  }, [
    data,
    dataDominoUs,
    dataDominoJp,
    dataDominoFeatures,
    dataReadyFeatures,
    dataEntitlementsStruct,
  ]);
  // }, []);
  console.log('currentUser', currentUser);

  const handleDisableSave = () => {
    if (disableSave) {
      setDisableSave(false);
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setCurrentUser({ ...currentUser, [name]: value });
    handleDisableSave();
  };

  const onDropdownChange = (e: { name: string; value: string }) => {
    const { name, value } = e;
    setCurrentUser({ ...currentUser, [name]: value });
    handleDisableSave();
  };

  const onMultiSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { name },
    } = event;
    let {
      target: { value },
    } = event;

    if (value.length) {
      const lastAdded = value[value.length - 1];
      if (lastAdded === INHERITED_DEFAULT_VALUE) {
        value = [INHERITED_DEFAULT_VALUE];
      } else if (value.length > 1) {
        if (typeof value !== 'string') {
          const exists = value.find((v) => v === INHERITED_DEFAULT_VALUE);
          if (exists) {
            value = value.filter((v) => v !== INHERITED_DEFAULT_VALUE);
          }
        }
      }
    }

    setCurrentUser({
      ...currentUser,
      [name]: typeof value === 'string' ? value.split(',') : value,
    });
    handleDisableSave();
  };

  const onProductsChange = (value: Array<ProductItemProps>) => {
    setCurrentUser({ ...currentUser, products: value });
    handleDisableSave();
  };

  const onSubmit = () => {
    const payload = getUserDetailsPayload(currentUser);
    const { ents } = createOrgEntsPayload(currentUser.products);
    payload.userInput.entitlements = ents;
    updateUserMutation({ variables: payload })
      .then(() => {
        onUpdateOrgData();
      })
      .catch((e) => {
        console.log('error', e.graphQLErrors[0].message);
        const convertedError = getErrorFromGraphqlError(e);
        setDisplayError(convertedError);
      });
  };

  if (
    loading ||
    loadingDominoUs ||
    loadingDominoJp ||
    loadingDominoFeatures ||
    loadingReadyFeatures ||
    loadingEntitlementsStruct
  ) {
    return <div>Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  const convertedErrorDominoUs = getErrorFromGraphqlError(errorDominoUs);
  const convertedErrorDominoJp = getErrorFromGraphqlError(errorDominoJp);
  const convertedErrorDominoFeatures = getErrorFromGraphqlError(errorDominoFeatures);
  const convertedErrorReadyFeatures = getErrorFromGraphqlError(errorReadyFeatures);
  const convertedErrorEntitlementsStruct = getErrorFromGraphqlError(errorEntitlementsStruct);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }
  if (convertedErrorDominoUs) {
    return <div>{convertedErrorDominoUs}</div>;
  }
  if (convertedErrorDominoJp) {
    return <div>{convertedErrorDominoJp}</div>;
  }
  if (convertedErrorDominoFeatures) {
    return <div>{convertedErrorDominoFeatures}</div>;
  }
  if (convertedErrorReadyFeatures) {
    return <div>{convertedErrorReadyFeatures}</div>;
  }
  if (convertedErrorEntitlementsStruct) {
    return <div>{convertedErrorEntitlementsStruct}</div>;
  }

  if (!currentUser) {
    return null;
  }

  return (
    <div>
      <Grid container justifyContent="flex-end">
        <IconButton onClick={() => handleState({ userIdDetails: '', view: 'usersList' })}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </Grid>
      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField fullWidth disabled label="Id" name="id" value={currentUser.id} />
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth disabled label="Status" name="status" value={currentUser.status} />
          </Grid>
        </Grid>
      </Box>

      <Typography
        sx={{ marginLeft: '10px', marginTop: '30px' }}
        variant="subtitle1"
        gutterBottom
        component="div"
      >
        Personal Info
      </Typography>
      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4}>
            <TextField
              label="First Name"
              name="firstName"
              fullWidth
              value={currentUser.firstName}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentUser.firstName ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-disabled"
              label="Last Name"
              name="lastName"
              fullWidth
              value={currentUser.lastName}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentUser.lastName ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="outlined-disabled"
              label="Title"
              name="title"
              fullWidth
              value={currentUser.title}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentUser.title ? null : '#D0D0D0' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginLeft: '10px', marginBottom: '10px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <TextField
              id="outlined-disabled"
              label="Email"
              name="email"
              fullWidth
              value={currentUser.email}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentUser.email ? null : '#D0D0D0' },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="outlined-disabled"
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              value={currentUser.phoneNumber}
              onChange={(e) => onInputChange(e)}
              InputLabelProps={{
                style: { color: currentUser.phoneNumber ? null : '#D0D0D0' },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ width: '100%', marginTop: '30px' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Typography
              sx={{ marginLeft: '10px' }}
              variant="subtitle1"
              gutterBottom
              component="div"
            >
              User Preferences
            </Typography>
            <Dropdown
              fullWidth
              selectedValue={currentUser.country}
              onChange={onDropdownChange}
              name="country"
              label="Country"
              list={[
                ...countries,
                { name: INHERITED_DEFAULT_NAME, value: INHERITED_DEFAULT_VALUE },
              ]}
            />
            <Dropdown
              fullWidth
              selectedValue={currentUser.timezone}
              onChange={onDropdownChange}
              name="timezone"
              label="Timezone"
              list={[
                ...timezones,
                { name: INHERITED_DEFAULT_NAME, value: INHERITED_DEFAULT_VALUE },
              ]}
            />
            <Dropdown
              fullWidth
              selectedValue={currentUser.language}
              onChange={onDropdownChange}
              name="language"
              label="Language"
              list={[
                ...languages,
                { name: INHERITED_DEFAULT_NAME, value: INHERITED_DEFAULT_VALUE },
              ]}
            />
            <Dropdown
              fullWidth
              selectedValue={currentUser.units}
              onChange={onDropdownChange}
              name="units"
              label="Units"
              list={[
                ...unitsPref,
                { name: INHERITED_DEFAULT_NAME, value: INHERITED_DEFAULT_VALUE },
              ]}
            />
            <Dropdown
              fullWidth
              selectedValue={currentUser.notificationsEnabled}
              onChange={onDropdownChange}
              name="notificationsEnabled"
              label="READY notifications enabled"
              list={[
                ...notificationsEnabled,
                { name: INHERITED_DEFAULT_NAME, value: INHERITED_DEFAULT_VALUE },
              ]}
            />

            <MultipleSelect
              name="notificationsChannels"
              label="Notifications channels"
              value={currentUser.notificationsChannels}
              fullWidth
              options={[...notificationsChannels, INHERITED_DEFAULT_VALUE]}
              onChange={(e) => {
                onMultiSelectChange(e);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ marginLeft: '10px' }}
              variant="subtitle1"
              gutterBottom
              component="div"
            >
              Organization Default
            </Typography>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                disabled
                fullWidth
                label="Country"
                name="id"
                value={org.preferences.defaultCountry}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                disabled
                fullWidth
                label="Timezone"
                name="id"
                value={org.preferences.defaultTimezone}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                disabled
                fullWidth
                label="Language"
                name="id"
                value={org.preferences.defaultLanguage}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                disabled
                fullWidth
                label="Units"
                name="id"
                value={org.preferences.defaultUnits}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                disabled
                fullWidth
                label="READY notifications enabled"
                name="id"
                value={org.preferences.defaultNotificationsEnabled}
              />
            </FormControl>
            <FormControl sx={{ m: 1 }} fullWidth>
              <TextField
                disabled
                fullWidth
                label="Notifications channels"
                name="id"
                value={org.preferences.defaultNotificationsChannels.toString()}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12}>
          <Typography
            sx={{ marginLeft: '10px', marginTop: '30px' }}
            variant="subtitle1"
            gutterBottom
            component="div"
          >
            Entitlements
          </Typography>
          <ProductsList value={currentUser.products} onChange={onProductsChange} forUser />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={2} justifyContent="flex-end">
        <Button
          variant="outlined"
          onClick={() => {
            handleState({ userIdDetails: '', view: 'usersList' });
          }}
        >
          Cancel
        </Button>
        <Button disabled={disableSave} variant="contained" onClick={() => onSubmit()}>
          Save
        </Button>
      </Stack>
      {displayError ? (
        <InputLabel sx={{ marginTop: '10px', color: 'red' }}>{displayError}</InputLabel>
      ) : null}
    </div>
  );
};

UserDetails.displayName = 'UserDetails';
export default UserDetails;
