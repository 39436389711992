import { GetOrganizationQuery, GetUserQuery, Product } from '../../../../__generated__/graphql';
import { INHERITED_DEFAULT_NAME, INHERITED_DEFAULT_VALUE } from '../../constants';

type ProductItemProps = {
  product?: {
    options: Array<{ key: Product; value: string }>;
    selected: { key: string; value: string };
  };
  subscription?: {
    options: {
      DOMINO?: Array<{ key: string; value: string; desc: string }>;
      READY?: Array<{ key: string; value: string; desc: string }>;
    };
    selected: { key: string; value: string; desc: string };
  };
  region?: {
    options: {
      DOMINO?: Array<{ key: string; value: string }>;
      READY: Array<{ key: string; value: string }>;
    };
    selected: Array<{ key: string; value: string }>;
  };
  hazard?: {
    options: {
      DOMINO: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
      READY: Array<{ key: string; label: string; value: boolean; disabled: boolean }>;
    };
  };
  subscriptionOptions?: {
    options: {
      DOMINO: Array<{
        key: string;
        label: string;
        value: boolean;
        desc: string;
        disabled: boolean;
      }>;
      READY: Array<{ key: string; label: string; value: boolean; desc: string; disabled: boolean }>;
    };
  };
  feature?: {
    options: {
      DOMINO: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
      READY: {
        allHazards?: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
        [key: string]: Array<{
          key: string;
          label: string;
          value: boolean;
          desc: string;
          disabled: boolean;
        }>;
      };
    };
  };
};

type UserDetailsState = {
  id: string;
  title: string;
  role: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  phoneNumber: string;
  country: string;
  language: string;
  timezone: string;
  units: string;
  notificationsEnabled: string;
  notificationsChannels: Array<string>;
  enable: boolean;
  entitlements?: GetUserQuery['getUser']['entitlements'];
  products?: Array<ProductItemProps>;
};

const convertUserResponse = (u: {
  data: GetUserQuery;
  dataDominoUs: GetUserQuery['getUser']['entitlements'];
  dataDominoJp: GetUserQuery['getUser']['entitlements'];
  dataDominoFeatures: GetUserQuery['getUser']['entitlements'];
  dataReadyFeatures: GetUserQuery['getUser']['entitlements'];
}): UserDetailsState => {
  const res = JSON.parse(JSON.stringify(u));
  const user = res.data.getUser;
  const userDominoUs = res.dataDominoUs;
  const userDominoJp = res.dataDominoJp;
  const userDominoFeatures = res.dataDominoFeatures;
  const userReadyFeatures = res.dataReadyFeatures;
  let notificationsEnabled = INHERITED_DEFAULT_NAME;
  if (
    user.preferences.notificationsEnabled !== null &&
    user.preferences.notificationsEnabled !== undefined
  ) {
    notificationsEnabled = user.preferences.notificationsEnabled.toString();
  }

  const entitlements: GetOrganizationQuery['getOrganization']['entitlements'] = {
    scopes: [],
    enabledProducts: [],
    enabledProductsModule: [],
    hasHazardsForProduct: [],
    hasFeaturesForProduct: [],
  };

  if (user.entitlements) {
    entitlements.scopes = [...entitlements.scopes, ...user.entitlements.scopes];
    entitlements.enabledProducts = [
      ...entitlements.enabledProducts,
      ...user.entitlements.enabledProducts,
    ];
    entitlements.enabledProductsModule = [
      ...entitlements.enabledProductsModule,
      ...user.entitlements.enabledProductsModule,
    ];
    entitlements.hasHazardsForProduct = [
      ...entitlements.hasHazardsForProduct,
      ...user.entitlements.hasHazardsForProduct,
    ];
    entitlements.hasFeaturesForProduct = [
      ...entitlements.hasFeaturesForProduct,
      ...user.entitlements.hasFeaturesForProduct,
    ];
  }
  if (userDominoUs) {
    entitlements.hasHazardsForProduct = [
      ...entitlements.hasHazardsForProduct,
      ...userDominoUs.hasHazardsForProduct,
    ];
    entitlements.hasFeaturesForProduct = [
      ...entitlements.hasFeaturesForProduct,
      ...userDominoUs.hasFeaturesForProduct,
    ];
  }
  if (userDominoJp) {
    entitlements.hasHazardsForProduct = [
      ...entitlements.hasHazardsForProduct,
      ...userDominoJp.hasHazardsForProduct,
    ];
    entitlements.hasFeaturesForProduct = [
      ...entitlements.hasFeaturesForProduct,
      ...userDominoJp.hasFeaturesForProduct,
    ];
  }
  if (userDominoFeatures) {
    entitlements.hasFeaturesForProduct = [
      ...entitlements.hasFeaturesForProduct,
      ...userDominoFeatures.hasFeaturesForProduct.filter(
        (f: GetUserQuery['getUser']['entitlements']['hasFeaturesForProduct'][0]) => !f.hazard,
      ),
    ];
  }

  if (userReadyFeatures) {
    entitlements.hasFeaturesForProduct = [
      ...entitlements.hasFeaturesForProduct,
      ...userReadyFeatures.hasFeaturesForProduct.filter(
        (f: GetUserQuery['getUser']['entitlements']['hasFeaturesForProduct'][0]) => !f.hazard,
      ),
    ];
  }

  return {
    id: user.id,
    title: user.title,
    role: user.role,
    status: user.status,
    firstName: user.personalInfo.firstName,
    lastName: user.personalInfo.lastName,
    email: user.personalInfo.contact.email,
    phoneNumber: user.personalInfo.contact.phoneNumber,
    country: user.preferences.country || INHERITED_DEFAULT_NAME,
    language: user.preferences.language
      ? user.preferences.language.toString()
      : INHERITED_DEFAULT_NAME,
    timezone: user.preferences.timezone || INHERITED_DEFAULT_NAME,
    units: user.preferences.units || INHERITED_DEFAULT_NAME,
    notificationsEnabled,
    notificationsChannels: user.preferences.notificationsChannels || [INHERITED_DEFAULT_VALUE],
    enable: user.status !== 'Disabled',
    entitlements,
  };
};

export default convertUserResponse;
