import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import dayjs, { Dayjs } from 'dayjs';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { getErrorFromGraphqlError } from '../../../../util/errors';
import DatePicker from '../../../CommonComponents/DatePicker';
import {
  AuditEventOperationType,
  AuditEventResourceType,
  AuditEventType,
  AuditFilter,
  GetUserQuery,
  useGetAuditTrailLazyQuery,
  useGetOrganizationsQuery,
} from '../../../../__generated__/graphql';
import makeId from '../../../../util/utils';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type SnackbarTypes = 'success' | 'error';
type TargetTypes = 'originator' | 'user' | 'organization' | 'permission';

const useStyles = makeStyles({
  container: {
    marginTop: '30px',
  },
});

const Header: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell width="3" align="left">
          Originator
        </TableCell>
        <TableCell width="3" align="left">
          Target
        </TableCell>
        <TableCell width="1" align="left">
          Action
        </TableCell>
        <TableCell width="1" align="left">
          Resource Type
        </TableCell>
        <TableCell width="2" align="left">
          Message
        </TableCell>
        <TableCell width="2" align="left">
          Event Time
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const limit = 10;
let scrollTimeout: NodeJS.Timeout = null;

type AuditTrailType = {
  id?: string;
  status: string;
  eventType: AuditEventType;
  resourceType: AuditEventResourceType;
  operationType?: AuditEventOperationType | null;
  realm: string;
  eventTime: string;
  message?: string | null;
  originator?: (GetUserQuery['getUser'] & { showId?: boolean }) | null;
  target: {
    user?: (GetUserQuery['getUser'] & { showId?: boolean }) | null;
    organization?: ({ id: string; name: string } & { showId?: boolean }) | null;
    permission?: ({ id: string; name?: string } & { showId?: boolean }) | null;
  } | null;
};

const initialData: {
  actions: Array<{ key: string; value: string }>;
} = {
  actions: [
    { key: 'CREATE', value: 'CREATE' },
    { key: 'DELETE', value: 'DELETE' },
    { key: 'UPDATE', value: 'UPDATE' },
    { key: 'ACTION', value: 'ACTION' },
  ],
};

const Audit = () => {
  const classes = useStyles();

  const [filters, setFilters] = useState<{
    byOriginatorUser?: string;
    byTargetUser?: string;
    byOrganization?: { key: string; value: string };
    byAction?: Array<{ key: string; value: string }>;
    byStartDate?: Dayjs | null;
    byEndDate?: Dayjs | null;
  }>({
    byOriginatorUser: '',
    byTargetUser: '',
    byOrganization: null,
    byAction: [],
    byStartDate: dayjs().subtract(30, 'day').startOf('d'),
    byEndDate: dayjs().startOf('d'),
  });
  const navigate = useNavigate();
  const [actions] = useState<Array<{ key: string; value: string }>>(initialData.actions);

  const [auditItems, setAuditItems] = useState<Array<AuditTrailType>>([]);
  const auditItemsRef = useRef(auditItems);
  const offsetRef = useRef(0);
  const [fetchMoreLoading, setFetchMoreLoading] = useState(false);
  const hasMoreRef = useRef(true);

  const [open, setOpen] = useState(false);
  const [auditResp, setAuditResp] = useState<{ type: SnackbarTypes; message: string }>({
    type: 'success',
    message: '',
  });

  const [getAuditTrailLazyQuery, { data }] = useGetAuditTrailLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { data: orgsData, loading: orgsLoading, error: orgsError } = useGetOrganizationsQuery();

  const organizations =
    orgsData?.getOrganizations?.map((o) => ({ key: o.id, value: o.name })) ?? [];

  const showSnackbar = () => {
    setOpen(true);
  };

  const fetchMoreData = () => {
    (async () => {
      const actions = filters.byAction.map((o) => {
        switch (o.key) {
          case AuditEventOperationType.Create.toString():
            return AuditEventOperationType.Create;
          case AuditEventOperationType.Delete.toString():
            return AuditEventOperationType.Delete;
          case AuditEventOperationType.Update.toString():
            return AuditEventOperationType.Update;
          case AuditEventOperationType.Action.toString():
            return AuditEventOperationType.Action;
          default:
            return null;
        }
      });
      const f: AuditFilter = {};
      if (filters.byOriginatorUser) {
        f.byOriginatorUser = filters.byOriginatorUser;
      }
      if (filters.byTargetUser) {
        f.byTargetUser = filters.byTargetUser;
      }
      if (filters.byOrganization) {
        f.byTargetOrganization = filters.byOrganization.key;
      }
      if (filters.byAction.length) {
        f.byAction = actions;
      }
      if (filters.byStartDate) {
        const startDate = filters.byStartDate.format('dddd, MMMM D, YYYY');
        const startDateUTC = new Date(`${startDate} 00:00 UTC`);
        f.byFromDate = startDateUTC.toISOString();
      }
      if (filters.byEndDate) {
        const endDate = filters.byEndDate.format('dddd, MMMM D, YYYY');
        const endDateUTC = new Date(`${endDate} 00:00 UTC`);
        f.byToDate = endDateUTC.toISOString();
      }
      const payload = {
        limit: 10,
        offset: offsetRef.current,
        filters: f,
      };
      console.log('payload', payload);
      getAuditTrailLazyQuery({ variables: payload, fetchPolicy: 'network-only' })
        .then(({ data }) => {
          setFetchMoreLoading(false);
          const newAuditItems = data.auditTrail.map((a) => ({ id: makeId(20), ...a }));
          if (newAuditItems.length < limit) {
            hasMoreRef.current = false;
          }
          offsetRef.current += newAuditItems.length;
          const currentAuditItems = [...auditItemsRef.current, ...newAuditItems];
          setAuditItems(currentAuditItems);
          auditItemsRef.current = currentAuditItems;
        })
        .catch((err) => {
          setFetchMoreLoading(false);
          const convertedError = getErrorFromGraphqlError(err);
          setAuditResp({ type: 'error', message: convertedError });
          showSnackbar();
        });
    })();
  };

  const onScroll = useCallback(
    (event) => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        const { clientHeight, scrollHeight } = event.srcElement.body;
        if (window.scrollY + clientHeight >= scrollHeight) {
          if (hasMoreRef.current) {
            setFetchMoreLoading(true);
            fetchMoreData();
          }
        }
      }, 300);
    },
    [data, fetchMoreData], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [onScroll]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const {
      target: { name, value },
    } = e;
    setFilters({ ...filters, [name]: value });
  };

  const onAutocompleteChange = (
    name: string,
    value: string | { key: string; value: string } | Array<string | { key: string; value: string }>,
  ) => {
    setFilters({ ...filters, [name]: value });
  };

  const onDatePickerChange = (name: string, value: Dayjs | null) => {
    setFilters({ ...filters, [name]: value });
  };

  const showItemId = (target: TargetTypes, eventId: string) => {
    let newAuditItems: Array<AuditTrailType> = [];
    switch (target) {
      case 'originator':
        newAuditItems = auditItems.map((a) =>
          a.id === eventId ? { ...a, originator: { ...a.originator, showId: true } } : { ...a },
        );
        break;
      case 'user':
        newAuditItems = auditItems.map((a) =>
          a.id === eventId
            ? { ...a, target: { ...a.target, user: { ...a.target.user, showId: true } } }
            : { ...a },
        );
        break;
      case 'organization':
        newAuditItems = auditItems.map((a) =>
          a.id === eventId
            ? {
                ...a,
                target: { ...a.target, organization: { ...a.target.organization, showId: true } },
              }
            : { ...a },
        );
        break;
      case 'permission':
        newAuditItems = auditItems.map((a) =>
          a.id === eventId
            ? {
                ...a,
                target: { ...a.target, permission: { ...a.target.permission, showId: true } },
              }
            : { ...a },
        );
        break;
      default:
        break;
    }
    setAuditItems(newAuditItems);
    auditItemsRef.current = newAuditItems;
  };

  const displayData = () => {
    return (
      <>
        {auditItems.map((row) => {
          const originator = [
            <div>
              <span
                style={{
                  fontWeight: row.originator.showId ? 'bold' : '',
                  cursor: row.originator.showId ? '' : 'pointer',
                  color: row.originator.showId ? '' : 'blue',
                }}
                onClick={() => {
                  if (!row.originator.showId) {
                    showItemId('originator', row.id);
                  }
                }}
              >
                {row.originator.showId ? 'Id:' : 'Id'}
              </span>
              {row.originator.showId ? row.originator.id : ''}
            </div>,
            <div>
              <span style={{ fontWeight: 'bold' }}>Email:</span>{' '}
              {row.originator.personalInfo.contact.email}
            </div>,
          ];
          const target = [];
          if (row.target.user) {
            target.push(
              <div>
                <span
                  style={{
                    fontWeight: row.target.user.showId ? 'bold' : '',
                    cursor: row.target.user.showId ? '' : 'pointer',
                    color: row.target.user.showId ? '' : 'blue',
                  }}
                  onClick={() => {
                    if (!row.target.user.showId) {
                      showItemId('user', row.id);
                    }
                  }}
                >
                  {row.target.user.showId ? 'UserId:' : 'UserId'}
                </span>
                {row.target.user.showId ? row.target.user.id : ''}
              </div>,
            );
            target.push(
              <div>
                <span style={{ fontWeight: 'bold' }}>Email:</span>{' '}
                {row.target.user.personalInfo.contact.email}
              </div>,
            );
            target.push(
              <div>
                <span style={{ fontWeight: 'bold' }}>FirstName:</span>{' '}
                {row.target.user.personalInfo.firstName}
              </div>,
            );
            target.push(
              <div>
                <span style={{ fontWeight: 'bold' }}>LastName:</span>{' '}
                {row.target.user.personalInfo.lastName}
              </div>,
            );
            target.push(
              <div>
                <span style={{ fontWeight: 'bold' }}>Status:</span> {row.target.user.status}
              </div>,
            );
          }
          if (row.target.organization) {
            if (target.length) {
              target.push(<Divider style={{ marginTop: '5px', marginBottom: '5px' }} />);
            }
            target.push(
              <div>
                <span style={{ fontWeight: 'bold' }}>OrganizationName:</span>
                <span
                  style={{ cursor: 'pointer', color: 'blue' }}
                  onClick={() =>
                    navigate(`/orgs/${encodeURIComponent(row.target.organization.id)}`)
                  }
                >
                  {row.target.organization.name}
                </span>
              </div>,
            );
          }
          if (row.target.permission) {
            if (target.length) {
              target.push(<Divider style={{ marginTop: '5px', marginBottom: '5px' }} />);
            }
            target.push(
              <div>
                <span
                  style={{
                    fontWeight: row.target.permission.showId ? 'bold' : '',
                    cursor: row.target.permission.showId ? '' : 'pointer',
                    color: row.target.permission.showId ? '' : 'blue',
                  }}
                  onClick={() => {
                    if (!row.target.permission.showId) {
                      showItemId('permission', row.id);
                    }
                  }}
                >
                  {row.target.permission.showId ? 'PermissionId:' : 'PermissionId'}
                </span>
                {row.target.permission.showId ? row.target.permission.id : ''}
              </div>,
            );
            target.push(
              <div>
                <span style={{ fontWeight: 'bold' }}>PermissionName:</span>{' '}
                {row.target.permission.name}
              </div>,
            );
          }

          const dateTime = dayjs(row.eventTime).format('DD/MM/YYYY HH:mm');

          return (
            <TableRow
              data-test-id="users-row"
              hover
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{originator}</TableCell>
              <TableCell align="left">{target}</TableCell>
              <TableCell align="left">{row.operationType}</TableCell>
              <TableCell align="left">{row.resourceType}</TableCell>
              <TableCell align="left">{row.message}</TableCell>
              <TableCell align="left">{dateTime}</TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };

  const closeSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setAuditResp({ type: 'success', message: '' });
    setOpen(false);
  };

  const onSearchUsers = () => {
    offsetRef.current = 0;
    auditItemsRef.current = [];
    setAuditItems([]);
    hasMoreRef.current = true;
    setFetchMoreLoading(true);
    fetchMoreData();
  };

  if (orgsLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress sx={{ margin: '15px' }} />
      </Box>
    );
  }

  const convertedOrgError = getErrorFromGraphqlError(orgsError);
  if (convertedOrgError) {
    return <div>{convertedOrgError}</div>;
  }

  return (
    <Container maxWidth="md" className={classes.container}>
      <Snackbar open={open} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={auditResp.type} sx={{ width: '100%' }}>
          {auditResp.message}
        </Alert>
      </Snackbar>
      <Paper sx={{ width: '1000px', height: 'auto', overflow: 'hidden' }}>
        <Box sx={{ width: '100%', margin: '10px' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item xs={4}>
              <TextField
                id="OriginatorUser"
                label="Originator User"
                name="byOriginatorUser"
                fullWidth
                value={filters.byOriginatorUser}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: filters.byOriginatorUser ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'OriginatorUser-Input',
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="TargetUser"
                label="Target User"
                name="byTargetUser"
                fullWidth
                value={filters.byTargetUser}
                onChange={(e) => onInputChange(e)}
                InputLabelProps={{
                  style: { color: filters.byTargetUser ? null : '#D0D0D0' },
                }}
                inputProps={{
                  'data-test-id': 'TargetUser-Input',
                }}
              />
            </Grid>
            <Grid style={{ paddingLeft: '10px', paddingRight: '16px' }} item xs={4}>
              <Autocomplete
                id="byOrganization"
                data-test-id="Organization-Select"
                options={organizations}
                freeSolo
                getOptionLabel={(option: { key: string; value: string }) => option.value}
                renderTags={(value: Array<{ key: string; value: string }>, getTagProps) =>
                  value.map((option: { key: string; value: string }, index: number) => (
                    <Chip
                      key={option.key}
                      variant="outlined"
                      label={option.value}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                value={filters.byOrganization}
                onChange={(e, value) => onAutocompleteChange('byOrganization', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Target Organization"
                    placeholder="Select Target Organization"
                    InputLabelProps={{
                      style: { color: filters.byOrganization ? null : '#D0D0D0' },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            style={{ marginTop: '10px' }}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={3}>
              <Autocomplete
                multiple
                options={actions}
                getOptionLabel={(option: { key: string; value: string }) => option.value}
                freeSolo
                id="byAction"
                data-test-id="Action-Select"
                value={filters.byAction}
                renderTags={(value: Array<{ key: string; value: string }>, getTagProps) =>
                  value.map((option: { key: string; value: string }, index: number) => (
                    <Chip
                      key={option.key}
                      variant="outlined"
                      label={option.value}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                onChange={(e, value) => onAutocompleteChange('byAction', value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Actions"
                    placeholder="Select Actions"
                    InputLabelProps={{
                      style: { color: filters.byAction ? null : '#D0D0D0' },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid style={{ paddingLeft: '10px' }} item xs={3}>
              <DatePicker
                id="fromDate"
                label="From Date"
                value={filters.byStartDate}
                handleChange={(e) => onDatePickerChange('byStartDate', e)}
                data-test-id="From-DatePicker"
              />
            </Grid>
            <Grid style={{ paddingLeft: '10px' }} item xs={3}>
              <DatePicker
                id="toDate"
                label="To Date"
                value={filters.byEndDate}
                handleChange={(e) => onDatePickerChange('byEndDate', e)}
                data-test-id="To-DatePicker"
              />
            </Grid>
            <Grid style={{ paddingLeft: '0px', paddingRight: '24px' }} item xs={3}>
              <FormControl
                fullWidth
                sx={{ m: 1, marginTop: '10px', marginBottom: '15px' }}
                variant="standard"
              >
                <Button
                  disabled={fetchMoreLoading}
                  variant="contained"
                  onClick={onSearchUsers}
                  data-test-id="Search-Btn"
                >
                  Search
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <TableContainer sx={{ maxHeight: 'auto', marginTop: '10px' }}>
          <Table stickyHeader aria-label="Audit Table" data-test-id="AuditTable">
            <Header />
            <TableBody>{displayData()}</TableBody>
          </Table>
          {fetchMoreLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress sx={{ margin: '15px' }} />
            </div>
          ) : null}
        </TableContainer>
      </Paper>
    </Container>
  );
};

Audit.displayName = 'Audit';
export default Audit;
