import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Product, useGetOrganizationsQuery } from '../../../../__generated__/graphql';
import { getErrorFromGraphqlError } from '../../../../util/errors';

const useStyles = makeStyles({
  container: {
    marginTop: '30px',
  },
});

const Header: React.FC = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell width="40%" align="left">
          Id
        </TableCell>
        <TableCell width="40%" align="left">
          Name
        </TableCell>
        <TableCell width="20%" align="left">
          Profile
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const OrganizationsTab = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { data, loading, error } = useGetOrganizationsQuery({
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <div data-test-id="organizations-loading">Loading..</div>;
  }

  const convertedError = getErrorFromGraphqlError(error);
  if (convertedError) {
    return <div>{convertedError}</div>;
  }

  const orgs = data?.getOrganizations ?? [];
  const displayData = () => {
    return (
      <>
        {orgs.map((row) => {
          let displayProfile;
          if (row.entitlements?.enabledProducts) {
            switch (row.entitlements.enabledProducts.length) {
              case 1:
                switch (row.entitlements.enabledProducts[0]) {
                  case Product.Domino:
                    displayProfile = 'D';
                    break;
                  case Product.Ready:
                    displayProfile = 'R';
                    break;
                  default:
                    break;
                }
                break;
              case 2:
                displayProfile = 'DR';
                break;
              default:
            }
          }
          return (
            <TableRow
              hover
              key={row.id}
              onClick={() => navigate(`/orgs/${encodeURIComponent(row.id)}`)}
              data-test-id={`Organization-${row.id}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{row.id}</TableCell>
              <TableCell align="left">{row.name}</TableCell>
              {displayProfile ? (
                <TableCell align="left">
                  <Avatar
                    alt="Remy Sharp"
                    src={`https://ui-avatars.com/api/?name=${displayProfile}&bold=true&background=a0a0a0`}
                    variant="rounded"
                  />
                </TableCell>
              ) : (
                <TableCell align="left" />
              )}
            </TableRow>
          );
        })}
      </>
    );
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <Stack marginBottom="10px" direction="row" justifyContent="flex-end">
        <Button
          data-test-id="CreateOrganization-Btn"
          size="small"
          onClick={() => navigate('/orgs/new')}
        >
          + Add Organization
        </Button>
      </Stack>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader aria-label="sticky table">
            <Header />
            <TableBody>{displayData()}</TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

OrganizationsTab.displayName = 'OrganizationsTab';
export default OrganizationsTab;
